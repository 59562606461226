import React, { ReactNode } from 'react';
import { PolicyIssuingBanner, SupportEmailLinkTag } from 'policy-issuing/common/policy-issuing-banner';
import { WarningIcon } from 'assets/warning-icon';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface PolicyIssuingErrorBannerParams {
  body: ReactNode | string;
}

export const PolicyIssuingErrorBanner = (params: PolicyIssuingErrorBannerParams) => {
  const { siteConfig } = useSiteConfigContext();
  const { body } = params;

  return (
    <PolicyIssuingBanner>
      <span>
        <WarningIconWrapper>
          <WarningIcon siteConfig={siteConfig} />
        </WarningIconWrapper>
        <div>
          {body}&nbsp;
          <SupportEmailLinkTag siteConfig={siteConfig} />.
        </div>
      </span>
    </PolicyIssuingBanner>
  );
};

const WarningIconWrapper = styled.div`
  align-items: center;
  width: 80px;
`;
