import qs from 'qs';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
// @ts-ignore no typings
import phone from 'phone';
import { formatToCurrency } from 'rootstrap/components/forms/new-fields/utils/currency-format';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { getPolicyBillingAbbreviation, PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { Countries } from 'rootstrap/components/forms/countries';

// import { DataTableProps, DataTableColumn } from .//data-table/data-table';
// import { Policyholder, PolicyholderType } from '../policyholders/domain/policyholder';
// import { BeneficiaryEntityType } from '../beneficiaries/domain/beneficiary-entity-type';
// import { Beneficiary } from '../beneficiaries/domain/beneficiary';

export type JSONPrimitive = string | number | boolean | null | undefined;
export type JSONObject = { [member: string]: JSONValue };
export type JSONArray = Array<JSONValue>;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const getCurrentUrl = () => {
  return window.location.href;
};

export const getCurrentUrlParams = () => {
  const parsedQueryParams = qs.parse(getCurrentUrl().split('?')[1]);
  return parsedQueryParams;
};

export const sum = (...args: number[]) => {
  return args.reduce((acc, curr) => acc + curr, 0);
};

export const buildApiUrl = (url: string, queryPrams: JSONObject) => {
  // Use comma as array format on Platform, indices on Dashboard.
  return `${url}${`?${qs.stringify(queryPrams, { arrayFormat: 'comma', encode: false })}`}`;
};

export const buildDashboardUrl = (url: string, queryPrams: JSONObject) => {
  // Use comma as array format on Platform, indices on Dashboard.
  return `${url}${`?${qs.stringify(queryPrams, { arrayFormat: 'indices', encode: false })}`}`;
};

export const tryCatch = async <R,>(
  f: (...args: any[]) => R | Promise<R>,
): Promise<{ result: R; error?: undefined } | { result?: undefined; error: any }> => {
  try {
    const result = await f();
    return {
      result,
      error: undefined,
    };
  } catch (error) {
    return {
      result: undefined,
      error,
    };
  }
};

export const parseCellphoneNumber = (number: string, countryCode?: string) => {
  const ph = parsePhoneNumber(number, countryCode as any);

  if (countryCode && countryCode !== ph?.country) {
    throw new Error('Invalid country code supplied');
  }

  if (!ph?.country) {
    throw new Error('Unable to determine country code');
  }

  if (!ph.number) {
    throw new Error('Unable to determine international number');
  }

  const value: any = {
    number,
    countryCode: ph.country,
    internationalNumber: ph.number.toString(),
  };

  return value;
};

export const isValidCellphoneNumber = (number: string, countryCode?: string) => {
  if (
    !phone(number, {
      country: countryCode,
    }).countryIso2
  ) {
    return false;
  }

  return isValidPhoneNumber(number, countryCode as any);
};

export const sentenceify = (message: string) => {
  const s = message;
  return s.endsWith('.') ? `${s}` : `${s}.`;
};

// export const mapBeneficiaries = ({
//   beneficiaries,
//   policyholder,
// }: {
//   beneficiaries: Beneficiary[];
//   policyholder?: Policyholder;
// }) => {
//   const entityTypeMap: { [key in PolicyholderType]: BeneficiaryEntityType } = {
//     [PolicyholderType.Individual]: BeneficiaryEntityType.Individual,
//     [PolicyholderType.Company]: BeneficiaryEntityType.Company,
//   };

//   return beneficiaries.map((b) => {
//     return b.policyholderId === policyholder?.policyholderId
//       ? new Beneficiary({
//           ...b,
//           firstName: policyholder?.firstName,
//           lastName: policyholder?.lastName,
//           email: policyholder?.email,
//           id: policyholder?.identification,
//           entityType: b.entityType || (policyholder ? entityTypeMap[policyholder.type] : undefined),
//         })
//       : b;
//   });
// };

export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const extractYoutubeVideoId = (youtubeUrl: string | undefined) => {
  if (!youtubeUrl) {
    return '';
  }
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = youtubeUrl.match(regExp);

  const trimmedId = match && match[7].length === 11 ? match[7] : false;
  return trimmedId ? trimmedId : youtubeUrl;
};

export enum PrefillInputTypes {
  Quote = 'quote_values',
  PersonalDetails = 'personal_details_values',
  Payment = 'payment_values',
  Application = 'application_values',
}

export const parsePrefillParams = (queryParams: any, prefillType: PrefillInputTypes) => {
  const input = queryParams[prefillType];

  if (input) {
    try {
      return JSON.parse(input);
    } catch (error) {
      console.error(`There was an error parsing ${prefillType}: ${error}`);
    }
  }

  return {};
};

export const getPremiumDisplay = (params: {
  premiumValue: number | undefined;
  currency: Currency | undefined;
  billingFrequency: PolicyBillingFrequency | undefined;
}) => {
  const { premiumValue, currency, billingFrequency } = params;
  return premiumValue && billingFrequency && currency
    ? `${formatToCurrency({
        number: premiumValue,
        currencyOptions: {
          currencyCode: currency,
          excludeSymbol: false,
          excludeCents: false,
        },
      })} ${getPolicyBillingAbbreviation(billingFrequency)}`
    : '-';
};

export const getDefaultCountryCode = () => {
  const defaultCountryEnv = process.env.REACT_APP_DEFAULT_COUNTRY_CODE;

  if (defaultCountryEnv) {
    return Countries.find(({ code }) => defaultCountryEnv === code)?.code || 'ZA';
  }

  try {
    const [, countryCode] = navigator.language.split('-');

    return Countries.find(({ code }) => countryCode === code)?.code || 'ZA';
  } catch {
    return 'ZA';
  }
};

export const getDateFormat = () => {
  const countryCode = getDefaultCountryCode();

  if (countryCode === 'GB') {
    return 'DD-MM-YYYY';
  }

  return 'YYYY-MM-DD';
};
