import React from 'react';
import { Input, InputProps } from 'reactstrap';
import styled, { css } from 'styled-components';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { globalStyles } from 'rootstrap/global-styles';
import { getSteppedFormLabelColor } from './utils/stepped-form';
import { NumberFormatBase } from 'react-number-format';
import { FormatInputValueFunction } from 'react-number-format/types/types';
import { Svg } from 'rootstrap/components/svg';

export const styledInputComponentContents = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement?: boolean;
  isTouched: boolean;
  prefix?: string;
}) => {
  const { isActive, isTouched, siteConfig, prefix } = params;

  const prefixStyles = prefix
    ? `
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;`
    : '';

  return `
  height: ${globalStyles.inputHeight.default} !important;
  line-height: ${globalStyles.lineHeight.default};
  padding-left: 18px !important;
  font-size: ${globalStyles.fontSize.body} !important;
  border: 1px solid !important;
  border-radius: ${globalStyles.borderRadius.default} !important;
  cursor: pointer;
  box-shadow: none !important;
  color: ${getSteppedFormLabelColor({
    isActive: isTouched || isActive,
    siteConfig,
    color: globalStyles.colors.Body,
  })} !important;
  
  border-color: ${
    isActive || isTouched ? globalStyles.colors.Body : getColor({ siteConfig, color: 'disabled' })
  } !important;
  box-shadow: none !important;
  
  &.focus,
  &:focus {
    box-shadow: none !important;
  }
  
  .invalid-feedback {
    margin-top: 12px !important;
    display: flex !important;
    color: ${getColor({ siteConfig, color: 'highlight' })} !important;
  }
  
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${getColor({ siteConfig, color: 'disabled' })} !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${getColor({ siteConfig, color: 'disabled' })} !important;
  }
  ::placeholder {
    color: ${getColor({ siteConfig, color: 'disabled' })} !important;
  }

${prefixStyles}
  `;
};

export const StyledInputComponent = styled(Input)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement?: boolean;
  isTouched: boolean;
  prefix?: string;
}>`
  ${({ isActive, isTouched, siteConfig, disableActiveElement, prefix }) =>
    styledInputComponentContents({
      prefix,
      isActive,
      isTouched,
      siteConfig,
      disableActiveElement,
    })}
`;

export const StyledReactNumberComponent = styled(NumberFormatBase)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement?: boolean;
  isTouched: boolean;
}>`
  width: 100%;

  ${({ isActive, isTouched, siteConfig, disableActiveElement }) =>
    styledInputComponentContents({
      isActive,
      isTouched,
      siteConfig,
      disableActiveElement,
    })}

  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  &:focus {
    border-color: ${globalStyles.colors.Body})};
    box-shadow: none !important;
    outline: none;
  }
`;

export const StyledInputWrapper = styled.div<{
  warningColor: string;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  isTouched: boolean;
  icon?: string;
}>`
  width: 100%;
  cursor: pointer;
  position: relative;

  ${({ warningColor, siteConfig, isActive, isTouched }) => `  
    .is-invalid {
      ${warningSvgString(warningColor)}
      border: 1px solid ${
        isActive ? globalStyles.colors.Body : `${getColor({ siteConfig, color: 'border' })} !important`
      };
      border-color: ${
        isActive
          ? globalStyles.colors.Body
          : `${!isTouched ? getColor({ siteConfig, color: 'border' }) : globalStyles.colors.Body} !important`
      } !important;
      box-shadow:none !important;
    }
  `};

  ${({ icon }) => `  
    ${
      icon &&
      `input {
      padding-left: 81px !important;
    }`
    }
`};

  .input-icon {
    position: absolute;
    height: calc(100% - 2px);
    padding-left: 31px;
    padding-right: 31px;
    top: 1px;
    border-right: 1px solid rgb(205, 212, 222);
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-anchor {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        position: absolute;
      }
    }
  }
`;

export const StyledInput = (props: InputProps & { disableActiveElement: boolean | undefined; icon?: string }) => {
  const { siteConfig } = useSiteConfigContext();
  const warningColor = getColor({ siteConfig, color: 'warning' });

  return (
    <StyledInputWrapper
      isTouched={props.isTouched}
      isActive={props.isActive}
      warningColor={warningColor}
      siteConfig={siteConfig}
      icon={props.icon}
    >
      {props.icon && (
        <div className='input-icon'>
          <div className='icon-anchor'>{<Svg href={props.icon} />}</div>
        </div>
      )}
      <StyledInputComponent
        {...props}
        isTouched={props.isTouched}
        isActive={props.isActive}
        disableActiveElement={props.disableActiveElement}
        siteConfig={siteConfig}
      />
    </StyledInputWrapper>
  );
};

export const StyledReactNumberInput = (props: InputProps & { format: FormatInputValueFunction }) => {
  const { siteConfig } = useSiteConfigContext();
  const warningColor = getColor({ siteConfig, color: 'warning' });
  const { format, onChange, onKeyDown, onFocus, disabled, onWheel, onBlur, onClick } = props;

  return (
    <StyledInputWrapper
      isTouched={props.isTouched}
      isActive={props.isActive}
      warningColor={warningColor}
      siteConfig={siteConfig}
    >
      <StyledReactNumberComponent
        isTouched={props.isTouched}
        format={format}
        isActive={props.isActive}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        onWheel={onWheel}
        onBlur={onBlur}
        onClick={onClick}
        disableActiveElement={props.disableActiveElement}
        siteConfig={siteConfig}
        getCaretBoundary={() => []}
        removeFormatting={() => ''}
      />
    </StyledInputWrapper>
  );
};

const warningSvgString = (warningColor: string) => {
  const formattedWarningColor = warningColor.replace('#', '%23');
  return `background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='${formattedWarningColor}' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='${formattedWarningColor}' stroke='none'/%3e%3c/svg%3e");`;
};
