import Joi from 'joi';
import { CountryCode } from 'libphonenumber-js';
import { validateWithJoi } from 'rootstrap/components/forms/new-fields/extended-components/email-field';
import { isValidSAIdNumber } from 'rootstrap/components/forms/new-fields/extended-components/id-number-field';
import { Validator } from '../interfaces';
import { isEqual } from 'lodash';

export enum ValidationTypes {
  ZAId = 'za_id',
  EMAIL = 'email',
  GREATER_THAN_NUMBER = 'greaterThanNumber',
  GREATER_THAN_LENGTH = 'greaterThanLength',
  GREATER_THAN_EQUALS_TO_LENGTH = 'greaterThanEqualsToLength', // This should be treated exactly the same as greaterThanLength
  GREATER_THAN_EQUALS_TO_NUMBER = 'greaterThanEqualsToNumber', // This should be treated exactly the same as greaterThanNumber
  LESS_THAN_NUMBER = 'lessThanNumber',
  LESS_THAN_EQUALS_TO_NUMBER = 'lessThanEqualsToNumber', // This should be treated exactly the same as lessThanNumber
  GREATER_THAN_CURRENCY = 'greaterThanCurrency',
  GREATER_THAN_EQUALS_TO_CURRENCY = 'greaterThanEqualsToCurrency', // This should be treated exactly the same as greaterThanCurrency
  LESS_THAN_CURRENCY = 'lessThanCurrency',
  LESS_THAN_EQUALS_TO_CURRENCY = 'lessThanEqualsToCurrency', // This should be treated exactly the same as lessThanCurrency
  LESS_THAN_LENGTH = 'lessThanLength',
  LESS_THAN_EQUALS_TO_LENGTH = 'lessThanEqualsToLength', // This should be treated exactly the same as lessThanLength
  REQUIRED = 'required',
  IMEI = 'imei',
  AreaCode = 'areaCode',
  URL = 'url',
  EQUALS = 'equals',
}

export interface ValidationProps {
  prefix?: string;
  countryCode?: CountryCode;
}

export interface GetValidationMessage {
  validators: Validator[] | undefined;
  props: ValidationProps | undefined;
  value: any;
}

interface ValidatorMapParams {
  value: any;
  validation: Validator;
  validators: Validator[] | undefined;
  props: ValidationProps | undefined;
}

const greaterThanNumberValidation = ({ value, validation }: ValidatorMapParams) => {
  return validation.validation.min !== undefined && value < validation.validation.min
    ? `Must be greater than or equal to ${validation.validation.min}`
    : undefined;
};
const lessThanNumberValidation = ({ value, validation }: ValidatorMapParams) => {
  return validation.validation.max !== undefined && value > validation.validation.max
    ? `Must be less than or equal to ${validation.validation.max}`
    : undefined;
};

const greaterThanCurrencyValidation = ({ value, validation, props }: ValidatorMapParams) => {
  return validation.validation.min !== undefined && value < validation.validation.min
    ? `Must be greater than or equal to ${props?.prefix || ''}${(validation.validation.min / 100).toLocaleString(
        'en-ZA',
      )}`
    : undefined;
};
const lessThanCurrencyValidation = ({ value, validation, props }: ValidatorMapParams) => {
  return validation.validation.max !== undefined && value > validation.validation.max
    ? `Must be less than or equal to ${props?.prefix || ''}${(validation.validation.max / 100).toLocaleString('en-ZA')}`
    : undefined;
};

const greaterThaLengthValidation = ({ value, validation, props }: ValidatorMapParams) => {
  return validation.validation.min !== undefined &&
    value !== undefined &&
    value.toString().replace(props?.prefix, '').length < validation.validation.min
    ? `Must have a length of ${validation.validation.min} or more characters.`
    : undefined;
};
const lessThanLengthValidator = ({ value, validation, props }: ValidatorMapParams) => {
  return validation.validation.max !== undefined &&
    value !== undefined &&
    value.toString().replace(props?.prefix, '').length > validation.validation.max
    ? `Must have a length of ${validation.validation.max} or fewer characters.`
    : undefined;
};

export const getValidationMessage = (params: GetValidationMessage) => {
  const { validators, value, props } = params;
  const validationMessage = validators
    ?.map((validation) => {
      const validatorFunc = validatorMap[validation.validation.type];
      if (!validatorFunc) {
        throw new Error(`No validator implemented for type: ${validation.validation.type}`);
      }

      return validatorFunc({ value, validation, validators, props });
    })
    .find((message) => !!message);

  return validationMessage;
};

// TODO: Add other validators
export const validatorMap: {
  [key: string]: ({ value, validation, validators, props }: ValidatorMapParams) => string | undefined;
} = {
  required: ({ value }) => {
    return value === undefined ? 'Required' : undefined;
  },
  [ValidationTypes.GREATER_THAN_CURRENCY]: greaterThanCurrencyValidation,
  [ValidationTypes.GREATER_THAN_EQUALS_TO_CURRENCY]: greaterThanCurrencyValidation,
  [ValidationTypes.LESS_THAN_CURRENCY]: lessThanCurrencyValidation,
  [ValidationTypes.LESS_THAN_EQUALS_TO_CURRENCY]: lessThanCurrencyValidation,
  [ValidationTypes.GREATER_THAN_NUMBER]: greaterThanNumberValidation,
  [ValidationTypes.LESS_THAN_NUMBER]: lessThanNumberValidation,
  [ValidationTypes.GREATER_THAN_EQUALS_TO_NUMBER]: greaterThanNumberValidation,
  [ValidationTypes.LESS_THAN_EQUALS_TO_NUMBER]: lessThanNumberValidation,
  [ValidationTypes.EQUALS]: ({ value, validation }) => {
    const validationValue = validation.validation.value;

    const displayText = () => {
      if (validationValue !== null && typeof validationValue === 'object') {
        return formatObject(validationValue);
      } else if (typeof validationValue === 'boolean') {
        return validationValue ? 'selected' : 'unselected';
      } else {
        return validationValue;
      }
    };

    if (validationValue !== undefined && !isEqual(value, validationValue)) {
      return `Must be ${displayText()}`;
    } else {
      return undefined;
    }
  },
  [ValidationTypes.ZAId]: ({ value }) => {
    return !isValidSAIdNumber(value) ? 'Must be a valid SA ID number' : undefined;
  },
  [ValidationTypes.GREATER_THAN_LENGTH]: greaterThaLengthValidation,
  [ValidationTypes.LESS_THAN_LENGTH]: lessThanLengthValidator,
  [ValidationTypes.GREATER_THAN_EQUALS_TO_LENGTH]: greaterThaLengthValidation,
  [ValidationTypes.LESS_THAN_EQUALS_TO_LENGTH]: lessThanLengthValidator,
  [ValidationTypes.EMAIL]: ({ value }) => {
    const errorMessage = value && validateWithJoi(value, Joi.string().email({ tlds: false }));
    return errorMessage;
  },
  [ValidationTypes.AreaCode]: ({ value, props, validation }) => {
    if (!value) {
      return undefined;
    }

    if (validation.validation.props?.countryCode === 'ZA') {
      if (value !== '' && value.length !== 4) {
        return 'Length must be four';
      }

      const areAllCharactersDigits = value && /^\d+$/.test(value);
      if (value !== '' && !areAllCharactersDigits) {
        return 'Must be numbers 0-9';
      }
    }

    return undefined;
  },
  [ValidationTypes.URL]: ({ value }) => {
    const stringContainsHTTPS = String(value || '').includes('https://');
    const stringContainsHTTP = String(value || '').includes('http://');
    const hasHttpPrefix = stringContainsHTTPS || stringContainsHTTP;

    const errorMessage =
      value &&
      validateWithJoi(
        hasHttpPrefix ? value : `https://${value}`,
        Joi.string().regex(
          new RegExp(
            '^' +
              // protocol identifier
              '(?:(?:https?|ftp)://)' +
              // user:pass authentication
              '(?:\\S+(?::\\S*)?@)?' +
              '(?:' +
              // IP address exclusion
              // private & local networks
              '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
              '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
              '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
              // IP address dotted notation octets
              // excludes loopback network 0.0.0.0
              // excludes reserved space >= 224.0.0.0
              // excludes network & broacast addresses
              // (first & last IP address of each class)
              '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
              '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
              '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
              '|' +
              // host name
              '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
              // domain name
              '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
              // TLD identifier
              '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
              ')' +
              // port number
              '(?::\\d{2,5})?' +
              // resource path
              '(?:/\\S*)?' +
              '$',
            'i',
          ),
        ),
      );
    return errorMessage ? 'Invalid URL' : undefined;
  },
};

const formatObject = (obj: object) => {
  return Object.entries(obj)
    .map(
      ([key, value]) =>
        `${snakeToReadable(key)}: ${typeof value === 'boolean' ? (value ? 'selected' : 'unselected') : value}`,
    )
    .join(', ');
};

const snakeToReadable = (text: string) => {
  const result = text
    .split('_')
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join(' ');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
