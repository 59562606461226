import { Environment } from 'models/environment';
import {
  NetworkProductModuleDefinitionSchema,
  ProductModuleDefinitionSchema,
} from 'product-modules/domain/product-module-definition-schema';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from '../../shared/api';

export const getProductModuleDefinitionSchema = async (params: {
  productModuleId: string;
  productDefinitionId: string;
  productModuleDefinitionSchemaId: string;
  environment: Environment;
  organizationId: string;
  auth: EmbedAuth
}) => {
  const { productModuleId, productDefinitionId, productModuleDefinitionSchemaId, environment, auth, organizationId } =
    params;

  const response: Response = await apiRequest({
    url: `${getApiHost({
      environment,
    })}${getBasePathname({ organizationId, auth, clientAppAuth: false })}/product-modules/${productModuleId}/definitions/${productDefinitionId}/schemas/${productModuleDefinitionSchemaId}`,
    method: 'get',
    auth,
  });

  const networkProductModuleDefinitionMarkdown: NetworkProductModuleDefinitionSchema = await response.json();

  return ProductModuleDefinitionSchema.fromNetwork(networkProductModuleDefinitionMarkdown);
};
