import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, Label, FormFeedback, Input } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { globalStyles } from 'rootstrap/global-styles';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { debounce } from 'utils';
import { getValidationMessage } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { usePromise } from 'shared/hooks/promise';
import { DisplayMarkdown } from '../new-field';
import { isMobile } from 'react-device-detect';

export const CheckboxField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  form,
  prefillAction,
  prefillValue,
  helpText,
  disableActiveElement,
  hideDivider,
  displayProperties,
  disableScrollToElement,
  validators,
  disableNextButton,
  isTouched: defaultIsTouched,
  submitOnChange,
  isLastComponent,
  hideBorder,
  hiddenComponent,
  isLastStep,
}: InputFieldParams<boolean>) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  const [checked, setChecked] = useState<boolean | undefined>(
    getCheckboxDefaultValue({
      defaultValue,
      formValue: form?.watch(name),
      prefillValue,
    }),
  );
  const ref = useRef<any>();

  const { result } = usePromise(async () => {
    const prefillBehaviorResult = prefillBehavior({
      prefillAction,
      options: undefined,
      prefillValue,
      validate: (value) =>
        getValidationMessage({
          validators: validators,
          value: value,
          props: undefined,
        }),
    });

    if (prefillValue) {
      const values = form?.getValues()[name];

      setFieldError(
        getValidationMessage({
          validators: validators,
          value: values,
          props: undefined,
        }),
      );
    }

    return prefillBehaviorResult;
  }, []);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
    isActive && setTouched(true);
  }, [isTouched, name, isActive]);

  useEffect(() => {
    if (isActive && result?.hiddenFromPrefill.display) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [result?.hiddenFromPrefill.display, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={result?.hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
        isMobile={isMobile}
      >
        <FormGroup
          check
          style={hiddenComponent ? { display: 'none' } : {}}
          onClick={(e) => {
            e.stopPropagation();
            setActiveElement({ elementId: name });
          }}
        >
          <Controller
            name={name}
            control={form?.control}
            defaultValue={getCheckboxDefaultValue({
              defaultValue,
              formValue: form?.watch(name),
              prefillValue,
            })}
            rules={{
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value: value,
                  props: undefined,
                }),
            }}
            render={({ onChange: localOnChange }) => {
              return (
                // TODO: Fix hardcoded width
                <StyledCheckboxField
                  disableActiveElement={disableActiveElement}
                  isActive={isActive || isTouched}
                  siteConfig={siteConfig}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <StyledCheckboxLabel check isActive={isActive || isTouched}>
                    <Checkbox
                      name={name}
                      id={name}
                      inputmode='none'
                      isChecked={checked}
                      isActive={isActive || isTouched}
                      disabled={isDisabled || result?.disabledFromPrefill}
                      ref={ref}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveElement({ elementId: name });

                        setTouched(true);

                        const updatedChecked = !form?.getValues()[name];
                        setChecked(updatedChecked);
                        localOnChange(updatedChecked);

                        const fieldError = getValidationMessage({
                          validators: validators,
                          value: updatedChecked,
                          props: undefined,
                        });
                        setFieldError(fieldError);

                        debounce(
                          'updating-check-box-field',
                          () => {
                            if (fieldError && document.activeElement instanceof HTMLElement) {
                              document.activeElement.blur();
                            }
                            setActiveElement &&
                              setActiveElement({
                                elementId: getNextComponentId({ ...displayProperties, key: name }),
                              });
                          },
                          100,
                        );
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        onEnterKeyDown({
                          e,
                          nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                          setFieldError: (fieldError) => setFieldError(fieldError),
                          setActiveElement: (elementId) => {
                            setActiveElement({
                              elementId,
                            });
                          },

                          getValue: () => form?.watch(name),
                          validationFunction: getValidationMessage,
                          validators,
                          validationProps: undefined,
                          setIsTouched: (isTouched) => setTouched(isTouched),
                          isActive,
                        });
                      }}
                      onBlur={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTouched(true);
                      }}
                      checked={[true, 'true'].includes(!!checked)}
                      siteConfig={siteConfig}
                      type='checkbox'
                      invalid={!!fieldError}
                      isDisabled={isDisabled}
                      style={{
                        borderColor: getColor({ color: 'disabled', siteConfig }),
                        caretColor: 'transparent',
                        opacity: 1,
                      }}
                    />

                    <span>
                      <DisplayMarkdown label={label} />
                    </span>
                  </StyledCheckboxLabel>
                </StyledCheckboxField>
              );
            }}
          />
          {fieldError &&
            [fieldError].map((error) => {
              return (
                <FormFeedback key={JSON.stringify(error)} style={{ display: 'block' }}>
                  {error}
                </FormFeedback>
              );
            })}
        </FormGroup>
        {!hiddenComponent && (
          <SteppedFormButton
            isLastStep={isLastStep}
            isLastComponent={isLastComponent}
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            hideDivider={hideDivider}
            setTouched={(isTouched) => setTouched(isTouched)}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) => {
              setActiveElement({
                elementId,
              });
            }}
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

const getCheckboxDefaultValue = (params: {
  formValue: boolean | undefined;
  prefillValue: boolean | undefined;
  defaultValue: boolean | undefined;
}) => {
  const { defaultValue, formValue, prefillValue } = params;
  if (formValue !== undefined) {
    return formValue;
  }

  if (prefillValue !== undefined) {
    return prefillValue;
  }
  if (defaultValue !== undefined) {
    return defaultValue;
  }
  return false;
};

const StyledCheckboxField = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement: boolean | undefined;
}>`
  padding: 22px;
  width: 100%;
  font-size: ${globalStyles.fontSize.body};
  border: 1px solid
    ${({ siteConfig, isActive }) => (isActive ? globalStyles.colors.Body : getColor({ siteConfig, color: 'disabled' }))};
  border-radius: 8px;
  padding-left: 20px !important;
  cursor: default;
  margin-bottom: 18px;

  color: ${({ siteConfig, isActive, disableActiveElement }) =>
    getSteppedFormLabelColor({ isActive, siteConfig, color: globalStyles.colors.Body, disableActiveElement })};
`;

export const StyledCheckboxLabel = styled(Label)<{ isDisabled: boolean; isActive: boolean }>`
  min-width: 200px;
  align-items: center;
  padding-top: 0 !important;
  display: flex;
  cursor: default !important;
  height: auto;

  input {
    height: 25px;
    width: 25px;
    opacity: 1;
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }

  span {
    padding-left: 20px;
    height: 20px;
  }
`;

export const Checkbox = styled(Input)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isChecked: boolean;
  isActive: boolean;
  isDisabled: boolean;
}>`
  -webkit-appearance: none;
  width: 15px;
  opacity: 1;
  height: 15px;
  outline: none;
  border: 1px solid;
  border-color: ${({ siteConfig, isActive }) =>
    isActive ? globalStyles.colors.Body : getColor({ siteConfig, color: 'border' })}};
  border-radius: 3px;
  background: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${({ isChecked, siteConfig, isActive }) => {
    if (isChecked && isActive) {
      return svgString(getColor({ siteConfig, color: 'highlight' }));
    } else if (isChecked) {
      return svgString(`${getColor({ siteConfig, color: 'highlight' })}80`);
    }
    return svgString('#FFF');
  }}');

  :before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
  }

  &:focus {
    box-shadow: none;
  }

  cursor: ${({ isDisabled }) => 'default'};
`;

const svgString = (color: string) => `data:image/svg+xml;utf8,
  <svg width="17" height="14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 17 14">
    <path fill="${color.replace(
      '#',
      '%23',
    )}" fill-rule="evenodd" clip-rule="evenodd" d="M7.15125 12.7566C6.76074 13.1471 6.1276 13.1471 5.73707 12.7566L0.707185 7.72729C0.316623 7.33677 0.316607 6.70357 0.707146 6.31303L2.10125 4.91893C2.49178 4.5284 3.12494 4.5284 3.51547 4.91893L6.44418 7.84764L13.5848 0.707017C13.9753 0.316528 14.6084 0.316487 14.9989 0.706925L16.3929 2.10053C16.7835 2.49104 16.7835 3.12428 16.393 3.51484L7.15125 12.7566Z"/>
  </svg>`;
