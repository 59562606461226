import { IdentificationType, SelectableIdType } from 'policyholder/domain/policyholder-identification';
import { PersonalDetailsCompanyDetailsData } from '../views/company-details';
import { ContactDetailsData } from '../views/contact-details';
import { PersonalDetailsIdentificationData } from '../views/identification';
import { PolicyholderPrefillValues, incomingCellphoneCountryCodeToState } from '../views/personal-details';
import { PersonalAddressData } from '../views/physical-address-section';
import { PolicyholderLookUpFormData } from '../views/unauthenticated-policyholder-lookup';

/**
 * To reset the policyholder after selecting an existing policyholder using the lookup
 */
export const resetPolicyholderToPrefill = (params: {
  setIdentificationSectionData: (v: PersonalDetailsIdentificationData) => void;
  setContactDetailsSectionData: (v: ContactDetailsData) => void;
  setCompanyDetailsSectionData: (v: PersonalDetailsCompanyDetailsData) => void;
  setAddressDetailsSectionData: (v: PersonalAddressData) => void;
  policyholderLookupFormData: PolicyholderLookUpFormData | undefined;
  prefillValues: PolicyholderPrefillValues | undefined;
}) => {
  const {
    prefillValues,
    setIdentificationSectionData,
    setAddressDetailsSectionData,
    setCompanyDetailsSectionData,
    setContactDetailsSectionData,
    policyholderLookupFormData,
  } = params;
  setIdentificationSectionData({
    gender: prefillValues?.gender,
    dateOfBirth: prefillValues?.dateOfBirth,
    firstName: prefillValues?.firstName,
    lastName: prefillValues?.lastName,
    identificationExpirationDate:
      policyholderLookupFormData?.idType === SelectableIdType.Passport
        ? prefillValues?.identification?.expirationDate
        : undefined,
    identificationCountry: policyholderLookupFormData?.identificationCountry,
    identificationNumber: policyholderLookupFormData?.identificationNumber,
    idType: policyholderLookupFormData?.idType as IdentificationType | undefined,
  });

  setContactDetailsSectionData({
    email:
      policyholderLookupFormData?.idType === SelectableIdType.Email
        ? policyholderLookupFormData.email
        : prefillValues?.email,
    cellphone:
      policyholderLookupFormData?.idType === SelectableIdType.Cellphone
        ? policyholderLookupFormData.cellphone
        : incomingCellphoneCountryCodeToState(prefillValues?.cellphone),
  });

  setAddressDetailsSectionData({
    addressLine1: prefillValues?.address?.addressLine1,
    addressLine2: prefillValues?.address?.addressLine2,
    areaCode: prefillValues?.address?.areaCode,
    city: prefillValues?.address?.city,
    country: prefillValues?.address?.country,
    suburb: prefillValues?.address?.suburb,
  });

  setCompanyDetailsSectionData({
    companyName: prefillValues?.companyName,
    companyWebsiteUrl: prefillValues?.companyWebsiteUrl,
    contactPosition: prefillValues?.contactPosition,
    dateOfEstablishment: prefillValues?.dateOfEstablishment,
    registrationNumber: prefillValues?.registrationNumber,
    subsidiaryCompanies: prefillValues?.subsidiaryCompanies,
  });
};
