import React from 'react';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { devices } from 'rootstrap/global-styles/devices';
import { getColor } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import closeIcon from '../assets/close_24px.png';

interface Props {
  embedId: string;
  title?: string;
  onCloseClicked: () => void;
}

const handleChildClick = (e: any) => {
  e.stopPropagation();
};

export const YoutubeEmbed = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { siteConfig } = useSiteConfigContext();

  return (
    <DimmedBackGround onClick={props.onCloseClicked}>
      <CenterDiv onClick={handleChildClick}>
        {props.title && (
          <VideoHeader>
            <span>{props.title}</span>
            <span onClick={props.onCloseClicked}>
              <img src={closeIcon} alt='close' />
            </span>
          </VideoHeader>
        )}
        <div className='iframe-container'>
          {isLoading && (
            <div className='iframe-container-div'>
              <NewSpinner
                animation={AnimationTypes.Border}
                size={SpinnerSize.lg}
                color={getColor({
                  color: 'highlight',
                  siteConfig,
                })}
              />
            </div>
          )}
          <iframe
            width='853'
            height='480'
            allowFullScreen
            src={`https://www.youtube.com/embed/${props.embedId}?&fs=1`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            title='Embedded youtube'
            style={{ display: isLoading ? 'none' : 'block' }}
            onLoad={() => setIsLoading(false)}
          />
        </div>
      </CenterDiv>
    </DimmedBackGround>
  );
};

const DimmedBackGround = styled.div`
  position: absolute;
  z-index: 1;
  background-color: rgba(109, 109, 110, 0.9);
  height: 100%;
  width: 100%;
`;

const VideoHeader = styled.div`
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border: none;

  span {
    padding-bottom: 10px;
    background-color: white;
    margin: 0;
    font-size: 18px;
    font-weight: bold;

    img {
      cursor: pointer;
    }

    @media ${devices.tablet} {
      padding: 10px;
    }
  }
`;

const CenterDiv = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  border: 20px solid white;
  background: white;
  width: 70%;

  @media ${devices.tablet} {
    border: 0px solid white;
    width: calc(100% - 15px);
  }
`;
