import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { Application, NetworkApplication } from '../domain/application';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  applicationId: string;
  data: { policyholderId?: string; billingDay: number };
}

export const updateApplication = async (params: Params) => {
  const { applicationId, auth, data, environment, organizationId } = params;
  const { policyholderId, billingDay } = data;

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/applications/${applicationId}`,
    method: 'put',
    body: {
      policyholder_id: policyholderId,
      billing_day: billingDay,
    },
    auth,
  });

  const networkApplication: NetworkApplication = await response.json();

  const application = Application.fromNetwork(networkApplication);

  return application;
};
