import { Icon16PXTick } from 'assets/done_24px';
import React from 'react';
import { HighlightButton, Size } from 'rootstrap/components/button/styles';
import { CardBody } from 'rootstrap/components/card';
import { Modal, ModalBody, ModalHeader } from 'rootstrap/components/modal';
import { globalStyles } from 'rootstrap/global-styles';
import {
  getColor,
  getDisplayOptionalSections,
  getFontFamily,
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
} from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface Props {
  toggle: () => void;
}

export const ConsentModal = (props: Props) => {
  const { siteConfig } = useSiteConfigContext();

  // landing page
  const consentDisclaimerEnabled = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.quote.displayOptionalSections.consentDisclaimer,
  });
  const consentItems = siteConfig?.quote.wording.consentDisclaimer?.consentItems || [];
  const consentDisclaimerTitleWording = getWording({ wording: siteConfig?.quote.wording.consentDisclaimer?.title });

  // Header
  return (
    <Modal centered id='consent-modal' isOpen>
      <ModalHeader>
        <ConsentMainHeader siteConfig={siteConfig || null}>{consentDisclaimerTitleWording}</ConsentMainHeader>
      </ModalHeader>
      <ModalBody>
        <StyledConsentCardBody>
          <p>By clicking confirm, you:</p>
          <div>
            {consentDisclaimerEnabled &&
              consentItems.map((consentItem) => (
                <ConsentItem>
                  <Icon16PXTick fill={getColor({ siteConfig, color: 'highlight' })} />
                  <div>{consentItem}</div>
                </ConsentItem>
              ))}
          </div>
        </StyledConsentCardBody>
        <ConsentCardFooter>
          <HighlightButton id='confirm-consent' siteConfig={siteConfig} fullWidth size={Size.lg} onClick={props.toggle}>
            Confirm
          </HighlightButton>
        </ConsentCardFooter>
      </ModalBody>
    </Modal>
  );
};

const StyledConsentCardBody = styled(CardBody)`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  p:nth-child(1) {
    margin-top: 0px;
    margin-bottom: 9px;
  }

  div:nth-child(1) {
    display: flex;
  }

  svg {
    margin-left: -20px;
    margin-right: 8px;
    margin-top: 1px;
  }
`;

const ConsentItem = styled.div`
  display: flex;
`;

const ConsentMainHeader = styled.h1<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
  font-size: ${globalStyles.fontSize.title};
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0 !important;
  margin-top: 30px;
`;

const ConsentCardFooter = styled.div`
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${globalStyles.spacing.default};
`;
