import React, { useState } from 'react';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { ApiError } from 'shared/api';
import { Quote } from '../domain/quote';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getColor, getWording, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { PolicyIssuingBanner } from 'policy-issuing/common/policy-issuing-banner';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { PolicyIssuingErrorBanner } from './policy-issuing-error-banner';
import { Colors } from 'rootstrap/global-styles/colors';
import { QuotePackageActionItem } from './quote-package-action-item';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

interface QuoteStepActionBarProps {
  step: IssuingSceneStepKeys | string;
  quotes?: Quote[];
  error?: ApiError;
  isLoadingCreateQuote: boolean;
  createQuoteButtonRef: React.MutableRefObject<any>;
  onNextClickedRef: React.MutableRefObject<any>;
  productModuleDefinition: ProductModuleDefinition | undefined;
  rootSchemaFormQuoteRef: React.MutableRefObject<any>;
  isLoadingMainPage: boolean;
  setSelectedQuote: (quote?: Quote) => void;
}

export const QuoteStepActionBar = (params: QuoteStepActionBarProps) => {
  const {
    step,
    quotes,
    error,
    isLoadingCreateQuote,
    onNextClickedRef,
    productModuleDefinition,
    rootSchemaFormQuoteRef,
    isLoadingMainPage,
    setSelectedQuote,
  } = params;
  const { siteConfig } = useSiteConfigContext();
  const coverRejectedWording = getWording({ wording: siteConfig?.quote.wording.coverRejected || 'cover' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Check if the current step is a quote input step (either 'quoteInput' or 'quoteInputStep${number}')
  const isQuoteInputStep = step.startsWith('quoteInputStep');

  if (isQuoteInputStep && (isLoadingCreateQuote || isLoadingMainPage)) {
    return (
      <PolicyIssuingBanner>
        <StyledValidQuoteDisplay>
          <StyledQuotePackageList siteConfig={siteConfig}>
            <div>
              <h5 id='quote-packages-header' className='quote-packages-header'>
                Fetching quote...
              </h5>
              <p style={{ paddingRight: 5 }}>
                <NewSpinner
                  animation={AnimationTypes.Border}
                  size={SpinnerSize.sm}
                  color={getColor({ siteConfig, color: 'primary' })}
                />
              </p>
            </div>
          </StyledQuotePackageList>
        </StyledValidQuoteDisplay>
      </PolicyIssuingBanner>
    );
  }

  if (isQuoteInputStep) {
    const fieldError = error?.fieldErrors && error?.fieldErrors[0];
    if (!quotes && fieldError) {
      return (
        <StyledQuotePackageList siteConfig={siteConfig}>
          <PolicyIssuingErrorBanner
            body={`We're sorry! We are unable to provide you with ${coverRejectedWording} for now. If you'd like to receive further information, contact us at`}
          />
        </StyledQuotePackageList>
      );
    }

    if (!quotes && error) {
      return <PolicyIssuingErrorBanner body={`${error.message}. Please try again or contact us at`} />;
    }

    if (!quotes) {
      return (
        <PolicyIssuingBanner>
          <StyledQuotePackageList siteConfig={siteConfig}>
            <h5 id='quote-packages-header' className='quote-packages-header'>
              Please fill out the form above to get a{' '}
              <span style={{ whiteSpace: 'nowrap', textAlign: 'center', display: 'inline-block' }}>
                personalised quote.
              </span>
            </h5>
          </StyledQuotePackageList>
        </PolicyIssuingBanner>
      );
    }

    return (
      <div
        id='quote-footer-steps'
        onMouseOver={() => {
          rootSchemaFormQuoteRef?.current?.resetActiveElement();
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }}
      >
        <PolicyIssuingBanner>
          <StyledQuotePackageList siteConfig={siteConfig}>
            <div>
              <h5 className='quote-packages-header bold'>
                {quotes.length === 1 ? 'Your quote' : `Your ${quotes.length} quote options`}
              </h5>
            </div>

            {quotes.map((quote, index) => (
              <QuotePackageActionItem
                key={index}
                index={index}
                setSelectedQuote={setSelectedQuote}
                isLoading={isLoading}
                onNextClickedRef={onNextClickedRef}
                productModuleDefinition={productModuleDefinition}
                quote={quote}
                setIsLoading={setIsLoading}
              />
            ))}
          </StyledQuotePackageList>
        </PolicyIssuingBanner>
      </div>
    );
  }

  return <div></div>;
};

export const StyledValidQuoteDisplay = styled.div`
  .pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  p {
    font-size: 16px;
    display: flex;
    justify-content: center;
  }

  div {
    font-size: 34px;
  }

  ${SuccessButton} {
    margin-top: 30px;
    width: 100%;
  }
`;

export const getBillingAbbreviation = (policyBillingFrequency: PolicyBillingFrequency) => {
  const billingAbbreviation = {
    [PolicyBillingFrequency.Daily]: '/ day',
    [PolicyBillingFrequency.Monthly]: '/ month',
    [PolicyBillingFrequency.OnceOff]: '',
    [PolicyBillingFrequency.Weekly]: '/ week',
    [PolicyBillingFrequency.Yearly]: '/ year',
  };

  return billingAbbreviation[policyBillingFrequency];
};

export const StyledQuotePackageList = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  width: 100%;
  text-align: center;

  .quote-packages-header {
    color: ${Colors.Body};
    font-size: 18px;
    margin-bottom: 30px;

    .bold {
      font-weight: 600;
    }

    span {
      white-space: nowrap;
      display: inline-block;
    }
  }
`;
