import React, { Props } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface TransitionProps extends Props<any> {
  in: boolean;
  width?: number;
  percent?: boolean;
}

export const Transition = (props: TransitionProps) => {
  const { children } = props;
  let toRender = <span>{props.in ? children : ''}</span>;
  if (!props.in) {
    if (props.width && !props.percent) {
      toRender = (
        <span className='transition-pending' style={{ width: `${props.width}px` }}>
          <TransitionText />
        </span>
      );
    } else if (props.width && props.percent) {
      toRender = (
        <span className='transition-pending' style={{ width: `${props.width}%` }}>
          <TransitionText />
        </span>
      );
    } else {
      let inner = <TransitionText />;
      if (children) {
        const ch = React.Children.map(props.children, (c: any) => c || <TransitionText />);
        inner = (
          <>
            {ch}
            <TransitionText />
          </>
        );
      }

      toRender = <span className='transition-pending'>{inner}</span>;
    }
  }

  return (
    <CSSTransition in={props.in} timeout={150} classNames='text'>
      {toRender}
    </CSSTransition>
  );
};

const TransitionText = () => {
  return <span className='transition-text'>&nbsp;</span>;
};
