import { Beneficiary, NetworkBeneficiary } from 'beneficiaries/domain/beneficiary';
import moment, { Moment } from 'moment';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { Construct } from '../../models/construct';
import { CreatedBy, CreatedByType } from '../../models/created-by';
import { ApplicationStatus } from './application-status';
import { ApplicationType } from './application-type';

export class Application {
  readonly applicationId: string;
  readonly organizationId: string;
  readonly environment: string;
  readonly createdAt: Moment;
  readonly updatedAt: Moment;
  readonly policyholderId: string;
  readonly policyId?: string;
  readonly packageName: string;
  readonly sumAssured?: number;
  readonly basePremium: number;
  readonly billingDay?: number;
  readonly monthlyPremium: number;
  readonly module: { type: string;[key: string]: any };
  readonly createdBy: CreatedBy;
  readonly currency: Currency;
  readonly productModuleDefinitionId: string;
  readonly status: ApplicationStatus;
  readonly applicationType?: ApplicationType;
  readonly quotePackage?: Quote;
  readonly billingFrequency: PolicyBillingFrequency;
  readonly paymentMethodId?: string;
  readonly inputData?: { [key: string]: any };
  readonly beneficiaries?: Beneficiary[];


  constructor(init: Construct<Application>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkApplication) {
    return new Application({
      applicationId: init.application_id,
      organizationId: init.organization_id,
      environment: init.environment,
      createdAt: moment(init.created_at),
      updatedAt: moment(init.updated_at),
      policyholderId: init.policyholder_id,
      policyId: init.policy_id,
      packageName: init.package_name,
      sumAssured: init.sum_assured,
      basePremium: init.base_premium,
      monthlyPremium: init.monthly_premium,
      module: init.module,
      createdBy: init.created_by,
      billingDay: init.billing_day,
      currency: init.currency,
      productModuleDefinitionId: init.product_module_definition_id,
      status: init.status,
      applicationType: init.type,
      quotePackage: init.quote_package ? Quote.fromNetwork(init.quote_package) : undefined,
      billingFrequency: init.billing_frequency,
      paymentMethodId: init.payment_method_id,
      inputData: init.input_data,
      beneficiaries: init.beneficiaries
        ? init.beneficiaries.map((beneficiary) => Beneficiary.fromNetwork(beneficiary))
        : [],
    });
  }
}

export class NetworkApplication {
  application_id: string;
  organization_id: string;
  environment: string;
  created_at: string;
  updated_at: string;
  created_by: {
    id: string;
    type: CreatedByType;
    ownerId: string;
  };
  policyholder_id: string;
  policy_id?: string;
  package_name: string;
  sum_assured?: number;
  base_premium: number;
  monthly_premium: number;
  module: {
    type: string;
    [key: string]: any;
  };
  currency: Currency;
  product_module_definition_id: string;
  status: ApplicationStatus;
  type?: ApplicationType;
  quote_package?: any;
  billing_day?: number;
  billing_frequency: PolicyBillingFrequency;
  payment_method_id?: string;
  input_data?: { [key: string]: any };
  beneficiaries: NetworkBeneficiary[];
}
