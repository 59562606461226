import { Environment } from 'models/environment';
import { ExternalPaymentMethod, NetworkExternalPaymentMethod } from 'payment-methods/domain/payment-method';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from 'shared/api';

export interface ExternalPaymentMethodParams {
  externalPaymentMethodType: string;
  paymentReference: string;
  billingDay: number;
  applicationId?: string;
  policyId?: string;
}
export interface CreateExternalPaymentMethod extends ExternalPaymentMethodParams {
  policyholderId: string;
  environment: Environment;
  auth: EmbedAuth;
  organizationId: string;
}

export const createExternalPaymentMethod = async (params: CreateExternalPaymentMethod) => {
  const {
    auth,
    billingDay,
    environment,
    externalPaymentMethodType,
    paymentReference,
    policyholderId,
    organizationId,
    applicationId,
    policyId,
  } = params;

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/policyholders/${policyholderId}/payment-methods`
      : `/v1/insurance/policyholders/${policyholderId}/payment-methods`;

  const response: Response = await apiRequest({
    url: `${getApiHost({ environment })}${url}`,
    method: 'post',
    body: {
      type: externalPaymentMethodType,
      external_reference: paymentReference || 'not provided',
      billing_day: billingDay,
      application_id: applicationId,
      policy_ids: policyId ? [policyId] : undefined,
    },
    auth,
  });

  const networkPaymentMethod: NetworkExternalPaymentMethod = await response.json();

  const paymentMethod = ExternalPaymentMethod.fromNetwork(networkPaymentMethod);

  return paymentMethod;
};
