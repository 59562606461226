import { RequestedBy } from 'general/created-by';
import { Construct } from '../../models/construct';
import moment, { Moment } from 'moment';
import { Environment } from 'models/environment';

export class PublicKey {
  readonly publicKeyId: string;
  readonly organizationId: string;
  readonly description: string | undefined;
  readonly type: string;
  readonly key: string;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;
  readonly archivedAt?: Moment;
  readonly archivedBy?: RequestedBy;
  readonly updatedAt: Moment;
  readonly updatedBy: RequestedBy;
  readonly environment: Environment;

  constructor(init: Construct<PublicKey>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkPublicKey): PublicKey {
    return new PublicKey({
      publicKeyId: init.public_key_id,
      organizationId: init.organization_id,
      description: init.description,
      type: init.type,
      key: init.key,
      createdAt: moment(init.created_at),
      createdBy: init.created_by,
      archivedAt: moment(init.archived_at),
      archivedBy: init.archived_by,
      updatedAt: moment(init.updated_at),
      updatedBy: init.updated_by,
      environment: init.environment,
    });
  }
}

export interface NetworkPublicKey {
  public_key_id: string;
  organization_id: string;
  description: string | undefined;
  type: string;
  key: string;
  created_at: Moment;
  created_by: RequestedBy;
  archived_at: Moment;
  archived_by: RequestedBy;
  updated_at: Moment;
  updated_by: RequestedBy;
  environment: Environment;
}
