import { PersonalDetailsPrefillParams } from 'shared/domain/prefill-values';
import { NetworkPolicyholderPrefillValues, PolicyholderPrefillValues } from '../views/personal-details';
import { utc as moment } from 'moment';
import { PolicyholderAddressOptInOptions } from 'policyholder/domain/policyholder';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { PolicyholderLookUpFormData } from '../views/unauthenticated-policyholder-lookup';

export const getPolicyholderDataFromPrefillValues = (params: {
  prefillValues?: PersonalDetailsPrefillParams;
  selectedQuote?: Quote;
  policyholderLookupFormData?: PolicyholderLookUpFormData;
}): PolicyholderPrefillValues => {
  const { prefillValues, selectedQuote, policyholderLookupFormData } = params;
  const policyholder = selectedQuote?.module.policyholder as NetworkPolicyholderPrefillValues | undefined;

  const identificationExpirationDate =
    prefillValues?.identification_expiration_date || policyholder?.id?.expiration_date;
  const dateOfBirth = prefillValues?.date_of_birth || policyholder?.date_of_birth;
  const dateOfEstablishment = prefillValues?.date_of_establishment || policyholder?.date_of_establishment;

  // Identification details
  const type =
    (policyholderLookupFormData?.idType as any) || prefillValues?.identification_type || policyholder?.id?.type;
  const number =
    policyholderLookupFormData?.identificationNumber ||
    policyholderLookupFormData?.passportNumber ||
    prefillValues?.identification_number ||
    policyholder?.id?.number;
  const country =
    policyholderLookupFormData?.identificationCountry ||
    prefillValues?.identification_country ||
    policyholder?.id?.country;

  // Contact details
  const email = policyholderLookupFormData?.email || prefillValues?.email || policyholder?.email;
  const cellphoneNumber =
    policyholderLookupFormData?.cellphone?.number ||
    prefillValues?.cellphone_number ||
    policyholder?.cellphone?.number ||
    '';
  const countryCode =
    policyholderLookupFormData?.cellphone?.countryCode ||
    prefillValues?.cellphone_country ||
    policyholder?.cellphone?.country ||
    '';

  return {
    policyholderType: prefillValues?.policyholder_type || policyholder?.policyholder_type,
    identification: {
      type,
      number,
      country,
      ...(identificationExpirationDate ? { expirationDate: moment(identificationExpirationDate) } : {}),
    },
    firstName: prefillValues?.first_name || policyholder?.first_name,
    lastName: prefillValues?.last_name || policyholder?.last_name,
    email,
    cellphone: {
      number: cellphoneNumber,
      country: countryCode,
    },
    gender: prefillValues?.gender || policyholder?.gender,
    dateOfBirth: dateOfBirth ? moment(dateOfBirth) : undefined,
    address: {
      addressLine1: prefillValues?.address_line_1 || policyholder?.address?.line_1,
      addressLine2: prefillValues?.address_line_2 || policyholder?.address?.line_2,
      areaCode: prefillValues?.area_code || policyholder?.address?.area_code,
      city: prefillValues?.city || policyholder?.address?.city,
      country: prefillValues?.country || policyholder?.address?.country,
      suburb: prefillValues?.suburb || policyholder?.address?.suburb,
    },
    addressOptIn:
      prefillValues?.address_opt_in !== undefined
        ? prefillValues?.address_opt_in === true
          ? PolicyholderAddressOptInOptions.Yes
          : PolicyholderAddressOptInOptions.No
        : undefined,
    companyName: prefillValues?.company_name || policyholder?.company_name,
    registrationNumber: prefillValues?.registration_number || policyholder?.registration_number,
    companyWebsiteUrl: prefillValues?.company_website_url || policyholder?.company_website_url,
    contactPosition: prefillValues?.contact_position || policyholder?.contact_position,
    subsidiaryCompanies: prefillValues?.subsidiary_companies || policyholder?.subsidiary_companies,
    dateOfEstablishment: dateOfEstablishment ? moment(dateOfEstablishment) : undefined,
  };
};
