import React, { ReactNode } from 'react';
import { StyledH3 } from 'rootstrap/components/headings/header';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components';
import { FullScreenModalPageComponentDimensions } from '../fullscreen-modal';

export const FormOverLay = styled.div<{
  width?: string;
}>`
  display: flex;
  padding: 0px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  .main {
    flex: 5;
    max-width: 812px;
  }
`;

export const FormOverLayMainContents = styled(FormOverLay)<{
  width?: string;
  isHeadingSplashDisplayed?: boolean;
  isQuotePackageFooterDisplayed?: boolean;
  pageComponentDimensions: FullScreenModalPageComponentDimensions;
}>`
  display: flex;
  padding: 0px;
  ${({ isHeadingSplashDisplayed, isQuotePackageFooterDisplayed, pageComponentDimensions }) => {
    if (isHeadingSplashDisplayed) {
      return 'min-height: calc(100% - 432px);';
    }

    if (isQuotePackageFooterDisplayed) {
      const { footerHeight, formContentHeight, fullScreenHeaderHeight, progressBarHeight } = pageComponentDimensions;
      const containerHeight = window.innerHeight;
      const offset: number = fullScreenHeaderHeight + progressBarHeight + formContentHeight + 20 + footerHeight;

      return `min-height: calc(${containerHeight}px - ${offset}px);`;
    }

    return 'min-height: calc(100% - 160px);';
  }}
`;

export const SpacerLeft = styled.div`
  flex: 2;

  &.padded {
    padding-left: 20px;
  }

  &.full-width {
    flex: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SpacerRight = styled.div`
  flex: 2;

  &.full-width {
    flex: 0;
  }

  &.padded {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FormOverLayNavBar = (props: {
  navBar: ReactNode;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}) => <NavBarContent>{props.navBar}</NavBarContent>;

export const FullScreenModalHeader = styled(StyledH3)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
}>`
  height: 70px;
  display: flex;
  align-items: center;
  ${({ siteConfig }) => siteConfig && `background: ${siteConfig?.styles.colors.primary}`};
  fontsize: 24px;
  font-weight: bold;
  color: ${({ siteConfig }) => siteConfig?.styles.colors.light};
  justify-content: flex-start;
`;

const NavBarContent = styled.div`
  @media ${devices.mobileL} {
    width: 100%;
  }
`;

export const FormOverLayNavBarContainer = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  width: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  height: 60px;
`;

export const FormOverlayTitleWrapper = styled.div`
  flex: 5;
  max-width: 812px;

  @media${devices.tablet} {
    display: flex;
    justify-content: center;
  }
`;
