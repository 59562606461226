import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { isValidSAIdNumber } from 'rootstrap/components/forms/new-fields/extended-components/id-number-field';
import { JSONObject } from 'shared/utils';
import { ContactDetailsData } from '../views/contact-details';
import { PersonalDetailsIdentificationData } from '../views/identification';
import { PersonalDetailsInputs } from '../views/personal-details';
import { PersonalAddressData } from '../views/physical-address-section';

interface Params {
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
  addressDetailsSectionData: PersonalAddressData | undefined;
  contactDetailsSectionData: ContactDetailsData | undefined;
  addressOptIn: boolean | undefined;
}

export const isPolicyholderPrefillComplete = (params: Params) => {
  const { addressDetailsSectionData, contactDetailsSectionData, identificationSectionData } = params;
  const optionalFields = getOptionalPolicyholderIdFields(identificationSectionData?.idType);

  const isAddressDetailsSectionComplete =
    addressDetailsSectionData &&
    isPolicyholderSectionComplete<PersonalAddressData>(addressDetailsSectionData, optionalFields);

  const isIdentificationSectionComplete =
    identificationSectionData &&
    isPolicyholderSectionComplete<PersonalDetailsIdentificationData>(identificationSectionData, optionalFields);

  const isContactDetailsSectionComplete =
    contactDetailsSectionData &&
    isPolicyholderSectionComplete<ContactDetailsData>(contactDetailsSectionData, optionalFields);

  const isIdentificationValid =
    identificationSectionData?.idType === IdentificationType.Id && identificationSectionData.identificationNumber
      ? isValidSAIdNumber(identificationSectionData.identificationNumber)
      : true;

  return (
    isIdentificationSectionComplete &&
    isContactDetailsSectionComplete &&
    isAddressDetailsSectionComplete &&
    isIdentificationValid
  );
};

export const isPolicyholderSectionComplete = <T,>(sectionData: T, optionalFields: string[]) => {
  if (
    (sectionData as any)[PersonalDetailsInputs.AreaCode] &&
    (sectionData as any)[PersonalDetailsInputs.Country] === 'ZA'
  ) {
    if (
      (sectionData as any)[PersonalDetailsInputs.AreaCode].length !== 4 ||
      (sectionData as any)[PersonalDetailsInputs.AreaCode].match(/[a-zA-Z]/)
    ) {
      return false;
    }
  }
  return Object.keys(sectionData as unknown as JSONObject)
    .map((key) => optionalFields.includes(key) || sectionData[key as keyof T] !== undefined)
    .every((value) => value === true);
};

export const getOptionalPolicyholderIdFields = (idType: IdentificationType | undefined) => {
  const alwaysOptional = [PersonalDetailsInputs.AddressLine2];

  if (idType === IdentificationType.Id) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Company) {
    const optionalFields: string[] = [
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationCountry,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Cellphone) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.IdentificationNumber,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Email) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.IdentificationNumber,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Passport) {
    const optionalFields: string[] = [
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  return [...alwaysOptional];
};
