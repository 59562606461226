import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components/macro';
import { Spacing, FontSize } from '../../global-styles';
import { Heading as StyledHeading } from '../headings/styles';

export const InfoCardHeading = styled.div`
  ${StyledHeading} {
    margin-top: ${Spacing.md};
    margin-bottom: ${Spacing.sm};
  }

  p {
    margin-top: 0;
    margin-bottom: ${Spacing.md};
  }
`;

export const CardGroup = styled.section`
  ${StyledHeading} {
    margin-top: ${Spacing.md};
    margin-bottom: ${Spacing.md};
  }

  p {
    margin-top: 0;
    margin-bottom: ${Spacing.md};
  }
`;

export const Card = styled.section<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  border: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
  border-radius: 4px;
  margin-bottom: 0;
`;

export const CardHeader = styled.header.attrs((props) => ({ className: props.className }))<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
}>`
  border-bottom: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
  align-items: center;
  height: ${Spacing.xl};
  align-items: center;
  padding: ${Spacing.default};
  font-style: normal;
  font-size: ${FontSize.body};
  margin-top: 0px !important;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'light' })};
`;

export const CardBody = styled.main`
  padding: ${Spacing.default};

  button {
    margin-top: ${Spacing.sm};
  }
`;

export const Heading = styled.header<{ siteConfig: ProductModuleDefinitionEmbeddedConfig }>`
  border-bottom: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })};
  margin-left: -${Spacing.default};
  margin-right: -${Spacing.default};
`;

const exports = {
  Card,
  CardBody,
  Heading,
  CardHeader,
};

export default exports;
