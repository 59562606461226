import React from 'react';
import styled from 'styled-components';
import RCSlider from 'rc-slider';

interface SliderParams {
  onChange: (value: number) => void;
  value: number;
  isDisabled: boolean;
}

export const Slider = (params: SliderParams) => {
  const { onChange, value, isDisabled } = params;

  return (
    <RCSlider
      value={value}
      max={100}
      min={0}
      disabled={isDisabled}
      onChange={(value: any) => {
        onChange(value);
      }}
      onChangeComplete={(value: any) => {
        onChange(value);
      }}
    />
  );
};

export const StyledSlider = styled(RCSlider)<{
  primaryColor: string;
  sliderRailColor: string;
  borderColor: string;
}>`
  margin-top: 30px;

  .rc-slider {
    position: relative;
    width: calc(100% - 10px);
    height: 30px;
    padding: 7px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-left: 14px;
    margin-right: 14px;
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 1px;
    margin-top: 7px;
    background-color: ${({ sliderRailColor }) => sliderRailColor};
    border-radius: 6px;
    padding-bottom: 4px !important;
  }
  .rc-slider-track {
    position: absolute;
    margin-top: 7px;
    height: 4px;
    background-color: ${({ primaryColor }) => primaryColor};
    border-radius: 6px;
  }
  .rc-slider-handle {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: -4px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    opacity: 1;
    touch-action: pan-x;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid ${({ borderColor }) => borderColor};
  }
  .rc-slider-handle::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid transparent;
    transition: border-color 0.2s;
  }

  .rc-slider-handle-dragging::before {
    border-color: ${({ primaryColor }) => primaryColor}; /* Change border color on dragging */
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    bordercolor: ${({ primaryColor }) => primaryColor};
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .rc-slider-handle:focus-visible {
    border: none;
  }
  .rc-slider-handle-click-focused:focus {
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
  }
  .rc-slider-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: #999;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
  .rc-slider-mark-text-active {
    color: #666;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
  }
  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  .rc-slider-dot-active {
    border-color: none;
    border: none;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }

  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
    100% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    min-width: 24px;
    height: 24px;
    padding: 6px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }
`;

export const StyledSchemaSlider = styled(StyledSlider)<{
  primaryColor: string;
  sliderRailColor: string;
  borderColor: string;
}>`
  margin-top: 30px;

  .rc-slider {
    width: 100%;
    padding: 5px 0;
    border-radius: 6px;
  }

  .rc-slider-rail {
    height: 4px;
    margin-top: 5px;
  }

  .rc-slider-track {
    margin-top: 5px;
  }

  .rc-slider-handle {
    margin-top: -5px;
  }
`;

export const getSliderTooltipAlignment = (params: { value: number; min: number; max: number }) => {
  const { value, min, max } = params;
  const breakPointPercentage = window.innerWidth > 768 ? 0.1 : 0.2;
  if (value - min < (max - min) * breakPointPercentage) {
    return 'left';
  } else if (max - value < (max - min) * breakPointPercentage) {
    return 'right';
  } else {
    return 'center';
  }
};
