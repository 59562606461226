import { Colors } from 'rootstrap/global-styles/colors';
import { devices } from 'rootstrap/global-styles/devices';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components';

export const FullScreenModalOffset = styled.div`
  flex: 2;

  @media${devices.tablet} {
    flex: 0;
  }
`;

export const FullscreenModalActionBarStyle = styled.div`
  @media${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const FullScreenModalDescriptionContainer = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  removeMarginBottom: boolean;
}>`
  background-color: ${({ siteConfig }) =>
    getColor({ siteConfig, color: 'backgroundHighlight', defaultColor: Colors.Loading })};
  min-height: 100px;
  display: flex;
  margin-bottom: ${({ removeMarginBottom }) => (removeMarginBottom ? '0px' : '20px')};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 0px;
  padding-right: 0px;
`;

export const FullscreenModalSplashHeadingContainer = styled(FullScreenModalDescriptionContainer)<{
  displayHeadingSplash: boolean;
}>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  border-radius: 20px 20px 0px 0px;
  height: 272px;
  ${({ displayHeadingSplash }) =>
    !displayHeadingSplash &&
    css`
      display: none;
    `}
`;

export const FullscreenModalDescriptionContent = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 812px;

  span {
    line-height: 30px;
  }

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }

  .description-header {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }

  .description-subheader {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Subscript};
  }

  @media ${devices.tablet} {
    // text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
      line-height: 25px;
    }

    .description-header {
      font-weight: 600;
      font-size: 18px;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`;

export const FullscreenModalSplashHeadingContent = styled(FullscreenModalDescriptionContent)`
  position: absolute;
  right: 0px;
  left: 0px;
  z-index: -1;
  margin-top: -20px;
  border-radius: 20px 20px 0px 0px;
`;

export const FullscreenModalDescriptionContentTextStyle = styled.div`
  display: flex;
  width: -webkit-fill-available;
`;

export const FullScreenModalFooterOverlay = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  fullWidthOnMobile?: boolean;
}>`
  display: flex;
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .main {
    flex: 5;

    @media ${devices.tablet} {
      padding-left: 20px;
      padding-right: 20px;
    }

    ${({ fullWidthOnMobile }) =>
      fullWidthOnMobile &&
      css`
        @media ${devices.mobileL} {
          padding-left: 0px;
          padding-right: 0px;
        }
      `}
  }
`;
