import { Environment } from 'models/environment';
import { DebitOrderPaymentMethod, NetworkDebitOrderPaymentMethod } from 'payment-methods/domain/payment-method';
import { DebitOrderDetailsFormData } from 'policy-issuing/payment/views/debit-order-payment';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from 'shared/api';

interface UpdateApplicationParams {
  details: DebitOrderDetailsFormData;
  policyholderId: string;
  applicationId?: string;
  policyId?: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
}

export const createDebitOrderPaymentMethod = async (params: UpdateApplicationParams) => {
  const { details, applicationId, policyholderId, auth, environment, organizationId, policyId } = params;

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/policyholders/${policyholderId}/payment-methods`
      : `/v1/insurance/policyholders/${policyholderId}/payment-methods`;

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${url}`,
    method: 'post',
    body: {
      type: details.type,
      bank_details: {
        account_holder: details.accountHolder,
        account_holder_identification: details.accountHolderIdentification,
        bank: details.bankName,
        branch_code: details.branchCode ? details.branchCode.toString() : undefined,
        account_number: details.accountNumber ? details.accountNumber.toString() : undefined,
        account_type: details.accountType,
      },
      application_id: applicationId,
      policy_ids: policyId ? [policyId] : undefined,
    },
    auth,
  });

  const networkPaymentMethod: NetworkDebitOrderPaymentMethod = await response.json();

  const application = DebitOrderPaymentMethod.fromNetwork(networkPaymentMethod);

  return application;
};
