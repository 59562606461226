import { Col } from 'reactstrap';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import styled from 'styled-components';

export const BeneficiaryDetailsStyle = styled.div`
  padding-bottom: 60px;
  padding-top: ${globalStyles.spacing.md};

  ${SuccessButton} {
    margin-top: 30px;
    margin-left: 0px;
  }
`;
export const StyledBeneficiaryCol = styled(Col)`
  padding-left: 0px !important;

  .schema-component {
    padding-top: 0;
  }
`;
