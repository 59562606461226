import React, { SetStateAction } from 'react';
import { useSiteConfigContext } from 'style-context';
import { ProgressBar, ProgressBarPremiumDisplay } from '../progress-bar/progress-bar';
import { getColor, ProductModuleEmbedConfigIssuingFlowStartingStep } from 'site-config';
import { useBaseUrl } from 'shared/api';
import { useHistory } from 'react-router-dom';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

export interface StepperStep {
  key: SetStateAction<any | undefined>;
  title: string;
  description: string;
}

interface Props {
  steps: StepperStep[];
  activeStepKey: SetStateAction<any | undefined>;
  priorStepKey: SetStateAction<any | undefined>;
  title?: string;
  setCurrentStepKey: (currentStepKey: SetStateAction<any | undefined>) => void;
  setPriorStepKey: (priorStepKey: SetStateAction<any | undefined>) => void;
  firstStep: IssuingSceneStepKeys;
  premiumDisplay: ProgressBarPremiumDisplay | undefined;
  isLoadingBackButton: boolean;
}

export const Stepper = (params: Props) => {
  const { activeStepKey, setCurrentStepKey, steps, setPriorStepKey, firstStep, premiumDisplay, isLoadingBackButton } =
    params;
  const { siteConfig } = useSiteConfigContext();
  const baseUrl = useBaseUrl();
  const history = useHistory();

  if (!siteConfig) {
    return <div style={{ width: '100%' }}></div>;
  }

  // Hack to deal with dynamic steps in the progress bar
  const screeningQuestionsEnabled = Boolean(
    siteConfig &&
      siteConfig.quote.displayOptionalSections.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions.length > 0,
  );

  const isPersonalDetailsStartingStep =
    siteConfig.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  const isPrePersonalDetailsComplianceEnabled =
    !!siteConfig.prePersonalDetailsCompliance?.displayOptionalSections.displayPrePersonalDetailsCompliance;

  const total = screeningQuestionsEnabled ? steps.length : steps.length - 1;

  const navigateBack = (activeStepKey: IssuingSceneStepKeys) => {
    const activeStepIndex = steps.findIndex(({ key }) => activeStepKey === key);

    if (activeStepIndex === 0) {
      return history.push(baseUrl);
    }

    if (isPersonalDetailsStartingStep && !isPrePersonalDetailsComplianceEnabled && activeStepIndex === 1) {
      return history.push(baseUrl);
    }

    if (!isPersonalDetailsStartingStep && !screeningQuestionsEnabled && activeStepIndex === 1) {
      return history.push(baseUrl);
    }

    const previousStep = steps[activeStepIndex - 1];
    setPriorStepKey(steps[activeStepIndex].key);
    setCurrentStepKey(previousStep.key);
  };

  return (
    <ProgressBar
      completedColor={getColor({ siteConfig, color: 'primary' })}
      stepKey={getCompletedStepIndex({
        activeStepKey,
        isPersonalDetailsStartingStep,
        isPrePersonalDetailsComplianceEnabled,
        screeningQuestionsEnabled,
        steps,
      })}
      total={total}
      totalColor={getColor({ siteConfig, color: 'disabled' })}
      navigateBack={(activeStepKey) => navigateBack(activeStepKey)}
      activeStepKey={activeStepKey}
      firstStep={firstStep}
      premiumDisplay={premiumDisplay}
      isLoadingBackButton={isLoadingBackButton}
    />
  );
};

const getCompletedStepIndex = (params: {
  steps: StepperStep[];
  activeStepKey: IssuingSceneStepKeys;
  screeningQuestionsEnabled: boolean;
  isPrePersonalDetailsComplianceEnabled: boolean;
  isPersonalDetailsStartingStep: boolean;
}) => {
  const {
    steps,
    activeStepKey,
    screeningQuestionsEnabled,
    isPersonalDetailsStartingStep,
    isPrePersonalDetailsComplianceEnabled,
  } = params;

  if (!isPersonalDetailsStartingStep && !screeningQuestionsEnabled) {
    return steps.findIndex(({ key }) => activeStepKey === key) - 1;
  }

  if (isPersonalDetailsStartingStep && !isPrePersonalDetailsComplianceEnabled) {
    return steps.findIndex(({ key }) => activeStepKey === key) - 1;
  }

  return steps.findIndex(({ key }) => activeStepKey === key);
};
