import { Environment } from 'models/environment';
import { NetworkPolicyholder, Policyholder } from 'policyholder/domain/policyholder';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  environment: Environment;
}

export const getPolicyholder = async (params: Params) => {
  const { auth, environment, organizationId } = params;

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/policyholders`,
    method: 'get',
    auth,
  });

  const networkPolicyholder: NetworkPolicyholder = await response.json();
  const policyholder = Policyholder.fromNetwork(networkPolicyholder);

  return policyholder;
};
