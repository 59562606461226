// @ts-ignore no typings
import { ManagementView } from 'policy-management/policy-management-scene';
import { Environment } from '../models/environment';
import { PrefillValues } from './domain/prefill-values';
import { useEmbedParamsContext } from './embed-params-context';
import { useQueryParams } from './hooks/url';
import { parsePrefillParams, PrefillInputTypes } from './utils';

export enum AuthTypes {
  JWT = 'jwt',
  APIKey = 'api_key',
}

export interface EmbedAuth {
  type: AuthTypes;
  token: string;
}

export const useAuth = (): EmbedAuth => {
  const { queryParams } = useQueryParams<{ api_key?: string; token?: string }>();

  if (queryParams.api_key) {
    return {
      token: queryParams.api_key,
      type: AuthTypes.APIKey,
    };
  }

  return {
    token: queryParams.token,
    type: AuthTypes.JWT,
  };
};

export const useUrlAuth = () => {
  const auth = useAuth();

  if (auth.type === AuthTypes.JWT) {
    return `token=${auth.token}`;
  }

  return `api_key=${auth.token}`;
};
export const useEnvironment = () => {
  const auth = useAuth();
  const { queryParams } = useQueryParams<{ environment: string }>();

  if (auth.type === AuthTypes.APIKey) {
    return auth.token.slice(0, auth.token.indexOf('_')) as Environment;
  }

  const environment = queryParams.environment;

  if (environment === Environment.Production) {
    return Environment.Production;
  }

  return Environment.Sandbox;
};

export const useDraftDefinition = () => {
  const { queryParams } = useQueryParams<{ draft_definition: boolean }>();
  return queryParams.draft_definition === 'true';
};

export const usePrefillValues = (): PrefillValues => {
  const { queryParams } = useQueryParams<{ prefill_values: string }>();

  return {
    screeningQuestions: undefined,
    quote: parsePrefillParams(queryParams, PrefillInputTypes.Quote),
    personalDetails: parsePrefillParams(queryParams, PrefillInputTypes.PersonalDetails),
    prePersonalDetailsConsent: undefined,
    application: parsePrefillParams(queryParams, PrefillInputTypes.Application),
    prePaymentDetailsConsent: undefined,
    payment: parsePrefillParams(queryParams, PrefillInputTypes.Payment),
  };
};

export const useLiveOrDraft = () => {
  const { queryParams } = useQueryParams<{ draft_definition: string }>();

  return `${queryParams.draft_definition ? `&draft_definition=${queryParams.draft_definition}` : ''}`;
};

export const useLiveOrDraftCollectionModule = () => {
  const { queryParams } = useQueryParams<{ draft_collection_module: string }>();

  return `${
    queryParams.draft_collection_module !== undefined
      ? `&draft_collection_module=${queryParams.draft_collection_module}`
      : ''
  }`;
};

export const useIsDraftCollectionModule = () => {
  const { queryParams } = useQueryParams<{ draft_collection_module: string }>();

  return queryParams.draft_collection_module === 'true';
};

export const getApiHost = (params: { environment: Environment }) => {
  const { environment } = params;
  const mapper: { [key in Environment]: string | undefined } = {
    [Environment.Sandbox]: process.env.REACT_APP_ROOT_SANDBOX_API,
    [Environment.Production]: process.env.REACT_APP_ROOT_PROD_API,
  };

  const apiBaseUrl = mapper[environment];
  if (!apiBaseUrl) {
    throw new Error('Cannot determine api base url');
  }

  return apiBaseUrl;
};

export const getBasePathname = (params: {
  organizationId: string;
  auth: EmbedAuth;
  clientAppAuth: boolean;
  pathOverride?: string;
}) => {
  const { auth, organizationId, clientAppAuth, pathOverride } = params;

  const apiKeyUrl = clientAppAuth
    ? `/v1/apps/${organizationId}/insurance`
    : `/v1/insurance/organizations/${organizationId}`;

  return auth.type === AuthTypes.JWT
    ? pathOverride
      ? pathOverride
      : `/v1/insurance/organizations/${organizationId}/embed`
    : apiKeyUrl;
};

export const getEmbedSessionString = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { queryParams } = useQueryParams<{ embed_session_id?: string }>();

  return queryParams.embed_session_id ? `&embed_session_id=${queryParams.embed_session_id}` : '';
};

const getPrefillQueryString = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const prefillValues = usePrefillValues();

  const prefillJson = [
    { name: PrefillInputTypes.PersonalDetails, data: prefillValues.personalDetails },
    { name: PrefillInputTypes.Quote, data: prefillValues.quote },
    { name: PrefillInputTypes.Payment, data: prefillValues.payment },
    { name: PrefillInputTypes.Application, data: prefillValues.application },
  ];

  const existingParams = prefillJson.filter((i: { data: any; name: string }) => Object.keys(i.data).length > 0);

  return existingParams.length > 0
    ? `${existingParams.map((i: { data: any; name: string }) => `&${i.name}=${JSON.stringify(i.data)}`).join('')}`
    : '';
};

export const useBaseUrl = () => {
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId, productModuleKey } = embedParams;
  const urlAuth = useUrlAuth();
  const prefillQueryString = getPrefillQueryString();
  const embedSessionString = getEmbedSessionString();
  const liveOrDraft = useLiveOrDraft();
  const liveOrDraftCollectionModule = useLiveOrDraftCollectionModule();

  const baseUrl = auth.type === AuthTypes.APIKey ? `/${productModuleKey}?` : `/organization/${organizationId}?`;

  if (auth.type === AuthTypes.APIKey) {
    return `${baseUrl}${urlAuth}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}`;
  }

  return `${baseUrl}${urlAuth}&environment=${environment}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}${embedSessionString}`;
};

export const useIssuePolicyUrl = () => {
  const { embedParams } = useEmbedParamsContext();
  const { organizationId, productModuleKey, environment } = embedParams;
  const urlAuth = useUrlAuth();
  const auth = useAuth();
  const prefillQueryString = getPrefillQueryString();
  const embedSessionString = getEmbedSessionString();
  const liveOrDraft = useLiveOrDraft();
  const liveOrDraftCollectionModule = useLiveOrDraftCollectionModule();

  console.log('liveOrDraftCollectionModule', liveOrDraftCollectionModule);

  const baseUrl =
    auth.type === AuthTypes.JWT
      ? `/organization/${organizationId}/issue-policy?`
      : `/${productModuleKey}/issue-policy?`;

  if (auth.type === AuthTypes.APIKey) {
    return `${baseUrl}${urlAuth}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}${embedSessionString}`;
  }

  return `${baseUrl}${urlAuth}&environment=${environment}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}${embedSessionString}`;
};

export const usePolicyManagementUrl = (params: {
  view: ManagementView;
  policyId: string | undefined;
  query?: { key: string; value: string }[];
}) => {
  const { view, policyId, query } = params;
  const { embedParams } = useEmbedParamsContext();
  const { organizationId, productModuleKey, environment } = embedParams;
  const urlAuth = useUrlAuth();
  const auth = useAuth();
  const liveOrDraft = useLiveOrDraft();
  const liveOrDraftCollectionModule = useLiveOrDraftCollectionModule();
  const queryString = (query || []).map(({ key, value }) => `${key}=${value}`).join('&');

  const baseUrl =
    auth.type === AuthTypes.APIKey ? `/${productModuleKey}/manage?` : `/organization/${organizationId}/manage?`;

  const policyQuery = policyId ? `&policy_id=${policyId}` : '';

  return `${baseUrl}view=${view}${policyQuery}&${urlAuth}&environment=${environment}${liveOrDraft}${liveOrDraftCollectionModule}&${queryString}`;
};

export const useConfirmationUrl = () => {
  const { embedParams } = useEmbedParamsContext();
  const { organizationId, productModuleKey, auth, environment } = embedParams;
  const urlAuth = useUrlAuth();
  const prefillQueryString = getPrefillQueryString();
  const liveOrDraft = useLiveOrDraft();
  const liveOrDraftCollectionModule = useLiveOrDraftCollectionModule();

  const baseUrl =
    auth.type === AuthTypes.APIKey
      ? `/${productModuleKey}/confirmation?`
      : `/organization/${organizationId}/confirmation?`;

  if (auth.type === AuthTypes.APIKey) {
    return `${baseUrl}${urlAuth}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}`;
  }

  return `${baseUrl}${urlAuth}&environment=${environment}${prefillQueryString}${liveOrDraft}${liveOrDraftCollectionModule}`;
};

export const applyPolicyDetailsToUrl = (props: {
  baseUrl: string;
  policyId: string;
  policyholderId: string;
  environment: Environment;
}) => {
  const { baseUrl, policyId, environment, policyholderId } = props;
  return `${baseUrl}${
    baseUrl.includes('?') ? '&' : '?'
  }policy_id=${policyId}&policyholder_id=${policyholderId}&environment=${environment}`;
};
export interface FieldError {
  path: string;
  message: string;
}

export class ApiError extends Error {
  type: string;
  fieldErrors?: FieldError[];

  constructor(
    { message, type, fieldErrors }: { message: string; type: string; fieldErrors?: FieldError[] },
    ...params: any[]
  ) {
    super(...params);
    this.name = 'ApiError';
    this.message = message;
    this.type = type;
    this.fieldErrors = fieldErrors;
  }
}

const defaultErrorMessage =
  'Something went wrong. Please try again or contact support@root.co.za if the problem persists.';

export const apiRequest = async ({
  url,
  method,
  headers,
  body,
  auth,
}: {
  url: string;
  method: 'get' | 'post' | 'put' | 'patch' | 'delete' | 'head';
  headers?: Record<string, string>;
  body?: Record<string, string | number | boolean | null | undefined | Record<string, any>> | Record<string, any>[];
  auth?: EmbedAuth;
}) => {
  const jwt = auth?.type === AuthTypes.JWT ? auth.token : undefined;
  const apiKey = auth?.type === AuthTypes.APIKey ? auth.token : undefined;

  const defaultHeaders = {
    'content-type': 'application/json',
    ...(jwt ? { 'x-access-token': jwt } : {}),
    ...(apiKey ? { authorization: `Bearer ${apiKey}` } : {}),
  };

  try {
    const response = await fetch(url, {
      method: method.toUpperCase(),
      headers: { ...defaultHeaders, ...headers },
      body: body && JSON.stringify(body),
    });
    if (!response.ok) {
      if (response.headers.get('content-type')?.includes('application/json')) {
        const json = await response.json();
        throw new ApiError({
          message: json?.error?.message || defaultErrorMessage,
          fieldErrors: json?.error?.errors,
          type: json?.error?.type || 'unknown_error',
        });
      } else {
        throw new ApiError({
          message: defaultErrorMessage,
          type: 'unknown_error',
        });
      }
    }

    return response;
  } catch (error: any) {
    throw new ApiError({
      message:
        error.message === 'Failed to fetch'
          ? 'Unable to make request, please check your network and try again.'
          : error.message || defaultErrorMessage,
      type: error.type || 'unknown_error',
      fieldErrors: error.fieldErrors,
    });
  }
};
