import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import React from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { useSiteConfigContext } from 'style-context';
import { PolicySummaryStyles } from '../styles/policy-summary-styles';
import { policyStatusColorMapping } from './policy-status-display';
import { PolicySummaryCell } from './policy-summary-cell';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise } from 'shared/hooks/promise';
import { compileHandleBarsWithLabels } from 'general/actions/compileHandleBarsWithLabels';
import { ErrorAlert } from 'rootstrap/components/error-alert';

interface PolicySummaryProps {
  policy: Policy | undefined;
  policyholder: Policyholder | undefined;
  isLoading: boolean;
}

const {
  BodyWrapper,
  PolicyNumberDisplay,
  PolicyStatusWrapper,
  PolicySummaryHeader,
  SplashContainer,
  StyledRow,
  SummaryPanel,
  ErrorWrapper,
} = PolicySummaryStyles;

export const PolicySummary = (params: PolicySummaryProps) => {
  const { siteConfig } = useSiteConfigContext();
  const { policy, policyholder, isLoading } = params;
  const { policyNumber } = policy || {};
  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;

  const siteConfigSummaryWording = siteConfig?.management?.policyDetails.wording.summary;

  const {
    result: compiledSummaryWording,
    isLoading: isLoadingHandlebars,
    error,
  } = usePromise(async () => {
    if (policy && policyholder && siteConfigSummaryWording) {
      const compiledHandleBars = await compileHandleBarsWithLabels({
        auth,
        organizationId,
        environment,
        siteConfigSummaryWording,
        policy,
        policyholder,
      });

      return compiledHandleBars;
    }
  }, [policy, siteConfig, policyholder]);

  return (
    <SplashContainer siteConfig={siteConfig}>
      {error && (
        <ErrorWrapper>
          <ErrorAlert error={error} />
        </ErrorWrapper>
      )}
      <SummaryPanel siteConfig={siteConfig}>
        {policy && !isLoading && !error && (
          <PolicySummaryHeader>
            <PolicyNumberDisplay id='policy-details-policy-number-display'>{policyNumber}</PolicyNumberDisplay>
            <PolicyStatusWrapper>
              <PolicySummaryStyles.PolicyStatus color={policyStatusColorMapping(policy.status, siteConfig)}>
                {policy.prettyPolicyStatus()}
              </PolicySummaryStyles.PolicyStatus>
            </PolicyStatusWrapper>
          </PolicySummaryHeader>
        )}
        <BodyWrapper>
          {(isLoading || isLoadingHandlebars) && <LoadingInputs count={1} />}
          {!isLoading && compiledSummaryWording && (
            <>
              <StyledRow>
                {}
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[0].label}
                    value={compiledSummaryWording[0].content || '-'}
                  />
                </div>
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[1].label}
                    value={compiledSummaryWording[1].content || '-'}
                    hideBorderRight={true}
                  />
                </div>
              </StyledRow>
              <StyledRow>
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[2].label}
                    value={compiledSummaryWording[2].content || '-'}
                  />
                </div>
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[3].label}
                    value={compiledSummaryWording[3].content || '-'}
                    hideBorderRight={true}
                  />
                </div>
              </StyledRow>
              <StyledRow>
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[4].label}
                    value={compiledSummaryWording[4].content || '-'}
                    hideBorderBottom={true}
                  />
                </div>
                <div>
                  <PolicySummaryCell
                    siteConfig={siteConfig}
                    label={compiledSummaryWording[5].label}
                    value={compiledSummaryWording[5].content || '-'}
                    hideBorderRight={true}
                    hideBorderBottom={true}
                  />
                </div>
              </StyledRow>
            </>
          )}
        </BodyWrapper>
      </SummaryPanel>
    </SplashContainer>
  );
};
