import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { devices } from 'rootstrap/global-styles/devices';
import { useSiteConfigContext } from 'style-context';
import { VideoIcon } from 'assets/video-icon';
import { getColor, getFontFamily, getFontSize, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { BlankButton, buttonContents, LongButtonSuccess } from 'rootstrap/components/button/styles';
import { Spacing } from 'rootstrap/global-styles';
import { useIssuePolicyUrl } from 'shared/api';
import { LandingPageFooter } from './landing-page-footer';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';

interface Props {
  isLoading: boolean;
  isCreatingEmbedSession: boolean;
  setVideoDisplayed: (v: boolean) => void;
}

export const LandingPageMainContent = (props: Props) => {
  const history = useHistory();
  const issuePolicyUrl = useIssuePolicyUrl();
  const { siteConfig } = useSiteConfigContext();
  const { stepCompletedTrack } = useMixpanelTrack();

  // Landing page params
  const title = siteConfig?.landing.wording.title;
  const subTitle = siteConfig?.landing.wording.subTitle;
  const description = siteConfig?.landing.wording.description;
  const descriptionBulletsEnabled = siteConfig?.landing.displayOptionalSections.descriptionBullets;
  const descriptionBullets = siteConfig?.landing.wording.descriptionBullets;
  const createQuoteButton = siteConfig?.landing.wording.createQuoteButton;
  const displayWatchVideo = siteConfig?.landing.displayOptionalSections.watchVideo;
  const youTubeVideoId = siteConfig?.landing.links.youTubeVideoId;

  // header page params;
  const titleImage = siteConfig?.header.displayOptionalSections.titleImage;
  const titleUrl = siteConfig?.header.images.titleUrl;
  const isHeaderImageSet = !!(titleImage && titleUrl);

  const isLoading = props.isLoading || props.isCreatingEmbedSession;
  const isLoadingPageMount = !siteConfig;

  // Style params
  const bodyFontSize = siteConfig?.styles.fontSize.body;

  // State for description container height
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState<number>(0);

  // Refs for header and footer
  useLayoutEffect(() => {
    const headerElement = document.getElementById('main-content-header');
    const footerElement = document.getElementById('main-content-footer');
    const mainContent = document.getElementById('main-content');
    const landingPageFooter = document.getElementById('landing-page-footer');

    if (headerElement && footerElement && mainContent && landingPageFooter) {
      setDescriptionMaxHeight(
        mainContent.offsetHeight -
          landingPageFooter.offsetHeight -
          headerElement.offsetHeight -
          footerElement.offsetHeight,
      );
    }

    function updateHeight() {
      if (headerElement && footerElement && mainContent && landingPageFooter) {
        setDescriptionMaxHeight(
          mainContent.offsetHeight -
            landingPageFooter.offsetHeight -
            headerElement.offsetHeight -
            footerElement.offsetHeight,
        );
      }
    }

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <LandingPageMainContentWrapper>
      <LandingPageMainContentStyle id='main-content'>
        <div id='main-content-header'>
          <MainHeader id='main-header' siteConfig={siteConfig || null} style={{ marginTop: isHeaderImageSet ? 50 : 0 }}>
            {title || ''}
          </MainHeader>
          {!isLoadingPageMount && <LandingSubTitle siteConfig={siteConfig}>{subTitle}</LandingSubTitle>}
        </div>
        <DescriptionContainer
          descriptionMaxHeight={descriptionMaxHeight}
          style={{ maxHeight: descriptionMaxHeight }}
          id='main-content-description'
          siteConfig={siteConfig}
        >
          {description?.map((description) => {
            return (
              <p
                style={{
                  fontSize: bodyFontSize,
                  fontWeight: 'normal',
                  lineHeight: '25px',
                  color: 'black',
                }}
              >
                {description}
              </p>
            );
          })}
          {descriptionBulletsEnabled && descriptionBullets && (
            <BulletList siteConfig={siteConfig}>
              {descriptionBullets.map((item) => (
                <li>{item}</li>
              ))}
            </BulletList>
          )}
        </DescriptionContainer>
        <div id='main-content-footer'>
          <ButtonContainer>
            <div>
              <LongButtonSuccess
                id='open-quote-flow'
                siteConfig={siteConfig || null}
                disabled={isLoading || isLoadingPageMount}
                onClick={() => {
                  stepCompletedTrack({
                    stepName: MixpanelStepNames.LandingPage,
                  });
                  history.push(`${issuePolicyUrl}`);
                }}
              >
                {buttonContents({ isLoading, children: createQuoteButton })}
              </LongButtonSuccess>
            </div>
            {displayWatchVideo && youTubeVideoId && (
              <LongButtonBlank siteConfig={siteConfig || null} onClick={() => props.setVideoDisplayed(true)}>
                <VideoIcon
                  fill={getColor({ siteConfig, color: 'highlight' })}
                  style={{ marginRight: 6, marginBottom: 2 }}
                />
                <span>Watch video</span>
              </LongButtonBlank>
            )}
          </ButtonContainer>
        </div>
      </LandingPageMainContentStyle>
      <LandingPageFooter />
    </LandingPageMainContentWrapper>
  );
};

const DescriptionContainer = styled.div<{
  descriptionMaxHeight: number;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  max-height: ${({ descriptionMaxHeight }) => descriptionMaxHeight}px;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
    padding-right: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
    border-radius: 20px;
    margin-right: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9ea0a3;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9ea0a3;
    border-radius: 20px;
  }
`;

const LandingPageMainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LandingPageMainContentStyle = styled.div`
  width: 40%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding-left: 95px;
  padding-right: 95px;
  border-radius: 20px 20px 0px 0px;
  height: 100vh;

  overflow: scroll;

  @media ${devices.laptopL} {
    width: 50%;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media ${devices.laptop} {
    width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media ${devices.tabletL} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${devices.mobileL} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MainHeader = styled.h1<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'title' })};
  line-height: 120%;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  font-weight: 700;
  font-style: normal;
  padding-top: 60px;
  margin-bottom: 0px;
`;

const LandingSubTitle = styled.h6<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })};
  font-weight: 400;
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'subTitle' })};
  padding-top: 12px;
  margin-bottom: 42px;

  @media ${devices.mobileL} {
    margin-bottom: 18px;
  }
`;

const BulletList = styled.ul<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'body' })};
  display: inline-flex;
  flex-direction: column;
  line-height: 35px;
  list-style: none;
  justify-items: left;
  padding-inline-start: 0;

  li {
    display: flex;
    color: black;
  }

  li::before {
    content: '•';
    font-size: 1.5em;
    width: 1.5em;
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const LongButtonBlank = styled(BlankButton).attrs((props) => ({
  className: props.className,
  buttonStyles: props.buttonStyles,
}))<{ siteConfig: ProductModuleDefinitionEmbeddedConfig }>`
  width: 100%;
  height: 45px;
  margin-bottom: ${Spacing.sm};
  font-weight: normal;
  border: 0px !important;

  ${({ buttonStyles }) =>
    buttonStyles &&
    css`
      ${buttonStyles};
    `};
`;
