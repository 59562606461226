import React from 'react';

export const LockIconClosed = (params: { fill?: string; style?: React.CSSProperties; onClick: () => void }) => {
  const { fill, style, onClick } = params;
  return (
    <svg
      onClick={onClick}
      style={{ ...style, cursor: 'pointer' }}
      width='32'
      height='33'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='1.03711' width='26' height='26.7551' rx='13' fill='white' />
      <rect x='1' y='1.03711' width='26' height='26.7551' rx='13' stroke='#CED1DA' stroke-width='2' />
      <g clip-path='url(#clip0_10853_182462)'>
        <path
          d='M9.72455 14.2447H10.2655H11.49H16.5104V11.5101C16.5104 10.2499 15.3844 9.22449 14.0002 9.22449C12.8923 9.22449 11.9494 9.88156 11.6174 10.7904C11.4783 11.1717 11.2157 11.5101 10.8778 11.5101C10.5396 11.5101 10.2572 11.1775 10.3466 10.7815C10.7044 9.19461 12.2068 8 14.0002 8C16.0596 8 17.7349 9.5747 17.7349 11.5101V14.2447C17.7349 14.2447 17.9771 14.2447 18.2757 14.2447C18.5744 14.2447 18.8164 14.5736 18.8164 14.9794V19.2653C18.8164 19.6711 18.4875 20 18.0817 20H9.91851C9.51272 20 9.18381 19.6711 9.18381 19.2653V14.9793C9.18381 14.5735 9.42577 14.2447 9.72455 14.2447ZM14.0002 17.5305C14.3046 17.5305 14.5512 17.2836 14.5512 16.9792C14.5512 16.6748 14.3044 16.428 14.0002 16.428C13.6958 16.428 13.449 16.6748 13.449 16.9792C13.449 17.2836 13.6956 17.5305 14.0002 17.5305Z'
          fill={fill}
        />
        <path
          d='M18.2755 14.2447H17.7345H16.51H11.4896V11.5101C11.4896 10.2499 12.6156 9.22449 13.9998 9.22449C15.1077 9.22449 16.0506 9.88156 16.3826 10.7904C16.5217 11.1717 16.7843 11.5101 17.1222 11.5101C17.4604 11.5101 17.7428 11.1775 17.6534 10.7815C17.2956 9.19461 15.7932 8 13.9998 8C11.9404 8 10.2651 9.5747 10.2651 11.5101V14.2447C10.2651 14.2447 10.0229 14.2447 9.72433 14.2447C9.42555 14.2447 9.18359 14.5736 9.18359 14.9794V19.2653C9.18359 19.6711 9.5125 20 9.9183 20H18.0815C18.4873 20 18.8162 19.6711 18.8162 19.2653V14.9793C18.8162 14.5735 18.5742 14.2447 18.2755 14.2447ZM13.9998 17.5305C13.6954 17.5305 13.4488 17.2836 13.4488 16.9792C13.4488 16.6748 13.6956 16.428 13.9998 16.428C14.3042 16.428 14.551 16.6748 14.551 16.9792C14.551 17.2836 14.3044 17.5305 13.9998 17.5305Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_10853_182462'>
          <rect width='12' height='12' fill='white' transform='matrix(-1 0 0 1 20 8)' />
        </clipPath>
      </defs>
    </svg>
  );
};
