import React, { useEffect } from 'react';
import { InputFieldParams } from './input-field';
import { getNextComponentId, setFormFocusOrBlur } from './utils';
import styled from 'styled-components';
import Markdown from 'react-markdown';

interface ParagraphProps extends InputFieldParams<any> {}

export const Paragraph = (params: ParagraphProps) => {
  const { name, displayProperties, disableScrollToElement, label } = params;
  const { activeElement, setActiveElement } = displayProperties;

  useEffect(() => {
    const isActive = activeElement.elementId === name;

    if (isActive) {
      setActiveElement && setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
      setFormFocusOrBlur({
        activeElement,
        scrollToId: name,
        isFirstElement: displayProperties.index === 0,
        disableScrollToElement,
      });
    }
  }, [activeElement, name]);

  return (
    <StyledParagraph id={name}>
      {typeof label === 'string' ? (
        <Markdown
          components={{
            p: ({ node, ...props }) => <p {...props} style={{ marginBottom: 0 }} />,
          }}
          children={label}
          className='markdown'
        />
      ) : (
        label
      )}
    </StyledParagraph>
  );
};

const StyledParagraph = styled.p`
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 0px;
`;
