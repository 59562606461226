import { CollectionModule } from 'collection-modules/domain/collection-module';
import { PaymentMethodType } from 'payment-methods/domain/payment-method';
import { sentenceCase } from 'sentence-case';

export const getNewPaymentMethodOptions = (params: {
  paymentMethodTypes: PaymentMethodType[];
  collectionModules: CollectionModule[];
}) => {
  const { paymentMethodTypes, collectionModules } = params;
  const isDebitOrderEnabled = paymentMethodTypes.includes(PaymentMethodType.DebitOrder);
  const isExternalEnabled = paymentMethodTypes.includes(PaymentMethodType.External);
  const isCardEnabled = paymentMethodTypes.includes(PaymentMethodType.Card);
  const isEftEnabled = paymentMethodTypes.includes(PaymentMethodType.Eft);

  const paymentMethods: { label: string; value: PaymentMethodType | string; enabled: boolean }[] = [
    { label: 'Debit order', value: PaymentMethodType.DebitOrder, enabled: isDebitOrderEnabled },
    { label: 'External', value: PaymentMethodType.External, enabled: isExternalEnabled },
    { label: 'Card', value: PaymentMethodType.Card, enabled: isCardEnabled },
    { label: 'Eft', value: PaymentMethodType.Eft, enabled: isEftEnabled },
  ];

  collectionModules.forEach(({ collectionModuleKey, collectionModuleName }) => {
    paymentMethods.push({
      label: sentenceCase(collectionModuleName),
      value: collectionModuleKey,
      enabled: true,
    });
  });

  return paymentMethods.filter((i) => i.enabled).map((i) => ({ label: i.label, value: i.value }));
};
