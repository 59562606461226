import React from 'react';
import { globalStyles } from 'rootstrap/global-styles';
import {
  getColor,
  ProductModuleDefinitionEmbeddedConfig,
  ProductModuleDefinitionEmbeddedConfigColors,
} from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

export const Divider = (props: any) => {
  const { siteConfig } = useSiteConfigContext();

  return <StyledDivider className='field-divider' siteConfig={siteConfig} />;
};

export const StyledDivider = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  siteConfigColor?: keyof ProductModuleDefinitionEmbeddedConfigColors;
  height?: number;
}>`
  border-top: ${({ siteConfig, siteConfigColor, height }) =>
    `${height || 1}px solid ${getColor({ siteConfig, color: siteConfigColor || 'disabled' })}`};
  border-radius: ${globalStyles.borderRadius.large};
  margin-bottom: 0px;
`;

export const StyledDividerOpenColor = styled.div<{
  height?: number;
  color: string;
}>`
  border-top: ${({ height, color }) => `${height || 1}px solid ${color}`};
  border-radius: ${globalStyles.borderRadius.large};
  margin-bottom: 0px;
`;
