import { globalStyles } from 'rootstrap/global-styles';
import styled from 'styled-components';

export const StyledTitle = styled.p`
  font-size: ${globalStyles.fontSize.title};
  font-weight: 400;
  color: ${globalStyles.colors.Body};
  margin-top: 39px !important;
  margin-bottom: 20px !important;
`;
