import React from 'react';

interface Icon24PxCrossParams {
  fillColor?: string;
  width?: number;
  height?: number;
  onClick: () => void;
}
export const Icon24PxCross = (params: Icon24PxCrossParams) => {
  const fillColor = params.fillColor || '#C3C3C3';
  const width = params.width || 24;
  const height = params.height || 24;
  return (
    <svg onClick={params.onClick} xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
      <g fill='none' fill-rule='evenodd' stroke={fillColor} stroke-linecap='round' stroke-width='2'>
        <path d='M4 4l16 16M20 4L4 20' />
      </g>
    </svg>
  );
};
