import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardGroup } from 'rootstrap/components/card';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { Col, Row } from 'reactstrap';
import { usePromise } from 'shared/hooks/promise';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { RadioField } from 'rootstrap/components/forms/new-fields/radio-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CountryCode } from 'libphonenumber-js';
import { EmbeddedConfigSection, getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { PolicyholderAddressOptInOptions } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { Address } from 'policyholder/domain/policyholder';
import { FetchifyAddressField } from 'rootstrap/components/forms/new-fields/fetchify-field';
import { getAddressStringForFetchifyDefault, getFetchifyDefaultValues } from '../utils/fetchify-helpers';

export interface PersonalAddressData {
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  areaCode?: string;
  suburb?: string;
  city?: string;
  googlePlaceId?: string;
  geoCoordinatesLatitude?: string;
  geoCoordinatesLongitude?: string;
}

interface Props {
  setIsValid: (contactDetailsIsValid: boolean) => void;
  setSectionData: (contactDetailsSectionData: PersonalAddressData) => void;
  addressDetailsSectionData?: PersonalAddressData;
  prefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  addressOptIn: boolean | undefined;
  setAddressOptIn: (v: boolean) => void;
  isCompleted: boolean;
  isCompany?: boolean;
  productModule: ProductModule | undefined;
  fetchifyData: Address | undefined;
  setFetchifyData: (v: Address | undefined) => void;
}

export const PhysicalAddressSection = (props: Props) => {
  const {
    setIsValid,
    addressDetailsSectionData,
    activeElement,
    setActiveElement,
    addressOptIn,
    setAddressOptIn,
    isCompleted,
    productModule,
    fetchifyData,
    setFetchifyData,
  } = props;
  const { siteConfig } = useSiteConfigContext();
  const { addressOptIn: prefillAddressOptIn, address: prefillValues } = props.prefillValues;
  const [sectionData, setSectionData] = useState<PersonalAddressData | undefined>();
  const fetchifyAutoCompleteEnabled =
    siteConfig?.[EmbeddedConfigSection.PersonalDetails].displayOptionalSections.fetchifyAutoComplete;

  const policyholderSettings = productModule?.productModuleDefinition?.settings.policyholder;
  const individualPolicyholderFields = policyholderSettings?.individualPolicyholderFields;
  const isSuburbRequired = individualPolicyholderFields?.address?.suburb?.required ?? true;

  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const { AddressLine1, AddressLine2, AddressOptIn, FetchifyAutocomplete, City, AreaCode, Country, Suburb } =
    PersonalDetailsInputs;

  const steppedComponentsBehavior = (personalDetailsInputs: PersonalDetailsInputs): SteppedComponentsBehavior => ({
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? (isCompleted ? isCompleted : !!form.watch(personalDetailsInputs)) : true,
  });

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: addressDetailsSectionData,
      }),
      [],
    ),
  });
  form.watch();

  const addressOptInForm = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: { AddressOptIn: fetchifyAutoCompleteEnabled ? PolicyholderAddressOptInOptions.No : addressOptIn },
      }),
      [],
    ),
  });
  addressOptInForm.watch();

  usePromise(async () => {
    execute();
    await form.trigger();
  }, []);

  usePromise(async () => {
    setIsValid(form.formState.isValid && (fetchifyAutoCompleteEnabled || addressOptIn !== undefined));
  }, [form.formState.isValid, JSON.stringify(fetchifyData)]);

  const execute = () => {
    const formValues = form.getValues() as PersonalAddressData;
    props.setSectionData(formValues);
    setSectionData(formValues);
  };

  const onPlaceSelected = (address: Address) => {
    props.setFetchifyData(address);
  };

  useEffect(() => {
    if (fetchifyData) {
      form.setValue(AddressLine1, `${fetchifyData?.line1}${fetchifyData?.line2 ? ` ${fetchifyData?.line2}` : ''}`);
      form.setValue(AddressLine2, undefined);
      form.setValue(PersonalDetailsInputs.Suburb, fetchifyData?.suburb);
      form.setValue(PersonalDetailsInputs.City, fetchifyData?.city);
      form.setValue(PersonalDetailsInputs.Country, fetchifyData?.country);
      form.setValue(PersonalDetailsInputs.AreaCode, fetchifyData?.areaCode);
      form.setValue(PersonalDetailsInputs.FetchifyAutocomplete, getAddressStringForFetchifyDefault(fetchifyData));

      form.trigger();
    }
  }, [fetchifyData]);

  const submit = (data: PersonalAddressData) => {
    props.setSectionData(data);
    setSectionData(data);
  };

  const executePolicyOptIn = () => {
    const formValues = addressOptInForm.getValues() as { AddressOptIn: string };
    setAddressOptIn(formValues.AddressOptIn === PolicyholderAddressOptInOptions.Yes);
    setIsValid(form.formState.isValid);
  };

  const addressFieldsRequired = addressOptIn === true;
  const country = form.watch(PersonalDetailsInputs.Country);

  useEffect(() => {
    if (
      (activeElement.elementId === PersonalDetailsInputs.AddressLine1 ||
        activeElement.elementId === PersonalDetailsInputs.FetchifyAutocomplete) &&
      !addressOptIn
    ) {
      document.getElementById('stepper-next')?.focus();
    }
  }, [activeElement]);

  const shouldDisplaySuburb =
    !fetchifyAutoCompleteEnabled ||
    (fetchifyAutoCompleteEnabled && fetchifyData?.line1 && !fetchifyData.suburb && isSuburbRequired);

  return (
    <>
      <CardGroup>
        <form
          onBlur={() => {
            executePolicyOptIn();
          }}
          onChange={() => {
            executePolicyOptIn();
          }}
        >
          <Row>
            <Col sm={12}>
              <RadioField
                placeholder={undefined}
                options={[
                  { label: 'Yes', value: PolicyholderAddressOptInOptions.Yes },
                  { label: 'No', value: PolicyholderAddressOptInOptions.No },
                ]}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                defaultValue={
                  addressOptIn === undefined
                    ? undefined
                    : addressOptIn
                    ? PolicyholderAddressOptInOptions.Yes
                    : PolicyholderAddressOptInOptions.No
                }
                prefillValue={prefillAddressOptIn}
                name={AddressOptIn}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.addressOptIn.label })}
                prefillAction={siteConfig?.inputFields.personalDetails.addressOptIn.prefillAction}
                form={addressOptInForm}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: fetchifyAutoCompleteEnabled
                      ? PersonalDetailsInputs.FetchifyAutocomplete
                      : PersonalDetailsInputs.AddressLine1,
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior(AddressOptIn)}
                disableNextButton={steppedComponentsBehavior(AddressOptIn).disableNextButton || !addressOptIn}
              />
            </Col>
          </Row>
        </form>
      </CardGroup>
      {addressOptIn && (
        <>
          {fetchifyAutoCompleteEnabled && (
            <form
              onBlur={() => execute()}
              onChange={() => execute()}
              onSubmit={form.handleSubmit((data: PersonalAddressData) => submit(data))}
            >
              <Row>
                <Col sm={12}>
                  <FetchifyAddressField
                    isSuburbRequired={isSuburbRequired}
                    onPlaceSelected={onPlaceSelected}
                    placeholder={undefined}
                    defaultValue={getFetchifyDefaultValues(addressDetailsSectionData)}
                    {...steppedComponentsBehavior(FetchifyAutocomplete)}
                    validators={
                      !!addressFieldsRequired
                        ? [
                            {
                              validation: {
                                type: ValidationTypes.REQUIRED,
                              },
                            },
                          ]
                        : []
                    }
                    name={FetchifyAutocomplete}
                    label={getWording({
                      wording: siteConfig?.inputFields.personalDetails.fetchifyAutocomplete?.label,
                    })}
                    prefillAction={siteConfig?.inputFields.personalDetails.fetchifyAutocomplete?.prefillAction}
                    form={form}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                        nextComponentName: PersonalDetailsInputs.AddressLine2,
                      } as InputFieldDisplayProperties
                    }
                  />
                </Col>
              </Row>
            </form>
          )}
          <CardGroup>
            <form
              onBlur={() => execute()}
              onChange={() => execute()}
              onSubmit={form.handleSubmit((data: PersonalAddressData) => submit(data))}
            >
              {!fetchifyAutoCompleteEnabled && (
                <Row>
                  <Col sm={12}>
                    <InputField
                      placeholder={undefined}
                      defaultValue={addressDetailsSectionData?.addressLine1}
                      validators={
                        !!addressFieldsRequired
                          ? [
                              {
                                validation: {
                                  type: ValidationTypes.REQUIRED,
                                },
                              },
                            ]
                          : []
                      }
                      name={AddressLine1}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.addressLine1.label })}
                      prefillAction={siteConfig?.inputFields.personalDetails.addressLine1.prefillAction}
                      form={form}
                      prefillValue={prefillValues?.addressLine1}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: AddressLine2,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior(AddressLine1)}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12}>
                  <InputField
                    validators={[]}
                    placeholder={undefined}
                    defaultValue={addressDetailsSectionData?.addressLine2}
                    name={AddressLine2}
                    label={getWording({ wording: siteConfig?.inputFields.personalDetails.addressLine2.label })}
                    prefillAction={siteConfig?.inputFields.personalDetails.addressLine2.prefillAction}
                    form={form}
                    prefillValue={prefillValues?.addressLine2}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                        nextComponentName: Suburb,
                      } as InputFieldDisplayProperties
                    }
                    {...steppedComponentsBehavior(AddressLine2)}
                  />
                </Col>
              </Row>
              {shouldDisplaySuburb && (
                <Row>
                  <Col sm={12}>
                    <InputField
                      placeholder={undefined}
                      defaultValue={addressDetailsSectionData?.suburb}
                      validators={
                        !!(addressFieldsRequired && isSuburbRequired)
                          ? [
                              {
                                validation: {
                                  type: ValidationTypes.REQUIRED,
                                },
                              },
                            ]
                          : []
                      }
                      name={Suburb}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.suburb.label })}
                      prefillAction={siteConfig?.inputFields.personalDetails.suburb.prefillAction}
                      form={form}
                      prefillValue={prefillValues?.suburb}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: City,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior(Suburb)}
                    />
                  </Col>
                </Row>
              )}
              {!fetchifyAutoCompleteEnabled && (
                <Row>
                  <Col sm={12}>
                    <InputField
                      placeholder={undefined}
                      defaultValue={addressDetailsSectionData?.city}
                      validators={
                        !!addressFieldsRequired
                          ? [
                              {
                                validation: {
                                  type: ValidationTypes.REQUIRED,
                                },
                              },
                            ]
                          : []
                      }
                      name={City}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.city.label })}
                      prefillAction={siteConfig?.inputFields.personalDetails.city.prefillAction}
                      form={form}
                      prefillValue={prefillValues?.city}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: Country,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior(City)}
                    />
                  </Col>
                </Row>
              )}
              {!fetchifyAutoCompleteEnabled && (
                <Row>
                  <Col sm={12}>
                    <CountrySelectField
                      clearable={true}
                      placeholder={undefined}
                      defaultValue={addressDetailsSectionData?.country}
                      validators={
                        !!addressFieldsRequired
                          ? [
                              {
                                validation: {
                                  type: ValidationTypes.REQUIRED,
                                },
                              },
                            ]
                          : []
                      }
                      name={Country}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.country.label })}
                      prefillAction={siteConfig?.inputFields.personalDetails.country.prefillAction}
                      form={form}
                      prefillValue={prefillValues?.country}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: AreaCode,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior(Country)}
                    />
                  </Col>
                </Row>
              )}
              {!fetchifyAutoCompleteEnabled && (
                <Row>
                  <Col sm={12}>
                    <InputField
                      placeholder={undefined}
                      defaultValue={addressDetailsSectionData?.areaCode}
                      validators={[
                        ...(!!addressFieldsRequired
                          ? [
                              {
                                validation: {
                                  type: ValidationTypes.REQUIRED,
                                },
                              },
                            ]
                          : []),
                        {
                          validation: {
                            type: ValidationTypes.AreaCode,
                            props: {
                              countryCode: (country as CountryCode) || 'ZA',
                            },
                          },
                        },
                      ]}
                      name={AreaCode}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.areaCode.label })}
                      prefillAction={siteConfig?.inputFields.personalDetails.areaCode.prefillAction}
                      form={form}
                      prefillValue={prefillValues?.areaCode}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: '',
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior(AreaCode)}
                    />
                  </Col>
                </Row>
              )}
            </form>
          </CardGroup>
        </>
      )}
    </>
  );
};
