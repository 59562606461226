import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { useSiteConfigContext } from 'style-context';
import { onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { getSteppedFormLabelColor } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledInput } from './styled-input';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { isMobile } from 'react-device-detect';

export const NumberField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  form,
  decimal,
  prefillValue,
  prefillAction,
  disableScrollToElement,
  disableActiveElement,
  validators,
  displayProperties,
  disableNextButton,
  hideDivider,
  disableTitle,
  placeholder,
  isTouched: defaultIsTouched,
  submitOnChange,
  hideBorder,
  hiddenComponent,
  isLastComponent,
  isLastStep,
}: InputFieldParams<number> & { decimal?: boolean }) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name;
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value: value,
        props: undefined,
      }),
  });

  useEffect(() => {
    if (prefillValue) {
      setFieldError(
        getValidationMessage({
          validators: validators,
          value: form?.watch(name),
          props: undefined,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement && !isTouched && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
    });
  }, [isTouched, name, isActive]);

  return (
    <div style={hiddenFromPrefill} id={name ? `${name}-form-group` : undefined}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
        isMobile={isMobile}
      >
        <Field
          isTouched={isTouched}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          name={name}
          disableTitle={disableTitle}
          hiddenComponent={hiddenComponent}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          isActive={isActive}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
          }}
        >
          <Controller
            name={name}
            control={form?.control}
            defaultValue={defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value: value,
                  props: undefined,
                }),
            }}
            render={({ onChange, value }) => {
              return (
                <>
                  <StyledInput
                    isTouched={isTouched}
                    siteConfig={siteConfig}
                    isActive={isActive}
                    disableActiveElement={disableActiveElement}
                    name={name}
                    id={name}
                    onFocus={(e) => {
                      setActiveElement({ elementId: name });
                    }}
                    onClick={() => {
                      setActiveElement({ elementId: name });
                      setFormFocusOrBlur({
                        activeElement,
                        scrollToId: name,
                        disableScrollToElement,
                        isFirstElement: false,
                      });
                    }}
                    onKeyDown={(e) =>
                      onEnterKeyDown({
                        e,
                        nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                        setFieldError: (fieldError) => setFieldError(fieldError),
                        setActiveElement: (elementId) =>
                          setActiveElement({
                            elementId,
                          }),
                        getValue: () => form?.watch(name),
                        validationFunction: getValidationMessage,
                        validators,
                        validationProps: undefined,
                        setIsTouched: (isTouched) => setTouched(isTouched),
                        isActive,
                      })
                    }
                    placeholder={placeholder}
                    disabled={isDisabled || disabledFromPrefill}
                    onTouchStart={(e) => {
                      setActiveElement({ elementId: name });
                      e.preventDefault();
                    }}
                    onWheel={(e) => {
                      // @ts-ignore
                      e.target.blur();
                    }}
                    onBlur={(e: any) => {
                      setTouched(true);
                      e.preventDefault();
                    }}
                    onChange={(e: any) => {
                      setTouched(true);
                      e.preventDefault();

                      const value = e.target.value;
                      if (decimal) {
                        const result = value ? Number.parseFloat(value) : undefined;
                        onChange(result);
                        return setFieldError(
                          getValidationMessage({
                            validators: validators,
                            value: value,
                            props: undefined,
                          }),
                        );
                      }
                      const result = value ? Number.parseInt(value) : undefined;
                      onChange(result);
                      return setFieldError(
                        getValidationMessage({
                          validators: validators,
                          value: value,
                          props: undefined,
                        }),
                      );
                    }}
                    value={value}
                    type='number'
                    invalid={!!fieldError}
                  />
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            isLastStep={isLastStep}
            isLastComponent={isLastComponent}
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            hideDivider={hideDivider}
            setTouched={(isTouched) => setTouched(isTouched)}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};
