import React, { useState } from 'react';
import { Quote } from '../domain/quote';
import { getPremiumDisplay } from 'shared/utils';
import { Size, SuccessButton } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import styled from 'styled-components';
import { debounce } from 'utils';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import { devices } from 'rootstrap/global-styles/devices';
import { ModuleData } from 'rootstrap/components/module-data/module-data-component';
import { Colors } from 'rootstrap/global-styles/colors';
import { pickNested } from 'rootstrap/components/module-data/utils';

interface Props {
  quote: Quote;
  productModuleDefinition: ProductModuleDefinition | undefined;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  onNextClickedRef: React.MutableRefObject<any>;
  setSelectedQuote: (quote?: Quote) => void;
  index: number;
}

export const QuotePackageActionItem = (props: Props) => {
  const { quote, productModuleDefinition, onNextClickedRef, setSelectedQuote, index } = props;
  const { suggestedPremium, billingFrequency } = quote;
  const { siteConfig } = useSiteConfigContext();
  const [isLoading, setIsLoading] = useState(false);
  const summary = siteConfig?.quote.wording.summary;

  const premiumDisplay = getPremiumDisplay({
    premiumValue: suggestedPremium,
    billingFrequency: billingFrequency,
    currency: productModuleDefinition?.settings.billing.currency,
  });

  return (
    <StyledValidQuoteDisplay siteConfig={siteConfig}>
      <div className='pricing-section'>
        <div>
          <p>You pay</p>
          <div className='premium'>{premiumDisplay}</div>
        </div>
        <div
          className='btn-container'
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();

            setSelectedQuote(quote);
            setIsLoading(true);
            debounce(
              'on-quote-completed',
              () => {
                setIsLoading(false);
                onNextClickedRef.current.click();
              },
              1500,
            );
          }}
        >
          <SuccessButton
            id={`accept-quote-${index}`}
            siteConfig={siteConfig}
            disabled={false}
            size={Size.default}
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();

              setSelectedQuote(quote);
              setIsLoading(true);
              debounce(
                'on-quote-completed',
                () => {
                  setIsLoading(false);
                  onNextClickedRef.current.click();
                },
                1500,
              );
            }}
          >
            <span style={{ justifyContent: 'center', fontWeight: 400 }}>
              {isLoading && (
                <span style={{ marginRight: '10px' }}>
                  <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
                </span>
              )}
              Sign me up!
            </span>
          </SuccessButton>
        </div>
      </div>
      {summary && summary.length > 0 && (
        <div className='summary-section'>
          <table style={{ width: '100%', padding: 50, marginTop: 20 }}>
            <tbody>
              <ModuleData
                columnIndex={0}
                moduleData={pickNested(quote.module, summary)}
                currency={props.quote?.currency}
                isLoading={false}
                topDivider
              />
            </tbody>
          </table>
        </div>
      )}
    </StyledValidQuoteDisplay>
  );
};

export const StyledValidQuoteDisplay = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  .pricing-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${devices.mobileL} {
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      align-items: flex-start;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  p {
    font-size: 16px;
    display: flex;
    margin-bottom: 8px;
    font-weight: 400;
    color: ${Colors.Body};
  }

  .summary-section {
    text-align: initial;
  }

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'light' })};

  .premium {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    font-size: 25px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${devices.mobileL} {
      width: 100%;
      margin-top: 20px;
    }
  }

  ${SuccessButton} {
    width: 100%;
  }
`;
