import { Icon16PXClipBoard } from 'assets/icon-16-px-clip-board';
import { Icon16PXCreditCard } from 'assets/icon-16-px-credit-card';
import { Icon16PXPersonGroup } from 'assets/icon-16-px-person-group';
import { Icon16PXShield } from 'assets/icon-16-px-shield';
import { PhoneIcon } from 'assets/phone-icon';
import { Policy } from 'policies/domain/policy';
import { PremiumDisplay } from 'policy-issuing/payment/payment-step';
import { ManagementView, PolicyManagementSceneBaseParams } from 'policy-management/policy-management-scene';
import { ProductModule } from 'product-modules/domain/product-module';
import React from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { getPremiumDisplay } from 'shared/utils';
import { getColor, getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { StyledPolicyDetailsScene } from './styles/policy-details-styles';
import {
  PolicyViewBodyWrapper,
  PolicyViewFooterStyle,
  PolicyViewLoadingWrapper,
  PolicyViewSplashContainer,
  PolicyViewStatusBadge,
  PolicyViewStatusBadgeWrapper,
  PolicyViewSuccessButton,
  PolicyViewSummaryPanel,
} from './styles/policy-view-styles';
import { policyStatusColorMapping } from './views/policy-status-display';
import { PolicySummaryErrorPage } from './views/policy-summary-error-page';

export interface PolicyDetailsProps extends PolicyManagementSceneBaseParams {
  productModule: ProductModule | undefined;
  policy: Policy | undefined;
  setView: (v: ManagementView) => void;
}

export const PolicyViewScene = (props: PolicyDetailsProps) => {
  const { policy, setView, isLoading, error } = props;
  const { siteConfig } = useSiteConfigContext();
  const primaryColor = getColor({ siteConfig, color: 'primary' });
  const contactNumberWording = getWording({ wording: siteConfig?.management?.policyView.wording.contactNumber });
  const { Beneficiaries, Claims, PolicyDetails, PaymentDetails } = ManagementView;
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;
  const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;
  const canViewPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.viewPaymentMethod;

  const displayPaymentButton = canEditBillingDay || canEditPaymentMethod || canViewPaymentMethod;

  const premiumDisplay = getPremiumDisplay({
    premiumValue: policy?.basePremium,
    billingFrequency: policy?.billingFrequency,
    currency: props.productModule?.productModuleDefinition?.settings.billing.currency,
  });

  if (error) {
    return <PolicySummaryErrorPage />;
  }

  return (
    <>
      <StyledPolicyDetailsScene isLoading={isLoading}>
        {policy && (
          <PolicyViewStatusBadgeWrapper>
            <PolicyViewStatusBadge color={policyStatusColorMapping(policy.status, siteConfig)}>
              {policy.prettyPolicyStatus()}
            </PolicyViewStatusBadge>
          </PolicyViewStatusBadgeWrapper>
        )}
        <PremiumDisplay siteConfig={siteConfig}>
          <p>Ongoing premium</p>
          {!isLoading && (
            <>
              <div>{premiumDisplay}</div>
              <span>Next payment: {policy?.nextBillingDate?.format('DD MMMM')}</span>
            </>
          )}
          {isLoading && <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />}
        </PremiumDisplay>
        <PolicyViewSplashContainer siteConfig={siteConfig}>
          <PolicyViewSummaryPanel siteConfig={siteConfig}>
            <PolicyViewBodyWrapper>
              {isLoading && (
                <PolicyViewLoadingWrapper>
                  <LoadingInputs count={1} />
                </PolicyViewLoadingWrapper>
              )}
              {!isLoading && (
                <>
                  <PolicyViewSuccessButton
                    outline
                    siteConfig={siteConfig}
                    onClick={() => setView(PolicyDetails)}
                    fullWidth={true}
                  >
                    <Icon16PXShield fill={primaryColor} />
                    <span>View policy details</span>
                  </PolicyViewSuccessButton>
                  <PolicyViewSuccessButton
                    outline
                    siteConfig={siteConfig}
                    onClick={() => setView(Claims)}
                    fullWidth={true}
                  >
                    <Icon16PXClipBoard fill={primaryColor} />
                    <span>Make a claim</span>
                  </PolicyViewSuccessButton>
                  <PolicyViewSuccessButton
                    outline
                    siteConfig={siteConfig}
                    onClick={() => setView(Beneficiaries)}
                    fullWidth={true}
                  >
                    <Icon16PXPersonGroup fill={primaryColor} />
                    <span>Your beneficiaries</span>
                  </PolicyViewSuccessButton>
                  {displayPaymentButton && (
                    <PolicyViewSuccessButton
                      outline
                      siteConfig={siteConfig}
                      onClick={() => setView(PaymentDetails)}
                      fullWidth={true}
                    >
                      <Icon16PXCreditCard fill={primaryColor} />
                      <span>Your payment details</span>
                    </PolicyViewSuccessButton>
                  )}
                </>
              )}
            </PolicyViewBodyWrapper>
          </PolicyViewSummaryPanel>
        </PolicyViewSplashContainer>
      </StyledPolicyDetailsScene>
      <FormWrapperStyle>
        <PolicyViewFooterStyle siteConfig={siteConfig}>
          <div>{siteConfig?.management?.policyView.wording.contactDescription}</div>
          <PhoneIcon className='phone-icon' fill={getColor({ siteConfig, color: 'highlight' })} />
          Call us on <a href={`tel:${contactNumberWording}`}>{contactNumberWording}</a>
        </PolicyViewFooterStyle>
      </FormWrapperStyle>
    </>
  );
};
