import { Environment } from 'models/environment';
import { Policy } from 'policies/domain/policy';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { JSONObject } from 'shared/utils';

interface Params {
  policyId: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  data: { billingDay?: number };
  appData?: JSONObject;
}

export const updatePolicy = async (params: Params) => {
  const { auth, organizationId, policyId, environment, data, appData } = params;

  const body = {
    billing_day: data.billingDay,
    ...appData ? { app_data: appData } : {}
  };

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/policies/${policyId}`,
    method: 'patch',
    auth,
    body,
    ...(appData ? { app_data: appData } : {})

  });

  const networkPolicy = await response.json();
  const policy = Policy.fromNetwork(networkPolicy);

  return policy;
};
