import React from 'react';
import { orderBy } from 'lodash';
import { SelectFieldParams, SelectField } from '../select-field';

export const BankSelectField = (props: Omit<SelectFieldParams<string>, 'options'>) => {
  return <SelectField searchable options={orderBy(banks, (bank) => bank.label)} {...props} clearable={true} />;
};

export const ZBank: { [key: string]: any } = {
  absa: { name: 'ABSA', branch: '632005' },
  african_bank: { name: 'African Bank', branch: '430000' },
  bidvest: { name: 'Bidvest', branch: '462005' },
  capitec: { name: 'Capitec', branch: '470010' },
  discovery_bank: { name: 'Discovery Bank', branch: '679000' },
  fnb: { name: 'FNB', branch: '250655' },
  investec: { name: 'Investec', branch: '580105' },
  ithala: { name: 'Ithala', branch: '' },
  mercantile: { name: 'Mercantile', branch: '450105' },
  nedbank: { name: 'Nedbank', branch: '198765' },
  old_mutual: { name: 'Old Mutual', branch: '462005' },
  postbank: { name: 'Postbank', branch: '460005' },
  standard_bank: { name: 'Standard Bank', branch: '051001' },
  tyme_bank: { name: 'Tyme Bank', branch: '678910' },
  ubank: { name: 'Ubank', branch: '431010' },
};

export const UkBank: { [key: string]: any } = {
  hsbc_holdings: { name: 'HSBC Holdings' },
  lloyds_banking_group: { name: 'Lloyds Banking Group' },
  royal_bank_of_scotland_group: { name: 'Royal Bank of Scotland Group' },
  barclays: { name: 'Barclays' },
  standard_chartered: { name: 'Standard Chartered' },
  santander_uk: { name: 'Santander UK' },
  nationwide_building_society: { name: 'Nationwide Building Society' },
  schroders: { name: 'Schroders' },
  close_brothers_group_plc: { name: 'Close Brothers Group Plc' },
  coventry_building_society: { name: 'Coventry Building Society' },
};

const BankInformation = process.env.REACT_APP_BANK_REGION === 'UK' ? UkBank : ZBank;

export const banks = Object.keys(BankInformation).map((entry) => {
  return { value: entry, label: BankInformation[entry].name };
});
