import React from 'react';
import { orderBy } from 'lodash';
import { SelectFieldParams, SelectField } from '../select-field';
import { Title } from 'general/title';

export const titleOptions = orderBy(Object.values(Title), (value) => value).map((value) => {
  const label = value.replace(/_/gi, ' ');
  return {
    label: label[0].toLocaleUpperCase() + label.substr(1),
    value,
  };
});

export const TitleSelectField = (props: Omit<SelectFieldParams<Title>, 'options'>) => {
  return <SelectField searchable {...props} options={titleOptions} clearable={true} />;
};
