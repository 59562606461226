import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { JSONObject } from 'shared/utils';
import { Application, NetworkApplication } from '../domain/application';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  quotePackageId: string;
  policyholderId: string;
  data?: JSONObject;
}

export const createApplication = async (params: Params) => {
  const { auth, environment, organizationId, policyholderId, quotePackageId, data } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/applications`,
    method: 'post',
    body: {
      quote_package_id: quotePackageId,
      policyholder_id: policyholderId,
      type: 'new_policy',
      ...data,
    },
    auth,
  });

  const networkApplication: NetworkApplication = await response.json();
  const application = Application.fromNetwork(networkApplication);

  return application;
};
