import { FormStoreModelList } from 'rootstrap/components-old/root-schema-form/interfaces';
import { Construct } from '../../models/construct';

export class ProductModuleDefinitionSchema {
  readonly productModuleDefinitionSchemaId: string;
  readonly json: FormStoreModelList[];
  readonly createdBy: any;
  readonly createdAt: string;

  constructor(init: Construct<ProductModuleDefinitionSchema>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionSchema) {
    return new ProductModuleDefinitionSchema({
      productModuleDefinitionSchemaId: init.product_module_definition_schema_id,
      json: init.json,
      createdBy: init.created_by,
      createdAt: init.created_at,
    });
  }
}

export interface NetworkProductModuleDefinitionSchema {
  product_module_definition_schema_id: string;
  json: FormStoreModelList[];
  created_by: any;
  created_at: string;
}