import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface NetworkHandleBarValues {
  content: string;
  merge_vars: any;
  fake_nip: boolean;
}

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  environment: Environment;
  content: string[];
  mergeVars: any;
}

export const compileHandleBars = async (params: Params) => {
  const { auth, environment, organizationId, content, mergeVars } = params;

  const handleBarValues: NetworkHandleBarValues[] = content.map((c) => {
    return { content: c, merge_vars: mergeVars, fake_nip: false };
  });

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response: any = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/handlebars`,
    method: 'post',
    auth,
    body: handleBarValues,
  });

  const json = await response.json();

  return json.result as string[];
};
