import { RequestedBy } from 'models';
import { Construct } from '../../models/construct';
import moment, { Moment } from 'moment';

export enum CollectionModuleDefinitionVersion {
  Draft = 'draft',
  Live = 'live',
}

export class CollectionModule {
  readonly collectionModuleId: string;
  readonly collectionModuleName: string;
  readonly collectionModuleKey: string;
  readonly draftDefinitionId: string;
  readonly liveDefinitionId: string;
  readonly ownedByOrganizationId: string;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;
  readonly updatedAt: Moment;
  readonly updatedBy: RequestedBy;

  constructor(init: Construct<CollectionModule>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkCollectionModule): CollectionModule {
    return new CollectionModule({
      collectionModuleId: init.collection_module_id,
      collectionModuleName: init.collection_module_key,
      collectionModuleKey: init.collection_module_key,
      draftDefinitionId: init.draft_definition_id,
      liveDefinitionId: init.live_definition_id,
      ownedByOrganizationId: init.owned_by_organization_id,
      updatedAt: moment(init.updated_at),
      updatedBy: init.updated_by,
      createdAt: moment(init.created_at),
      createdBy: init.created_by,
    });
  }
}

export interface NetworkCollectionModule {
  collection_module_id: string;
  collection_module_name: string;
  collection_module_key: string;
  draft_definition_id: string;
  live_definition_id: string;
  owned_by_organization_id: string;
  created_at: string;
  created_by: RequestedBy;
  updated_at: string;
  updated_by: RequestedBy;
}
