import { CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';
import { Environment } from 'models/environment';
import {
  CollectionModulePaymentMethod,
  NetworkCollectionModulePaymentMethod,
  PaymentMethodType,
} from 'payment-methods/domain/payment-method';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from 'shared/api';
import { JSONObject, buildApiUrl } from 'shared/utils';

interface CreateCollectionModulePaymentMethodParams {
  collectionModuleKey: string;
  policyholderId: string;
  applicationId?: string;
  policyId?: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  data: JSONObject;
  version: CollectionModuleDefinitionVersion | undefined;
}

export const createCollectionModulePaymentMethod = async (params: CreateCollectionModulePaymentMethodParams) => {
  const {
    applicationId,
    policyholderId,
    auth,
    environment,
    organizationId,
    policyId,
    data,
    collectionModuleKey,
    version,
  } = params;

  const queryParams = {
    ...(version ? { version } : {}),
  };

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/policyholders/${policyholderId}/payment-methods`
      : `/v1/insurance/policyholders/${policyholderId}/payment-methods`;

  const response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${url}`, queryParams),
    method: 'post',
    body: {
      type: PaymentMethodType.CollectionModule,
      collection_module_key: collectionModuleKey,
      data,
      application_id: applicationId,
      policy_ids: policyId ? [policyId] : undefined,
    },
    auth,
  });

  const networkCollectionModulePaymentMethod: NetworkCollectionModulePaymentMethod = await response.json();
  return CollectionModulePaymentMethod.fromNetwork(networkCollectionModulePaymentMethod);
};
