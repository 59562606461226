import React from 'react';

export const Icon12PXChevronDown = (params: {
  fill: string;
  style?: React.CSSProperties;
  id?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}) => (
  <svg
    id={params.id}
    width='12'
    height='8'
    style={params.style}
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={params.onClick}
  >
    <path
      d='M1.41 -9.25807e-07L6 4.58L10.59 -1.23266e-07L12 1.41L6 7.41L-1.23266e-07 1.41L1.41 -9.25807e-07Z'
      fill={params.fill}
    />
  </svg>
);

export const Icon12PXChevronUp = (params: {
  fill: string;
  style?: React.CSSProperties;
  id?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}) => (
  <svg
    id={params.id}
    width='12'
    height='8'
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={params.onClick}
    style={{ transform: 'rotate(180deg)', ...params.style }}
  >
    <path
      d='M1.41 -9.25807e-07L6 4.58L10.59 -1.23266e-07L12 1.41L6 7.41L-1.23266e-07 1.41L1.41 -9.25807e-07Z'
      fill={params.fill}
    />
  </svg>
);
