import { IdentificationType, SelectableIdType } from 'policyholder/domain/policyholder-identification';
import { ProductModule } from 'product-modules/domain/product-module';
import { PolicyholderLookUpFormData } from '../views/unauthenticated-policyholder-lookup';
import { getWording, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';

export const getSelectableIdTypes = (idTypes: IdentificationType[] | undefined): SelectableIdType[] => {
  const idTypeToSelectableIdTypeMap: { [key in IdentificationType]: SelectableIdType | undefined } = {
    [IdentificationType.Id]: SelectableIdType.Id,
    [IdentificationType.Passport]: SelectableIdType.Passport,
    [IdentificationType.Custom]: undefined,
    [IdentificationType.Email]: SelectableIdType.Email,
    [IdentificationType.Cellphone]: SelectableIdType.Cellphone,
    [IdentificationType.Company]: SelectableIdType.Company,
  };

  if (!idTypes) return [];

  const filteredTypes = idTypes
    .filter((idType) => idTypeToSelectableIdTypeMap[idType] !== undefined)
    .map((idType) => idTypeToSelectableIdTypeMap[idType] as SelectableIdType);

  return filteredTypes.length ? filteredTypes : [];
};

export const getIdTypeValue = (params: {
  identificationType: IdentificationType | SelectableIdType | undefined;
  idTypes: SelectableIdType[];
  // prefillValue: IdentificationType | undefined;
}): SelectableIdType | undefined => {
  const { idTypes, identificationType } = params;

  if (idTypes.includes(identificationType as SelectableIdType)) {
    return identificationType as SelectableIdType;
  }

  if (idTypes.length === 1) {
    return idTypes[0];
  }
};

export const getIdType = (params: { formIdType: IdentificationType; productModule: ProductModule }) => {
  const { formIdType, productModule } = params;

  if (productModule.productModuleDefinition?.settings.policyholder.idTypes.length === 1) {
    return productModule.productModuleDefinition?.settings.policyholder.idTypes[0];
  }

  return formIdType;
};

export const getIdTypes = (productModule: ProductModule): SelectableIdType[] => {
  const selectableIdTypes = getSelectableIdTypes(productModule.productModuleDefinition?.settings.policyholder.idTypes);
  return selectableIdTypes;
};

export const isPrefilledFromLookupData = (
  policyholderLookupData: PolicyholderLookUpFormData | undefined,
  identificationType: SelectableIdType,
) => {
  if (!policyholderLookupData) {
    return false;
  }

  const { idType } = policyholderLookupData;

  switch (idType) {
    case SelectableIdType.Id:
      return identificationType === SelectableIdType.Id;
    case SelectableIdType.Passport:
      return identificationType === SelectableIdType.Passport;
    case SelectableIdType.Email:
      return identificationType === SelectableIdType.Email;
    case SelectableIdType.Cellphone:
      return identificationType === SelectableIdType.Cellphone;
  }
};

export const getStepHeaderDetails = (
  stepKey: IssuingSceneStepKeys | undefined,
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null,
) => {
  if (!stepKey) {
    return { title: '', description: '' };
  }

  const mapping: { [key in StaticSceneStepKeys]: () => { title: string; description: string } } = {
    [StaticSceneStepKeys.PersonalDetails]: () => ({
      title: siteConfig?.personalDetails.wording.title || '',
      description: siteConfig?.personalDetails.wording.description || '',
    }),
    [StaticSceneStepKeys.PrePersonalDetailsConsent]: () => ({
      title: siteConfig?.prePersonalDetailsCompliance?.wording.title || '',
      description: siteConfig?.prePersonalDetailsCompliance?.wording.description || '',
    }),
    [StaticSceneStepKeys.PrePaymentConsent]: () => ({
      title: siteConfig?.prePaymentCompliance?.wording.title || '',
      description: siteConfig?.prePaymentCompliance?.wording.description || '',
    }),
    [StaticSceneStepKeys.Payment]: () => ({
      title: siteConfig?.payment.wording.title || '',
      description: siteConfig?.payment.wording.description || '',
    }),
    [StaticSceneStepKeys.Application]: () => ({
      title: siteConfig?.application.wording.title || '',
      description: siteConfig?.application.wording.description || '',
    }),
    [StaticSceneStepKeys.ScreeningQuestions]: () => ({
      title: siteConfig?.quote?.wording.screeningQuestionsDescription || '',
      description: '',
    }),
    [StaticSceneStepKeys.Beneficiaries]: () => ({
      title: siteConfig?.beneficiaries?.wording.title || '',
      description: siteConfig?.beneficiaries?.wording.description || '',
    }),
  };

  // Handle static step keys

  if (Object.values(StaticSceneStepKeys).includes(stepKey as StaticSceneStepKeys)) {
    const staticStepKey = stepKey as StaticSceneStepKeys;
    return mapping[staticStepKey]();
  }

  // Handle dynamic quoteInputStep steps
  if (stepKey.startsWith('quoteInputStep')) {
    return {
      title: siteConfig?.quote.wording.title,
      description: getWording({ wording: siteConfig?.quote.wording.description }),
    };
  }

  // Handle unknown step keys
  throw new Error(`Unknown step key: ${stepKey}`);
};
