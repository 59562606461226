import { BorderRadius } from './border-radius';
import { Colors } from './colors';
import { FontFamily } from './font-family';
import { InputHeight } from './input-height';
import { LineHeight } from './line-height';
import { Spacing } from './spacing';
import { FontSize } from './typography';
export { Spacing } from './spacing';
export { FontSize } from './typography';

export const globalStyles = {
  spacing: Spacing,
  fontSize: FontSize,
  fontFamily: FontFamily,
  colors: Colors,
  lineHeight: LineHeight,
  inputHeight: InputHeight,
  borderRadius: BorderRadius,
};
