import React, { createContext, useContext } from 'react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { useSiteConfigContext } from 'style-context';
import { EmbeddedConfigSection } from 'site-config';
import { useEnvironment } from 'shared/api';

export enum MixpanelEventNames {
  SessionInitiated = 'Session initiated',
  StepStarted = 'Step started',
  StepCompleted = 'Step completed',
  SubStepCompleted = 'Sub-step completed',
  PackageReturned = 'Package returned',
  StepExited = 'Step exited',
  StepRetried = 'Step retried',
}

export enum MixpanelLocation {
  PrePolicyholder = 'pre_policyholder',
  PrePayment = 'pre_payment',
}

export enum MixpanelOutcome {
  Success = 'success',
  Failure = 'failure',
}

export interface MixpanelExtraProperties {
  outcome?: MixpanelOutcome;
  policyholder?: {
    identificationType: string | undefined;
    identificationCountry: string | undefined;
    gender: string | undefined;
    dateOfBirth: string | undefined;
    city: string | undefined;
    country: string | undefined;
  };
  beneficiaries?: {
    identificationType: string | undefined;
    identificationCountry: string | undefined;
    gender: string | undefined;
    dateOfBirth: string | undefined;
    relationship: string | undefined;
  }[];
  quotes?:
    | {
        billingFrequency: PolicyBillingFrequency;
        currency: Currency;
        sumAssured: number;
        suggestedPremium: number;
        quotePackageId: string;
      }[]
    | {
        error: string;
      };
}

export enum MixpanelStepNames {
  LandingPage = 'landing_page',
  ScreeningQuestions = 'screening_questions',
  QuoteStep = 'quote_step',
  ConsentPrePersonalDetails = 'consent_pre_personal_details',
  PersonalDetails = 'personal_details',
  Application = 'application',
  Beneficiaries = 'beneficiaries',
  ConsentPrePayment = 'consent_pre_payment',
  Payment = 'payment',
  Confirmation = 'confirmation',
}

interface MixpanelContextValue {
  track: (
    eventName: MixpanelEventNames,
    step: MixpanelStepNames | string,
    extraProperties?: MixpanelExtraProperties,
  ) => void;
}

const MixpanelContext = createContext<MixpanelContextValue | undefined>(undefined);

export const MixpanelProvider: React.FC = ({ children }) => {
  const { embedSessionId } = useEmbedSessionContext();
  const { embedParams } = useEmbedParamsContext();
  const { siteConfig } = useSiteConfigContext();
  const environment = useEnvironment();
  const internalMixPanelKey = 'aabc4faea38ad2c269f95d1c9505af97';
  const thirdPartyMixPanelKey = siteConfig?.[EmbeddedConfigSection.Settings]?.mixpanelProjectToken;

  mixpanel.init(internalMixPanelKey, {}, 'internal');

  if (thirdPartyMixPanelKey) {
    mixpanel.init(thirdPartyMixPanelKey, {}, 'external');
  }

  const track = (
    eventName: MixpanelEventNames,
    step: MixpanelStepNames | string,
    extraProperties?: MixpanelExtraProperties,
  ) => {
    const isTestEnvironment = process.env.NODE_ENV === 'test';
    const isLocalEnvironment = process.env.NODE_ENV === 'development';

    const typedMixPanel = mixpanel as unknown as { internal: Mixpanel; external?: Mixpanel };

    // Include embedSessionId and organizationId in the properties sent to Mixpanel
    const baseProperties = {
      step,
      embedSessionId,
      organizationId: embedParams.organizationId,
      organizationName: embedParams.organizationName,
      productModuleKey: embedParams.productModuleKey,
      environment,
    };

    const properties = { ...baseProperties, ...extraProperties };

    if (!isTestEnvironment && !isLocalEnvironment) {
      typedMixPanel.internal.track(eventName, properties);
    }

    typedMixPanel.external?.track(eventName, properties);
  };

  return <MixpanelContext.Provider value={{ track }}>{children}</MixpanelContext.Provider>;
};

export const useMixpanel = () => {
  const context = useContext(MixpanelContext);
  if (context === undefined) {
    // In a non-production environment, return a no-op function for track.
    return {
      track: () => {},
    };
  }
  return context;
};

export const useMixpanelTrack = () => {
  const { track } = useMixpanel();

  const startedTrack = (params: {
    stepName: MixpanelStepNames | string;
    extraProperties?: MixpanelExtraProperties;
  }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepStarted, stepName, extraProperties);
  };

  const subStepCompletedTrack = (params: {
    stepName: MixpanelStepNames | string;
    extraProperties?: MixpanelExtraProperties;
  }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.SubStepCompleted, stepName, extraProperties);
  };

  const packageReturnedCompletedTrack = (params: {
    stepName: MixpanelStepNames | string;
    extraProperties?: MixpanelExtraProperties;
  }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.PackageReturned, stepName, extraProperties);
  };

  const stepCompletedTrack = (params: {
    stepName: MixpanelStepNames | string;
    extraProperties?: MixpanelExtraProperties;
  }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepCompleted, stepName, extraProperties);
  };

  return {
    stepCompletedTrack,
    subStepCompletedTrack,
    packageReturnedCompletedTrack,
    startedTrack,
  };
};
