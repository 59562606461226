import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

export enum PolicyClientCancellationOptions {
  TooExpensive = 'Too expensive',
  AlternateProduct = 'Alternate product',
  UnhappyWithService = 'Unhappy with service',
  UnhappyWithBenefits = 'Unhappy with benefits',
  FinancialConstraints = 'Financial constraints',
  Retrenched = 'Retrenched',
  Unemployed = 'Unemployed',
  leavingTheCountry = 'Leaving the country',
  Other = 'Other',
}

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  policyId: string;
  environment: Environment;
  data: {
    cancellationType: PolicyClientCancellationOptions;
    reason?: string;
  };
}

export const cancelPolicy = async (params: Params) => {
  const { auth, environment, organizationId, policyId, data } = params;

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: false,
  });

  const body = {
    cancellation_type: data.cancellationType,
    cancellation_requestor: 'client',
  };

  const response = await apiRequest({
    body,
    url: `${getApiHost({ environment })}${basePathName}/policies/${policyId}/cancel`,
    method: 'post',
    auth,
  });

  return response;
};
