interface BeneficiariesDescriptionWordingParams {
  minimumBeneficiaries: number | undefined;
  maximumBeneficiaries: number | undefined;
}

export const beneficiariesDescriptionWording = (params: BeneficiariesDescriptionWordingParams) => {
  const { minimumBeneficiaries, maximumBeneficiaries } = params;

  let text = ``;

  if (minimumBeneficiaries !== 0) {
    text = `Please add at least ${minimumBeneficiaries} beneficiary below.`;
  }

  return `${text} You can add up to ${maximumBeneficiaries} beneficiaries. You'll be able to change them or add more later. `;
};
