import { FloatingFooter } from 'App';
import React from 'react';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModuleDefinitionEmbeddedConfig, getColor, getFontSize } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

export const LandingPageFooter = () => {
  const { siteConfig } = useSiteConfigContext();
  const footerDisclaimerImageEnabled = siteConfig?.footer.displayOptionalSections.disclaimerImage;
  const footerDisclaimerUrl = siteConfig?.footer.images.disclaimerUrl;
  const footerDisclaimer = siteConfig?.footer.wording.disclaimer;

  const footerFontSize = getFontSize({ siteConfig, fontSize: 'footer' });

  return (
    <FooterContainer id='landing-page-footer' siteConfig={siteConfig}>
      <FloatingFooter />
      <FooterContainerContents id='landing-page-footer-contents' siteConfig={siteConfig}>
        <InnerContainer>
          {footerDisclaimerImageEnabled && footerDisclaimerUrl && (
            <div>{<Logo src={footerDisclaimerUrl} alt='' />}</div>
          )}
          <DisclaimerText fontSize={footerFontSize}>{footerDisclaimer}</DisclaimerText>
        </InnerContainer>
      </FooterContainerContents>
    </FooterContainer>
  );
};

const FooterContainerContents = styled(FormWrapperStyle)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: flex;
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })}}}
`;

const FooterContainer = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  justify-content: center;
  font-size: 8px;
  bottom: 0;
  width: 100%;
  margin-top: -30px;

  @media ${devices.mobileL} {
    font-size: 8px;
  }
`;

const InnerContainer = styled.div`
  width: 40%;
  align-items: center;
  display: flex;
  padding-top: ${Spacing.xs};
  padding-bottom: ${Spacing.xs};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  @media ${devices.laptopL} {
    width: 50%;
  }

  @media ${devices.laptop} {
    width: 100%;
  }
`;

const Logo = styled.img`
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex: 1;

  max-height: 21px;
  @media ${devices.mobileL} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const DisclaimerText = styled.span<{ fontSize: string }>`
  color: rgba(0, 0, 0, 0.6);
  font-size: ${({ fontSize }) => fontSize};
`;
