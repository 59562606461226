import React from 'react';

export const VideoIcon = (props: { fill?: string; style?: React.CSSProperties }) => {
  return (
    <svg style={props.style} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.83325 11C1.83325 5.93999 5.93992 1.83333 10.9999 1.83333C16.0599 1.83333 20.1666 5.93999 20.1666 11C20.1666 16.06 16.0599 20.1667 10.9999 20.1667C5.93992 20.1667 1.83325 16.06 1.83325 11ZM14.6666 11L9.16658 6.87499V15.125L14.6666 11Z'
        fill={props.fill}
      />
    </svg>
  );
};
