import React from 'react';
import { Spinner } from 'reactstrap';

export enum AnimationTypes {
  Border = 'border',
}

export enum SpinnerSize {
  sm = 'sm',
  lg = 'lg',
}

interface Props {
  animation: AnimationTypes;
  size: SpinnerSize;
  color: string;
  id?: string;
}

const NewSpinner = (props: Props) => {
  return (
    <Spinner
      id={props.id}
      animation={props.animation}
      variant='warning'
      size={props.size}
      style={{ color: props.color, padding: 5 }}
    />
  );
};

export default NewSpinner;
