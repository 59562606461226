import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { NetworkPolicy, Policy } from '../domain/policy';
import { JSONObject } from 'shared/utils';

interface Params {
  applicationId: string;
  billingDay?: number;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  appData?: JSONObject;
}

export const issuePolicy = async (params: Params) => {
  const { applicationId, billingDay, auth, environment, organizationId, appData } = params;

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${getBasePathname({ organizationId, auth, clientAppAuth: true })}/policies`,
    method: 'post',
    body: {
      application_id: applicationId,
      billing_day: billingDay,
      ...(appData ? { app_data: appData } : {})
    },
    auth,
  });

  const networkPolicy: NetworkPolicy = await response.json();

  const policy = Policy.fromNetwork(networkPolicy);

  return policy;
};
