import React from 'react';

export const Icon16PXClipBoard = (params: { fill: string; style?: React.CSSProperties }) => (
  <svg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.4615 2.54637H12.9324V2.39129C12.9324 1.99565 12.5529 1.67408 12.0862 1.67408H10.3878V0.717209C10.3878 0.321569 10.0082 0 9.54181 0H7.06497C6.59832 0 6.21877 0.321793 6.21877 0.717209V1.67386H4.52056C4.05391 1.67386 3.67436 1.99565 3.67436 2.39107V2.54615H1.14525C0.513634 2.54615 0 2.98139 0 3.51734V20.0288C0 20.5645 0.513634 21 1.14525 21H15.461C16.0929 21 16.6063 20.5645 16.6063 20.0288V3.51757C16.6065 2.98162 16.0929 2.54637 15.4615 2.54637ZM4.52768 5.66964H12.0791C12.5302 5.66964 12.8979 5.35836 12.8979 4.97593V4.20658H14.8917L14.8972 19.4602L1.71616 19.4649V4.20725H3.70999V4.97615C3.70946 5.35836 4.07634 5.66964 4.52768 5.66964Z'
      fill={params.fill}
    />
  </svg>
);
