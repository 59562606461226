import React, { useEffect } from 'react';
import { InputFieldParams } from './input-field';
import { getNextComponentId, setFormFocusOrBlur } from './utils';

interface BlankSpaceProps extends InputFieldParams<string> {}

export const BlankSpace = (params: BlankSpaceProps) => {
  const { name, displayProperties, disableScrollToElement } = params;
  const { activeElement, setActiveElement } = displayProperties;

  useEffect(() => {
    const isActive = activeElement.elementId === name;

    if (isActive) {
      setActiveElement && setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
      setFormFocusOrBlur({
        activeElement,
        scrollToId: name,
        isFirstElement: displayProperties.index === 0,
        disableScrollToElement,
      });
    }
  }, [activeElement, name]);

  return <br id={name} />;
};
