export const collectionModuleHTMLSanitization = (params: { htmlContent: string; script: string }) => {
  const { htmlContent, script } = params;

  const updatedHtmlContent = htmlContent
    .replace(/submitRenderPaymentMethod/g, 'submitCreatePaymentMethod')
    .replace(/completeRenderPaymentMethod/g, 'completeCreatePaymentMethod');

  const closingBodyTagRegex = /<\/body\s*>/i; // Case-insensitive regex to match </body> with possible whitespace
  const match = closingBodyTagRegex.exec(updatedHtmlContent);

  if (match) {
    const { index } = match;
    return updatedHtmlContent.substring(0, index) + script + updatedHtmlContent.substring(index);
  }

  // If there's no closing body tag, just append the script at the end.
  return htmlContent + script;
};

export const collectionModuleHTMLScript = `
    <script>
      window.submitCreatePaymentMethod = submitCreatePaymentMethod; // Expose the function
      const completeCreatePaymentMethod = (data) => window.parent.completeCreatePaymentMethod(data);
      const setError = (error) => window.parent.setError(error);
      const setIsLoading = (isLoading) => window.parent.setIsLoading(isLoading);
      const setIsValid = (isLoading) => window.parent.setIsValid(isLoading);
    </script>
  `;
