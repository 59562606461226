import {
  NetworkProductModuleDefinitionAlterationHook,
  ProductModuleDefinitionAlterationHook,
} from 'alteration-hooks/domain/product-module-definition-alteration-hooks';
import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  policyId: string;
}

export const getPolicyAlterationHooks = async (params: Params) => {
  const { auth, environment, organizationId, policyId } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({
      environment,
    })}${basePathName}/policies/${policyId}/alteration-hooks`,
    method: 'get',
    auth,
  });

  const networkAlterationHooks: NetworkProductModuleDefinitionAlterationHook[] = await response.json();

  return networkAlterationHooks.map((networkAlterationHook) =>
    ProductModuleDefinitionAlterationHook.fromNetwork(networkAlterationHook),
  );
};
