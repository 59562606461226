import React, { useEffect } from 'react';
import { InputFieldParams } from './input-field';
import { getNextComponentId, setFormFocusOrBlur } from './utils';

interface HorizontalLineProps extends InputFieldParams<any> {}

export const HorizontalLine = (params: HorizontalLineProps) => {
  const { name, displayProperties, disableScrollToElement } = params;
  const { activeElement, setActiveElement } = displayProperties;

  useEffect(() => {
    const isActive = activeElement.elementId === name;

    if (isActive) {
      setActiveElement && setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
      setFormFocusOrBlur({
        activeElement,
        scrollToId: name,
        isFirstElement: displayProperties.index === 0,
        disableScrollToElement,
      });
    }
  }, [activeElement, name]);

  return <div id={name} style={{ height: 10 }} />;
};
