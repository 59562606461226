import React from 'react';
import { PaymentMethodProps } from '../payment-step';
import { PaymentDeclarationSection } from './payment-declaration-section';

export const CreditCardPaymentMethod = (props: PaymentMethodProps) => {
  const { setAccountPermission } = props;
  return (
    <>
      <PaymentDeclarationSection setDebitAccountPermission={(value) => setAccountPermission(value)} />
      {/* {debitAccountPermission && application && <CardPaymentWindow policyId={application?.policyId} />} */}
    </>
  );
};
