export enum Title {
  Mr = 'mr',
  Mrs = 'mrs',
  Miss = 'miss',
  Mx = 'mx',
  Sir = 'sir',
  Dr = 'dr',
  Adv = 'adv',
  Ms = 'ms',
  Prof = 'prof',
}
