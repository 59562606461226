import React from 'react';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { StyledManagePaymentMethodCard } from '../styles';
import { Policy } from 'policies/domain/policy';
import { formatBillingDate } from '../utils';
import { UpdateBillingDayButton } from './components/update-billing-day-button';
import { ExternalPaymentMethod } from 'payment-methods/domain/payment-method';

export const ManageExternalPaymentMethodCard = (props: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  paymentMethod: ExternalPaymentMethod;
  policy: Policy;
  setShowUpdateBillingDay: (showUpdateBillingDay: boolean) => void;
}) => {
  const { siteConfig, policy, setShowUpdateBillingDay } = props;

  return (
    <StyledManagePaymentMethodCard siteConfig={siteConfig}>
      <p className='header'>External payment method</p>
      {policy.nextBillingDate && (
        <p className='billing'>Next billing day: {formatBillingDate(policy.nextBillingDate)}</p>
      )}
      <UpdateBillingDayButton setShowUpdateBillingDay={setShowUpdateBillingDay} />
    </StyledManagePaymentMethodCard>
  );
};
