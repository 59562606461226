import moment, { Moment } from 'moment';
import uuid from 'uuid';
import phone from 'phone';
import { RequestedBy, CreatedByType } from '../../models';
import { Construct } from '../../models/construct';
import { Gender } from '../../models/gender';
import { PolicyholderEntityType } from './policyholder-entity-type';
import { Title } from 'general/title';
import { Countries } from 'rootstrap/components/forms/countries';
import {
  IdentificationType,
  NetworkPolicyholderIdentification,
  PolicyholderIdentification,
} from './policyholder-identification';

export enum PolicyholderFieldNames {
  Email = 'email',
  Cellphone = 'cellphone',
  PhoneOther = 'phoneother',
  AddressLine1 = 'address.line1',
  AddressLine2 = 'address.line2',
  AddressSuburb = 'address.suburb',
  AddressCity = 'address.city',
  AddressAreaCode = 'address.areaCode',
  AddressCountry = 'address.country',
  AddressGooglePlaceId = 'address.googlePlaceId',
  AddressGeoCoordinatesLatitude = 'address.geoCoordinatesLatitude',
  AddressGeoCoordinatesLongitude = 'address.geoCoordinatesLongitude',
}

export const policyholderFieldConfig = {
  [PolicyholderFieldNames.Email]: { apiKey: 'email' },
  [PolicyholderFieldNames.Cellphone]: { apiKey: 'cellphone' },
  [PolicyholderFieldNames.PhoneOther]: { apiKey: 'phoneother' },
  [PolicyholderFieldNames.AddressLine1]: { apiKey: 'address.line_1' },
  [PolicyholderFieldNames.AddressLine2]: { apiKey: 'address.line_2' },
  [PolicyholderFieldNames.AddressSuburb]: { apiKey: 'address.suburb' },
  [PolicyholderFieldNames.AddressCity]: { apiKey: 'address.city' },
  [PolicyholderFieldNames.AddressAreaCode]: { apiKey: 'address.area_code' },
  [PolicyholderFieldNames.AddressCountry]: { apiKey: 'address.country' },
  [PolicyholderFieldNames.AddressGooglePlaceId]: { apiKey: 'address.google_place_id' },
  [PolicyholderFieldNames.AddressGeoCoordinatesLatitude]: { apiKey: 'address.geo_coordinates_latitude' },
  [PolicyholderFieldNames.AddressGeoCoordinatesLongitude]: { apiKey: 'address.geo_coordinates_longitude' },
};

export const policyholderFieldMap = Object.keys(policyholderFieldConfig).reduce((acc, fieldKey) => {
  return {
    ...acc,
    [fieldKey]: policyholderFieldConfig[fieldKey as PolicyholderFieldNames].apiKey,
  };
}, {});

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
  expirationDate?: Moment;
}

export interface Cellphone {
  country: string;
  number: string;
  internationalNumber?: string;
}

export enum PolicyholderType {
  Individual = 'individual',
  Company = 'company',
}

export interface Address {
  googlePlaceId?: string;
  geoCoordinatesLatitude?: string;
  geoCoordinatesLongitude?: string;
  line1: string;
  line2?: string;
  suburb?: string;
  city: string;
  country: string;
  areaCode: string;
}

interface NetworkAddress {
  google_place_id?: string;
  geo_coordinates_latitude?: string;
  geo_coordinates_longitude?: string;
  line_1: string;
  line_2?: string;
  suburb?: string;
  city: string;
  country: string;
  area_code: string;
}

export enum PolicyholderAddressOptInOptions {
  Yes = 'yes',
  No = 'no',
}

export class Policyholder {
  readonly policyholderId: string;
  readonly type: PolicyholderEntityType;
  readonly createdAt: Moment;
  readonly identification?: PolicyholderIdentification;
  readonly dateOfBirth?: Moment;
  readonly gender?: Gender;
  readonly firstName: string;
  readonly middleName?: string;
  readonly lastName: string;
  readonly email?: string;
  readonly cellphone?: Cellphone;
  readonly phoneOther?: Cellphone;
  readonly policyIds: string[];
  readonly createdBy: RequestedBy;
  readonly appData?: { [key: string]: any };
  readonly address?: Address;
  readonly title?: Title;
  // readonly policies?: Policy[];
  readonly updatedAt: Moment;
  public isFromLead?: boolean;

  // Company fields
  readonly companyName?: string;
  readonly registrationNumber?: string;
  readonly contactPosition?: string;
  readonly companyWebsiteUrl?: string;
  readonly dateOfEstablishment?: Moment;
  readonly subsidiaryCompanies?: string[];

  constructor(init: Construct<Policyholder>) {
    Object.assign(this, init);
  }

  displayAddress() {
    if (!this.address) {
      return undefined;
    }

    const displayAddress = [
      this.address.line1,
      this.address.line2,
      this.address.suburb,
      this.address.city,
      this.address.areaCode,
      Countries.find((x) => x.code === this.address?.country)?.name,
    ]
      .filter((x) => !!x && !!x.trim())
      .join(', ')
      .replace(/^[,\s]+|[,\s]+$/g, '')
      .replace(/,[,\s]*,/g, ',');

    return displayAddress;
  }

  prettyPhoneOtherNumber() {
    const number = this.phoneOther ? this.phoneOther.number : undefined;
    if (!number) {
      return undefined;
    }

    return `+27 ${number.substr(1, 2)} ${number.substr(3, 3)} ${number.substr(6, 4)}`;
  }

  isIndividual() {
    return this.type === PolicyholderEntityType.Individual;
  }

  isCompany() {
    return this.type === PolicyholderEntityType.Company;
  }

  identificationNumber() {
    if (this.type === PolicyholderEntityType.Individual) {
      return this.identification ? this.identification.number : '';
    }
    return this.registrationNumber;
  }

  getLegalId() {
    if (this.type === PolicyholderEntityType.Company) {
      return `Company reg. ${this.registrationNumber}`;
    }

    return this.identification && this.identification.number;
  }

  fullName() {
    if (this.type === PolicyholderEntityType.Individual) {
      return `${this.firstName || ' '} ${this.middleName ? `${this.middleName} ` : ''}${this.lastName || ' '}`;
    }
    return this.companyName;
  }

  getCountry() {
    if (this.type === PolicyholderEntityType.Company) {
      return 'za';
    }
    return (this.identification && this.identification.country.toLowerCase()) || 'za';
  }

  prettyCellNumber = () => {
    const number = this.cellphone && this.cellphone.number;
    if (!number) {
      return undefined;
    }
    if (number.charAt(0) === '+') {
      return `${number.substring(0, 3)} ${number.substring(3, 5)} ${number.substring(5, 8)} ${number.substring(8, 12)}`;
    }
    return `+27 ${number.substr(1, 2)} ${number.substr(3, 3)} ${number.substr(6, 4)}`;
  };

  public computedDateOfBirth() {
    if (this.type === PolicyholderEntityType.Company || !this.identification) {
      return moment();
    }

    if (this.identification.type === IdentificationType.Passport) {
      return moment(this.dateOfBirth);
    }

    const currentYear = new Date().getFullYear();
    const currentCentury = Math.floor(currentYear / 100) * 100;
    let yearPart = currentCentury + parseInt(this.identification.number.substr(0, 2), 10);
    if (yearPart > currentYear) {
      yearPart -= 100;
    }

    const monthPart = parseInt(this.identification.number.substr(2, 2), 10) - 1;
    const dayPart = parseInt(this.identification.number.substr(4, 2), 10);

    const dateOfBirth = new Date(yearPart, monthPart, dayPart);

    return moment(dateOfBirth);
  }

  public age() {
    if (this.type === PolicyholderEntityType.Company) {
      return 0;
    }

    if (this.identification && this.identification.type === IdentificationType.Passport) {
      return moment().diff(moment(this.dateOfBirth), 'years', false);
    }

    return moment().diff(this.computedDateOfBirth(), 'years', false);
  }

  // used with the policy wizard
  public toInputValues = () => ({
    age: this.age(),
    firstName: this.firstName,
    lastName: this.lastName,
    gender: this.gender,
  });

  public static fromNetwork(init: NetworkPolicyholder) {
    return new Policyholder({
      policyholderId: init.policyholder_id,
      type: init.type,
      createdAt: moment(init.created_at),
      updatedAt: moment(init.updated_at),
      identification: init.id && PolicyholderIdentification.fromNetwork(init.id),
      dateOfBirth: init.date_of_birth ? moment(init.date_of_birth) : undefined,
      gender: init.gender,
      title: init.title,
      firstName: init.first_name,
      middleName: init.middle_name,
      lastName: init.last_name,
      companyName: init.company_name,
      registrationNumber: init.registration_number,
      email: init.email,
      // policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      cellphone: init.cellphone
        ? typeof init.cellphone === 'string'
          ? { country: phone(init.cellphone).countryIso2 || 'ZA', number: init.cellphone.replace('+27', '0') }
          : init.cellphone
        : undefined,
      phoneOther: init.phone_other
        ? typeof init.phone_other === 'string'
          ? { country: phone(init.phone_other).countryIso2 || 'ZA', number: init.phone_other.replace('+27', '0') }
          : init.phone_other
        : undefined,
      policyIds: init.policy_ids,
      appData: init.app_data,
      createdBy: init.created_by,
      address: init.address
        ? {
            googlePlaceId: init.address.google_place_id,
            geoCoordinatesLatitude: init.address.geo_coordinates_latitude,
            geoCoordinatesLongitude: init.address.geo_coordinates_longitude,
            line1: init.address.line_1,
            line2: init.address.line_2,
            suburb: init.address.suburb,
            city: init.address.city,
            country: init.address.country,
            areaCode: init.address.area_code,
          }
        : undefined,
      // Company fields
      companyWebsiteUrl: init.company_website_url,
      contactPosition: init.contact_position,
      dateOfEstablishment: init.date_of_establishment ? moment(init.date_of_establishment) : undefined,
      subsidiaryCompanies: init.subsidiary_companies,
    });
  }

  public static fromObfuscated(init: NetworkObfuscatedPolicyholder) {
    return new Policyholder({
      policyholderId: init.policyholder_id,
      type: init.type,
      createdAt: moment(moment()),
      updatedAt: moment(moment()),
      firstName: '****',
      lastName: '****',
      email: '*****',
      policyIds: [],
      appData: {},
      createdBy: { id: 'undefined', type: CreatedByType.System },
    });
  }

  public toNetworkPolicyholder() {
    return {
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      email: this.email,
      cellphone: this.cellphone !== undefined ? this.cellphone.number : undefined,
      phone_other: this.phoneOther !== undefined ? this.phoneOther.number : undefined,
      gender: this.gender,
      title: this.title,
      date_of_birth: this.dateOfBirth ? moment(this.dateOfBirth).format('YYYYMMDD') : null,
      company_name: this.companyName,
      registration_number: this.registrationNumber,
      type: this.type,
      address:
        this.address && this.address.line1
          ? {
              google_place_id: this.address.googlePlaceId || undefined,
              geo_coordinates_latitude: this.address.geoCoordinatesLatitude || undefined,
              geo_coordinates_longitude: this.address.geoCoordinatesLongitude || undefined,
              line_1: this.address.line1,
              line_2: this.address.line2,
              suburb: this.address.suburb,
              city: this.address.city,
              area_code: this.address.areaCode,
              country: this.address.country,
            }
          : undefined,
      company_website_url: this.companyWebsiteUrl,
      contact_position: this.contactPosition,
      date_of_establishment: this.dateOfEstablishment?.format('YYYYMMDD'),
      subsidiary_companies: this.subsidiaryCompanies,
    };
  }

  public toSubmitModel() {
    if (this.type === PolicyholderEntityType.Company) {
      return {
        type: PolicyholderEntityType.Company,
        first_name: this.firstName,
        middle_name: this.middleName || null,
        last_name: this.lastName,
        email: this.email || null,
        title: this.title || null,
        cellphone: this.cellphone && this.cellphone.number ? this.cellphone.number : null,
        phone_other: this.phoneOther && this.phoneOther.number ? this.phoneOther.number : null,
        registration_number: this.registrationNumber || null,
        company_name: this.companyName || null,
        address:
          this.address && this.address.line1
            ? {
                google_place_id: this.address.googlePlaceId,
                geo_coordinates_latitude: this.address.geoCoordinatesLatitude,
                geo_coordinates_longitude: this.address.geoCoordinatesLongitude,
                line_1: this.address.line1,
                line_2: this.address.line2 || null,
                suburb: this.address.suburb,
                city: this.address.city,
                area_code: this.address.areaCode,
                country: this.address.country,
              }
            : undefined,
      };
    }

    const data = {
      first_name: this.firstName,
      middle_name: this.middleName || null,
      last_name: this.lastName,
      email: this.email || null,
      title: this.title || null,
      cellphone: this.cellphone && this.cellphone.number ? this.cellphone.number : null,
      phone_other: this.phoneOther && this.phoneOther.number ? this.phoneOther.number : null,
      id: this.identification || null,
      address:
        this.address && this.address.line1
          ? {
              google_place_id: this.address.googlePlaceId,
              geo_coordinates_latitude: this.address.geoCoordinatesLatitude,
              geo_coordinates_longitude: this.address.geoCoordinatesLongitude,
              line_1: this.address.line1,
              line_2: this.address.line2 || null,
              suburb: this.address.suburb,
              city: this.address.city,
              area_code: this.address.areaCode,
              country: this.address.country,
            }
          : undefined,
    };

    if (this.identification?.type === IdentificationType.Passport) {
      if (this.gender) {
        (data as any).gender = this.gender;
      }

      if (this.dateOfBirth) {
        (data as any).date_of_birth = this.dateOfBirth.format('YYYYMMDD');
      }
    }

    return data;
  }

  public static New() {
    return new Policyholder({
      type: PolicyholderEntityType.Individual,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      identification: {
        type: IdentificationType.Id,
        number: '',
      } as any,
      dateOfBirth: moment(),
      isFromLead: false,
      createdAt: moment(),
      updatedAt: moment(),
      policyIds: [],
      policyholderId: undefined as any, // speak to allister
      createdBy: {
        id: uuid().toString(), // speak to allister
        type: CreatedByType.System, // speak to allister
      },
    });
  }

  public static NewCompany() {
    const p = new Policyholder({
      type: PolicyholderEntityType.Individual,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      updatedAt: moment(),
      identification: {
        type: IdentificationType.Passport,
        country: 'ZA',
        number: `1234${Math.floor(Math.random() * 100000)}`,
        expirationDate: undefined,
      },
      dateOfBirth: moment('1993-01-01'),
      isFromLead: false,
      createdAt: moment(),
      policyIds: [],
      policyholderId: uuid().toString(),
      createdBy: {
        id: uuid().toString(), // speak to allister
        type: CreatedByType.System, // speak to allister
      },
    });
    return p;
  }
}

export class NetworkPolicyholder {
  policyholder_id: string;
  type: PolicyholderEntityType;
  first_name: string;
  middle_name?: string;
  last_name: string;
  company_name?: string;
  registration_number?: string;
  id?: NetworkPolicyholderIdentification;
  email?: string;
  cellphone?: string;
  phone_other?: string;
  date_of_birth?: string;
  gender?: Gender;
  title?: Title;
  created_at: string;
  updated_at: string;
  app_data?: object;
  policy_ids: string[];
  created_by: RequestedBy;
  address?: NetworkAddress;
  company_website_url?: string;
  contact_position?: string;
  date_of_establishment?: string;
  subsidiary_companies?: string[];
}

export interface NetworkObfuscatedPolicyholder {
  policyholder_id: string;
  type: PolicyholderEntityType;
}
