import { useState, useCallback, useEffect } from 'react';
import { ApiError } from 'shared/api';

export interface UsePromiseError extends Error {
  fieldErrors?: {
    message: string;
    path: string;
  }[];
}

export const usePromiseLazy = <F, P>(f: (...args: P[]) => Promise<F>, deps: any[]) => {
  const [state, setState] = useState<{ isLoading: boolean; result: F | undefined; error: ApiError | undefined }>({
    isLoading: false,
    result: undefined,
    error: undefined,
  });

  const execute = useCallback(
    async (...args: P[]) => {
      setState({ isLoading: true, result: state.result, error: undefined });
      try {
        const theResult = await f(...args);
        setState({ isLoading: false, result: theResult, error: undefined });
        return { result: theResult, error: undefined };
      } catch (caughtError: any) {
        setState({ isLoading: false, result: undefined, error: caughtError });
        return { result: undefined, error: caughtError };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [f, state.result, ...deps],
  );

  const setResult = (result: F | undefined) => {
    setState({ ...state, result });
  };

  return { result: state.result, error: state.error, isLoading: state.isLoading, setResult, execute };
};

export const usePromise = <F, P>(f: (...args: P[]) => Promise<F>, deps: any[]) => {
  const { execute, result, setResult, isLoading, error } = usePromiseLazy(f, deps);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return { execute, result, setResult, isLoading, error };
};

export const useSyncLazy = <F, P>(f: (...args: P[]) => F, deps: any[]) => {
  const [state, setState] = useState<{
    result: F | undefined;
    error: Error | undefined;
  }>({
    result: undefined,
    error: undefined,
  });

  const execute = useCallback(
    (...args: P[]) => {
      setState({ result: state.result, error: undefined });
      try {
        const theResult = f(...args);
        setState({ result: theResult, error: undefined });
        return { result: theResult, error: undefined };
      } catch (caughtError: any) {
        if (caughtError.error) {
          setState({ result: undefined, error: caughtError.error });
          return { result: undefined, error: caughtError.error };
        }
        setState({ result: undefined, error: caughtError });
        return { result: undefined, error: caughtError };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [f, state.result, ...deps],
  );

  const clearState = () => {
    setState({ result: undefined, error: undefined });
  };

  const setResult = (result: F | undefined) => {
    setState({ ...state, result });
  };

  return { result: state.result, error: state.error, setResult, execute, clearState };
};

export const useSync = <F, P>(f: (...args: P[]) => F, deps: any[]) => {
  const { execute, result, setResult, error } = useSyncLazy(f, deps);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return { execute, result, setResult, error };
};
