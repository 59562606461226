import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { useSiteConfigContext } from 'style-context';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import styled from 'styled-components';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { StyledInput } from './styled-input';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { isMobile } from 'react-device-detect';

type DateValue = string | Moment | null | undefined;

// TODO: Fix defaultValue not being applied after initially being undefined/null.
export const DatePickerField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  minDate,
  helpText,
  form,
  formatValue,
  prefillAction,
  prefillValue,
  disableScrollToElement,
  displayProperties,
  hideDivider,
  disableActiveElement,
  validators,
  disableNextButton,
  disableTitle,
  isTouched: defaultIsTouched,
  submitOnChange,
  hideBorder,
  hiddenComponent,
  isLastComponent,
  isLastStep,
}: Omit<InputFieldParams<DateValue>, 'formatValue'> & {
  minDate?: Moment;
  formatValue?: (date?: Moment | null) => any;
}) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );

  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;

  const defaultFormControl = useForm().control;

  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      }),
  });

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: false,
    });
  }, [isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
        isMobile={isMobile}
      >
        <Field
          hiddenComponent={hiddenComponent}
          isTouched={isTouched}
          disableTitle={disableTitle}
          isActive={isActive}
          name={name}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body, disableActiveElement }),
          }}
        >
          <Controller
            name={name}
            control={form?.control || defaultFormControl}
            defaultValue={typeof defaultValue === 'string' ? moment(defaultValue) : defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange, value }: { onChange: (date: DateValue) => void; value: DateValue }) => {
              return (
                <>
                  <StyledInput
                    isTouched={isTouched}
                    name={name}
                    className={`form-control react-date-picker`}
                    onChange={(e) => {
                      const formattedDate = e.target.value;
                      onChange(
                        formatValue ? formatValue(moment(formattedDate || value)) : moment(formattedDate || value),
                      );
                      setTouched(true);

                      const fieldError = getValidationMessage({
                        validators: validators,
                        value: formattedDate,
                        props: undefined,
                      });
                      setFieldError(fieldError);
                    }}
                    disableActiveElement={disableActiveElement}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      onEnterKeyDown({
                        e,
                        nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                        setFieldError: (fieldError) => setFieldError(fieldError),
                        setActiveElement: (elementId) =>
                          setActiveElement({
                            elementId,
                          }),
                        getValue: () => form?.watch(name),
                        validationFunction: getValidationMessage,
                        validators,
                        validationProps: undefined,
                        setIsTouched: (isTouched) => setTouched(isTouched),
                        isActive,
                      })
                    }
                    isActive={isActive || isTouched}
                    siteConfig={siteConfig}
                    onFocus={(e) => {
                      setActiveElement({ elementId: name });
                    }}
                    onBlur={(e) => {
                      setTouched(true);
                    }}
                    id={name}
                    type='date'
                    disabled={isDisabled || disabledFromPrefill}
                    max={'3000-01-01'}
                    value={typeof value === 'string' ? value : value?.format('YYYY-MM-DD')}
                    style={{
                      color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
                    }}
                  />
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            isLastStep={isLastStep}
            isLastComponent={isLastComponent}
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

export const StyledReactDatePicker = styled(ReactDatePicker)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement: boolean | undefined;
}>`
height: ${globalStyles.inputHeight.default} !important;
line-height: ${globalStyles.lineHeight.default};
padding-left: 18px;
font-size: ${globalStyles.fontSize.body} !important;
border: 1px solid ${({ siteConfig }) => getColor({ color: 'disabled', siteConfig })} !important;
border-radius: ${globalStyles.borderRadius.default} !important;
cursor: pointer;
box-shadow: none !important;
color: ${({ siteConfig, isActive, disableActiveElement }) =>
  getSteppedFormLabelColor({
    isActive,
    siteConfig,
    color: globalStyles.colors.Body,
    disableActiveElement,
  })} !important;

${({ isActive, siteConfig }) => `
  border: 1px solid ${isActive ? Colors.Body : getColor({ siteConfig, color: 'disabled' })} !important;
  box-shadow: none !important;
`}

&.focus,
&:focus {
  box-shadow: none !important;
  border: 1px solid
    ${({ siteConfig, isActive }) => (isActive ? Colors.Body : getColor({ siteConfig, color: 'disabled' }))}; !important;
}

.invalid-feedback {
  margin-top: 12px !important;
  display: flex !important;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })} !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })} !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })} !important;
}
::placeholder {
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })} !important;
}
`;
