import React from 'react';
import { PaymentMethodAll, PaymentMethodType } from 'payment-methods/domain/payment-method';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { DebitOrderPaymentMethodForm } from './debit-order-payment-method-form';
import { ExternalPaymentMethodForm } from './external-payment-method-form';
import { ProductModule } from 'product-modules/domain/product-module';
import { NewCollectionsModuleDetails } from './collection-module-payment-method';
import { CollectionModule, CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';

export interface UpdatePaymentMethodFormProps {
  policy: Policy;
  policyholder: Policyholder;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  setPolicy: (policy: Policy) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  selectedPaymentMethodType: PaymentMethodType | string;
  paymentMethod: PaymentMethodAll | undefined;
  productModule: ProductModule;
  collectionModules: CollectionModule[];
}

export const PaymentMethodForm = (props: UpdatePaymentMethodFormProps) => {
  const {
    activeElement,
    policy,
    policyholder,
    setActiveElement,
    setIsUpdatingOrCreatingPaymentMethod,
    setPaymentMethod,
    setPolicy,
    selectedPaymentMethodType,
    productModule,
    paymentMethod,
  } = props;

  if (selectedPaymentMethodType === PaymentMethodType.DebitOrder) {
    return (
      <DebitOrderPaymentMethodForm
        activeElement={activeElement}
        paymentMethod={paymentMethod as any}
        policy={policy}
        policyholder={policyholder}
        setActiveElement={setActiveElement}
        setIsUpdatingOrCreatingPaymentMethod={setIsUpdatingOrCreatingPaymentMethod}
        setPaymentMethod={setPaymentMethod}
        setPolicy={setPolicy}
      />
    );
  }

  if (selectedPaymentMethodType === PaymentMethodType.External) {
    return (
      <ExternalPaymentMethodForm
        activeElement={activeElement}
        paymentMethod={paymentMethod as any}
        policy={policy}
        policyholder={policyholder}
        setActiveElement={setActiveElement}
        setIsUpdatingOrCreatingPaymentMethod={setIsUpdatingOrCreatingPaymentMethod}
        setPaymentMethod={setPaymentMethod}
        setPolicy={setPolicy}
      />
    );
  }

  if (
    !Object.values(PaymentMethodType).includes(selectedPaymentMethodType as PaymentMethodType) &&
    (productModule?.productModuleDefinition?.settings?.billing?.collectionModules || []).some(
      (module) => module.key === selectedPaymentMethodType,
    )
  ) {
    const collectionModuleKey = selectedPaymentMethodType;
    return (
      <NewCollectionsModuleDetails
        collectionModuleDefinitionVersion={CollectionModuleDefinitionVersion.Draft}
        collectionModuleKey={collectionModuleKey}
        activeElement={activeElement}
        paymentMethod={paymentMethod as any}
        policy={policy}
        policyholder={policyholder}
        setActiveElement={setActiveElement}
        setIsUpdatingOrCreatingPaymentMethod={setIsUpdatingOrCreatingPaymentMethod}
        setPaymentMethod={setPaymentMethod}
        setPolicy={setPolicy}
      />
    );
  }

  return <div />;
};
