import React, { ReactNode } from 'react';
import { Svg } from 'rootstrap/components/svg';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';

interface FormOverlayProps {
  loading?: boolean;
  customStyles?: React.CSSProperties;
  className?: string;
}

export class FormOverlay extends React.Component<FormOverlayProps> {
  render() {
    return (
      <div
        className={`overlay-form ${this.props.className || ''} ${this.props.loading ? ' loading' : ''}`}
        style={this.props.customStyles}
      >
        {this.props.children}
      </div>
    );
  }
}

interface FormOverlayHeaderProps {
  close: () => void;
  customStyles?: React.CSSProperties;
  className?: string;
  extendedWidth?: boolean;
}

export class FormOverlayHeader extends React.Component<FormOverlayHeaderProps> {
  render() {
    return (
      <div
        className={`overlay-form-header ${this.props.className}`}
        style={{ ...this.props.customStyles, padding: 0, borderBottom: 'none' }}
      >
        <div
          style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}
          className={`${
            this.props.extendedWidth ? 'overlay-form-inner-content-extended-width' : 'overlay-form-inner-content'
          }`}
        >
          <h3>
            {this.props.children}
            <span style={{ cursor: 'pointer' }}>
              <Svg onClick={this.props.close} href='/assets/images/icons/icon-24-px-cross.svg' />
            </span>
          </h3>
        </div>
      </div>
    );
  }
}

export const FormOverlayHeaderContents = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: flex;
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
`;

interface FormOverlayContentProps {
  padded?: boolean;
  leftColumn?: ReactNode;
  rightColumn?: ReactNode;
  extendedWidth?: boolean;
}

export class FormOverlayContent extends React.Component<FormOverlayContentProps> {
  render() {
    return (
      <div style={{ paddingTop: 0 }} className={`overlay-form-content${this.props.padded ? ' padded' : ''}`}>
        <div className='spacer-left'>{this.props.leftColumn}</div>
        <div style={{ overflowX: 'hidden' }} className={'overlay-form-inner-content-extended-width'}>
          {this.props.children}
        </div>
        <div className='spacer-right'>{this.props.rightColumn}</div>
      </div>
    );
  }
}

export const FormOverlayFooter = (params: {
  id?: string;
  extendedWidth?: boolean;
  disableBackgroundColor: boolean;
  children: React.ReactNode;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}) => {
  const { children } = params;
  return <div id='fullscreen-modal-footer'>{children}</div>;
};

export const FormOverlayFooterWrapper = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  background: ${({ siteConfig }) => siteConfig?.styles.colors.backgroundHighlight};
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export const OverlayFormInnerContentExtendedWidth = styled.div``;
