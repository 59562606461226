import { Environment } from 'models/environment';
import { PolicyholderLookUpFormData } from 'policy-issuing/personal-details/views/unauthenticated-policyholder-lookup';
import { NetworkObfuscatedPolicyholder, Policyholder } from 'policyholder/domain/policyholder';
import { SelectableIdType } from 'policyholder/domain/policyholder-identification';
import { EmbedAuth, apiRequest, getApiHost, getBasePathname } from 'shared/api';
import { buildApiUrl } from 'shared/utils';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  data: PolicyholderLookUpFormData;
  environment: Environment;
}

export const lookupUnauthenticatedPolicyholder = async ({ organizationId, auth, data, environment }: Params) => {
  const queryParams = {
    identification_type: data.idType,
    identification_number: getImplicitIdNumberFromType(data),
    identification_country:
      data.idType === SelectableIdType.Cellphone
        ? data.cellphone?.countryCode
        : data.idType === SelectableIdType.Passport
          ? data.identificationCountry
          : undefined,
  };

  const apiUrl = buildApiUrl(
    `${getApiHost({ environment })}${getBasePathname({
      organizationId,
      auth,
      clientAppAuth: true,
    })}/policyholders/lookup`,
    queryParams,
  );

  const response = await apiRequest({
    url: apiUrl,
    method: 'get',
    auth,
  });

  const networkObfuscatedPolicyholder: NetworkObfuscatedPolicyholder = await response.json();
  const policyholder = Policyholder.fromObfuscated(networkObfuscatedPolicyholder);

  return policyholder;
};

const getImplicitIdNumberFromType = (policyholderLookUpFormData: Partial<PolicyholderLookUpFormData>) => {
  if (policyholderLookUpFormData.idType === SelectableIdType.Email) {
    return policyholderLookUpFormData.email;
  }

  if (policyholderLookUpFormData.idType === SelectableIdType.Cellphone) {
    return policyholderLookUpFormData.cellphone?.number;
  }

  return policyholderLookUpFormData.identificationNumber;
};
