import React from 'react';
import styled from 'styled-components';

interface PseudoTooltipProps {
  content: string;
  alignment?: 'center' | 'left' | 'right';
  isDisabled?: boolean;
  borderColor: string;
  id?: string;
}

export const PseudoTooltip = ({ content, alignment = 'center', isDisabled, borderColor, id }: PseudoTooltipProps) => {
  return (
    <div style={{ opacity: isDisabled ? 0.2 : 1 }}>
      <StyledMainBody borderColor={borderColor} alignment={alignment}>
        <span id={id}>{content}</span>
        <StyledArrow borderColor={borderColor} alignment={alignment}>
          <div className='border-arrow' />
          <div className='white-arrow' />
        </StyledArrow>
      </StyledMainBody>
    </div>
  );
};

const arrowLeftPosition = -7.5; // This is to keep the position of the arrows in sync

const StyledMainBody = styled.div<{ alignment: 'center' | 'left' | 'right'; borderColor: string }>`
  background-color: #fff;
  color: black;
  padding: 7px !important;
  border-radius: 5px;
  position: absolute;
  font-size: 14px;
  max-width: 200px;
  text-align: center;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  bottom: 100%;
  margin-bottom: 15px;
  display: inline-block;
  border: 1px solid ${({ borderColor }) => borderColor};

  ${({ alignment }) =>
    alignment === 'center' &&
    `
    left: 50%;
    transform: translateX(-50%);
  `}

  ${({ alignment }) =>
    alignment === 'left' &&
    `
    left: 0;
    transform: translateX(0);
  `}

  ${({ alignment }) =>
    alignment === 'right' &&
    `
    right: 0;
    transform: translateX(0);
  `}
`;

const StyledArrow = styled.div<{ alignment: 'center' | 'left' | 'right'; borderColor: string }>`
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  z-index: 1;

  ${({ alignment }) =>
    alignment === 'center' &&
    `
    left: 50%;
    transform: translateX(-50%);
  `}

  ${({ alignment }) =>
    alignment === 'left' &&
    `
    left: 10px;
    transform: translateX(0);
  `}

  ${({ alignment }) =>
    alignment === 'right' &&
    `
    right: 10px;
    transform: translateX(0);
  `}

  .border-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${({ borderColor }) => borderColor};
    top: 0px;
    left: ${arrowLeftPosition}px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  }

  .white-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
    top: 0px; /* Adjust to match the arrow's border-top width */
    left: ${arrowLeftPosition + 1}px;
  }
`;
