import {
  PrefillAction,
  RootSchemaFormInputFieldDisplayProperties,
  StandAloneInputFieldDisplayProperties,
} from './input-field';
import { DeepMap, FieldError } from 'react-hook-form';
import { debounce } from 'utils';
import { JSONObject } from 'shared/utils';
import { get } from 'lodash';
import { isMobile } from 'react-device-detect';

interface GetFieldErrorParams {
  isTouched: boolean;
  errors: DeepMap<any, FieldError> | undefined;
  name: string;
}

export const getFieldError = (params: GetFieldErrorParams) => {
  const { errors, isTouched, name } = params;

  return isTouched ? get(errors, name)?.message : undefined;
};

export const validatePrefillValue = (params: {
  prefillValue: any;
  validate?: (i: any) => string | undefined;
  options: { label: string; value: any }[] | undefined;
}) => {
  const { prefillValue, validate, options } = params;
  if (!prefillValue) {
    return false;
  }

  const validationPassed = prefillValue && validate && validate(prefillValue) ? false : true;

  return options
    ? options.find((option: { value: any; label: string }) => option.value === prefillValue) && validationPassed
    : validationPassed;
};

export const doesValueRepresentBoolean = (prefillValue: any) => {
  return [true, false, 'true', 'false'].includes(prefillValue);
};

// Updated scrollTo function using scroll-into-view-if-needed
export const scrollTo = (params: { scrollToId: string; containerId?: string; customBlock?: ScrollLogicalPosition }) => {
  const { scrollToId, customBlock } = params;
  const targetElement = document.getElementById(scrollToId);

  targetElement?.scrollIntoView({
    behavior: 'smooth',
    block: customBlock ? customBlock : isMobile ? 'center' : 'center',
  });
};

export const setFormFocusOrBlur = (params: {
  scrollToId: string;
  disableScrollToElement?: boolean;
  isFirstElement?: boolean;
  activeElement: ActiveElement;
  nextButtonFocus?: boolean;
}) => {
  const { scrollToId, disableScrollToElement, isFirstElement, activeElement } = params;

  const isActive = activeElement.elementId === scrollToId;
  const nextActiveElement = document.getElementById(scrollToId);

  if (!disableScrollToElement) {
    if (isActive && !disableScrollToElement && !isFirstElement) {
      document
        .getElementById(`${scrollToId}-form-group`)
        ?.scrollIntoView({ behavior: 'smooth', block: isMobile ? 'center' : 'center' });
      !isMobile &&
        debounce(
          'scroll-debounce',
          () => {
            nextActiveElement?.focus();
          },
          300,
        );
    }
  }
};

interface PrefillBehavior {
  prefillValue: boolean | string | number | undefined | JSONObject;
  validate: ((value: any | undefined) => string | undefined) | undefined;
  prefillAction: PrefillAction | undefined;
  options: { label: string; value: any }[] | undefined;
}
export const prefillBehavior = (params: PrefillBehavior) => {
  const { prefillValue, validate, prefillAction, options } = params;
  const prefillIsValid = validatePrefillValue({ prefillValue, validate, options });
  const disabledFromPrefill = prefillAction === 'disabled' && prefillIsValid ? true : false;
  const hiddenFromPrefill = prefillAction === 'hidden' && prefillIsValid ? { display: 'none' } : {};

  return {
    disabledFromPrefill,
    hiddenFromPrefill,
  };
};

export interface ActiveElement {
  elementId: string | undefined;
}

export const getNextComponentId = (
  params: RootSchemaFormInputFieldDisplayProperties | StandAloneInputFieldDisplayProperties,
) => {
  if (isRootSchemaFormInput(params)) {
    const { schema: getSchema, index, key } = params;
    const schema = (getSchema && getSchema()) || [];
    const indexInSchema = schema.findIndex((component) => component.key === key);
    return (getSchema && index !== undefined && (schema.slice(indexInSchema + 1)[0]?.key as string)) || '';
  }
  return params.nextComponentName;
};

function isRootSchemaFormInput(obj: any): obj is RootSchemaFormInputFieldDisplayProperties {
  return 'schema' in obj;
}

export const getNonBreakingString = (input: string) => input.split(' ').join('\u00A0');

export const scrollToTopOfPage = () => {
  const container = document.getElementById('form-overlay-content-wrapper');
  container?.scrollTo({ top: 0, left: 0, behavior: 'auto' });
};
