import { buildApiUrl } from 'shared/utils';
import { Environment } from '../../models/environment';
import { AuthTypes, EmbedAuth, apiRequest, getApiHost } from '../../shared/api';
import {
  CollectionModule,
  CollectionModuleDefinitionVersion,
  NetworkCollectionModule,
} from '../domain/collection-module';

interface Params {
  organizationId: string;
  environment: Environment;
  productModuleKey: string;
  auth: EmbedAuth;
  version: CollectionModuleDefinitionVersion | undefined;
}

export const getProductModuleCollectionModules = async ({
  organizationId,
  environment,
  productModuleKey,
  auth,
  version,
}: Params) => {
  const queryParams = {
    version,
  };

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/product-modules/${productModuleKey}/collection-modules`
      : `/v1/insurance/product-modules/${productModuleKey}/collection-modules`;

  const response: Response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${url}`, queryParams),
    method: 'get',
    auth,
  });
  const networkCollectionModule: NetworkCollectionModule[] = await response.json();
  return networkCollectionModule.map(CollectionModule.fromNetwork);
};
