import { Environment } from 'models/environment';
import { Policy } from 'policies/domain/policy';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  policyId: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
}

export const getPolicyById = async (params: Params) => {
  const { auth, organizationId, policyId, environment } = params;

  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/policies/${policyId}`,
    method: 'get',
    auth,
  });

  const networkPolicy = await response.json();
  const policy = Policy.fromNetwork(networkPolicy);

  return policy;
};
