import { Input } from 'reactstrap';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { SuccessButton } from 'rootstrap/components/button/styles';
import {
  RadioButtonOptionsContainer,
  StyledRadioLabel,
} from 'rootstrap/components/forms/new-fields/radio-button-field';
import { StyledRootSchemaComponent } from 'rootstrap/components/forms/new-fields/styles/root-schema-component-style';
import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import { globalStyles } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { devices } from 'rootstrap/global-styles/devices';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components';

export const BeneficiaryPercentageInput = styled(Input)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  border: none !important;
  border-bottom: 1px solid ${({ siteConfig }) => getColor({ color: 'border', siteConfig })} !important;
  border-radius: 0px !important;
  max-width: 40px;
  box-shadow: none !important;
  text-align: center;
  font-size: 20px !important;
  font-weight: 400;
  margin: 0px !important;
  padding: 0px !important;
  height: 27px !important;

  :disabled {
    background-color: white;
  }
`;

export const BeneficiaryButtonContents = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 5px;
    font-size: 16px;
  }
`;

export const BeneficiaryAllocateButtonWrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  ${SuccessButton} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin-right: 10px;
  }
`;

export const BeneficiaryCardTitleWrapperStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  border-bottom: ${({ siteConfig }) => `1px solid ${getColor({ color: 'border', siteConfig })}`};
`;

export const BeneficiaryCardTitleStyle = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 400;
  font-size: ${globalStyles.fontSize.title};
`;

export const BeneficiaryStyledRadioLabel = styled(StyledRadioLabel)<{
  isFirstItem: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isDisabled: boolean;
}>`
  margin-bottom: 12px;
  cursor: pointer;

  ${({ isFirstItem, siteConfig, isDisabled }) =>
    isFirstItem &&
    !isDisabled &&
    css`
      border: 1px solid ${getColor({ siteConfig, color: 'primary' })};
      color: ${getColor({ siteConfig, color: 'primary' })};
    `}

  ${({ isFirstItem, isDisabled, siteConfig }) =>
    isFirstItem &&
    isDisabled &&
    css`
      border: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
      color: ${getColor({ siteConfig, color: 'disabled' })} !important;
    `}
`;

export const BeneficiaryRadioButtonOptionsContainer = styled(RadioButtonOptionsContainer)`
  padding-top: 20px;
`;

export const BeneficiarySplashContainer = styled(SplashContainer)`
  display: block !important;
`;

export const BeneficiaryInputFieldWrapper = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  flex: 1;
  display: flex;
  justify-content: end;

  span {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
  }
`;

export const BeneficiaryPaymentSummaryPanel = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isMobile: boolean;
}>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          grid-template-rows: 1fr 1fr;
        `
      : css`
          display: grid;
          grid-template-columns: 1fr 1fr;
        `}

  width: 100%;
  align-items: center;
  margin-left: 0px;
`;

export const BeneficiarySummaryCellStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  hideBorderRight?: boolean;
  hideBorderBottom?: boolean;
  isLeftComponent: boolean;
}>`
  flex-direction: column !important;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  height: 100%;

  ${({ siteConfig, hideBorderBottom }) =>
    !hideBorderBottom &&
    css`
      border-bottom: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
    `};

  ${({ isLeftComponent }) =>
    isLeftComponent
      ? css`
          padding-right: 10px;
          margin-right: 10px;
        `
      : css`
          padding-left: 20px;
        `}

  div:nth-child(1) {
    color: ${Colors.Body};
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0px;

    @media ${devices.tablet} {
      border-right: none;
    }
  }
`;

export const BeneficiarySliderAndIconStyle = styled.div`
  padding-top: 22px;
  padding-bottom: 25px;
  margin-top: 10px;

  svg {
    float: right;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    margin-top: -8px !important;
  }
`;

export const BeneficiarySliderWrapper = styled.div<{ isLeftComponent: boolean }>`
  border: none;
  border-radius: 8px;
  justify-content: left;
  position: relative;
  width: calc(100% - 52px);
`;

export const BeneficiaryStyledRow = styled.div<{ isLeftComponent: boolean; siteConfig: any }>`
  flex: 1;
  display: flex;
  flex-direction: row;

  ${({ isLeftComponent, siteConfig }) =>
    !isLeftComponent &&
    css`
      border-left: 1px solid ${getColor({ siteConfig, color: 'border' })};
    `}

  ${BeneficiarySliderWrapper} {
    flex-direction: column;
  }

  ${StyledRootSchemaComponent} {
    margin-top: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  @media ${devices.tablet} {
    flex-direction: column;
  }

  border-bottom: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
`;

export const BeneficiaryRelationshipDisplayStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  font-size: 16px;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  font-style: italic;
  font-weight: 400;
`;

export const BeneficiaryLoadingLinesWrapper = styled.div`
  margin-top: 20px !important;
`;

export const AddBeneficiaryFormWrapperStyle = styled(FormWrapperStyle)`
  margin-top: 30px;
`;
