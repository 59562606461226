import React, { ReactElement } from 'react';
import { Spacing } from 'rootstrap/global-styles';
import { getColor } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { HighlightButton, LongButtonBlank, Size } from '../button/styles';
import { CardBody } from '../card';
import { Modal as StyledModal, ModalBody } from './styles';

export interface Props {
  isOpen: boolean;
  toggle?: () => void;
  children: React.ReactNode;
  backdropClassName?: string;
  hideEnvironmentOverlay?: boolean;
  fade?: boolean;
  id?: string;
  centered?: boolean;
  fullWidthOnMobile?: boolean;
}

export default function Modal({
  isOpen,
  toggle,
  backdropClassName,
  fade = true,
  children,
  id,
  centered,
  fullWidthOnMobile,
}: Props): ReactElement {
  return (
    <StyledModal
      centered={centered}
      id={id}
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName={backdropClassName}
      fade={fade}
      fullWidthOnMobile={fullWidthOnMobile}
    >
      {children}
    </StyledModal>
  );
}

interface GeneralModalParams {
  title: string;
  subTitle: string;
  secondaryButtonClicked: () => void;
  secondaryButtonText: string;
  primaryButtonClicked: () => void;
  primaryButtonText: string;
  isOpen: boolean;
  isVertical: boolean;
  buttonColorTheme: string;
}

export const GeneralModal = (params: GeneralModalParams) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    title,
    subTitle,
    primaryButtonClicked,
    primaryButtonText,
    secondaryButtonClicked,
    secondaryButtonText,
    isOpen,
    isVertical,
    buttonColorTheme,
  } = params;

  return (
    <Modal centered isOpen={isOpen} fullWidthOnMobile>
      <GeneralModalBody>
        <CardBody style={{ padding: Spacing.md, textAlign: 'center' }}>
          <StyledH5>{title}</StyledH5>
          <p>{subTitle}</p>
          <GeneralModalButtonWrapper isVertical={isVertical}>
            <LongButtonBlank
              color={buttonColorTheme}
              outline
              siteConfig={siteConfig}
              fullWidth
              size={Size.lg}
              onClick={secondaryButtonClicked}
            >
              {secondaryButtonText}
            </LongButtonBlank>
            <HighlightButton
              color={getColor({ siteConfig, color: 'primary' })}
              siteConfig={siteConfig}
              onClick={primaryButtonClicked}
            >
              {primaryButtonText}
            </HighlightButton>
          </GeneralModalButtonWrapper>
        </CardBody>
      </GeneralModalBody>
    </Modal>
  );
};

const GeneralModalBody = styled(ModalBody)`
  padding: 0px;
  margin-bottom: 0px;
`;

const GeneralModalButtonWrapper = styled.div<{ isVertical: boolean }>`
  text-align: center;
  display: grid;
  align-items: baseline;

  ${({ isVertical }) => (isVertical ? 'grid-template-row: 1fr 1fr' : 'grid-template-columns: 1fr 1fr')};
  grid-column-gap: ${Spacing.default};
  grid-row-gap: ${Spacing.default};
`;

const StyledH5 = styled.h5`
  font-size: 20px;
`;
