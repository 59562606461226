import { Beneficiary } from 'beneficiaries/domain/beneficiary';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { beneficiaryToNetwork } from 'beneficiaries/utils';
import { NetworkPolicy, Policy } from 'policies/domain/policy';
import { Environment } from 'models/environment';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  policyId: string;
  beneficiaries: Beneficiary[];
  environment: Environment;
}

export const updatePolicyBeneficiaries = async ({
  organizationId,
  policyId,
  beneficiaries,
  auth,
  environment,
}: Params) => {
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/policies/${policyId}/beneficiaries`,
    method: 'put',
    body: beneficiaries.map((beneficiary) => beneficiaryToNetwork(beneficiary)),
    auth,
  });

  const networkPolicy: NetworkPolicy = await response.json();

  const policy = Policy.fromNetwork(networkPolicy);

  return policy;
};
