import { IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';
import { Policyholder } from 'policyholder/domain/policyholder';
import React from 'react';
import { HighlightButton, Size } from 'rootstrap/components/button/styles';
import { CardBody } from 'rootstrap/components/card';
import { Modal, ModalBody, ModalHeader } from 'rootstrap/components/modal';
import { globalStyles } from 'rootstrap/global-styles';
import { getFontFamily, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface Props {
  foundPolicyholder?: Policyholder;
  setPolicyholder: (p: Policyholder) => void;
  setCurrentStepKey: (s: IssuingSceneStepKeys) => void;
  onNextCompleted: (() => void | Promise<void>) | undefined;
  setNewPersonalDetailsAccepted: (v: boolean) => void;
  setLookupPolicyholderModalOpen: (v: boolean) => void;
  resetPolicyholderData: () => void;
}

export const PolicyholderLookUpModal = (props: Props) => {
  const {
    foundPolicyholder,
    setCurrentStepKey,
    setPolicyholder,
    onNextCompleted,
    setNewPersonalDetailsAccepted,
    setLookupPolicyholderModalOpen,
    resetPolicyholderData,
  } = props;
  const { siteConfig } = useSiteConfigContext();

  const onAccept = () => {
    if (foundPolicyholder) {
      setPolicyholder(foundPolicyholder);
      setCurrentStepKey(StaticSceneStepKeys.Application);
      onNextCompleted && onNextCompleted();
    } else {
      resetPolicyholderData();
      setNewPersonalDetailsAccepted(true);
    }
    setLookupPolicyholderModalOpen(false);
  };

  return (
    <Modal centered id='consent-modal' isOpen>
      <ModalHeader>
        <ConsentMainHeader id='lookup-modal-header' siteConfig={siteConfig || null}>
          {foundPolicyholder ? 'Welcome back!' : 'Welcome!'}
        </ConsentMainHeader>
      </ModalHeader>
      <ModalBody>
        <StyledConsentCardBody>
          {foundPolicyholder && (
            <p id='lookup-modal-body'>
              We see you're already with us. No need to type in your details again. Your new policy will be added to
              your existing account. Let’s proceed to make your insurance even better!
            </p>
          )}
          {!foundPolicyholder && (
            <p id='lookup-modal-body'>
              It appears you're new here. Let's take a moment to get to know you better. Please provide all of your
              personal details in the next step.
            </p>
          )}
        </StyledConsentCardBody>
        <ConsentCardFooter>
          <HighlightButton
            id='proceed-policyholder-modal'
            siteConfig={siteConfig}
            fullWidth
            size={Size.lg}
            onClick={onAccept}
          >
            Proceed
          </HighlightButton>
        </ConsentCardFooter>
      </ModalBody>
    </Modal>
  );
};

// TODO rather re-use original styles
const StyledConsentCardBody = styled(CardBody)`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  p:nth-child(1) {
    margin-top: 0px;
    margin-bottom: 9px;
  }

  div:nth-child(1) {
    display: flex;
  }

  svg {
    margin-left: -20px;
    margin-right: 8px;
    margin-top: 1px;
  }
`;

const ConsentMainHeader = styled.h1<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
  font-size: ${globalStyles.fontSize.title};
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0 !important;
  margin-top: 30px;
`;

const ConsentCardFooter = styled.div`
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${globalStyles.spacing.default};
`;
