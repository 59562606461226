import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components';
import React from 'react';
import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';

export const PolicySummaryCell = (params: {
  label: string;
  value: any;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  hideBorderRight?: boolean;
  hideBorderBottom?: boolean;
}) => {
  const { label, siteConfig, value, hideBorderRight, hideBorderBottom } = params;
  const displayValue = value ? value : '-';
  return (
    <PolicySummaryCellStyle
      siteConfig={siteConfig}
      hideBorderRight={hideBorderRight}
      hideBorderBottom={hideBorderBottom}
    >
      <div>{label}</div>
      <div>{displayValue}</div>
    </PolicySummaryCellStyle>
  );
};

const PolicySummaryCellStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  hideBorderRight?: boolean;
  hideBorderBottom?: boolean;
}>`
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  height: 100%;

  ${({ siteConfig }) =>
    css`
      border-top: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
    `};

  div:nth-child(1) {
    color: ${globalStyles.colors.Body};
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;

    ${({ hideBorderRight, siteConfig }) =>
      !hideBorderRight &&
      css`
        border-right: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
      `}

    @media ${devices.tablet} {
      border-right: none;
    }
  }
  div:nth-child(2) {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    font-size: 20px;
    display: flex;
    justify-content: center;

    ${({ hideBorderRight, siteConfig }) =>
      !hideBorderRight &&
      css`
        border-right: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
      `}

    @media ${devices.tablet} {
      border-right: none;
    }
  }
`;
