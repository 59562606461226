import { FullScreenModalHeader } from 'rootstrap/components/modal/styles/form-overlay-styles';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, getFontFamily, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';

export const BeneficiarySummaryFullScreenModalHeader = styled(FullScreenModalHeader)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  color: ${globalStyles.colors.Body};
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
`;

export const BeneficiarySummaryTitleBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BeneficiarySummaryTitleContents = styled.span`
  display: flex;
`;
