import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardGroup } from 'rootstrap/components/card';
import { sentenceCase } from 'sentence-case';
import { AccountType, PaymentMethodType } from 'payment-methods/domain/payment-method';
import { useConfirmationUrl, applyPolicyDetailsToUrl, useBaseUrl, AuthTypes } from 'shared/api';
import { Identification } from 'policyholder/domain/policyholder-identification';
import { PaymentMethodProps } from '../payment-step';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { Col, Row } from 'reactstrap';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { banks } from 'rootstrap/components/forms/new-fields/extended-components/bank-select-field';
import { updateApplicationBillingDay } from 'applications/actions/update-application-billing-day';
import { createDebitOrderPaymentMethod } from 'payment-methods/actions/create-debit-order-payment-method';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { useHistory } from 'react-router-dom';
import { issuePolicy } from 'policies/actions/issue-policy';
import { PaymentMethodPrefillParams } from 'shared/domain/prefill-values';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import styled from 'styled-components';
import { handleRedirect } from '../utils/handle-payment-redirect';
import { useSiteConfigContext } from 'style-context';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { NumberField } from 'rootstrap/components/forms/new-fields/number-field';
import { ActiveElement, scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { updateApplication } from 'applications/actions/update-application';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { DebitOrderPaymentInputs } from 'policy-management/payment-details/forms/debit-order-payment-method-form';

export const accountTypeOptions = Object.values(AccountType).map((accountType) => ({
  label: sentenceCase(accountType),
  value: accountType,
}));

export const DebitOrderPaymentMethod = (params: PaymentMethodProps) => {
  const { setPaymentMethodIsValid, application, policyholder, prefillValues, isConfirmationSkipped } = params;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const history = useHistory();
  const confirmationUrl = useConfirmationUrl();
  const { stepCompletedTrack } = useMixpanelTrack();

  const canEditBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.editBillingDay);
  const displayBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.billingDay);

  const debitOrderInputData = getDebitOrderInputData({
    prefillValues: prefillValues?.payment,
    billingDay: application?.billingDay,
    canEditBillingDay,
  });
  const baseUrl = useBaseUrl();
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const paymentMethodSubmitButtonRef = useRef<any>();
  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const [isTouched] = useState<boolean>(disableSteppedComponents);
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: DebitOrderPaymentInputs.BankName,
  });

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });
  form.watch();

  usePromise(async () => {
    setPaymentMethodIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  useEffect(() => {
    scrollTo({ scrollToId: 'bankName-form-group' });
  }, []);

  const { execute, error, isLoading } = usePromiseLazy(async (debitFormData: BankDetailsFormData) => {
    if (!policyholder || !application) {
      throw new Error(`No ${!policyholder ? 'policyholder' : 'application'} found`);
    }

    const details: DebitOrderDetailsFormData = {
      accountHolder: debitFormData.accountHolder,
      accountNumber: debitFormData.accountNumber,
      bankName: debitFormData.bankName,
      branchCode: debitFormData.branchCode,
      type: PaymentMethodType.DebitOrder,
      accountType: debitFormData.accountType as AccountType,
      accountHolderIsNotPolicyholder: debitFormData.accountHolderIsNotPolicyholder,
      identificationNumber: debitFormData.identificationNumber,
      billingDay: debitFormData.billingDay,
    };

    await createDebitOrderPaymentMethod({
      details,
      policyholderId: policyholder.policyholderId,
      applicationId: application.applicationId,
      auth,
      environment,
      organizationId,
    });

    if (application.billingDay !== debitFormData.billingDay && canEditBillingDay && displayBillingDay) {
      if (auth.type === AuthTypes.APIKey) {
        await updateApplication({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: debitFormData.billingDay || 1,
            // ...props.applicationInputData,
          },
        });
      } else {
        await updateApplicationBillingDay({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: debitFormData.billingDay || 1,
            // ...props.applicationInputData,
          },
        });
      }
    }

    const { policyId, policyholderId } = await issuePolicy({
      applicationId: application.applicationId,
      billingDay: debitFormData.billingDay,
      auth,
      environment,
      organizationId,
    });

    stepCompletedTrack({
      stepName: MixpanelStepNames.Payment,
    });

    handleRedirect({
      applyPolicyDetailsToUrl,
      baseUrl,
      confirmationUrl,
      environment,
      history,
      isConfirmationSkipped,
      policyholderId,
      policyId,
      redirectOnCompletedUrl,
    });
  }, []);

  return (
    <FormWrapperStyle>
      <ErrorAlert error={error} />
      <CardGroup>
        <form onSubmit={form.handleSubmit((data: BankDetailsFormData) => execute(data))}>
          <Row>
            <Col sm={12}>
              <SelectFieldDebitOrderSelect
                clearable={true}
                isTouched={isTouched}
                disableScrollToElement={false}
                hideDivider={true}
                options={banks}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                name={DebitOrderPaymentInputs.BankName}
                label='Bank'
                form={form}
                defaultValue={debitOrderInputData.bank}
                prefillValue={debitOrderInputData.bank}
                placeholder={'Bank'}
                disableTitle={true}
                disableActiveElement={true}
                disableNextButton={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: DebitOrderPaymentInputs.AccountNumber,
                  } as InputFieldDisplayProperties
                }
              />
            </Col>
            <Col sm={12}>
              <NumberField
                isTouched={isTouched}
                disableScrollToElement={false}
                name={DebitOrderPaymentInputs.AccountNumber}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                label='Account number'
                form={form}
                defaultValue={debitOrderInputData.accountNumber}
                prefillValue={debitOrderInputData.accountNumber}
                disableTitle={true}
                disableNextButton={true}
                hideDivider={true}
                placeholder={'Account number'}
                disableActiveElement={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: DebitOrderPaymentInputs.AccountHolder,
                  } as InputFieldDisplayProperties
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InputField
                isTouched={isTouched}
                disableScrollToElement={false}
                name={DebitOrderPaymentInputs.AccountHolder}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                label='Account holder'
                form={form}
                defaultValue={debitOrderInputData.accountHolder}
                prefillValue={debitOrderInputData.accountHolder}
                placeholder={'Account holder*'}
                disableTitle={true}
                hideDivider={true}
                disableNextButton={true}
                disableActiveElement={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: DebitOrderPaymentInputs.BranchCode,
                  } as InputFieldDisplayProperties
                }
              />
            </Col>
            <Col sm={12}>
              <NumberField
                isTouched={isTouched}
                disableScrollToElement={false}
                name={DebitOrderPaymentInputs.BranchCode}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                label='Branch code'
                form={form}
                defaultValue={debitOrderInputData.branchCode}
                prefillValue={debitOrderInputData.branchCode}
                disableTitle={true}
                placeholder={'Branch code'}
                disableNextButton={true}
                disableActiveElement={true}
                hideDivider={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: DebitOrderPaymentInputs.BillingDay,
                  } as InputFieldDisplayProperties
                }
              />
            </Col>
          </Row>
          <Row>
            {displayBillingDay && (
              <Col sm={12}>
                <BillingDaySelectField
                  clearable={true}
                  isTouched={isTouched}
                  disableScrollToElement={false}
                  name={DebitOrderPaymentInputs.BillingDay}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  label='Debit day'
                  form={form}
                  isDisabled={!canEditBillingDay}
                  defaultValue={debitOrderInputData.billingDay}
                  prefillValue={debitOrderInputData.billingDay}
                  disableTitle={true}
                  placeholder={'Debit day'}
                  disableActiveElement={true}
                  disableNextButton={true}
                  hideDivider={true}
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: DebitOrderPaymentInputs.AccountType,
                    } as InputFieldDisplayProperties
                  }
                />
              </Col>
            )}
            <Col sm={12}>
              <SelectField
                clearable={true}
                isTouched={isTouched}
                disableScrollToElement={false}
                options={accountTypeOptions}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                name={DebitOrderPaymentInputs.AccountType}
                label='Account type'
                form={form}
                defaultValue={debitOrderInputData.accountType}
                prefillValue={debitOrderInputData.accountType}
                disableTitle={true}
                placeholder={'Account type'}
                disableActiveElement={true}
                disableNextButton={true}
                hideDivider={true}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: '',
                  } as InputFieldDisplayProperties
                }
              />
            </Col>
          </Row>
          <button style={{ display: 'none' }} ref={paymentMethodSubmitButtonRef} type='submit' />
          <DebitOrderLongButtonSuccess
            siteConfig={siteConfig}
            onClick={() => paymentMethodSubmitButtonRef.current.click()}
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            Pay now
          </DebitOrderLongButtonSuccess>
        </form>
      </CardGroup>
    </FormWrapperStyle>
  );
};

const DebitOrderLongButtonSuccess = styled(LongButtonSuccess)`
  width: 142px;
  float: right;
`;

const SelectFieldDebitOrderSelect = styled(SelectField)`
  margin-bottom: -10px !important;
`;

export interface BankDetailsFormData {
  bankName?: string;
  branchCode?: string;
  type: PaymentMethodType;
  accountNumber?: string;
  accountType?: string;
  accountHolder?: string;
  identificationNumber?: string;
  accountHolderIsNotPolicyholder?: boolean;
  billingDay?: number;
}

export interface DebitOrderDetailsFormData {
  type?: PaymentMethodType;
  accountHolder?: string;
  accountHolderIdentification?: Identification;
  bankName?: string;
  branchCode?: string;
  accountNumber?: string;
  billingDay?: number;
  accountType?: AccountType;
  accountHolderIsNotPolicyholder?: boolean;
  identificationNumber?: string;
}

export interface DebitOrderDebitOrderPrefillParams {
  bank?: any;
  accountNumber?: number;
  accountHolder?: string;
  branchCode?: number;
  billingDay?: number;
  accountType?: string;
}

const getDebitOrderInputData = (params: {
  prefillValues?: PaymentMethodPrefillParams;
  canEditBillingDay: boolean;
  billingDay: number | undefined;
}): DebitOrderDebitOrderPrefillParams => {
  const { canEditBillingDay, prefillValues, billingDay } = params;
  return {
    bank: banks.find((bank) => prefillValues?.bank?.toLowerCase() === bank.label.toLowerCase())?.value,
    accountNumber: prefillValues?.account_number ? Number(prefillValues.account_number) : undefined,
    accountHolder: prefillValues?.account_holder,
    branchCode: prefillValues?.branch_code ? Number(prefillValues.branch_code) : undefined,
    billingDay: canEditBillingDay ? prefillValues?.debit_day || billingDay : billingDay,
    accountType: prefillValues?.account_type,
  };
};
