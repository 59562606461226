import { Environment } from 'models/environment';
import { NetworkPublicKey, PublicKey } from 'public-keys/domain/public-key';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

export interface PublicKeyParams {
  type: string;
  auth: EmbedAuth;
  organizationId: string;
  environment: Environment;
}

export const getPublicKey = async (params: PublicKeyParams) => {
  const { auth, environment, organizationId, type } = params;

  const response: Response = await apiRequest({
    url: `${getApiHost({ environment })}${getBasePathname({
      organizationId,
      auth,
      clientAppAuth: true,
    })}/public-keys/${type}`,
    method: 'get',
    auth,
  });

  const networkPublicKey: NetworkPublicKey = await response.json();

  const publicKey = PublicKey.fromNetwork(networkPublicKey);

  return publicKey;
};
