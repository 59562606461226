import { Environment } from 'models/environment';
import { NetworkQuote, Quote } from 'policy-issuing/quotes/domain/quote';
import { getProductModuleDefinitionLiveOrDraft } from 'product-modules/actions/get-product-module';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { buildApiUrl, JSONObject } from 'shared/utils';
import _ from 'lodash'

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  data: JSONObject;
  targetDraftProductModuleDefinition: boolean;
  environment: Environment;
  embedSessionId: string | undefined
}

export const createQuotes = async ({ organizationId, auth, data, targetDraftProductModuleDefinition, environment, embedSessionId }: Params) => {
  const productModuleDefinitionLiveOrDraft = getProductModuleDefinitionLiveOrDraft({
    environment,
    targetDraftProductModuleDefinition,
  });

  const version = !!productModuleDefinitionLiveOrDraft.live_definition ? 'live' : 'draft';
  const baseBathName = getBasePathname({ organizationId, auth, clientAppAuth: true });

  let quoteData: any = { ...data };

  if (embedSessionId) {
    quoteData = _.assign({}, quoteData, { embed_session_id: embedSessionId });
  }

  if (data.policyholder) {
    let policyholder: any = _.cloneDeep(data.policyholder);

    if (policyholder.cellphone) {
      const { country_code, ...rest } = policyholder.cellphone;
      policyholder.cellphone = {
        ...rest,
        country: country_code,
      };
    }

    quoteData = _.assign({}, quoteData, { policyholder });
  }

  const response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${baseBathName}/quotes`, {
      version,
    }),
    method: 'post',
    body: quoteData,
    auth,
  });

  const networkQuotes: NetworkQuote[] = await response.json();

  const quote = networkQuotes.map((networkQuote) => Quote.fromNetwork(networkQuote));

  return quote;
};
