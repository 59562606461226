import moment from 'moment';

export const isValidSAIdNumber = (potentialId: string) => {
  if (/[^0-9]+/.test(potentialId)) {
    return false;
  }

  if (!moment(potentialId.substring(0, 6), 'YYMMDD', true).isValid()) {
    return false;
  }

  if (potentialId.length !== 13) {
    return false;
  }

  const month = parseInt(potentialId.substr(2, 2), 10);
  if (month === 0 || month > 12) {
    return false;
  }

  const day = parseInt(potentialId.substr(4, 2), 10);
  if (day === 0 || day > 31) {
    return false;
  }

  // The Luhn Algorithm for the check digit
  // tslint:disable-next-line:no-shadowed-variable
  const [sum] = potentialId
    .split('')
    .map((x) => parseInt(x, 10))
    .reduce(
      ([sum, isOdd]: any, digit) => {
        if (isOdd) {
          const doubledDigit = digit * 2;
          return [sum + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit), !isOdd];
        }
        return [sum + digit, !isOdd];
      },
      [0, false],
    );

  return sum % 10 === 0;
};
