import React from 'react';
import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import { ModuleData } from 'rootstrap/components/module-data/module-data-component';
import { pickNested } from 'rootstrap/components/module-data/utils';
import { compileHandleBarsWithLabels } from 'general/actions/compileHandleBarsWithLabels';
import { usePromise } from 'shared/hooks/promise';
import { Application } from 'applications/domain/application';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { ApiError } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useSiteConfigContext } from 'style-context';

interface CombinedPaymentSummaryProps {
  application: Application | undefined;
  policyholder: Policyholder | undefined;
  isLoadingCollectionModules: boolean;
  collectionModulesError: ApiError | undefined;
  isHandleBarModuleDataSummary: boolean;
  summaryData: Record<string, any>;
  summaryConfig: string[];
}

const CombinedPaymentSummary: React.FC<CombinedPaymentSummaryProps> = ({
  application,
  policyholder,
  isLoadingCollectionModules,
  collectionModulesError,
  isHandleBarModuleDataSummary,
  summaryData,
  summaryConfig,
}) => {
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;

  const {
    result: compiledSummaryWording,
    isLoading: isLoadingHandlebars,
    error,
  } = usePromise(async () => {
    if (application && policyholder && isHandleBarModuleDataSummary) {
      const compiledHandleBars = await compileHandleBarsWithLabels({
        auth,
        organizationId,
        environment,
        siteConfigSummaryWording: siteConfig?.payment?.wording.summary as { label: string; content: string }[],
        application,
        policyholder,
      });

      return convertToLabelObject(compiledHandleBars);
    }
  }, [application, siteConfig, policyholder]);

  return (
    <>
      <SplashContainer
        siteConfig={siteConfig}
        style={{
          paddingLeft: 44,
          paddingRight: 44,
          paddingTop: 20,
          paddingBottom: 34,
        }}
      >
        <table style={{ width: '100%', padding: 50 }}>
          <tbody>
            <ModuleData
              columnIndex={0}
              moduleData={
                isHandleBarModuleDataSummary ? compiledSummaryWording || {} : pickNested(summaryData, summaryConfig)
              }
              currency={application?.currency}
              isLoading={isLoadingHandlebars || isLoadingCollectionModules}
            />
          </tbody>
        </table>
      </SplashContainer>
      {(error || collectionModulesError) && (
        <div style={{ marginTop: '20px', width: '100%' }}>
          <ErrorAlert error={error || collectionModulesError} />
        </div>
      )}
    </>
  );
};

export default CombinedPaymentSummary;

// Helper function to convert a camelCase string to snake_case
const camelToSnakeString = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const convertToLabelObject = (compiledHandleBars: { label: string; content: string }[]) => {
  const resultObject: Record<string, any> = {};

  compiledHandleBars.forEach(({ label, content }) => {
    const snakeCaseLabel = camelToSnakeString(label); // Convert the label to snake_case using the string helper
    resultObject[snakeCaseLabel] = content;
  });

  return resultObject;
};
