import React from 'react';
import { Countries } from '../../countries';
import { SelectFieldParams, SelectField } from '../select-field';

export const CountrySelectField = ({ ...props }: Omit<SelectFieldParams<string>, 'options'>) => {
  return (
    <SelectField
      searchable
      options={Countries.map((country) => {
        return {
          name: country.name,
          label: (
            <>
              <span style={{ marginRight: '6px' }} className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
              {country.name}
            </>
          ) as any,
          value: country.code,
        };
      })}
      {...props}
      clearable={true}
    />
  );
};
