import { EmbedSession, NetworkEmbedSession } from 'embed-sessions/domain/embed-session';
import { Environment } from 'models/environment';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from 'shared/api';

interface Params {
  organizationId: string;
  productModuleId: string;
  environment: Environment;
  auth: EmbedAuth;
}

export const createEmbedSession = async (params: Params) => {
  const { organizationId, productModuleId, environment, auth } = params;

  const url =
    auth.type === AuthTypes.JWT
      ? `${getApiHost({ environment })}/v1/organizations/${organizationId}/embed/embed-sessions/create`
      : `${getApiHost({ environment })}/v1/embed/embed-sessions/create`;

  const response = await apiRequest({
    url,
    method: 'post',
    body: {
      organization_id: organizationId,
      product_module_id: productModuleId,
    },
    auth,
  });

  const networkEmbedSession: NetworkEmbedSession = await response.json();

  const embedSession = EmbedSession.fromNetwork(networkEmbedSession);

  return embedSession;
};
