import { Environment } from 'models/environment';
import React from 'react';
import { EmbedAuth } from './api';

export interface EmbedParams {
  organizationId: string;
  productModuleKey: string;
  environment: Environment;
  auth: EmbedAuth;
  organizationName: string;
  isUnauthenticated: boolean | undefined;
}

export interface EmbedParamsContextState {
  embedParams: EmbedParams;
  setEmbedParams: React.Dispatch<React.SetStateAction<EmbedParams>>;
}

export const EmbedParamsContext = React.createContext<EmbedParamsContextState | undefined>(undefined);

export function useEmbedParamsContext() {
  const context = React.useContext(EmbedParamsContext);
  if (context === undefined) {
    throw new Error('useEmbedParamsContext must be used within EmbedParamsContext.Provider');
  }
  return context;
}
