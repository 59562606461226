import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'rootstrap/global-styles';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';

interface UpdateBillingDayButtonProps {
  setShowUpdateBillingDay: (show: boolean) => void;
}

export const UpdateBillingDayButton = (params: UpdateBillingDayButtonProps) => {
  const { siteConfig } = useSiteConfigContext();
  const { setShowUpdateBillingDay } = params;

  return (
    <>
      <StyledHr />
      <SuccessButton
        outline
        id='policy-management-update-billing-day-button'
        siteConfig={siteConfig}
        onClick={() => setShowUpdateBillingDay(true)}
        fullWidth={true}
      >
        <span>Update debit day</span>
      </SuccessButton>
    </>
  );
};

const StyledHr = styled.hr`
  margin-top: ${Spacing.default};
  margin-bottom: ${Spacing.default};
`;
