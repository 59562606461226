import React, { useState } from 'react';
import { getPolicyPaymentMethod } from 'payment-methods/actions/get-policy-payment-method';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise } from 'shared/hooks/promise';
import { Policy } from 'policies/domain/policy';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';
import { PaymentMethodCard } from './payment-method-cards';
import { PolicyStatus } from 'policies/domain/policy-status';
import { CreateOrUpdatePaymentMethod } from './create-or-update-payment-details';
import { Policyholder } from 'policyholder/domain/policyholder';
import { PaymentMethodAll, PaymentMethodType } from 'payment-methods/domain/payment-method';
import { ProductModule } from 'product-modules/domain/product-module';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import styled from 'styled-components';
import { devices } from 'rootstrap/global-styles/devices';
import { getPaymentMethodType } from './utils';
import { UpdateBillingDayForm } from './update-payment-method-billing-day';
import { StyledManagePaymentMethodCard } from './styles';

interface Props {
  policy: Policy | undefined;
  setPolicy: (policy: Policy) => void;
  policyholder: Policyholder | undefined;
  productModule: ProductModule;
  refetchPolicy: () => void;
}

export const PaymentMethodScene = (props: Props) => {
  const { policy, setPolicy, policyholder, productModule, refetchPolicy } = props;
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const { siteConfig } = useSiteConfigContext();
  const [isUpdatingOrCreatingPaymentMethod, setIsUpdatingOrCreatingPaymentMethod] = useState(false);
  const [showUpdateBillingDay, setShowUpdateBillingDay] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodAll | undefined>();
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;
  const canViewPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.viewPaymentMethod;
  const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;

  const updateButtonDisabled = isUpdateButtonDisabled({
    canEditBillingDay,
    canEditPaymentMethod,
    paymentMethod,
    productModule,
  });

  const { isLoading, error } = usePromise(async () => {
    if (!policy) {
      return;
    }

    try {
      const paymentMethod = await getPolicyPaymentMethod({
        auth,
        environment,
        organizationId,
        policyId: policy.policyId,
      });

      setPaymentMethod(paymentMethod);
    } catch (error: any) {
      if (error.message === 'Policy has no payment method set') {
        return undefined;
      }

      throw error;
    }
  }, [policy]);

  if (isLoading) {
    return (
      <MainContainer>
        <LoadingLines />
      </MainContainer>
    );
  }

  if (error || !policy || !policyholder) {
    return <ErrorAlert error={error} />;
  }

  if (isUpdatingOrCreatingPaymentMethod) {
    return (
      <MainContainer>
        <CreateOrUpdatePaymentMethod
          productModule={productModule}
          setPaymentMethod={setPaymentMethod}
          policyholder={policyholder}
          policy={policy}
          setPolicy={setPolicy}
          setIsUpdatingOrCreatingPaymentMethod={setIsUpdatingOrCreatingPaymentMethod}
          paymentMethod={paymentMethod}
        />
      </MainContainer>
    );
  }

  if (showUpdateBillingDay) {
    return (
      <MainContainer>
        <UpdateBillingDayForm
          productModule={productModule}
          setPaymentMethod={setPaymentMethod}
          policyholder={policyholder}
          policy={policy}
          setPolicy={setPolicy}
          setShowUpdateBillingDay={setShowUpdateBillingDay}
          paymentMethod={paymentMethod}
        />
      </MainContainer>
    );
  }

  if (!canViewPaymentMethod && canEditBillingDay) {
    return (
      <MainContainer>
        <LongButtonSuccess
          siteConfig={siteConfig}
          onClick={() => {
            setIsUpdatingOrCreatingPaymentMethod(true);
          }}
          fullWidth={true}
          disabled={policy?.status === PolicyStatus.Cancelled}
        >
          <span>Update billing day</span>
        </LongButtonSuccess>
      </MainContainer>
    );
  }

  if (!paymentMethod) {
    return (
      <MainContainer>
        <StyledManagePaymentMethodCard siteConfig={siteConfig}>
          <p className='header'>No existing payment method</p>
        </StyledManagePaymentMethodCard>
        <LongButtonSuccess
          id='policy-management-update-payment-method-button'
          siteConfig={siteConfig}
          onClick={() => {
            setIsUpdatingOrCreatingPaymentMethod(true);
          }}
          fullWidth={true}
          disabled={policy?.status === PolicyStatus.Cancelled || updateButtonDisabled}
        >
          <span>Add payment method</span>
        </LongButtonSuccess>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <p id='manage-payment-method-heading' style={{ fontWeight: 700, fontSize: '16px' }}>
        Your payment method
      </p>
      <PaymentMethodCard
        refetchPolicy={refetchPolicy}
        siteConfig={siteConfig}
        paymentMethod={paymentMethod}
        policy={policy}
        setShowUpdateBillingDay={setShowUpdateBillingDay}
      />
      <LongButtonSuccess
        id='policy-management-update-payment-method-button'
        siteConfig={siteConfig}
        onClick={() => {
          setIsUpdatingOrCreatingPaymentMethod(true);
        }}
        fullWidth={true}
        disabled={policy?.status === PolicyStatus.Cancelled || updateButtonDisabled}
      >
        <span>Change payment method</span>
      </LongButtonSuccess>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const isUpdateButtonDisabled = (params: {
  canEditBillingDay: boolean | undefined;
  canEditPaymentMethod: boolean | undefined;
  paymentMethod: PaymentMethodAll | undefined;
  productModule: ProductModule;
}) => {
  const { canEditBillingDay, canEditPaymentMethod, paymentMethod, productModule } = params;

  const paymentMethodType = getPaymentMethodType(paymentMethod, productModule);

  if (paymentMethodType === PaymentMethodType.External) {
    return !canEditBillingDay;
  }

  return !canEditBillingDay && !canEditPaymentMethod;
};
