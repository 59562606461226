import React from 'react';

export const Icon16PXTick = (props: { fill: string; style?: React.CSSProperties }) => (
  <svg style={props.style} width='22' height='16' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.71927 11.343C6.33306 11.7109 5.72608 11.7109 5.33985 11.3431L0.760198 6.98108C0.346466 6.58701 0.346447 5.92699 0.760158 5.5329L1.93795 4.41097C2.32418 4.04306 2.93118 4.04306 3.31741 4.41097L6.02958 6.99452L12.6827 0.656935C13.0689 0.289059 13.6758 0.28902 14.062 0.656846L15.2397 1.77836C15.6535 2.17241 15.6535 2.83247 15.2398 3.22658L6.71927 11.343Z'
      fill={props.fill}
    />
  </svg>
);
