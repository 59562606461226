import { Application } from 'applications/domain/application';
import { compileHandleBarsWithLabels } from 'general/actions/compileHandleBarsWithLabels';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import React, { useLayoutEffect, useState } from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import { globalStyles } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { devices } from 'rootstrap/global-styles/devices';
import { ApiError } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise } from 'shared/hooks/promise';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled, { css } from 'styled-components';
import { camelToSnake } from 'utils';

interface PaymentSummaryProps {
  application: Application | undefined;
  policyholder: Policyholder | undefined;
  productModuleDefinition: ProductModuleDefinition | undefined;
  isLoadingCollectionModules: boolean;
  collectionModulesError: ApiError | undefined;
}

export const LegacyPaymentSummary = (props: PaymentSummaryProps) => {
  const { siteConfig } = useSiteConfigContext();
  const { application, policyholder, isLoadingCollectionModules, collectionModulesError } = props;
  const siteConfigSummaryWording = siteConfig?.payment?.wording.summary;
  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;
  const [isMobile, setIsMobile] = useState<boolean>(window.matchMedia('(max-width: 767px)').matches);

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const {
    result: compiledSummaryWording,
    isLoading: isLoadingHandlebars,
    error,
  } = usePromise(async () => {
    if (application && policyholder && siteConfigSummaryWording) {
      const compiledHandleBars = await compileHandleBarsWithLabels({
        auth,
        organizationId,
        environment,
        siteConfigSummaryWording: siteConfigSummaryWording as { label: string; content: string }[],
        application,
        policyholder,
      });

      return compiledHandleBars;
    }
  }, [application, siteConfig, policyholder]);

  return (
    <>
      <SplashContainer siteConfig={siteConfig}>
        <>
          <PaymentSummaryPanel siteConfig={siteConfig}>
            {(!application || isLoadingHandlebars || isLoadingCollectionModules) && (
              <StyledPaymentDetailsLoadingInputsWrapper>
                <LoadingInputs count={1} />
              </StyledPaymentDetailsLoadingInputsWrapper>
            )}
            {application && compiledSummaryWording && !isLoadingCollectionModules && (
              <>
                <StyledRow>
                  <div id='payment-summary-cell-1'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[0].label}
                      value={compiledSummaryWording[0].content}
                    />
                  </div>
                  <div id='payment-summary-cell-2'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[1].label}
                      value={compiledSummaryWording[1].content}
                      hideBorderRight={true}
                    />
                  </div>
                </StyledRow>
                <StyledRow>
                  <div id='payment-summary-cell-3'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[2].label}
                      value={compiledSummaryWording[2].content}
                    />
                  </div>
                  <div id='payment-summary-cell-4'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[3].label}
                      value={compiledSummaryWording[3].content}
                      hideBorderRight={true}
                    />
                  </div>
                </StyledRow>
                <StyledRow>
                  <div id='payment-summary-cell-5'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[4].label}
                      value={compiledSummaryWording[4].content}
                      hideBorderBottom={!isMobile}
                    />
                  </div>
                  <div id='payment-summary-cell-6'>
                    <PaymentSummaryCell
                      siteConfig={siteConfig}
                      label={compiledSummaryWording[5].label}
                      value={compiledSummaryWording[5].content}
                      hideBorderRight={true}
                      hideBorderBottom={true}
                    />
                  </div>
                </StyledRow>
              </>
            )}
          </PaymentSummaryPanel>
        </>
      </SplashContainer>
      {(error || collectionModulesError) && (
        <StyledErrorWrapper>
          <ErrorAlert error={error || collectionModulesError} />
        </StyledErrorWrapper>
      )}
    </>
  );
};

export const StyledPaymentDetailsLoadingInputsWrapper = styled.div`
  margin-top: ${globalStyles.spacing.lg};
  width: 100%;
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${devices.tablet} {
    flex-direction: column;
  }

  div:nth-child(1) {
    display: flex;
    flex: 1;
  }

  div:nth-child(2) {
    display: flex;
    flex: 1;
  }
`;

const PaymentSummaryCell = (params: {
  label: string;
  value: any;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  hideBorderRight?: boolean;
  hideBorderBottom?: boolean;
}) => {
  const { label, siteConfig, value, hideBorderRight, hideBorderBottom } = params;
  const displayValue = value ? value : '-';
  return (
    <PaymentSummaryCellStyle
      siteConfig={siteConfig}
      hideBorderRight={hideBorderRight}
      hideBorderBottom={hideBorderBottom}
    >
      <div>{label}</div>
      <div>{displayValue}</div>
    </PaymentSummaryCellStyle>
  );
};

const PaymentSummaryPanel = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const PaymentSummaryCellStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  hideBorderRight?: boolean;
  hideBorderBottom?: boolean;
}>`
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  height: 100%;

  ${({ siteConfig, hideBorderBottom }) =>
    !hideBorderBottom &&
    css`
      border-bottom: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
    `};

  div:nth-child(1) {
    color: ${Colors.Body};
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;

    ${({ hideBorderRight, siteConfig }) =>
      !hideBorderRight &&
      css`
        border-right: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
      `}

    @media ${devices.tablet} {
      border-right: none;
    }
  }
  div:nth-child(2) {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    font-size: 20px;
    display: flex;
    justify-content: center;

    ${({ hideBorderRight, siteConfig }) =>
      !hideBorderRight &&
      css`
        border-right: 1px solid ${getColor({ siteConfig, color: 'disabled' })};
      `}

    @media ${devices.tablet} {
      border-right: none;
    }
  }
`;

const StyledErrorWrapper = styled.div`
  margin-top: 20px !important;
  width: 100%;
`;
