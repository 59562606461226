import React from 'react';

export const Icon16PXPersonGroup = (params: { fill: string; style?: React.CSSProperties }) => (
  <svg width='19' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.0429 9.3744H10.4852C10.9859 8.76318 11.3124 8.02258 11.4278 7.23359C11.5485 6.40871 11.4335 5.56578 11.0966 4.80579C10.7596 4.0458 10.2149 3.40091 9.52758 2.94851C8.84026 2.4961 8.03955 2.25542 7.2214 2.25542C6.40326 2.25542 5.60254 2.4961 4.91523 2.94851C4.22792 3.40091 3.68321 4.0458 3.34623 4.80579C3.00926 5.56578 2.89427 6.40871 3.01498 7.23359C3.13045 8.02258 3.45688 8.76318 3.95756 9.3744H3.42974L3.42965 9.3744C2.77128 9.37558 2.14048 9.64322 1.67566 10.1182C1.21087 10.5932 0.949999 11.2368 0.95 11.9075V17.9957V18.0457H1H13.4769H13.5269V17.9957V11.9075C13.5269 11.575 13.4627 11.2457 13.338 10.9384C13.2132 10.6312 13.0303 10.3519 12.7997 10.1167C12.5691 9.88142 12.2953 9.69474 11.9938 9.56736C11.6924 9.43998 11.3693 9.3744 11.0429 9.3744ZM4.51983 6.60203C4.519 6.05228 4.6781 5.51472 4.97687 5.05742C5.27563 4.60013 5.70058 4.24372 6.19779 4.03315C6.69499 3.82259 7.24219 3.7673 7.77016 3.87422C8.29813 3.98114 8.78327 4.24551 9.16409 4.63402C9.54493 5.02253 9.8043 5.51772 9.90922 6.05692C10.0141 6.59613 9.95986 7.15497 9.75329 7.66263C9.54673 8.17029 9.19721 8.60383 8.74916 8.90849C8.30111 9.21314 7.77464 9.37525 7.2364 9.3744C6.51659 9.37327 5.82637 9.08107 5.31707 8.5615C4.80774 8.04189 4.52094 7.33728 4.51983 6.60203ZM11.9946 16.4888H2.48227V11.9075C2.48226 11.6501 2.58221 11.4034 2.75994 11.2213C2.93763 11.0393 3.17856 10.9366 3.42995 10.9356H11.0429C11.295 10.9356 11.537 11.0377 11.7155 11.2199C11.8941 11.4021 11.9946 11.6494 11.9946 11.9075V16.4888Z'
      fill={params.fill}
      stroke={params.fill}
      stroke-width='0.1'
    />
    <path
      d='M8.93945 11.2571C8.93945 10.1526 9.83488 9.25714 10.9395 9.25714H14.745C15.2973 9.25714 15.745 9.70486 15.745 10.2571V17.3572H8.93945V11.2571Z'
      fill='white'
    />
    <g clip-path='url(#clip0_12800_378066)'>
      <path
        d='M20.4641 8.10913H19.8086C20.3878 7.40646 20.7654 6.55329 20.8985 5.64402C21.0368 4.6988 20.9051 3.73291 20.5189 2.86208C20.1328 1.99124 19.5087 1.2523 18.7211 0.73394C17.9336 0.21557 17.0162 -0.0601929 16.0788 -0.0601929C15.1414 -0.0601929 14.2239 0.21557 13.4364 0.73394C12.6489 1.2523 12.0247 1.99124 11.6386 2.86208C11.2525 3.73291 11.1207 4.6988 11.259 5.64402C11.3921 6.55329 11.7697 7.40646 12.349 8.10913H11.7277H11.7276C10.9741 8.11048 10.2521 8.4168 9.72007 8.96051C9.18807 9.50419 8.88945 10.2408 8.88945 11.0086V17.995V18.045H8.93945H23.2572H23.3072V17.995V11.0086C23.3072 10.628 23.2337 10.251 23.0909 9.89934C22.9481 9.54763 22.7388 9.228 22.4748 8.95872C22.2109 8.68943 21.8975 8.47577 21.5524 8.32998C21.2074 8.18418 20.8376 8.10913 20.4641 8.10913ZM12.9712 4.92035C12.9703 4.28806 13.1533 3.66978 13.4969 3.1438C13.8405 2.61783 14.3293 2.20787 14.9013 1.96566C15.4732 1.72346 16.1026 1.65985 16.71 1.78284C17.3173 1.90583 17.8753 2.20994 18.3134 2.65681C18.7514 3.10369 19.0497 3.67324 19.1704 4.2934C19.2911 4.91357 19.2286 5.55631 18.9911 6.14021C18.7535 6.72409 18.3515 7.22277 17.8361 7.5732C17.3207 7.92363 16.7151 8.1101 16.096 8.10913C15.268 8.10783 14.474 7.7717 13.8882 7.17408C13.3024 6.57642 12.9725 5.76599 12.9712 4.92035ZM21.5636 16.2733H10.633V11.0086C10.633 10.7113 10.7485 10.4264 10.9538 10.216C11.159 10.0057 11.4374 9.88713 11.7279 9.88587H20.4641C20.7554 9.88587 21.035 10.0039 21.2412 10.2144C21.4476 10.4248 21.5636 10.7105 21.5636 11.0086V16.2733Z'
        fill={params.fill}
        stroke={params.fill}
        stroke-width='0.1'
      />
    </g>
    <defs>
      <clipPath id='clip0_12800_378066'>
        <rect width='14.3178' height='18' fill='white' transform='translate(8.93945)' />
      </clipPath>
    </defs>
  </svg>
);
