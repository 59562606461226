import React, { useState } from 'react';
import { getPremiumDisplay } from 'shared/utils';
import { Size, SuccessButton } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { AlterationPackage } from 'alteration-hooks/domain/alteration-package';
import { Policy } from 'policies/domain/policy';
import { StyledValidQuoteDisplay } from 'policy-issuing/quotes/views/quote-package-action-item';

interface Props {
  alterationPackage: AlterationPackage;
  productModuleDefinition: ProductModuleDefinition | undefined;
  policy: Policy;
  applyAlteration: (alterationPackageId: string) => void;
}

export const AlterationPackageActionItem = (props: Props) => {
  const { productModuleDefinition, alterationPackage, policy, applyAlteration } = props;

  const { monthlyPremium } = alterationPackage;
  const { siteConfig } = useSiteConfigContext();
  const [isLoading, setIsLoading] = useState(false);

  const premiumDisplay = getPremiumDisplay({
    premiumValue: monthlyPremium,
    billingFrequency: policy.billingFrequency,
    currency: productModuleDefinition?.settings.billing.currency,
  });

  return (
    <StyledValidQuoteDisplay siteConfig={siteConfig}>
      <div className='pricing-section'>
        <div>
          <p>You pay</p>
          <div className='premium'>{premiumDisplay}</div>
        </div>
        <div className='btn-container'>
          <SuccessButton
            id='close-confirmation'
            siteConfig={siteConfig}
            disabled={isLoading}
            size={Size.default}
            onClick={async (e: any) => {
              e.stopPropagation();
              e.preventDefault();
              setIsLoading(true);

              await applyAlteration(alterationPackage.alterationPackageId);
              setIsLoading(false);
            }}
          >
            <span style={{ justifyContent: 'center', fontWeight: 300 }}>
              {isLoading && (
                <span style={{ marginRight: '10px' }}>
                  <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
                </span>
              )}
              Update policy
            </span>
          </SuccessButton>
        </div>
      </div>
    </StyledValidQuoteDisplay>
  );
};
