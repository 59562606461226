import React, { useEffect, useState } from 'react';
import ReactSelect, { Options } from 'react-select';
import { Controller } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { InputFieldParams } from './input-field';
import { isValidCellphoneNumber } from '../../../../shared/utils';
import { Countries } from '../countries';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { Field } from '../new-field';
import {
  getSteppedFormLabelColor,
  SteppedFormButton,
  getSteppedFormInputStyle,
  onEnterKeyDown,
} from './utils/stepped-form';
import { useSiteConfigContext } from 'style-context';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import styled from 'styled-components';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { globalStyles } from 'rootstrap/global-styles';
import { StyledInput } from './styled-input';
import { ValidationProps, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { Validator } from 'rootstrap/components-old/root-schema-form/interfaces';
import { isMobile } from 'react-device-detect';

export interface PhoneNumberFieldValue {
  countryCode?: string;
  number?: string;
  internationalNumber?: string;
}

const handleFilter = (options: Options, filter: string) => {
  return options.filter((opt: any) => opt.name.toLowerCase().includes(filter.toLowerCase()));
};

export const PhoneNumberField = ({
  name,
  prefillAction,
  form,
  disableScrollToElement,
  disableActiveElement,
  validators,
  hideDivider,
  disableTitle,
  isTouched: defaultIsTouched,
  submitOnChange,
  hideBorder,
  hiddenComponent,
  isLastComponent,
  isLastStep,
  ...props
}: Omit<InputFieldParams<PhoneNumberFieldValue>, 'type' | 'defaultValue'> & {
  defaultValue?: Partial<PhoneNumberFieldValue>;
}) => {
  const { label, defaultValue, isDisabled, prefillValue, displayProperties, disableNextButton } = props;

  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue?.number || defaultIsTouched);

  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const isActive = activeElement.elementId === name;

  useEffect(() => {
    const value = form?.watch(name);
    if (prefillValue?.number || prefillValue?.country) {
      setFieldError(validate(value));
    }
  }, []);

  const validate = (value: any) => {
    const internationalNumber = parsePhoneNumber(
      value?.number || '',
      value?.countryCode || value?.code,
    )?.number.toString();

    const result = phoneNumberValidation({
      value: {
        number: value?.number,
        countryCode: value?.countryCode,
        internationalNumber,
      },
      validators,
      props: undefined,
    });

    return result;
  };

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue: {
      number: prefillValue?.number,
      countryCode: prefillValue?.countryCode || prefillValue?.country || prefillValue?.country_code || '',
    },
    options: undefined,
    validate,
  });

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }

    const value = form?.watch(name);
    const internationalNumber = parsePhoneNumber(
      value?.number || prefillValue?.number || '',
      value?.countryCode || prefillValue?.countryCode || prefillValue?.country || prefillValue.country_code || '',
    )?.number.toString();

    form?.setValue(
      name,
      {
        countryCode: value?.countryCode || value?.country_code,
        number: value.number,
        internationalNumber,
      },
      { shouldDirty: false },
    );
  }, [hiddenFromPrefill.display, isActive]);

  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
    });
  }, [isActive, name]);

  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
    });
  }, [isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaPhoneNumberComponent
        className='schema-component'
        siteConfig={siteConfig}
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
        isMobile={isMobile}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          name={name}
          disableTitle={disableTitle}
          isRequired={isRequired}
          label={label}
          hiddenComponent={hiddenComponent}
          errors={fieldError ? [fieldError] : []}
          style={{
            color: getSteppedFormLabelColor({ isActive: isActive || isTouched, siteConfig, color: Colors.Body }),
          }}
          onClick={() => setActiveElement({ elementId: name })}
        >
          <Controller
            name={name}
            control={form?.control}
            defaultValue={defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value?: PhoneNumberFieldValue) => {
                const internationalNumber = parsePhoneNumber(
                  value?.number || '',
                  value?.countryCode as any,
                )?.number.toString();

                return phoneNumberValidation({
                  value: {
                    number: value?.number,
                    countryCode: value?.countryCode,
                    internationalNumber,
                  },
                  validators,
                  props: undefined,
                });
              },
            }}
            render={(params: {
              onChange: (value: Partial<PhoneNumberFieldValue>) => any;
              value?: PhoneNumberFieldValue;
            }) => {
              const { onChange, value } = params;
              if (!value?.internationalNumber) {
                const internationalNumber =
                  value?.number &&
                  value?.countryCode &&
                  parsePhoneNumber(value.number, value.countryCode as any)?.number.toString();

                if (internationalNumber) {
                  form?.setValue(
                    name,
                    {
                      ...value,
                      internationalNumber,
                    },
                    { shouldDirty: false },
                  );
                }
              }
              return (
                <>
                  <div className='select-cellphone-country'>
                    <ReactSelect
                      name={`date-picker-country-select-${name}`}
                      id={`date-picker-country-select-${name}`}
                      className={`${isTouched ? 'is-focused' : ''} ${fieldError ? 'is-invalid' : 'is-valid'}`}
                      inputProps={{ autoComplete: 'none' }}
                      disabled={isDisabled || disabledFromPrefill}
                      autoFocus={false}
                      onFocus={() => {
                        setActiveElement({ elementId: name });
                      }}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        e.preventDefault();
                      }}
                      placeholder=''
                      style={{
                        // TODO update to styled component
                        ...getSteppedFormInputStyle({ isActive: isActive || isTouched, siteConfig }),
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        height: 40,
                        zIndex: 1,
                        caretColor: 'transparent',
                        borderLeftColor:
                          isActive || isTouched
                            ? globalStyles.colors.Body
                            : getColor({ siteConfig, color: 'disabled' }),
                        borderTopColor:
                          isActive || isTouched
                            ? globalStyles.colors.Body
                            : getColor({ siteConfig, color: 'disabled' }),
                        borderBottomColor:
                          isActive || isTouched
                            ? globalStyles.colors.Body
                            : getColor({ siteConfig, color: 'disabled' }),
                        borderRightColor: getColor({ siteConfig, color: 'disabled' }),
                      }}
                      onChange={(option: any) => {
                        const internationalNumber =
                          value?.number &&
                          option.value &&
                          parsePhoneNumber(value.number, option.value)?.number.toString();
                        const newValue = {
                          ...value,
                          internationalNumber,
                          countryCode: option.value,
                        };
                        onChange(newValue);
                        setFieldError(
                          phoneNumberValidation({
                            value: newValue,
                            validators,
                            props: undefined,
                          }),
                        );
                      }}
                      value={value?.countryCode}
                      clearable={false}
                      filterOptions={handleFilter}
                      options={
                        Countries.map((country) => {
                          return {
                            name: country.name,
                            label: (
                              <span>
                                <span className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
                                <span>&nbsp;&nbsp;{country.name}</span>
                              </span>
                            ),
                            value: country.code,
                          };
                        }) as any
                      }
                    />
                    <div className='select-cellphone-country-text-container'>
                      <StyledInput
                        isTouched={isTouched}
                        siteConfig={siteConfig}
                        isActive={isActive}
                        disableActiveElement={disableActiveElement}
                        name={name}
                        id={name}
                        disabled={isDisabled || disabledFromPrefill}
                        onChange={(e: any) => {
                          e.preventDefault();
                          const number = e.target.value;
                          const ph = parsePhoneNumber(number, value?.countryCode as any);

                          const phoneNumberFieldValue = {
                            ...value,
                            countryCode: value?.countryCode || ph?.country,
                            internationalNumber: ph?.number.toString(),
                            number,
                          };
                          onChange(phoneNumberFieldValue);
                          setFieldError(
                            phoneNumberValidation({
                              value: phoneNumberFieldValue,
                              validators,
                              props: undefined,
                            }),
                          );
                          setTouched(true);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                          onEnterKeyDown({
                            e,
                            nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                            setFieldError: (fieldError) => setFieldError(fieldError),
                            setActiveElement: (elementId) =>
                              setActiveElement({
                                elementId,
                              }),
                            getValue: () => form?.watch(name),
                            validationFunction: phoneNumberValidation,
                            validators,
                            validationProps: undefined,
                            setIsTouched: (isTouched) => setTouched(isTouched),
                            isActive,
                          })
                        }
                        onFocus={() => {
                          setActiveElement({ elementId: name });
                        }}
                        value={value?.number}
                        type='text'
                        invalid={!!fieldError}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            isLastStep={isLastStep}
            isLastComponent={isLastComponent}
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={phoneNumberValidation}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaPhoneNumberComponent>
    </div>
  );
};

export const phoneNumberValidation = (params: {
  validators: Validator[] | undefined;
  props: ValidationProps | undefined;
  value:
    | {
        number?: string;
        countryCode?: string;
        internationalNumber?: string;
      }
    | undefined;
}) => {
  const { value, validators } = params;
  const isRequired = !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED);

  if (isRequired || value?.number) {
    if (!value) {
      return 'Required';
    }
    if (!value.number) {
      return 'Missing phone number';
    }
    if (!value.countryCode) {
      return 'Missing country code';
    }
    if (!isValidCellphoneNumber(value.internationalNumber || value.number, value.countryCode as any)) {
      return 'Invalid phone number';
    }
    if (value.internationalNumber && parsePhoneNumber(value.internationalNumber)?.country !== value.countryCode) {
      return 'Invalid country code and number combination';
    }
  }
  return undefined;
};

const StyledRootSchemaPhoneNumberComponent = styled(StyledRootSchemaComponent)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  .select-cellphone-country {
    display: flex;
    height: ${globalStyles.inputHeight.default};

    .Select {
      flex: 0 0 64px;
      display: inline-block;

      .Select-control {
        height: 34px;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .Select-value {
        padding-left: 20px !important;
        .Select-value-label {
          span {
            span:last-child {
              display: none;
            }
          }
        }
      }

      .Select-arrow-zone {
        border: none;
        height: 31px;
      }

      .Select-menu-outer {
        width: 400px;
      }
    }

    &-text-container {
      flex: 1;

      input {
        border-left-width: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &:focus,
        &.focus {
          border-left-width: 0 !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
`;
