import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { createExternalPaymentMethod } from 'payment-methods/actions/create-external-payment-method';
import { getExternalPaymentMethodTypes } from 'payment-methods/actions/get-external-payment-method-types';
import { ExternalPaymentMethod, PaymentMethodAll } from 'payment-methods/domain/payment-method';
import { updatePolicy } from 'policies/actions/update-policy';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import React, { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';

export enum ExternalPaymentMethodDetailsInput {
  BillingDay = 'billingDay',
}

export interface ExternalPaymentMethodFormData {
  [ExternalPaymentMethodDetailsInput.BillingDay]: number;
}

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  setPolicy: (policy: Policy) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  paymentMethod: ExternalPaymentMethod | undefined;
}

export const ExternalPaymentMethodForm = (props: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    setActiveElement,
    setIsUpdatingOrCreatingPaymentMethod,
    policy,
    setPolicy,
    paymentMethod,
    policyholder,
    setPaymentMethod,
  } = props;

  const submitButtonRef = useRef<any>();
  const isTouched = false;

  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });
  form.watch();

  const { execute, isLoading } = usePromiseLazy(async (formData: ExternalPaymentMethodFormData) => {
    const formValues = form.getValues() as { billingDay: number };
    const billingDay = formValues.billingDay;
    const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;
    const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;

    if (!paymentMethod && canEditPaymentMethod) {
      const externalPaymentMethodTypes = await getExternalPaymentMethodTypes({
        auth,
        environment,
        organizationId,
      });

      const updatedPaymentMethod = await createExternalPaymentMethod({
        billingDay,
        externalPaymentMethodType: externalPaymentMethodTypes[0].key,
        paymentReference: policyholder.policyholderId,
        policyholderId: policyholder.policyholderId,
        auth,
        environment,
        organizationId,
        policyId: policy.policyId,
      });

      setPaymentMethod(updatedPaymentMethod);
    }

    if (billingDay !== policy.billingDay && canEditBillingDay) {
      const updatedPolicy = await updatePolicy({
        environment,
        organizationId,
        auth,
        data: { billingDay },
        policyId: policy.policyId,
      });

      setPolicy(updatedPolicy);
    }

    setIsUpdatingOrCreatingPaymentMethod(false);
  }, []);

  return (
    <BeneficiaryDetailsStyle style={{ paddingBottom: 60 }}>
      <form onSubmit={form.handleSubmit((data: ExternalPaymentMethodFormData) => execute(data))}>
        <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
          <StyledBeneficiaryCol sm={12}>
            <BillingDaySelectField
              clearable={true}
              isTouched={isTouched}
              disableScrollToElement={true}
              name={ExternalPaymentMethodDetailsInput.BillingDay}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              label='Debit day'
              form={form}
              defaultValue={policy.billingDay}
              prefillValue={ExternalPaymentMethodDetailsInput.BillingDay}
              placeholder={'Debit day'}
              disableActiveElement={true}
              hideDivider={true}
              displayProperties={
                {
                  activeElement: {
                    elementId: '',
                  },
                  setActiveElement,
                  nextComponentName: '',
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        </StaticFormWithTitleRowStyle>
        <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
        <LongButtonSuccess
          id='manage-payment-methods-submit-button'
          siteConfig={siteConfig}
          onClick={() => submitButtonRef.current.click()}
          disabled={isLoading || !form.formState.isValid}
        >
          {isLoading && (
            <span style={{ marginRight: '10px' }}>
              <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
            </span>
          )}
          {paymentMethod ? 'Update' : 'Add'}
        </LongButtonSuccess>
      </form>
    </BeneficiaryDetailsStyle>
  );
};
