import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { buildDashboardUrl, JSONObject, getCurrentUrl } from '../utils';
import { defaultPagination } from '../pagination';

export const useQueryParams = <P extends JSONObject>() => {
  const history = useHistory();

  const setQueryParams = (params: P) => {
    const newUrl = buildDashboardUrl(history.location.pathname, params);
    history.push(newUrl);
  };

  const parsedQueryParams = qs.parse(getCurrentUrl().split('?')[1]);

  return {
    setQueryParams,
    queryParams: parsedQueryParams as any,
  };
};

export const useUrlPagination = () => {
  const { setQueryParams, queryParams } = useQueryParams();

  const setPagination = ({ page, pageSize }: { page?: number; pageSize?: number }) => {
    setQueryParams({ ...queryParams, page, page_size: pageSize });
  };

  const pagination = {
    page: queryParams.page ? Number.parseInt(queryParams.page) : defaultPagination.page,
    pageSize: queryParams.page_size ? Number.parseInt(queryParams.page_size) : defaultPagination.pageSize,
  };

  return {
    pagination,
    setPagination,
  };
};
