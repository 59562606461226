import { EmbedSessionPrefillData, EmbedSessionPrefillValuesObject, NetworkEmbedSessionPrefillData } from 'embed-sessions/domain/embed-session-prefill-data';
import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  embedSessionId: string;
  embedSessionPrefillData?: EmbedSessionPrefillValuesObject;
}

export const createEmbedSessionPrefillData = async (params: Params) => {
  const { auth, environment, organizationId, embedSessionId, embedSessionPrefillData } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/embed-sessions/${embedSessionId}/prefill-data`,
    method: 'put',
    body: {
      screening_questions: embedSessionPrefillData?.screeningQuestions,
      quote: embedSessionPrefillData?.quote,
      pre_personal_details_consent: embedSessionPrefillData?.prePersonalDetailsConsent,
      personal_details: embedSessionPrefillData?.personalDetails,
      application: embedSessionPrefillData?.application,
      pre_payment_details_consent: embedSessionPrefillData?.prePaymentDetailsConsent,
      payment: embedSessionPrefillData?.payment,
    },
    auth,
  });

  const networkEmbedSessionPrefillData: NetworkEmbedSessionPrefillData = await response.json();
  return EmbedSessionPrefillData.fromNetwork(networkEmbedSessionPrefillData);
};
