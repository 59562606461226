import { Alert as OldAlert } from 'rootstrap/components-old/alerts/alerts';
import React, { ReactNode } from 'react';
import { formatError } from 'shared/format-error';
import { useSiteConfigContext } from 'style-context';
import { Alert as ReactstrapAlert } from 'reactstrap';
import styled from 'styled-components';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import { Icon13PXInformation } from 'assets/icon-13-px-information';
import { Spacing } from 'rootstrap/global-styles';

export const Alert = ({
  title,
  body,
  variant,
  children,
  error,
  id,
}: {
  title?: string;
  body?: ReactNode;
  variant: 'danger' | 'info' | 'success' | 'warning';
  children?: ReactNode;
  error?: any;
  id?: string;
}) => {
  return (
    <OldAlert id='error_alert' color={variant}>
      {title && <strong>{title}</strong>}
      <div id={id} style={{ marginTop: title ? 10 : 0, marginBottom: 0, color: getTextColorFromVariant(variant) }}>
        {error ? formatError(error) : body || children}
      </div>
    </OldAlert>
  );
};

export const ThemedAlert = (props: {
  title?: string;
  body?: ReactNode;
  children?: ReactNode;
  hideIcon?: boolean;
  id?: string;
}) => {
  const { siteConfig } = useSiteConfigContext();
  const { body, hideIcon, id } = props;
  return (
    <StyledReactstrapAlert id={id} siteConfig={siteConfig} color={siteConfig?.styles.colors.highlight}>
      {!hideIcon ? (
        <IconWrapper>
          <Icon13PXInformation fill={getColor({ siteConfig, color: 'highlight' })} />
        </IconWrapper>
      ) : (
        <div style={{ margin: '10px' }} />
      )}
      <StyledContent siteConfig={siteConfig}>{body}</StyledContent>
    </StyledReactstrapAlert>
  );
};

const StyledReactstrapAlert = styled(ReactstrapAlert)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  background: ${({ siteConfig }) => `${getColor({ siteConfig, color: 'highlight' })}20`};
  border-color: ${({ siteConfig }) => `${getColor({ siteConfig, color: 'highlight' })}20`};
  padding: 0;
  justify-content: flex-start;
  display: flex;
`;

const StyledContent = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  margin: 10px 0;
  display: inline-block;
  color: ${({ siteConfig }) => `${getColor({ siteConfig, color: 'highlight' })}`};
  width: 100%;
  padding-right: 10px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  margin-left: ${Spacing.default};
`;

const getTextColorFromVariant = (variant: 'danger' | 'info' | 'success' | 'warning') => {
  switch (variant) {
    case 'success':
      return 'rgb(61, 61, 61);';
    case 'danger':
      return 'rgb(61, 61, 61);';
    case 'info':
      return 'rgb(61, 61, 61);';
    case 'warning':
      return '#FFAB00';
    default:
      return 'inherit';
  }
};
