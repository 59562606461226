import { Title } from 'general/title';
import { isEmpty } from 'lodash';
import { Environment } from 'models/environment';
import { Gender } from 'models/gender';
import { Moment } from 'moment';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { PhoneNumberFieldValue } from 'rootstrap/components/forms/new-fields/phone-number-field';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { JSONObject } from 'shared/utils';
import { Policyholder, NetworkPolicyholder, Address } from '../domain/policyholder';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  data: Partial<PolicyholderData>;
  environment: Environment;
  isCompany?: boolean;
}

export const upsertPolicyholder = async ({ organizationId, auth, data, environment, isCompany }: Params) => {
  const implicitIdNumber = getImplicitIdNumberFromType(data);
  const identificationType = data.identification?.type;

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${getBasePathname({
      organizationId,
      auth,
      clientAppAuth: true,
    })}/policyholders`,
    method: 'put',
    body: {
      id: isCompany
        ? undefined
        : data.identification && {
            type: identificationType,
            number: implicitIdNumber || data.identification.number,
            country: data.identification.country,
            expiration_date: data.identification.expirationDate?.toISOString(),
          },
      type: isCompany ? IdentificationType.Company : undefined,
      date_of_birth: data.dateOfBirth?.format('YYYYMMDD'),
      gender: identificationType !== IdentificationType.Id ? data.gender : undefined,
      title: data.title,
      first_name: data.firstName,
      middle_name: data.middleName,
      last_name: data.lastName,
      email: data.email === '' ? null : data.email,
      app_data: data.appData,
      cellphone: phoneNumberToNetwork(data.cellphone),
      phone_other: phoneNumberToNetwork(data.phoneOther),
      address: addressToNetwork(data.address),
      registration_number: data.registrationNumber,
      company_name: data.companyName,
      subsidiary_companies: data.subsidiaryCompanies,
      contact_position: data.contactPosition,
      company_website_url: data.companyWebsiteUrl,
      date_of_establishment: data.dateOfEstablishment?.format('YYYYMMDD'),
    },
    auth,
  });

  const networkPolicyholder: NetworkPolicyholder = await response.json();

  const policyholder = Policyholder.fromNetwork(networkPolicyholder);
  return policyholder;
};

export const phoneNumberToNetwork = (input?: PhoneNumberFieldValue | null) => {
  return input?.number === undefined
    ? undefined
    : input.number
    ? {
        number: input.number,
        country: input.countryCode,
      }
    : null;
};

export const parsePolicyholderAddress = (addressInput?: Address) => {
  const address =
    isEmpty(addressInput) ||
    Object.values(addressInput || {}).every((value) => value === undefined || value === '' || value === null)
      ? null
      : addressInput;

  return address;
};

export const addressToNetwork = (addressInput?: Address) => {
  const address = parsePolicyholderAddress(addressInput);

  return (
    address && {
      google_place_id: address.googlePlaceId,
      geo_coordinates_latitude: address.geoCoordinatesLatitude,
      geo_coordinates_longitude: address.geoCoordinatesLongitude,
      line_1: address.line1,
      line_2: address.line2,
      suburb: address.suburb,
      city: address.city,
      area_code: address.areaCode,
      country: address.country,
    }
  );
};

export type PolicyholderData = EditPolicyholderFormData & { appData?: JSONObject };
export interface EditPolicyholderFormData {
  // Company specific fields
  companyName?: string;
  registrationNumber?: string;
  subsidiaryCompanies?: string[];
  companyWebsiteUrl?: string;
  contactPosition?: string;
  dateOfEstablishment?: Moment;

  // Individual specific fields
  identification?: {
    number?: string;
    type?: IdentificationType;
    country?: string;
    expirationDate?: Moment;
  };
  dateOfBirth: Moment;
  gender?: Gender;
  address?: {
    googlePlaceId?: string;
    geoCoordinatesLatitude?: string;
    geoCoordinatesLongitude?: string;
    line1: string;
    line2?: string;
    suburb?: string;
    city: string;
    areaCode: string;
    country: string;
  };

  // Shared fields
  title?: Title;
  firstName: string;
  middleName?: string;
  lastName: string;
  email?: string;
  cellphone?: PhoneNumberFieldValue;
  phoneOther?: PhoneNumberFieldValue;
}

const getImplicitIdNumberFromType = (policyholder: Partial<PolicyholderData>) => {
  if (policyholder.identification?.type === IdentificationType.Email) {
    return policyholder.email;
  }

  if (policyholder.identification?.type === IdentificationType.Cellphone) {
    return policyholder.cellphone?.number;
  }

  return;
};
