import { globalStyles } from 'rootstrap/global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';
import { StyledLabel } from '../new-field';
import { StyledReactDatePicker } from '../new-fields/date-picker-field';
import { StyledInputComponent } from '../new-fields/styled-input';
import { StyledRootSchemaComponent } from '../new-fields/styles/root-schema-component-style';

export const StaticFormWithTitleRowStyle = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  padding-top: 0px;

  ${StyledLabel} {
    font-size: 14px !important;
    font-weight: 400;
  }

  ${StyledRootSchemaComponent} {
    padding-top: 0px;

    label {
      padding-bottom: 0px;
      color: ${globalStyles.colors.MediumEmphasis};
    }
  }

  ${StyledInputComponent} {
    border: none !important;
    border-radius: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    color: ${globalStyles.colors.Body};
    border-bottom: 1px solid
      ${({ siteConfig }) =>
        getColor({
          siteConfig,
          color: 'disabled',
        })} !important;
    border-color: black;
  }

  .Select-placeholder {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .select-cellphone-country {
    display: grid;
    grid-template-columns: auto 1fr;

    select-cellphone-country-text-container {
      padding-left: 30px;
    }

    .Select {
      .Select-value {
        padding-left: 0px !important;
        width: 20px;
        padding-right: 0px;
      }

      .Select-control {
        .Select-multi-value-wrapper {
        }
      }
    }
  }

  .Select-control {
    border: none !important;
    border-radius: 0px !important;
    padding-left: 0px !important;
    border: none !important;
    border-bottom: 1px solid
      ${({ siteConfig }) =>
        getColor({
          siteConfig,
          color: 'disabled',
        })} !important;
  }

  .Select-value {
    padding-left: 0px !important;
  }

  .Select-value-label {
    margin-left: 0px !important;
  }

  ${StyledReactDatePicker} {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid
      ${({ siteConfig }) =>
        getColor({
          siteConfig,
          color: 'disabled',
        })} !important;
  }
`;
