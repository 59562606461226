import styled, { css } from 'styled-components/macro';
import { Modal as RSModal, ModalBody as RSModalBody, ModalFooter as RSModalFooter } from 'reactstrap';
import { Spacing } from '../../global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { devices } from 'rootstrap/global-styles/devices';

export const Modal = styled(RSModal)<{ fullWidthOnMobile?: boolean }>`
  .modal-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: none;
    padding: 0;
  }

  & .modal-content:before {
    content: '';
    display: none;
  }

  ${({ fullWidthOnMobile }) =>
    fullWidthOnMobile &&
    `
    @media ${devices.mobileL} {
      width: 100%;
      margin: 0;

      .modal-content {
        border-radius: 0px;
      }
    }
  `}
`;

export const ModalHeader = styled.header`
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
`;

export const ModalBody = styled(RSModalBody)`
  margin-top: 0px;
  padding-top: 0px;
`;

export const ModalFooter = styled(RSModalFooter)<{ vertical?: boolean; borderTopDisabled?: boolean }>`
  ${({ vertical }) =>
    vertical &&
    css`
       {
        display: flex;
        flex-direction: column;
        flex: 1;

        button {
          width: 100%;
        }

        &.flex-row {
          flex-direction: row;
        }
      }
    `}

  ${({ borderTopDisabled }) =>
    borderTopDisabled &&
    css`
       {
        border-top: none;
      }
    `}
`;

export const ModalErrorMessage = styled.p<{ siteConfig: ProductModuleDefinitionEmbeddedConfig }>`
  margin-top: ${Spacing.default};
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'error' })};
`;

export const FormOverlayContentWrapper = styled.div<{ display?: string; alignItems?: string }>`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
`;
