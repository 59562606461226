import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { InputFieldParams } from '../forms/new-fields/input-field';
import { getNextComponentId } from '../forms/new-fields/utils';

interface HeaderInterface extends InputFieldParams<string> {
  content: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bolded?: boolean;
  style?: Record<string, string | number>;
  className?: string;
  colorPrimary?: boolean;
  marginVertical?: number;
  id?: string;
  name: string;
}
// TODO: Remove inline styles.
export const Header = (params: HeaderInterface) => {
  const { name, displayProperties, content, bolded, className, colorPrimary, marginVertical, size, style, id } = params;

  const { activeElement, setActiveElement } = displayProperties;

  const { siteConfig } = useSiteConfigContext();
  const sizeMap = {
    xs: {
      fontSize: '18px',
    },
    sm: {
      fontSize: '20px',
    },
    md: {
      fontSize: '24px',
    },
    lg: {
      fontSize: '28px',
    },
    xl: {
      fontSize: '36px',
    },
  };
  const fontSize = (size && sizeMap[size].fontSize) || sizeMap.md.fontSize;

  useEffect(() => {
    const isActive = activeElement.elementId === name;
    if (isActive) {
      setActiveElement && setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [activeElement, name]);

  return (
    // TODO: Use different h tags for sizes.
    <StyledH3
      id={id}
      colorPrimary={colorPrimary}
      siteConfig={siteConfig}
      className={className}
      size={fontSize}
      bolded={bolded}
      style={style}
      marginVertical={marginVertical}
    >
      {content}
    </StyledH3>
  );
};

// padding-top: 25px;
// padding-bottom: 5px;
export const StyledH3 = styled.h3<{
  colorPrimary?: boolean;
  bolded?: boolean;
  size: string;
  marginVertical?: number;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
}>`
  font-style: normal;
  font-weight: normal;
  line-height: 1.143;
  margin: 0;
  margin-bottom: 0;
  margin-top: 39px;

  ${({ colorPrimary, siteConfig }) =>
    colorPrimary &&
    css`
      color: ${getColor({ siteConfig, color: 'primary' })} !important;
    `}

  ${({ bolded }) =>
    bolded &&
    css`
      font-weight: bold;
    `}

  ${({ size }) => css`
    font-size: ${size};
  `}

  ${({ marginVertical }) =>
    marginVertical &&
    css`
      margin-top: ${marginVertical}px;
      margin-bottom: ${marginVertical}px;
    `}
`;
