export enum PolicyBillingFrequency {
  Yearly = 'yearly',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  OnceOff = 'once_off',
}

export const prettyBillingFrequency = (frequency: PolicyBillingFrequency) => {
  switch (frequency) {
    case PolicyBillingFrequency.Yearly:
      return 'Yearly';
    case PolicyBillingFrequency.Monthly:
      return 'Monthly';
    case PolicyBillingFrequency.Weekly:
      return 'Weekly';
    case PolicyBillingFrequency.Daily:
      return 'Daily';
    case PolicyBillingFrequency.OnceOff:
      return 'Once-off';
  }
};
export const getPolicyBillingAbbreviation = (policyBillingFrequency: PolicyBillingFrequency | undefined) => {
  if (!policyBillingFrequency) {
    return '-';
  }

  const billingAbbreviation = {
    [PolicyBillingFrequency.OnceOff]: '',
    [PolicyBillingFrequency.Daily]: '/ day',
    [PolicyBillingFrequency.Monthly]: '/ month',
    [PolicyBillingFrequency.Weekly]: '/ week',
    [PolicyBillingFrequency.Yearly]: '/ year',
  };

  return billingAbbreviation[policyBillingFrequency];
};
