import React from 'react';

export const IconCheckmark = () => {
  return (
    <svg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.15125 12.7566C6.76074 13.1471 6.1276 13.1471 5.73707 12.7566L0.707185 7.72729C0.316623 7.33677 0.316607 6.70357 0.707146 6.31303L2.10125 4.91893C2.49178 4.5284 3.12494 4.5284 3.51547 4.91893L6.44418 7.84764L13.5848 0.707017C13.9753 0.316528 14.6084 0.316487 14.9989 0.706925L16.3929 2.10053C16.7835 2.49104 16.7835 3.12428 16.393 3.51484L7.15125 12.7566Z'
        fill='#240E8B'
      />
    </svg>
  );
};
