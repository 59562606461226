import { Environment } from 'models/environment';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from '../../shared/api';
import { buildApiUrl } from '../../shared/utils';
import { NetworkOrganization, Organization } from '../domain/organization';

interface Params {
  auth: EmbedAuth;
  organizationId: string | undefined;
  environment: Environment;
}

export const getOrganization = async (params: Params) => {
  const { auth, organizationId, environment } = params;

  const queryParams = {};

  const getOrganizationUrl = auth.type === AuthTypes.JWT ?
    `v1/insurance/organizations/${organizationId}/embed/organization` :
    `v1/insurance/embedded-organization`;

  const response: Response = await apiRequest({
    url: buildApiUrl(
      `${getApiHost({ environment })}/${getOrganizationUrl}`,
      queryParams,
    ),
    method: 'get',
    auth,
  });

  const networkOrganization: NetworkOrganization = await response.json();
  const organization = Organization.fromNetwork(networkOrganization);

  localStorage.setItem('organization_id', organization.organizationId);
  return organization;
};
