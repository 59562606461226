import { isPlainObject, snakeCase } from 'lodash';
import { useEffect } from 'react';
import { ProductModuleDefinitionEmbeddedConfig, ProductModuleEmbedConfigIssuingFlowStartingStep } from 'site-config';

/**
 * Static step keys that do not change.
 */
export enum StaticSceneStepKeys {
  ScreeningQuestions = 'screeningQuestions',
  PrePersonalDetailsConsent = 'prePersonalDetailsConsent',
  Application = 'application',
  Beneficiaries = 'beneficiaries',
  PrePaymentConsent = 'prePaymentConsent',
  PersonalDetails = 'personalDetails',
  Payment = 'payment',
}

/**
 * Dynamic step keys generated based on the number of quote input sections.
 */
export enum DynamicSceneStepKeys {
  QuoteInputStep1 = 'quoteInputStep1',
  QuoteInputStep2 = 'quoteInputStep2',
  QuoteInputStep3 = 'quoteInputStep3',
  QuoteInputStep4 = 'quoteInputStep4',
  QuoteInputStep5 = 'quoteInputStep5',
  QuoteInputStep6 = 'quoteInputStep6',
  QuoteInputStep7 = 'quoteInputStep7',
  QuoteInputStep8 = 'quoteInputStep8',
  QuoteInputStep9 = 'quoteInputStep9',
  QuoteInputStep10 = 'quoteInputStep10',
  QuoteInputStep11 = 'quoteInputStep11',
  QuoteInputStep12 = 'quoteInputStep12',
  QuoteInputStep13 = 'quoteInputStep13',
  QuoteInputStep14 = 'quoteInputStep14',
  QuoteInputStep15 = 'quoteInputStep15',
  QuoteInputStep16 = 'quoteInputStep16',
  QuoteInputStep17 = 'quoteInputStep17',
  QuoteInputStep18 = 'quoteInputStep18',
  QuoteInputStep19 = 'quoteInputStep19',
  QuoteInputStep20 = 'quoteInputStep20',
  QuoteInputStep21 = 'quoteInputStep21',
  QuoteInputStep22 = 'quoteInputStep22',
  QuoteInputStep23 = 'quoteInputStep23',
  QuoteInputStep24 = 'quoteInputStep24',
  QuoteInputStep25 = 'quoteInputStep25',
  QuoteInputStep26 = 'quoteInputStep26',
  QuoteInputStep27 = 'quoteInputStep27',
  QuoteInputStep28 = 'quoteInputStep28',
  QuoteInputStep29 = 'quoteInputStep29',
  QuoteInputStep30 = 'quoteInputStep30',
  QuoteInputStep31 = 'quoteInputStep31',
  QuoteInputStep32 = 'quoteInputStep32',
  QuoteInputStep33 = 'quoteInputStep33',
  QuoteInputStep34 = 'quoteInputStep34',
  QuoteInputStep35 = 'quoteInputStep35',
  QuoteInputStep36 = 'quoteInputStep36',
  QuoteInputStep37 = 'quoteInputStep37',
  QuoteInputStep38 = 'quoteInputStep38',
  QuoteInputStep39 = 'quoteInputStep39',
  QuoteInputStep40 = 'quoteInputStep40',
  QuoteInputStep41 = 'quoteInputStep41',
  QuoteInputStep42 = 'quoteInputStep42',
  QuoteInputStep43 = 'quoteInputStep43',
  QuoteInputStep44 = 'quoteInputStep44',
  QuoteInputStep45 = 'quoteInputStep45',
  QuoteInputStep46 = 'quoteInputStep46',
  QuoteInputStep47 = 'quoteInputStep47',
  QuoteInputStep48 = 'quoteInputStep48',
  QuoteInputStep49 = 'quoteInputStep49',
  QuoteInputStep50 = 'quoteInputStep50',
  QuoteInputStep51 = 'quoteInputStep51',
  QuoteInputStep52 = 'quoteInputStep52',
  QuoteInputStep53 = 'quoteInputStep53',
  QuoteInputStep54 = 'quoteInputStep54',
  QuoteInputStep55 = 'quoteInputStep55',
  QuoteInputStep56 = 'quoteInputStep56',
  QuoteInputStep57 = 'quoteInputStep57',
  QuoteInputStep58 = 'quoteInputStep58',
  QuoteInputStep59 = 'quoteInputStep59',
  QuoteInputStep60 = 'quoteInputStep60',
}

/**
 * Combined type for all step keys.
 */
export type IssuingSceneStepKeys = StaticSceneStepKeys | DynamicSceneStepKeys;

/**
 * Utility function to generate a dynamic quote input step key.
 * @param index - The index of the quote input step.
 * @returns A dynamic step key string.
 */
export const stepOrders = (params: { sectionIndices: number[] }) => {
  const {
    Application,
    Beneficiaries,
    Payment,
    PersonalDetails,
    PrePaymentConsent,
    PrePersonalDetailsConsent,
    ScreeningQuestions,
  } = StaticSceneStepKeys;

  const quoteInputSteps = params.sectionIndices.map((index) => `quoteInputStep${index}` as IssuingSceneStepKeys);

  return {
    default: [
      ScreeningQuestions,
      ...quoteInputSteps,
      PrePersonalDetailsConsent,
      PersonalDetails,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
    [ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails]: [
      PrePersonalDetailsConsent,
      PersonalDetails,
      ScreeningQuestions,
      ...quoteInputSteps,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
  };
};

export const getPolicyIssuingFlowStepOrder = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  sectionIndices: number[];
}) => {
  const { siteConfig, sectionIndices } = params;
  const stepOrder = stepOrders({ sectionIndices })[
    siteConfig?.settings.issuingFlowStartingStep || ProductModuleEmbedConfigIssuingFlowStartingStep.Default
  ];

  return stepOrder.reduce(
    (acc, step, index) => ({
      ...acc,
      [step]: index,
    }),
    {} as Record<IssuingSceneStepKeys, number>,
  );
};

export const GetPolicyIssuingFlowStepFromStepIndex = (params: {
  issuingFlowStartingStep?: ProductModuleEmbedConfigIssuingFlowStartingStep | '';
  step: number;
  sectionIndices: number[];
}) => {
  const { issuingFlowStartingStep, step, sectionIndices } = params;
  return stepOrders({ sectionIndices })[
    issuingFlowStartingStep || ProductModuleEmbedConfigIssuingFlowStartingStep.Default
  ][step];
};

export const getFirstStep = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  sectionIndices: number[];
}) => {
  const { siteConfig, sectionIndices } = params;

  const isPersonalDetailsStartingStep =
    siteConfig?.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  if (isPersonalDetailsStartingStep) {
    return siteConfig?.prePersonalDetailsCompliance?.displayOptionalSections.displayPrePersonalDetailsCompliance
      ? StaticSceneStepKeys.PrePersonalDetailsConsent
      : StaticSceneStepKeys.PersonalDetails;
  }

  return siteConfig?.quote.displayOptionalSections.screeningQuestions
    ? StaticSceneStepKeys.ScreeningQuestions
    : (`quoteInputStep${sectionIndices[0]}` as DynamicSceneStepKeys);
};

export const scrollTo = () => {
  const scrollable = document.getElementById('insurer-disclaimer-container');

  setTimeout(() => scrollable && scrollable.scrollIntoView({ behavior: 'smooth', block: 'end' }));
};

export const sanitizeModel = (model: any): any =>
  Object.keys(model).reduce(
    (output, key) => ({
      ...output,
      [snakeCase(key)]: isPlainObject(model[key]) ? sanitizeModel(model[key]) : model[key],
    }),
    {},
  );

export const useSetIframeHeight = (params: {
  iframeId: string;
  isLoading: boolean;
  modifiedHtmlContent: string | undefined;
}): void => {
  const { iframeId, isLoading, modifiedHtmlContent } = params;
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let currentInterval = 50; // Start with 1 second
    let previousHeight: number | null = null;
    const maxInterval = 1000; // Maximum interval of 10 seconds

    const checkHeightAndUpdateInterval = () => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement | null;
      if (!iframe) return;

      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDocument) {
        const height = iframeDocument.documentElement.offsetHeight;

        if (height !== previousHeight) {
          currentInterval = 50; // Reset interval
          previousHeight = height;
          iframe.style.height = `${height}px`;
        } else {
          currentInterval = Math.min(currentInterval * 2, maxInterval);
        }

        clearInterval(intervalId);
        intervalId = setInterval(checkHeightAndUpdateInterval, currentInterval);
      }
    };

    if (!isLoading && modifiedHtmlContent) {
      intervalId = setInterval(checkHeightAndUpdateInterval, currentInterval);
    }

    return () => clearInterval(intervalId);
  }, [isLoading, iframeId]);
};
