import React from 'react';

export const LockIconOpen = (params: { fill?: string; style?: React.CSSProperties; onClick: () => void }) => {
  const { fill, style, onClick } = params;
  return (
    <svg
      onClick={onClick}
      style={{ ...style, cursor: 'pointer' }}
      width='32'
      height='33'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='1' width='26' height='26.7437' rx='13' fill='white' />
      <rect x='1' y='1' width='26' height='26.7437' rx='13' stroke='#CED1DA' stroke-width='2' />
      <g clip-path='url(#clip0_10772_191352)'>
        <path
          d='M9.72455 13.6353H10.2655H11.49H16.5104V10.9008C16.5104 9.6405 15.3844 8.61512 14.0002 8.61512C12.8923 8.61512 11.9494 9.27218 11.6174 10.181C11.4783 10.5623 11.2157 10.9008 10.8778 10.9008C10.5396 10.9008 10.2572 10.5682 10.3466 10.1722C10.7044 8.58523 12.2068 7.39062 14.0002 7.39062C16.0596 7.39062 17.7349 8.96533 17.7349 10.9008V13.6353C17.7349 13.6353 17.9771 13.6353 18.2757 13.6353C18.5744 13.6353 18.8164 13.9642 18.8164 14.37V18.656C18.8164 19.0617 18.4875 19.3907 18.0817 19.3907H9.91851C9.51272 19.3907 9.18381 19.0617 9.18381 18.656V14.37C9.18381 13.9642 9.42577 13.6353 9.72455 13.6353ZM14.0002 16.9211C14.3046 16.9211 14.5512 16.6742 14.5512 16.3698C14.5512 16.0655 14.3044 15.8186 14.0002 15.8186C13.6958 15.8186 13.449 16.0655 13.449 16.3698C13.449 16.6742 13.6956 16.9211 14.0002 16.9211Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_10772_191352'>
          <rect width='12' height='12' fill='white' transform='matrix(-1 0 0 1 20 7.39062)' />
        </clipPath>
      </defs>
    </svg>
  );
};
