import { SplashContainer as BaseSplashContainer } from 'rootstrap/components/splash-header/splash-container';
import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';

const PolicyStatus = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  height: 100%;
  border-top-right-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 180px;
`;

const PolicyStatusWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: end;
  display: flex;
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  padding-left: ${globalStyles.spacing.default};
  padding-right: ${globalStyles.spacing.default};

  .loading-inputs {
    margin-top: ${globalStyles.spacing.lg};
  }
`;

const SplashContainer = styled(BaseSplashContainer)`
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
`;

const PolicySummaryHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
`;

const PolicyNumberDisplay = styled.div`
  color: ${globalStyles.colors.Body};
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  padding-left: ${globalStyles.spacing.default};
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${devices.tablet} {
    flex-direction: column;
  }

  div:nth-child(1) {
    display: flex;
    flex: 1;
  }

  div:nth-child(2) {
    display: flex;
    flex: 1;
  }
`;

const SummaryPanel = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const ErrorWrapper = styled.div`
  margin-top: ${globalStyles.spacing.default};
  width: 100%;
  margin-left: 40px;
`;

export const PolicySummaryStyles = {
  PolicyStatus,
  PolicyStatusWrapper,
  BodyWrapper,
  SplashContainer,
  PolicySummaryHeader,
  PolicyNumberDisplay,
  StyledRow,
  SummaryPanel,
  ErrorWrapper,
};
