import React from 'react';
import { orderBy } from 'lodash';
import { SelectFieldParams, SelectField } from './select-field';
import { IdentificationType, SelectableIdType } from 'policyholder/domain/policyholder-identification';

export const IdTypeSelectField = (
  props: Omit<SelectFieldParams<IdentificationType | SelectableIdType>, 'options'> & { idTypes?: SelectableIdType[] },
) => {
  const options: { value: SelectableIdType; label: string }[] = props.idTypes
    ? props.idTypes.map((i) => ({
        label: idLabelMappings[i],
        value: i,
      }))
    : [
        {
          label: idLabelMappings[SelectableIdType.Id],
          value: SelectableIdType.Id,
        },
        {
          label: idLabelMappings[SelectableIdType.Passport],
          value: SelectableIdType.Passport,
        },
      ];

  return <SelectField {...props} searchable options={orderBy(options, (value) => value.value)} clearable={true} />;
};

const idLabelMappings: {
  [key in SelectableIdType]: string;
} = {
  [SelectableIdType.Id]: 'ID number',
  [SelectableIdType.Passport]: 'Passport',
  [SelectableIdType.Email]: 'Email',
  [SelectableIdType.Cellphone]: 'Cellphone',
  [SelectableIdType.Company]: 'Company',
};
