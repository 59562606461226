import { IconCheckmark } from 'assets/icon-checkmark';
import { Policyholder } from 'policyholder/domain/policyholder';
import React from 'react';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { Checkbox, StyledCheckboxLabel } from 'rootstrap/components/forms/new-fields/checkbox-field';
import { Divider } from 'rootstrap/components/forms/new-fields/divider';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface Props {
  setDebitAccountPermission: (completed: boolean) => void;
  policyholder?: Policyholder;
}

export const PaymentDeclarationSection = (props: Props) => {
  const { policyholder } = props;
  const [debitAccountPermission, setDebitAccountPermission] = React.useState<boolean>(false);
  const { siteConfig } = useSiteConfigContext();
  const declarationWording = getWording({ wording: siteConfig?.payment.wording.declaration });

  return (
    <StyledDeclarationCardGroup>
      <DeclarationTitle>Declaration</DeclarationTitle>
      <StyledDeclarationBody>{declarationWording}</StyledDeclarationBody>
      <p
        id='debit-account-permission'
        style={{ marginBottom: 10 }}
        onClick={(e) => {
          props.setDebitAccountPermission(!debitAccountPermission);
          setDebitAccountPermission(!debitAccountPermission);
        }}
      >
        <StyledCheckboxLabel check>
          <Checkbox
            style={{ borderColor: getColor({ color: 'disabled', siteConfig }), caretColor: 'transparent' }}
            icon={<IconCheckmark />}
            siteConfig={siteConfig}
            isActive={true}
            inputmode='none'
            isChecked={debitAccountPermission}
            onChange={() => {
              props.setDebitAccountPermission(!debitAccountPermission);
              setDebitAccountPermission(!debitAccountPermission);
            }}
            checked={debitAccountPermission}
            name=''
          />
          <span
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              props.setDebitAccountPermission(!debitAccountPermission);
              setDebitAccountPermission(!debitAccountPermission);
            }}
          >
            I, {policyholder ? `${policyholder.firstName} ${policyholder?.lastName},` : ''} confirm that:
          </span>
        </StyledCheckboxLabel>
      </p>
      <ConsentItems>
        <li>The information provided is true and correct</li>
        <li>I am aware of no other information which might be relevant to the insurer's decision to offer cover.</li>
      </ConsentItems>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
    </StyledDeclarationCardGroup>
  );
};

const DeclarationTitle = styled.p`
  font-size: ${globalStyles.fontSize.title};
  font-weight: 400;
  color: ${globalStyles.colors.Body};
  margin-top: 40px !important;
  margin-bottom: 37px !important;
`;

const DividerWrapper = styled.div`
  margin-top: 40px;
`;

const StyledDeclarationBody = styled.p`
  margin-bottom: 30px;
`;

const StyledDeclarationCardGroup = styled(FormWrapperStyle)`
  margin-top: 25px;
`;

const ConsentItems = styled.ul`
  margin-left: 25px;
`;
