import _ from 'lodash';
import { JSONObject } from 'shared/utils';

export const pickNested = (obj: JSONObject, paths: string[]) => {
  return paths.reduce((acc, path) => {
    const value = _.get(obj, path);
    if (value !== undefined) {
      _.set(acc, path, value);
    }
    return acc;
  }, {});
};
