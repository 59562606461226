import Joi from 'joi';
import { UnpackNestedValue, useForm as useHookForm, DeepPartial, UseFormMethods, FieldValues } from 'react-hook-form';
import { set } from 'lodash';
import { ApiError } from '../api';

export const getButtonPropsFromForm = ({ form, isLoading }: { form: UseFormMethods<any>; isLoading: boolean }) => {
  const { formState } = form;

  // Needs to exist as a distinct expression and assignment to handle some strange Proxy stuff.
  const otherCondition = !formState.isDirty || formState.submitCount > 0 ? !formState.isValid : false;

  const props = {
    isDisabled: isLoading || otherCondition,
    isLoading,
  };

  return props;
};

export const setFormErrorsFromApiError = ({
  form,
  error,
  fieldMap,
}: {
  form: UseFormMethods<any>;
  error?: ApiError;
  fieldMap: Record<string, string>;
}) => {
  Object.keys(fieldMap).forEach((field) => {
    const apiField = fieldMap[field];
    const fieldErrorMessage = error?.fieldErrors?.find(({ path }) => path === apiField)?.message;
    if (fieldErrorMessage) {
      form.setError(field, { message: fieldErrorMessage });
    }
  });
};

export const useForm = <F extends FieldValues>(params?: { schema?: Joi.Schema; defaultValues?: UnpackNestedValue<DeepPartial<F>> }) => {
  const { schema, defaultValues } = params || {};

  const form = useHookForm<F>({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
    mode: 'onChange',
    resolver: schema
      ? (data) => {
        const { error, value: values } = schema.validate(data, {
          abortEarly: false,
        });

        return {
          values: error ? {} : values,
          errors: error
            ? error.details.reduce((previous, currentError) => {
              return set(previous, currentError.path.join('.'), currentError);
            }, {})
            : {},
        };
      }
      : undefined,
  });

  return form;
};
