import React, { ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { DefaultStepKeys, SteppedFullscreenModal } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import { ApiError, useBaseUrl } from 'shared/api';
import { usePromise } from 'shared/hooks/promise';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { useHistory } from 'react-router-dom';
import { JSONObject } from 'shared/utils';
import { Quote } from './quotes/domain/quote';
import { InsurerDisclaimerContainer } from 'general/insurer-disclaimer';
import { getProductModuleDefinitionSchema } from 'product-modules/actions/get-product-module-definition-schema';
import { QuoteStepActionBar } from './quotes/views/quote-step-action-bar';
import { PaymentSection } from './payment/payment-step';
import { Application } from 'applications/domain/application';
import { Policyholder } from 'policyholder/domain/policyholder';
import { useSiteConfigContext } from 'style-context';
import { ProductModule } from 'product-modules/domain/product-module';
import {
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
  ProductModuleEmbedConfigIssuingFlowStartingStep,
} from 'site-config';
import { PrefillValues } from 'shared/domain/prefill-values';
import { CreateApplication } from './application/create-application';
import {
  FormOverlayFooterWrapper,
  OverlayFormInnerContentExtendedWidth,
} from 'rootstrap/components-old/forms/form-overlay';
import { ScreeningQuestions } from './screening-questions/screening-questions';
import { ConsentSection } from './consent-section/consent-section';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { BeneficiarySummary, BeneficiarySummaryType } from 'beneficiaries/views/beneficiary-summary';
import { EmbedFlowType } from 'App';
import { FullScreenModalFooterOverlay } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { SpacerLeft, SpacerRight } from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { beneficiariesDescriptionWording } from './personal-details/utils/beneficiaries-description-wording';
import { PersonalDetailsStep } from './personal-details/views/stepped-full-screen-modal-personal-details';
import { QuoteDetailsStep } from './quotes/views/stepped-full-screen-modal-quote';
import { MixpanelEventNames, MixpanelStepNames, useMixpanel, useMixpanelTrack } from 'context/mix-pannel-context';
import { PolicyFlowSiteConfigIsLoading } from './page-mount/site-config-is-loading';
import { createEmbedSessionPrefillData } from 'embed-sessions/actions/create-embed-session-prefill-data';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { getPrefillValuesFromPolicyholder } from './personal-details/utils/get-prefill-values-from-policyholder';
import { toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { PolicyholderLookUpFormData } from './personal-details/views/unauthenticated-policyholder-lookup';
import _, { cloneDeep, merge } from 'lodash';
import { getFirstStep, getPolicyIssuingFlowStepOrder, IssuingSceneStepKeys, StaticSceneStepKeys } from './utils';

export interface GetSteps {
  setCurrentStepKey: (key: IssuingSceneStepKeys) => void;
  stepKey: IssuingSceneStepKeys;
}

export interface PolicyholderInputData {
  identification: {
    type: IdentificationType;
    number: any;
    country: any;
  };
  firstName: any;
  lastName: any;
  email: any;
  cellphone: any;
}

export interface Step<StepKey> {
  key: StepKey;
  onBackClick?: () => void | Promise<void>;
  onNextClick: () => void | Promise<void>;
  getHooks?: () => void;
  description: string;
  title?: string;
  isWide?: boolean;
  wideBody?: boolean;
  continueButtonProps?: {
    disabled?: boolean;
    isLoading?: boolean;
    isNextButtonHidden?: boolean;
  };
  body: () => ReactNode;
}

export const PolicyIssuingScene = (props: { productModule: ProductModule; prefillValues: PrefillValues }) => {
  const { prefillValues } = props;
  const { screeningQuestions, prePersonalDetailsConsent, prePaymentDetailsConsent } = prefillValues;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { embedSessionId } = useEmbedSessionContext();

  const mixpanel = useMixpanel();
  const { auth, environment, organizationId } = embedParams;
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { stepCompletedTrack } = useMixpanelTrack();

  // Steps
  const [policyIssuingFlowStepOrder, setPolicyIssuingFlowStepOrder] = useState<
    Record<IssuingSceneStepKeys, number> | undefined
  >();
  const [firstStep, setFirstStep] = useState<IssuingSceneStepKeys>();
  const [isLoadingBackButton, setIsLoadingBackButton] = useState<boolean>(false);

  // Screening questions
  const screeningQuestionsEnabled = Boolean(
    siteConfig &&
      siteConfig.quote.displayOptionalSections.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions.length > 0,
  );

  const [screeningQuestionsData, setScreeningQuestionsData] = useState<JSONObject | undefined>(screeningQuestions);

  const [screeningQuestionsValid, setScreeningQuestionsValid] = useState(
    !!screeningQuestions || !screeningQuestionsEnabled,
  );
  const [screeningQuestionsComplete, setScreeningQuestionsComplete] = useState(
    !!screeningQuestions || !screeningQuestionsEnabled,
  );
  const [screeningQuestionsModalIsOpen, setScreeningQuestionsModalIsOpen] = useState(false);

  // Quotes
  const [isLoadingCreateQuote, setLoadingCreateQuote] = useState<boolean>(false);
  const [quoteSchemaFormDataParts, setQuoteSchemaFormDataParts] = useState<{ [key: number]: JSONObject | undefined }>(
    {},
  );
  const [isQuoteCompletedSteps, setIsQuoteCompletedSteps] = useState<{ [key: number]: boolean }>({});
  const [isValidQuoteParts, setIsValidQuoteParts] = useState<{ [key: number]: boolean }>({});
  const [selectedQuote, setSelectedQuote] = useState<Quote | undefined>();
  const [quotePackages, setQuotePackages] = useState<Quote[] | undefined>();
  const [createQuoteError, setCreateQuoteError] = useState<any>(undefined);
  const [isQuoteCompleted, setIsQuoteCompleted] = useState<boolean>(false);
  const [isLoadingMainPage, setIsLoadingMainPage] = useState<boolean>(false);

  const [quoteToDisplayInProgressBar, setQuoteToDisplayInProgressBar] = useState<Quote | undefined>();
  useEffect(() => {
    if (selectedQuote && quotePackages) {
      const mostRecent = selectedQuote.createdAt >= quotePackages[0].createdAt ? selectedQuote : quotePackages[0];
      setQuoteToDisplayInProgressBar(mostRecent);
    } else {
      setQuoteToDisplayInProgressBar(selectedQuote || (quotePackages && quotePackages[0]));
    }
  }, [selectedQuote, quotePackages]);

  // Pre policyholder consent
  const [isPrePersonalDetailsConsentChecked, setIsPrePersonalDetailsConsentChecked] = useState<boolean>(
    !!prePersonalDetailsConsent,
  );

  // Personal details
  const [policyholder, setPolicyholder] = useState<Policyholder | undefined>();
  const [policyholderIsValid, setPolicyholderIsValid] = useState<boolean>(false);
  const [policyholderIsLoading, setPolicyholderIsLoading] = useState<boolean>(false);
  const [isPersonalDetailsCompleted, setIsPersonalDetailsCompleted] = useState<boolean>(false);
  const [policyholderLookupFormData, setPolicyholderLookupFormData] = useState<PolicyholderLookUpFormData>({});
  const [isCreatingPolicyholderFromPrefill, setIsCreatingPolicyholderFromPrefill] = useState(
    Boolean(siteConfig?.personalDetails.displayOptionalSections.skipOnPrefill),
  );

  // Applications
  const [application, setApplication] = useState<Application | undefined>();
  const [applicationSchemaFormData, setApplicationSchemaFormData] = useState<JSONObject | undefined>();
  const [createApplicationError, setCreateApplicationError] = useState<ApiError | undefined>();
  const [applicationIsLoading, setApplicationIsLoading] = useState<boolean>();
  const [isApplicationCompleted, setIsApplicationCompleted] = useState<boolean>(false);
  const [applicationIsValid, setApplicationIsValid] = useState<boolean>(false);

  // Pre payment consent
  const [isPrePaymentConsentChecked, setIsPrePaymentConsentChecked] = useState<boolean>(!!prePaymentDetailsConsent);
  const prePaymentConsentRequired = Boolean(
    siteConfig?.prePaymentCompliance &&
      siteConfig.prePaymentCompliance.displayOptionalSections.displayPrePaymentCompliance,
  );

  // Beneficiaries
  const [isBeneficiariesValid, setIsBeneficiariesValid] = useState<boolean>(true);
  const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState<boolean>(false);

  // Payments
  const [paymentMethodIsValid, setPaymentMethodIsValid] = useState<boolean>(false);
  const [paymentAccountPermission, setPaymentAccountPermission] = useState<boolean>(false);
  const [isLoadingCreatePaymentMethod] = useState<boolean>(false);
  const [declarationCompleted, setDeclarationCompleted] = useState<boolean>(false);
  const skipPaymentMethod = siteConfig?.payment.displayOptionalSections.displayPaymentMethod === false;

  // Button refs
  const onNextClickedRef = useRef<any>();
  const createQuoteButtonRef = useRef<any>();
  const createPolicyholderSubmitButtonRef = useRef<any>();
  const applicationSchemaFormSubmitButtonRef = useRef<any>();
  const beneficiaryFormSubmitButtonRef = useRef<any>();
  const paymentMethodSubmitButtonRef = useRef<any>();

  // Root schema refs
  const rootSchemaFormQuoteRef = useRef<any>();

  const {
    result: productModuleAndSchema,
    isLoading: isLoadingProductModule,
    error: productModuleError,
  } = usePromise(async () => {
    const { productModule } = props;

    if (!productModule.productModuleDefinition?.productModuleDefinitionId) {
      throw new Error('No product module definition exists on product module');
    }

    const productModuleDefinitionQuoteSchema = await getProductModuleDefinitionSchema({
      productModuleId: productModule.productModuleId,
      productDefinitionId: productModule.productModuleDefinition.productModuleDefinitionId,
      productModuleDefinitionSchemaId: productModule.productModuleDefinition?.quoteSchemaId,
      environment,
      auth,
      organizationId,
    });

    const productModuleDefinitionApplicationSchema = await getProductModuleDefinitionSchema({
      productModuleId: productModule.productModuleId,
      productDefinitionId: productModule.productModuleDefinition.productModuleDefinitionId,
      productModuleDefinitionSchemaId: productModule.productModuleDefinition?.applicationSchemaId,
      environment,
      auth,
      organizationId,
    });

    if (!productModuleDefinitionQuoteSchema) {
      throw new Error('No product module definition quote schema found');
    }

    // **Assign default sectionIndex of 1 to components without sectionIndex**
    const processedSchema = (productModuleDefinitionQuoteSchema.json || []).map((component) => ({
      ...component,
      sectionIndex: component.sectionIndex !== undefined ? component.sectionIndex : 1,
    }));

    // **Extract unique sectionIndices from the processed schema**
    const sectionIndices = [...new Set(processedSchema.map(({ sectionIndex }) => sectionIndex))] as number[];
    sectionIndices.sort((a, b) => a - b);

    setPolicyIssuingFlowStepOrder(getPolicyIssuingFlowStepOrder({ siteConfig, sectionIndices }));
    setFirstStep(getFirstStep({ siteConfig, sectionIndices }));

    return {
      productModuleDefinitionQuoteSchema: {
        ...productModuleDefinitionQuoteSchema,
        json: processedSchema, // Use the processed schema with default sectionIndex
      },
      productModule,
      productModuleDefinitionApplicationSchema,
      sectionIndices,
    };
  }, []);

  useEffect(() => {
    if (siteConfig?.landing.displayOptionalSections.displayLandingPage === false) {
      if (isPersonalDetailsStartingStep) {
        if (
          siteConfig?.prePersonalDetailsCompliance?.displayOptionalSections.displayPrePersonalDetailsCompliance === true
        ) {
          return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.ConsentPrePersonalDetails);
        }
        return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.PersonalDetails);
      }

      if (siteConfig?.quote.displayOptionalSections.screeningQuestions === true) {
        return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.ScreeningQuestions);
      }
      const firstSectionIndex = sectionIndices[0];
      const stepName = `${MixpanelStepNames.QuoteStep}_${firstSectionIndex}` as keyof typeof MixpanelStepNames;
      return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames[stepName]);
    }
  }, []);

  usePromise(async () => {
    if (embedSessionId && siteConfig?.settings.enableSessionPersistence) {
      const quoteSchema = productModuleAndSchema?.productModuleDefinitionQuoteSchema?.json || [];

      // Merge all quoteSchemaFormDataParts using deep merge
      const mergedQuoteFormData =
        Object.values(quoteSchemaFormDataParts).reduce((acc, curr) => merge(cloneDeep(acc), cloneDeep(curr)), {}) || {};

      const quoteData = toOutputData({
        formData: mergedQuoteFormData || {},
        schema: quoteSchema,
      });

      const quote = quoteData ? quoteData : prefillValues.quote;

      const applicationSchema = productModuleAndSchema?.productModuleDefinitionApplicationSchema?.json || [];
      const application = applicationSchemaFormData
        ? toOutputData({ formData: applicationSchemaFormData || {}, schema: applicationSchema })
        : prefillValues.application;

      await createEmbedSessionPrefillData({
        auth,
        embedSessionId,
        environment,
        organizationId,
        embedSessionPrefillData: {
          screeningQuestions: screeningQuestionsData,
          quote,
          application,
          prePersonalDetailsConsent: isPrePersonalDetailsConsentChecked,
          personalDetails: getPrefillValuesFromPolicyholder({ policyholder }),
          prePaymentDetailsConsent: isPrePaymentConsentChecked,
          payment: undefined,
        },
      });
    }
  }, [
    screeningQuestionsData,
    quoteSchemaFormDataParts,
    isPrePersonalDetailsConsentChecked,
    policyholder,
    applicationSchemaFormData,
    isPrePaymentConsentChecked,
  ]);

  const onClose = () => {
    history.push(baseUrl);
  };

  const isPersonalDetailsStartingStep =
    siteConfig?.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  const payment = siteConfig?.payment;
  const quote = siteConfig?.quote;
  const additionalDetails = siteConfig?.application;
  const header = siteConfig?.header;
  const prePaymentCompliance = siteConfig?.prePaymentCompliance;

  const screeningQuestionsTotal = siteConfig?.quote.wording.screeningQuestions
    ? siteConfig?.quote.wording.screeningQuestions.length
    : 0;

  const sectionIndices = productModuleAndSchema?.sectionIndices || [];

  const beneficiaries = props.productModule?.productModuleDefinition?.settings.beneficiaries;
  const minimumBeneficiaries = beneficiaries?.min as number | undefined;
  const maximumBeneficiaries = beneficiaries?.max as number | undefined;

  if (!policyIssuingFlowStepOrder) {
    return <PolicyFlowSiteConfigIsLoading />;
  }

  return (
    <MainLayout>
      <MainLayoutContent>
        <Content>
          <FlexContainer>
            <div className='section' id='summary'>
              <SteppedFullscreenModal
                title={header?.wording.title || ''}
                productModule={props.productModule}
                quotePackageDisplaySteps={sectionIndices.map(
                  (index) => `quoteInputStep${index}` as IssuingSceneStepKeys,
                )}
                headingSplashSteps={[
                  StaticSceneStepKeys.PrePersonalDetailsConsent,
                  StaticSceneStepKeys.Payment,
                  StaticSceneStepKeys.PrePaymentConsent,
                ]}
                isLoadingBackButton={isLoadingBackButton}
                error={createApplicationError || productModuleError}
                onNextClickedRef={onNextClickedRef}
                firstStep={firstStep}
                isLoading={false}
                premiumDisplay={{
                  billingFrequency: quoteToDisplayInProgressBar?.billingFrequency,
                  currency: quoteToDisplayInProgressBar?.currency,
                  premiumValue: application?.monthlyPremium || quoteToDisplayInProgressBar?.suggestedPremium,
                  isLoading: isLoadingCreateQuote || applicationIsLoading,
                }}
                onClose={onClose}
                getSteps={(params: {
                  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
                  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
                  getPriorStepKey: () => SetStateAction<any | undefined>;
                  onNextCompleted: (() => void | Promise<void>) | undefined;
                  onBackCompleted: (() => void | Promise<void>) | undefined;
                }) => {
                  return [
                    ...(isPersonalDetailsStartingStep
                      ? PersonalDetailsStep({
                          isCreatingPolicyholderFromPrefill,
                          setIsCreatingPolicyholderFromPrefill,
                          policyholderLookupFormData,
                          setPolicyholderLookupFormData: setPolicyholderLookupFormData,
                          applicationIsLoading,
                          applicationSchemaFormData,
                          createPolicyholderSubmitButtonRef,
                          getPriorStepKey: params.getPriorStepKey,
                          isLoadingProductModule,
                          isPersonalDetailsCompleted,
                          isPrePersonalDetailsConsentChecked,
                          onNextCompleted: params.onNextCompleted,
                          policyholder,
                          policyholderIsLoading,
                          policyholderIsValid,
                          prefillValues,
                          selectedQuote,
                          productModuleAndSchema,
                          setCurrentStepKey: params.setCurrentStepKey,
                          setPriorStepKey: params.setPriorStepKey,
                          setIsPersonalDetailsCompleted,
                          setIsPrePersonalDetailsConsentChecked,
                          setPolicyholder,
                          setPolicyholderIsLoading,
                          setPolicyholderIsValid,
                          stepOrder: policyIssuingFlowStepOrder,
                          sectionIndices,
                        })
                      : []),
                    {
                      title: getWording({ wording: quote?.wording.screeningQuestionsDescription }),
                      stepName: DefaultStepKeys.ScreeningQuestions,
                      description: `Just answer these ${screeningQuestionsTotal} quick question${
                        screeningQuestionsTotal > 1 ? 's' : ''
                      }...`,
                      key: StaticSceneStepKeys.ScreeningQuestions,
                      onBackClick: () => {
                        history.push(baseUrl);
                      },
                      body: () => (
                        <ScreeningQuestions
                          sectionIndices={productModuleAndSchema?.sectionIndices || []}
                          stepOrder={policyIssuingFlowStepOrder}
                          isLoading={isLoadingProductModule}
                          modalIsOpen={screeningQuestionsModalIsOpen}
                          setModalIsOpen={setScreeningQuestionsModalIsOpen}
                          prefillValues={prefillValues}
                          setCurrentStepKey={(currentStepKey) => params.setCurrentStepKey(currentStepKey)}
                          setScreeningQuestionsValid={setScreeningQuestionsValid}
                          screeningQuestionsData={screeningQuestionsData}
                          setScreeningQuestionsData={setScreeningQuestionsData}
                          setScreeningQuestionsComplete={setScreeningQuestionsComplete}
                          screeningQuestionsEnabled={screeningQuestionsEnabled}
                          onNextCompleted={() => undefined}
                          priorStepKey={params.getPriorStepKey()}
                          setStepProgress={(step) => params.setCurrentStepKey(step)}
                          setPriorStepKey={(step) => params.setPriorStepKey(step)}
                          step={StaticSceneStepKeys.ScreeningQuestions}
                        />
                      ),
                      continueButtonProps: {
                        disabled: !screeningQuestionsComplete,
                        isNextButtonHidden: false,
                      },
                      onNextClick: () => {
                        if (!screeningQuestionsValid && screeningQuestionsComplete) {
                          setScreeningQuestionsModalIsOpen(true);
                        } else {
                          stepCompletedTrack({
                            stepName: MixpanelStepNames.ScreeningQuestions,
                          });
                          params.onNextCompleted && params.onNextCompleted();
                        }
                      },
                    },
                    ...QuoteDetailsStep({
                      ...params,
                      prefillValues,
                      isLoadingCreateQuote,
                      isQuoteCompleted,
                      quoteSchemaFormDataParts,
                      setQuoteSchemaFormDataParts,
                      isQuoteCompletedSteps,
                      setIsQuoteCompletedSteps,
                      isValidQuoteParts,
                      setIsValidQuoteParts,
                      productModuleAndSchema,
                      createQuoteButtonRef,
                      rootSchemaFormQuoteRef,
                      screeningQuestionsData,
                      selectedQuote,
                      setLoadingCreateQuote,
                      setIsQuoteCompleted,
                      setQuotePackages,
                      setCreateQuoteError,
                      setIsLoadingBackButton,
                      setIsLoadingMainPage,
                      isLoadingMainPage,
                      sectionIndices: sectionIndices,
                    }),
                    ...(!isPersonalDetailsStartingStep
                      ? PersonalDetailsStep({
                          isCreatingPolicyholderFromPrefill,
                          setIsCreatingPolicyholderFromPrefill,
                          policyholderLookupFormData,
                          setPolicyholderLookupFormData,
                          applicationIsLoading,
                          applicationSchemaFormData,
                          createPolicyholderSubmitButtonRef,
                          isLoadingProductModule,
                          isPersonalDetailsCompleted,
                          isPrePersonalDetailsConsentChecked,
                          policyholder,
                          policyholderIsLoading,
                          policyholderIsValid,
                          prefillValues,
                          productModuleAndSchema,
                          stepOrder: policyIssuingFlowStepOrder,
                          selectedQuote,
                          setCurrentStepKey: params.setCurrentStepKey,
                          setPriorStepKey: params.setPriorStepKey,
                          setIsPersonalDetailsCompleted,
                          setIsPrePersonalDetailsConsentChecked,
                          setPolicyholder,
                          setPolicyholderIsLoading,
                          setPolicyholderIsValid,
                          onNextCompleted: params.onNextCompleted,
                          getPriorStepKey: params.getPriorStepKey,
                          sectionIndices,
                        })
                      : []),
                    {
                      key: StaticSceneStepKeys.Application,
                      stepName: DefaultStepKeys.AdditionalDetails,
                      title: additionalDetails?.wording.title,
                      description: getWording({ wording: additionalDetails?.wording.description }),
                      continueButtonProps: {
                        isLoading: applicationIsLoading,
                        disabled: !!applicationIsLoading || !!createApplicationError || !applicationIsValid,
                        isNextButtonHidden: false,
                      },
                      onBackClick: () => {
                        setCreateApplicationError(undefined);
                      },
                      body: () => (
                        <CreateApplication
                          stepOrder={policyIssuingFlowStepOrder}
                          prefillValues={prefillValues}
                          isLoading={!!applicationIsLoading}
                          applicationSchemaFormSubmitButtonRef={applicationSchemaFormSubmitButtonRef}
                          productModuleDefinition={props.productModule.productModuleDefinition}
                          sectionIndices={sectionIndices}
                          setStepProgress={(step) => {
                            params.setCurrentStepKey(step);
                          }}
                          setApplicationIsValid={(applicationIsValid) => setApplicationIsValid(applicationIsValid)}
                          applicationSchemaFormData={_.merge(
                            cloneDeep(prefillValues.application),
                            cloneDeep(applicationSchemaFormData),
                          )}
                          setApplicationSchemaFormData={(data) => setApplicationSchemaFormData(data)}
                          productModuleDefinitionApplicationSchema={
                            productModuleAndSchema?.productModuleDefinitionApplicationSchema
                          }
                          productModuleDefinitionQuoteSchema={
                            productModuleAndSchema?.productModuleDefinitionQuoteSchema
                          }
                          quoteSchemaFormData={Object.values(quoteSchemaFormDataParts).reduce(
                            (acc, curr) => merge(cloneDeep(acc), cloneDeep(curr)),
                            {},
                          )}
                          setIsLoading={(isLoading) => setApplicationIsLoading(isLoading)}
                          policyholder={policyholder}
                          selectedQuote={selectedQuote}
                          application={application}
                          priorStepKey={params.getPriorStepKey()}
                          setApplication={(application) => setApplication(application)}
                          setError={setCreateApplicationError}
                          onNextCompleted={() => params.onNextCompleted && params.onNextCompleted()}
                          isCompleted={isApplicationCompleted}
                        />
                      ),
                      onNextClick: async () => {
                        applicationSchemaFormSubmitButtonRef.current.click();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Application,
                        });
                        setIsApplicationCompleted(true);
                      },
                    },
                    {
                      key: StaticSceneStepKeys.Beneficiaries,
                      title: getWording({ wording: siteConfig?.beneficiaries?.wording.title }),
                      stepName: DefaultStepKeys.Beneficiaries,
                      description: beneficiariesDescriptionWording({ minimumBeneficiaries, maximumBeneficiaries }),
                      continueButtonProps: {
                        isLoading: applicationIsLoading || isLoadingBeneficiaries,
                        disabled: !isBeneficiariesValid,
                      },
                      body: () => (
                        <BeneficiarySummary
                          type={BeneficiarySummaryType.Application}
                          policyholder={policyholder}
                          error={productModuleError}
                          setIsLoading={(isLoading) => setIsLoadingBeneficiaries(isLoading)}
                          linkedEntity={application}
                          productModule={productModuleAndSchema?.productModule}
                          setLinkedEntity={(application) => setApplication(application)}
                          setIsValid={(isBeneficiariesValid) => setIsBeneficiariesValid(isBeneficiariesValid)}
                          setStepProgress={(step) => {
                            params.onNextCompleted && params.onNextCompleted();
                            params.setCurrentStepKey(step);
                          }}
                          beneficiarySummarySceneType={EmbedFlowType.PolicyIssuing}
                          priorStepKey={params.getPriorStepKey()}
                          submitButtonRef={beneficiaryFormSubmitButtonRef}
                          isLoading={!!applicationIsLoading}
                          sectionIndices={sectionIndices} // Add this line
                        />
                      ),
                      onNextClick: async () => {
                        beneficiaryFormSubmitButtonRef.current.click();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Beneficiaries,
                          extraProperties: {
                            beneficiaries: (application?.beneficiaries || []).map((b) => ({
                              dateOfBirth: b.dateOfBirth?.format('YYYY-MM-DD'),
                              gender: b.gender,
                              identificationCountry: b.id?.country,
                              identificationType: b.id?.type,
                              relationship: b.relationship,
                            })),
                          },
                        });
                      },
                    },
                    {
                      key: StaticSceneStepKeys.PrePaymentConsent,
                      stepName: DefaultStepKeys.PrePaymentDetailsConsent,
                      title: prePaymentCompliance?.wording.title,
                      description: getWording({ wording: prePaymentCompliance?.wording.description }),
                      onBackClick: () => undefined,
                      continueButtonProps: {
                        isLoading: false,
                        disabled: !isPrePaymentConsentChecked,
                        isNextButtonHidden: false,
                      },
                      body: () => (
                        <ConsentSection
                          stepOrder={policyIssuingFlowStepOrder}
                          isRequired={prePaymentConsentRequired}
                          setStepProgress={(step) => params.setCurrentStepKey(step)}
                          setPriorStepKey={(step) => params.setPriorStepKey(step)}
                          priorStepKey={params.getPriorStepKey()}
                          step={StaticSceneStepKeys.PrePaymentConsent}
                          content={getWording({ wording: prePaymentCompliance?.wording.content })}
                          setIsConsentChecked={(isConsentChecked) => setIsPrePaymentConsentChecked(isConsentChecked)}
                          isConsentChecked={isPrePaymentConsentChecked}
                          isLoading={isLoadingProductModule}
                          sectionIndices={sectionIndices}
                        />
                      ),
                      onNextClick: async () => {
                        params.onNextCompleted && params.onNextCompleted();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.ConsentPrePayment,
                        });
                      },
                    },
                    {
                      key: StaticSceneStepKeys.Payment,
                      title: payment?.wording.title,
                      stepName: DefaultStepKeys.Payment,
                      description: getWording({ wording: payment?.wording.description }),
                      continueButtonProps: {
                        isLoading: applicationIsLoading || isLoadingCreatePaymentMethod,
                        disabled:
                          paymentAccountPermission ||
                          !paymentMethodIsValid ||
                          !declarationCompleted ||
                          isLoadingCreatePaymentMethod,
                        isNextButtonHidden: true,
                      },
                      body: () => (
                        <PaymentSection
                          skipPaymentMethod={skipPaymentMethod}
                          prefillValues={prefillValues}
                          debitAccountPermission={paymentAccountPermission}
                          policyholder={policyholder}
                          application={application}
                          productModule={productModuleAndSchema?.productModule}
                          productModuleDefinitionQuoteSchema={
                            productModuleAndSchema?.productModuleDefinitionQuoteSchema
                          }
                          setAccountPermission={setPaymentAccountPermission}
                          setPaymentMethodIsValid={(isValid) => setPaymentMethodIsValid(isValid)}
                          isLoading={isLoadingCreatePaymentMethod}
                          setDeclarationCompleted={(isCompleted) => setDeclarationCompleted(isCompleted)}
                          onNextCompleted={() => {
                            stepCompletedTrack({
                              stepName: MixpanelStepNames.Payment,
                            });
                            params.onNextCompleted && params.onNextCompleted();
                          }}
                        />
                      ),
                      onNextClick: async () => {
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Payment,
                        });
                        paymentMethodSubmitButtonRef.current.click();
                      },
                    },
                  ];
                }}
                finalStepButtonContent='Pay now'
                CustomActionBarContent={(step: any) => <></>}
                footer={(step) => {
                  const lastStepIndex = sectionIndices[sectionIndices.length - 1];
                  const lastStepKey = `quoteInputStep${lastStepIndex}` as IssuingSceneStepKeys;

                  return (
                    <>
                      {step === lastStepKey && (
                        <FullScreenModalFooterOverlay fullWidthOnMobile siteConfig={siteConfig} key={lastStepKey}>
                          <SpacerLeft />
                          <div className='main'>
                            <QuoteStepActionBar
                              setSelectedQuote={setSelectedQuote}
                              quotes={quotePackages}
                              step={lastStepKey}
                              error={createQuoteError}
                              isLoadingCreateQuote={isLoadingCreateQuote}
                              isLoadingMainPage={isLoadingMainPage}
                              createQuoteButtonRef={createQuoteButtonRef}
                              onNextClickedRef={onNextClickedRef}
                              productModuleDefinition={props.productModule.productModuleDefinition}
                              rootSchemaFormQuoteRef={rootSchemaFormQuoteRef}
                            />
                          </div>
                          <SpacerRight />
                        </FullScreenModalFooterOverlay>
                      )}
                      <InsurerDisclaimerFooter siteConfig={siteConfig} />
                    </>
                  );
                }}
              />
            </div>
          </FlexContainer>
        </Content>
      </MainLayoutContent>
    </MainLayout>
  );
};

export const InsurerDisclaimerFooter = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => {
  const { siteConfig } = params;
  return (
    <FormOverlayFooterWrapper siteConfig={siteConfig} className='overlay-form-footer'>
      <div className='spacer-left' />
      <OverlayFormInnerContentExtendedWidth className={'overlay-form-inner-content-extended-width'}>
        <FormWrapperStyle>
          <InsurerDisclaimerContainer />
        </FormWrapperStyle>
      </OverlayFormInnerContentExtendedWidth>
      <div className='spacer-right' />
    </FormOverlayFooterWrapper>
  );
};

export const stepOrders = (params: { sectionIndices: number[] }) => {
  const {
    Application,
    Beneficiaries,
    Payment,
    PersonalDetails,
    PrePaymentConsent,
    PrePersonalDetailsConsent,
    ScreeningQuestions,
  } = StaticSceneStepKeys;

  const quoteInputSteps = params.sectionIndices.map((index) => `quoteInputStep${index}` as IssuingSceneStepKeys);

  return {
    default: [
      ScreeningQuestions,
      ...quoteInputSteps,
      PrePersonalDetailsConsent,
      PersonalDetails,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
    [ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails]: [
      PrePersonalDetailsConsent,
      PersonalDetails,
      ScreeningQuestions,
      ...quoteInputSteps,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
  };
};
