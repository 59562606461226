export const formatToNumber = (params: { number: number | string }) => {
  const { number } = params;
  const formattedNumber = typeof number === 'string' ? parseInt(number.replace(/[^0-9-.]/g, '')) : number;

  const locale = navigator.language;

  const formatter = new Intl.NumberFormat(locale);

  return formatter
    .format(formattedNumber)
    .replace(/[A-Za-z]/g, '')
    .trim();
};
