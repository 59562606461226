import { Environment } from 'models/environment';
import { buildApiUrl } from 'shared/utils';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost, getBasePathname } from '../../shared/api';
import { NetworkProductModule, ProductModule } from '../domain/product-module';

export const getProductModule = async (params: {
  auth: EmbedAuth;
  organizationId: string;
  environment: Environment;
  targetDraftProductModuleDefinition: boolean;
  productModuleKey: string | undefined;
}) => {
  const { auth, organizationId, environment, targetDraftProductModuleDefinition, productModuleKey } = params;
  const queryParams = getProductModuleDefinitionLiveOrDraft({ environment, targetDraftProductModuleDefinition });

  const pathname = auth.type === AuthTypes.JWT ? `/product-modules` : `/product-modules/${productModuleKey}`;
  const basePathname = getBasePathname({ organizationId, auth, clientAppAuth: true });

  const response: Response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${basePathname}${pathname}`, queryParams),
    method: 'get',
    auth,
  });

  const networkProductModule: NetworkProductModule = await response.json();

  const productModule = ProductModule.fromNetwork(networkProductModule);
  localStorage.setItem('product_module_key', productModule.key);

  return productModule;
};

export const getProductModuleDefinitionLiveOrDraft = (params: {
  environment: Environment;
  targetDraftProductModuleDefinition: boolean;
}) => {
  const { environment, targetDraftProductModuleDefinition } = params;

  if (environment === Environment.Sandbox && targetDraftProductModuleDefinition) {
    return { draft_definition: true };
  }

  return { live_definition: true };
};
