import { ProductModuleDefinitionAlterationHook } from 'alteration-hooks/domain/product-module-definition-alteration-hooks';
import { Policy } from 'policies/domain/policy';
import { PolicyStatus } from 'policies/domain/policy-status';
import { PremiumDisplay } from 'policy-issuing/payment/payment-step';
import { AlterationHooksListView } from 'policy-management/alteration-hooks/views/alteration-hooks-list-view';
import {
  ManagementView,
  PolicyManagementSceneBaseParams,
  SetManagementViewParams,
} from 'policy-management/policy-management-scene';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import React from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { SuccessButton } from 'rootstrap/components/button/styles';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { getPremiumDisplay } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import {
  StyledPolicyDetailsScene,
  StyledPolicyDetailsActionsWrapper,
  StyledPolicyDetailsLoadingInputsWrapper,
} from '../styles/policy-details-styles';
import { PolicySummary } from './policy-summary';
import { PolicySummaryErrorPage } from './policy-summary-error-page';

export interface PolicyDetailsProps extends PolicyManagementSceneBaseParams {
  productModule: ProductModule | undefined;
  policyholder: Policyholder | undefined;
  alterationHooks: ProductModuleDefinitionAlterationHook[];
  policy: Policy | undefined;
  setView: (managementViewParams: SetManagementViewParams) => void;
}

export const PolicyDetailsScene = (props: PolicyDetailsProps) => {
  const { productModule, policyholder, policy, setView, alterationHooks, isLoading, error } = props;
  const { siteConfig } = useSiteConfigContext();

  const premiumDisplay = getPremiumDisplay({
    premiumValue: policy?.basePremium,
    billingFrequency: policy?.billingFrequency,
    currency: props.productModule?.productModuleDefinition?.settings.billing.currency,
  });

  if (error) {
    return <PolicySummaryErrorPage />;
  }

  return (
    <StyledPolicyDetailsScene isLoading={isLoading}>
      <PremiumDisplay siteConfig={siteConfig}>
        <p>Ongoing premium</p>
        {!isLoading && <div>{premiumDisplay}</div>}
        {isLoading && <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />}
      </PremiumDisplay>
      <PolicySummary policy={policy} policyholder={policyholder} isLoading={isLoading} />
      {isLoading && (
        <StyledPolicyDetailsLoadingInputsWrapper>
          <LoadingInputs count={1} />
        </StyledPolicyDetailsLoadingInputsWrapper>
      )}
      {!isLoading && (
        <StyledPolicyDetailsActionsWrapper>
          <AlterationHooksListView
            policy={policy}
            productModule={productModule}
            alterationHooks={alterationHooks}
            setView={(view) => setView(view)}
          />
          <FormWrapperStyle>
            <SuccessButton
              id={'cancel-policy'}
              outline
              siteConfig={siteConfig}
              onClick={() =>
                setView({
                  view: ManagementView.Cancel,
                })
              }
              fullWidth={true}
              disabled={policy?.status === PolicyStatus.Cancelled}
            >
              <span>Cancel policy</span>
            </SuccessButton>
          </FormWrapperStyle>
        </StyledPolicyDetailsActionsWrapper>
      )}
      <br />
    </StyledPolicyDetailsScene>
  );
};
