import { Environment } from 'models/environment';
import {
  ExternalPaymentMethodType,
  NetworkExternalPaymentMethodType,
} from 'payment-methods/domain/external-payment-method-type';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from '../../shared/api';

interface Params {
  environment: Environment;
  organizationId: string;
  auth: EmbedAuth;
}

export const getExternalPaymentMethodTypes = async (params: Params) => {
  const { auth, environment, organizationId } = params;

  const url = auth.type === AuthTypes.JWT ?
    `/v1/insurance/organizations/${organizationId}/embed/payment-methods/external-types` :
    `/v1/insurance/payment-methods/external-types`


  const response: Response = await apiRequest({
    url: `${getApiHost({ environment })}${url}`,
    method: 'get',
    auth,
  });

  const networkExternalPaymentMethodTypes: NetworkExternalPaymentMethodType[] = await response.json();
  const externalPaymentMethodTypes = networkExternalPaymentMethodTypes.map((type) =>
    ExternalPaymentMethodType.fromNetwork(type),
  );

  return externalPaymentMethodTypes;
};
