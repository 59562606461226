import { Environment } from "models/environment";
import { ProductModule } from "product-modules/domain/product-module";

export const isCollectionModuleOnDraftProductModuleDefinition = (params: {
    environment: Environment;
    productModule: ProductModule;
  }) => {
    const { environment, productModule } = params;
    return (
      environment === Environment.Sandbox && !productModule.live
    );
  };
  