import { ArrowIcon } from 'assets/arrow-icon';
import {
  BeneficiarySummaryFullScreenModalHeader,
  BeneficiarySummaryTitleBody,
  BeneficiarySummaryTitleContents,
} from 'beneficiaries/styles/create-or-update-beneficiary';
import { PaymentMethodAll } from 'payment-methods/domain/payment-method';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import React, { useRef, useState } from 'react';
import { FormOverlay, FormOverlayHeader, FormOverlayHeaderContents } from 'rootstrap/components-old/forms/form-overlay';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { FormOverlayContentWrapper } from 'rootstrap/components/modal/styles';
import {
  FormOverlayTitleWrapper,
  FormOverLay,
  SpacerLeft,
  SpacerRight,
} from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FullScreenModalOffset } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { ArrowIconWrapper } from 'rootstrap/components/progress-bar/progress-bar';
import { MainLayout, MainLayoutContent, Content, FlexContainer } from 'rootstrap/components/structure';
import { globalStyles } from 'rootstrap/global-styles';
import { useSiteConfigContext } from 'style-context';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { useForm } from 'react-hook-form';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { getProductModule } from 'product-modules/actions/get-product-module';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useDraftDefinition } from 'shared/api';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import styled from 'styled-components';
import { Padding } from 'rootstrap/global-styles/padding';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { updatePolicy } from 'policies/actions/update-policy';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  setPolicy: (policy: Policy) => void;
  setShowUpdateBillingDay: (v: boolean) => void;
  paymentMethod: PaymentMethodAll | undefined;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  productModule: ProductModule;
}

export enum ExternalPaymentMethodDetailsInput {
  BillingDay = 'billingDay',
}

export interface ExternalPaymentMethodFormData {
  [ExternalPaymentMethodDetailsInput.BillingDay]: number;
}

export const UpdateBillingDayForm = (props: Props) => {
  const { policy, setPolicy, setShowUpdateBillingDay, paymentMethod } = props;
  const { siteConfig } = useSiteConfigContext();
  const targetDraftProductModuleDefinition = useDraftDefinition();
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: ExternalPaymentMethodDetailsInput.BillingDay,
  });
  const { embedParams } = useEmbedParamsContext();
  const { environment, organizationId, auth, productModuleKey } = embedParams;
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;
  const submitButtonRef = useRef<any>();

  const form = useForm<ExternalPaymentMethodFormData>({
    mode: 'onChange',
  });
  const isTouched = false;

  const {
    result,
    isLoading: isLoadingCollectionModules,
    error: collectionModulesError,
  } = usePromise(async () => {
    const productModule = await getProductModule({
      auth,
      organizationId,
      environment,
      targetDraftProductModuleDefinition,
      productModuleKey,
    });
  }, []);

  const { execute, error, isLoading } = usePromiseLazy(async (formData: ExternalPaymentMethodFormData) => {
    if (formData.billingDay !== policy.billingDay && canEditBillingDay) {
      const updatedPolicy = await updatePolicy({
        environment,
        organizationId,
        auth,
        data: { billingDay: formData.billingDay },
        policyId: policy.policyId,
      });

      setPolicy(updatedPolicy);
    }

    setShowUpdateBillingDay(false);
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <MainLayout>
        <MainLayoutContent>
          <Content>
            <FlexContainer>
              <FormOverlay>
                <FormOverlayHeader extendedWidth close={() => null}>
                  <FormOverlayHeaderContents siteConfig={siteConfig}>
                    <FullScreenModalOffset />
                    <FormOverlayTitleWrapper id='full-screen-header'>
                      <BeneficiarySummaryFullScreenModalHeader siteConfig={siteConfig} size={'20px'}>
                        <ArrowIconWrapper onClick={() => setShowUpdateBillingDay(false)}>
                          <ArrowIcon id='progress-bar-arrow-back-button' color={globalStyles.colors.Body} size={20} />
                        </ArrowIconWrapper>
                        <BeneficiarySummaryTitleBody>
                          <BeneficiarySummaryTitleContents>Update payment method</BeneficiarySummaryTitleContents>
                        </BeneficiarySummaryTitleBody>
                      </BeneficiarySummaryFullScreenModalHeader>
                    </FormOverlayTitleWrapper>
                    <FullScreenModalOffset />
                  </FormOverlayHeaderContents>
                </FormOverlayHeader>
                <div style={{ height: '100%', overflow: 'scroll' }} id='form-overflow-content-wrapper'>
                  <FormOverlayContentWrapper id='form-overlay-content-wrapper'>
                    <FormOverLay>
                      <SpacerLeft />
                      <div className='main'>
                        <ErrorAlert error={collectionModulesError} />

                        <StyledFormWrapperStyle>
                          <BeneficiaryDetailsStyle style={{ paddingBottom: 60 }}>
                            <form onSubmit={form.handleSubmit((data: ExternalPaymentMethodFormData) => execute(data))}>
                              <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                                <StyledBeneficiaryCol sm={12}>
                                  <BillingDaySelectField
                                    clearable={true}
                                    isTouched={isTouched}
                                    disableScrollToElement={true}
                                    name={ExternalPaymentMethodDetailsInput.BillingDay}
                                    validators={[
                                      {
                                        validation: {
                                          type: ValidationTypes.REQUIRED,
                                        },
                                      },
                                    ]}
                                    label='Debit day'
                                    form={form}
                                    defaultValue={policy.billingDay}
                                    prefillValue={ExternalPaymentMethodDetailsInput.BillingDay}
                                    placeholder={'Debit day'}
                                    disableActiveElement={true}
                                    hideDivider={true}
                                    displayProperties={
                                      {
                                        activeElement: {
                                          elementId: '',
                                        },
                                        setActiveElement,
                                        nextComponentName: '',
                                      } as InputFieldDisplayProperties
                                    }
                                  />
                                </StyledBeneficiaryCol>
                              </StaticFormWithTitleRowStyle>
                              <LongButtonSuccess
                                siteConfig={siteConfig}
                                onClick={() => submitButtonRef.current.click()}
                                disabled={isLoading || !form.formState.isValid}
                                id='manage-payment-methods-submit-button'
                              >
                                {isLoading && (
                                  <span style={{ marginRight: '10px' }}>
                                    <NewSpinner
                                      animation={AnimationTypes.Border}
                                      size={SpinnerSize.sm}
                                      color='FFFFFF'
                                    />
                                  </span>
                                )}
                                {paymentMethod ? 'Update' : 'Add'}
                                <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
                              </LongButtonSuccess>
                            </form>
                          </BeneficiaryDetailsStyle>
                        </StyledFormWrapperStyle>
                      </div>
                      <SpacerRight />
                    </FormOverLay>
                  </FormOverlayContentWrapper>
                </div>
              </FormOverlay>
            </FlexContainer>
          </Content>
        </MainLayoutContent>
      </MainLayout>
    </div>
  );
};

const StyledFormWrapperStyle = styled(FormWrapperStyle)`
  padding-top: ${Padding.md};
`;
