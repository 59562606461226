import { updateApplicationBillingDay } from 'applications/actions/update-application-billing-day';
import { issuePolicy } from 'policies/actions/issue-policy';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CardGroup } from 'rootstrap/components/card';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { applyPolicyDetailsToUrl, useConfirmationUrl, useBaseUrl, AuthTypes, ApiError } from 'shared/api';
import { PaymentMethodPrefillParams } from 'shared/domain/prefill-values';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { PaymentMethodProps } from '../payment-step';
import { handleRedirect } from '../utils/handle-payment-redirect';
import { updateApplication } from 'applications/actions/update-application';
import { renderCreatePaymentMethod } from 'collection-modules/actions/render-create-payment-method';
import { createCollectionModulePaymentMethod } from 'payment-methods/actions/create-collection-module-payment-method';
import { JSONObject } from 'shared/utils';
import { useSetIframeHeight } from 'policy-issuing/utils';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { SubmitPaymentMethodLongButtonSuccess } from './styles';
import {
  collectionModuleHTMLSanitization,
  collectionModuleHTMLScript,
} from 'collection-modules/views/utils/collection-module-html-sanitization';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { Environment } from 'models/environment';
import { useQueryParams } from 'shared/hooks/url';
import { getCollectionModuleVersionFromQueryParam } from '../utils/get-collection-module-version-from-query-param';
import { DebitOrderPaymentInputs } from 'policy-management/payment-details/forms/debit-order-payment-method-form';
import { scrollTo } from 'rootstrap/components/forms/new-fields/utils';

export enum CollectionModulePaymentInputs {
  BillingDay = 'billingDay',
}

export interface CreateCollectionModulePaymentMethodProps extends PaymentMethodProps {
  collectionModuleKey: string;
}

export const CreateCollectionModulePaymentMethod = (params: PaymentMethodProps) => {
  const { prefillValues, application, policyholder, setActiveElement, isConfirmationSkipped, collectionModuleKey } =
    params;

  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();

  const history = useHistory();
  const baseUrl = useBaseUrl();
  const confirmationUrl = useConfirmationUrl();
  const [isIframeLoading, setIframeIdLoading] = useState<boolean>(false);
  const [iframeError, setIframeError] = useState<ApiError>();
  const [paymentMethodIsValid, setPaymentMethodIsValid] = useState(true);
  const { stepCompletedTrack } = useMixpanelTrack();
  const { queryParams } = useQueryParams<{ draft_collection_module: string }>();

  const versionIsDraft = getCollectionModuleVersionFromQueryParam(queryParams.draft_collection_module);

  const { auth, environment, organizationId } = embedParams;
  const canEditBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.editBillingDay);
  const displayBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.billingDay);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const collectionModulePaymentInputData = getCollectionModulePaymentMethodInputData({
    prefillValues: prefillValues?.payment,
    billingDay: application?.billingDay,
    canEditBillingDay,
  });
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const paymentMethodSubmitButtonRef = useRef<any>();

  const form = useForm<{ billingDay: number }>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });

  useEffect(() => {
    scrollTo({ scrollToId: 'bankName-form-group' });
  }, []);

  const billingDay = form.watch(CollectionModulePaymentInputs.BillingDay);

  const {
    execute: createPaymentMethod,
    isLoading: isLoadingCreatePaymentMethod,
    error: errorCreatePaymentMethod,
  } = usePromiseLazy(async (params: { data: JSONObject; billingDay: number | undefined }) => {
    if (!policyholder || !application) {
      throw new Error(`No ${!policyholder ? 'policyholder' : 'application'} found`);
    }

    await createCollectionModulePaymentMethod({
      organizationId,
      environment,
      collectionModuleKey: collectionModuleKey || '',
      data: params.data,
      policyholderId: policyholder.policyholderId,
      applicationId: application.applicationId,
      auth,
      version: environment === Environment.Sandbox ? versionIsDraft : undefined,
    });

    if (canEditBillingDay && application.billingDay !== billingDay && displayBillingDay) {
      if (auth.type === AuthTypes.APIKey) {
        await updateApplication({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: billingDay || 1,
          },
        });
      } else {
        await updateApplicationBillingDay({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay: billingDay || 1,
          },
        });
      }
    }

    const { policyId, policyholderId } = await issuePolicy({
      applicationId: application.applicationId,
      billingDay: billingDay,
      auth,
      environment,
      organizationId,
    });

    stepCompletedTrack({
      stepName: MixpanelStepNames.Payment,
    });

    handleRedirect({
      applyPolicyDetailsToUrl,
      baseUrl,
      confirmationUrl,
      environment,
      history,
      isConfirmationSkipped,
      policyholderId,
      policyId,
      redirectOnCompletedUrl,
    });
  }, []);

  const {
    result,
    isLoading: isLoadingRenderPaymentMethod,
    error: renderPaymentMethodError,
  } = usePromise(async () => {
    if (!application || !policyholder || !collectionModuleKey) {
      let missingComponent;
      if (!application) {
        missingComponent = 'application';
      } else if (!policyholder) {
        missingComponent = 'policyholder';
      } else if (!collectionModuleKey) {
        missingComponent = 'collection module key';
      }
      throw new Error(`No ${missingComponent} found`);
    }

    return renderCreatePaymentMethod({
      collectionModuleKey: collectionModuleKey || '',
      environment,
      organizationId,
      auth,
      applicationId: application.applicationId,
      policyholderId: policyholder.policyholderId,
      version: environment === Environment.Sandbox ? versionIsDraft : undefined,
    });
  }, [errorCreatePaymentMethod, collectionModuleKey]);

  const modifiedHtmlContent = collectionModuleHTMLSanitization({
    htmlContent: result?.html || '',
    script: collectionModuleHTMLScript,
  });

  const submitIframeForm = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      (iframe.contentWindow as any).submitCreatePaymentMethod();
    }
  };

  useEffect(() => {
    (window as any).completeCreatePaymentMethod = (data: any) =>
      createPaymentMethod({
        data,
        billingDay,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDay]);

  // Assuming your iframe has an id of 'your-iframe-id'
  useEffect(() => {
    (window as any).setIsLoading = setIframeIdLoading;
    (window as any).setError = setIframeError;
    (window as any).setIsValid = setPaymentMethodIsValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isIframeLoading || isLoadingCreatePaymentMethod || isLoadingRenderPaymentMethod;
  const error = renderPaymentMethodError || errorCreatePaymentMethod || iframeError;

  useSetIframeHeight({ iframeId: 'collections-module-iframe', isLoading, modifiedHtmlContent });

  return (
    <FormWrapperStyle>
      <ErrorAlert error={error} />
      <CardGroup>
        {(isLoadingCreatePaymentMethod || isLoadingRenderPaymentMethod) && (
          <StyledLoadingLinesWrapper>
            <LoadingLines lines={[100, 80, 90, 70]} />
          </StyledLoadingLinesWrapper>
        )}
        {!isLoadingCreatePaymentMethod && !isLoadingRenderPaymentMethod && (
          <div>
            <iframe
              ref={iframeRef} // Attach the ref to the iframe
              id='collections-module-iframe'
              title='collections module iframe'
              srcDoc={modifiedHtmlContent}
              width='100%'
              height='200px'
              frameBorder='0'
            />
          </div>
        )}
        <form>
          {displayBillingDay && (
            <Row>
              <Col sm={12} style={{ marginTop: 0 }}>
                <BillingDaySelectField
                  isTouched={true}
                  clearable={true}
                  disableScrollToElement={true}
                  name={CollectionModulePaymentInputs.BillingDay}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  label='Debit day'
                  form={form}
                  isDisabled={!canEditBillingDay}
                  defaultValue={collectionModulePaymentInputData.billingDay}
                  prefillValue={collectionModulePaymentInputData.billingDay}
                  disableTitle={false}
                  placeholder={'Debit day'}
                  disableActiveElement={true}
                  hideDivider={true}
                  displayProperties={
                    {
                      activeElement: {
                        elementId: '',
                      },
                      setActiveElement,
                      nextComponentName: '',
                    } as InputFieldDisplayProperties
                  }
                />
              </Col>
            </Row>
          )}
          <SubmitPaymentMethodLongButtonSuccess
            siteConfig={siteConfig}
            onClick={submitIframeForm}
            disabled={isLoading || !paymentMethodIsValid}
            ref={paymentMethodSubmitButtonRef}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner
                  id='collection-module-pay-now-loading-spinner'
                  animation={AnimationTypes.Border}
                  size={SpinnerSize.sm}
                  color='FFFFFF'
                />
              </span>
            )}
            Pay now
          </SubmitPaymentMethodLongButtonSuccess>
        </form>
      </CardGroup>
    </FormWrapperStyle>
  );
};

export interface ExternalPaymentMethodFormData {
  billingDay?: number;
}

export interface ExternalPaymentMethodPrefillParams {
  billingDay?: number;
}

const getCollectionModulePaymentMethodInputData = (params: {
  prefillValues?: PaymentMethodPrefillParams;
  canEditBillingDay: boolean;
  billingDay: number | undefined;
}): ExternalPaymentMethodPrefillParams => {
  const { billingDay, canEditBillingDay, prefillValues } = params;
  return {
    billingDay: canEditBillingDay ? prefillValues?.debit_day || billingDay : billingDay,
  };
};

export const StyledLoadingLinesWrapper = styled.div`
  margin-bottom: 22px;
`;
