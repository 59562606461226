import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CardGroup } from 'rootstrap/components/card';
import { Col, Row } from 'reactstrap';
import { PhoneNumberField } from 'rootstrap/components/forms/new-fields/phone-number-field';
import { usePromise } from 'shared/hooks/promise';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import {
  InputField,
  InputFieldDisplayProperties,
  PrefillAction,
} from 'rootstrap/components/forms/new-fields/input-field';
import phone from 'phone';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { BCP47ToIsoCountryCode } from 'rootstrap/components/forms/countries';
import { useSiteConfigContext } from 'style-context';
import {
  EmbeddedConfigSection,
  getDisplayOptionalSections,
  getWording,
  ProductModuleEmbedPersonalDetailsContactDetailTypes,
} from 'site-config';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { isPrefilledFromLookupData } from '../utils/helpers';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';
import { SelectableIdType } from 'policyholder/domain/policyholder-identification';

export interface ContactDetailCellphone {
  countryCode: string;
  number: string;
}
export interface ContactDetailsData {
  email?: string;
  cellphone?: ContactDetailCellphone;
}
interface Params {
  setIsValid: (contactDetailsIsValid: boolean) => void;
  setSectionData: (contactDetailsSectionData: ContactDetailsData) => void;
  contactDetailsSectionData: ContactDetailsData | undefined;
  prefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  isCompleted: boolean;
  policyholderLookupFormData: PolicyholderLookUpFormData | undefined;
}

export const ContactDetailsSection = (params: Params) => {
  const {
    setIsValid,
    setSectionData,
    prefillValues,
    contactDetailsSectionData,
    setActiveElement,
    activeElement,
    isCompleted,
    policyholderLookupFormData,
  } = params;

  const { siteConfig } = useSiteConfigContext();
  const fetchifyAutoCompleteEnabled =
    siteConfig?.[EmbeddedConfigSection.PersonalDetails].displayOptionalSections.fetchifyAutoComplete;
  const defaultCountryCodeFromBrowser = siteConfig?.settings?.defaultCountryCodeFromBrowser;

  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const steppedComponentsBehavior: SteppedComponentsBehavior = {
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? isCompleted : true,
  };

  const displayCellphoneNumber = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.personalDetails.displayOptionalSections.contactDetailTypes.includes(
      ProductModuleEmbedPersonalDetailsContactDetailTypes.Cellphone,
    ),
  });

  const displayEmailAddress = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.personalDetails.displayOptionalSections.contactDetailTypes.includes(
      ProductModuleEmbedPersonalDetailsContactDetailTypes.Email,
    ),
  });

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: { ...contactDetailsSectionData },
      }),
      [],
    ),
  });
  form.watch();

  usePromise(async () => {
    execute();
    await form.trigger();
  }, []);

  usePromise(async () => {
    setIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  const execute = () => {
    const formValues = form.getValues() as ContactDetailsData;
    setSectionData(formValues);
  };

  return (
    <CardGroup>
      <form
        onBlur={() => execute()}
        onChange={() => execute()}
        onSubmit={form.handleSubmit((data: ContactDetailsData) => setSectionData(data))}
      >
        {displayEmailAddress && (
          <Row>
            <Col sm={12}>
              <InputField
                placeholder={undefined}
                defaultValue={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Email)
                    ? policyholderLookupFormData?.email
                    : contactDetailsSectionData?.email
                }
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                  {
                    validation: {
                      type: ValidationTypes.EMAIL,
                    },
                  },
                ]}
                name={PersonalDetailsInputs.Email}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.email.label })}
                prefillAction={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Email)
                    ? PrefillAction.Disabled
                    : siteConfig?.inputFields.personalDetails.email.prefillAction
                }
                form={form}
                prefillValue={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Email)
                    ? policyholderLookupFormData?.email
                    : prefillValues.email
                }
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: displayCellphoneNumber
                      ? PersonalDetailsInputs.Cellphone
                      : PersonalDetailsInputs.AddressOptIn,
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior}
              />
            </Col>
          </Row>
        )}
        {displayCellphoneNumber && (
          <Row>
            <Col sm={12}>
              <PhoneNumberField
                placeholder={undefined}
                defaultValue={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Cellphone)
                    ? policyholderLookupFormData?.cellphone
                    : defaultValues({
                        contactDetailsSectionData,
                        defaultCountryCodeFromBrowser,
                      })
                }
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                name={PersonalDetailsInputs.Cellphone}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.cellphone.label })}
                prefillAction={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Cellphone)
                    ? PrefillAction.Disabled
                    : siteConfig?.inputFields.personalDetails.cellphone.prefillAction
                }
                form={form}
                prefillValue={
                  isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Cellphone)
                    ? policyholderLookupFormData?.cellphone
                    : prefillValues.cellphone
                }
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: fetchifyAutoCompleteEnabled
                      ? PersonalDetailsInputs.FetchifyAutocomplete
                      : PersonalDetailsInputs.AddressOptIn,
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior}
              />
            </Col>
          </Row>
        )}
      </form>
    </CardGroup>
  );
};

const defaultValues = (params: {
  contactDetailsSectionData: ContactDetailsData | undefined;
  defaultCountryCodeFromBrowser: boolean | undefined;
}) => {
  const { contactDetailsSectionData, defaultCountryCodeFromBrowser } = params;

  return {
    countryCode:
      contactDetailsSectionData?.cellphone?.countryCode ||
      phone(contactDetailsSectionData?.cellphone?.number || '').countryIso2 ||
      (defaultCountryCodeFromBrowser && BCP47ToIsoCountryCode()) ||
      'ZA',
    number: contactDetailsSectionData?.cellphone?.number?.replace(
      phone(contactDetailsSectionData?.cellphone?.number || '').countryCode || '',
      '',
    ),
  };
};
