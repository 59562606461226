import { Application } from 'applications/domain/application';
import { issuePolicy } from 'policies/actions/issue-policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { applyPolicyDetailsToUrl, useBaseUrl, useConfirmationUrl } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { handleRedirect } from '../utils/handle-payment-redirect';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';

interface Props {
  policyholder: Policyholder | undefined;
  application: Application | undefined;
  isConfirmationSkipped: boolean;
}

export const SkippedPaymentMethod = (props: Props) => {
  const { policyholder, application, isConfirmationSkipped } = props;
  const history = useHistory();
  const confirmationUrl = useConfirmationUrl();
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { stepCompletedTrack } = useMixpanelTrack();

  const { auth, environment, organizationId } = embedParams;

  const baseUrl = useBaseUrl();
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { execute, isLoading, error } = usePromiseLazy(async () => {
    if (!policyholder || !application) {
      throw new Error(`No ${!policyholder ? 'policyholder' : 'application'} found`);
    }

    setIsRedirecting(true);

    try {
      const { policyId, policyholderId } = await issuePolicy({
        applicationId: application.applicationId,
        auth,
        environment,
        organizationId,
      });

      stepCompletedTrack({
        stepName: MixpanelStepNames.Payment,
      });

      handleRedirect({
        applyPolicyDetailsToUrl,
        baseUrl,
        confirmationUrl,
        environment,
        history,
        isConfirmationSkipped,
        policyholderId,
        policyId,
        redirectOnCompletedUrl,
      });
    } catch (error) {
      setIsRedirecting(false);
      throw error;
    }
  }, []);

  return (
    <FormWrapperStyle>
      <ErrorAlert error={error} />
      <AcceptQuoteLongButtonPress siteConfig={siteConfig} onClick={execute} disabled={isLoading || isRedirecting}>
        {(isLoading || isRedirecting) && (
          <span style={{ marginRight: '10px' }}>
            <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
          </span>
        )}
        Accept quote
      </AcceptQuoteLongButtonPress>
    </FormWrapperStyle>
  );
};

const AcceptQuoteLongButtonPress = styled(LongButtonSuccess)`
  width: 100%;
`;
