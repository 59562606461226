import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components';

export const MainSidebarStyle = styled.div<{ isActive: boolean; siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: grid;
  height: 60px;

  div {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  div a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  div span {
    font-size: 7px;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
    ${({ isActive, siteConfig }) => (isActive && css`color: ${getColor({ siteConfig, color: 'light'})}`)};
  }
`;
