import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import styled from 'styled-components';

export const StyledManagePaymentMethodCard = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  border: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  border-radius: 5px;
  padding: 26px 22px;

  .header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .details {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14px;
  }

  .billing {
    font-size: 12px;
    font-weight: 400;
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    margin-bottom: 0px;
  }
`;
