import React from 'react';
import { StyledManagePaymentMethodCard } from '../styles';
import { useSiteConfigContext } from 'style-context';
import { useSetIframeHeight } from 'policy-issuing/utils';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { Policy } from 'policies/domain/policy';
import { usePromise } from 'shared/hooks/promise';
import { renderViewPaymentMethodSummary } from 'collection-modules/actions/render-view-payment-method-summary';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { CollectionModulePaymentMethodSummaryModuleDataView } from './collection-module-payment-method-module-data';
import { formatBillingDate } from '../utils';
import { UpdateBillingDayButton } from './components/update-billing-day-button';
import { CollectionModulePaymentMethod } from 'payment-methods/domain/payment-method';

export const CollectionModulePaymentMethodSummaryView = (params: {
  paymentMethod: CollectionModulePaymentMethod;
  policy: Policy;
  setShowUpdateBillingDay: (showUpdateBillingDay: boolean) => void;
}) => {
  const { embedParams } = useEmbedParamsContext();
  const { environment, organizationId, auth } = embedParams;
  const { paymentMethod, policy, setShowUpdateBillingDay } = params;
  const { siteConfig } = useSiteConfigContext();
  const existingViewCollectionModuleId = `collection-module-payment-method-view`;

  const { result, isLoading } = usePromise(async () => {
    return renderViewPaymentMethodSummary({
      auth,
      environment,
      organizationId,
      policyId: policy.policyId,
      policyholderId: policy.policyholderId,
      paymentMethodId: paymentMethod.paymentMethodId,
      collectionModuleKey: paymentMethod.collectionModuleKey,
      collectionModuleDefinitionId: paymentMethod.collectionModuleDefinitionId,
    });
  }, []);

  useSetIframeHeight({
    iframeId: existingViewCollectionModuleId,
    isLoading: !result?.html || isLoading,
    modifiedHtmlContent: result?.html,
  });

  return (
    <StyledManagePaymentMethodCard siteConfig={siteConfig}>
      {isLoading && <LoadingLines />}
      {!isLoading && (
        <div>
          {!result?.html && (
            <CollectionModulePaymentMethodSummaryModuleDataView collectionModulePaymentMethod={paymentMethod} />
          )}
          {result?.html && (
            <iframe
              id={existingViewCollectionModuleId}
              title='collections module iframe'
              srcDoc={result.html}
              width='100%'
              height='1px'
              frameBorder='0'
              style={{ overflow: 'auto' }} // This will allow scrolling within the iframe
            />
          )}
          {policy.nextBillingDate && (
            <p className='billing'>Next billing day: {formatBillingDate(policy.nextBillingDate)}</p>
          )}
          <UpdateBillingDayButton setShowUpdateBillingDay={setShowUpdateBillingDay} />
        </div>
      )}
    </StyledManagePaymentMethodCard>
  );
};
