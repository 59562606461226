import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  applicationId: string;
  environment: Environment;
  isUnauthenticated: boolean;
}

export const archiveApplication = async (params: Params) => {
  const { applicationId, auth, organizationId, environment, isUnauthenticated } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  if (!isUnauthenticated) {
    await apiRequest({
      url: `${getApiHost({ environment })}${basePathName}/applications/${applicationId}/archive`,
      method: 'post',
      auth,
    });
  }
};
