import React from 'react';
import { sentenceify } from './utils';
import { FieldError } from './api';

export const formatError = (error: any) => {
  const message = sentenceify(error.message || 'Something went wrong.');
  const fieldErrors: FieldError[] = error.fieldErrors || [];
  return (
    <>
      <p>
        {message}
        {fieldErrors.length > 0 && ` The following field errors occurred:`}
      </p>
      {fieldErrors.length > 0 && (
        <>
          <ul>
            {fieldErrors.map((fieldError) => {
              return <li>{sentenceify(fieldError.message)}</li>;
            })}
          </ul>
        </>
      )}
    </>
  );
};
