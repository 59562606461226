import { Environment } from 'models/environment';
import moment, { Moment } from 'moment';
// import { BankAccount, PaymentType } from './payee-bank-details';
import { Construct } from '../../models/construct';

export class ExternalPaymentMethodType {
  readonly paymentMethodId: string;
  readonly organizationId: string;
  readonly environment: Environment;
  readonly key: string;
  readonly outbound_channels: OutboundHttp[];
  readonly created_at: Moment;

  constructor(init: Construct<ExternalPaymentMethodType>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkExternalPaymentMethodType) {
    return new ExternalPaymentMethodType({
      paymentMethodId: init.payment_method_id,
      key: init.key,
      outbound_channels: init.outbound_channels,
      organizationId: init.organization_id,
      created_at: moment(init.created_at).local(),
      environment: init.environment,
    });
  }
}

export class NetworkExternalPaymentMethodType {
  payment_method_id: string;
  organization_id: string;
  environment: Environment;
  key: string;
  outbound_channels: OutboundHttp[];
  created_at: string;
}

export enum OutboundChannelType {
  Http = 'http',
}

interface OutboundHttp {
  readonly id: string;
  readonly type: OutboundChannelType;
  readonly url: string;
  readonly token: string;
  readonly secret: string;
}
