import React from 'react';
import { SelectFieldParams, SelectField } from '../select-field';
import { orderedBeneficiaryRelationshipOptions } from 'general/relations';

const beneficiaryRelationshipOptions = orderedBeneficiaryRelationshipOptions.map((x) => {
  const label = x.replace(/_/gi, ' ');
  return {
    label: label[0].toLocaleUpperCase() + label.substr(1),
    value: x,
  };
});

export const BeneficiaryRelationshipSelectField = (props: Omit<SelectFieldParams<string>, 'options'>) => {
  return <SelectField {...props} searchable options={beneficiaryRelationshipOptions} clearable={true} />;
};
