import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled, { css } from 'styled-components/macro';
import { Spacing } from '../../global-styles';

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 1.143;
  margin-top: ${Spacing.sm};
  margin-bottom: ${Spacing.sm};
`;

export const Subtitle = styled.h2`
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  line-height: 1.3333333;
  font-weight: 700;
  margin-top: ${Spacing.sm};
  margin-bottom: ${Spacing.sm};
`;

export const Heading = styled.h3<{
  textAlign?: 'center' | 'left';
  colorPrimary?: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
}>`
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  margin-top: ${Spacing.sm};
  margin-bottom: ${Spacing.sm};
  ${({ textAlign }) =>
    css`
      text-align: ${textAlign};
    `}

  ${({ colorPrimary, siteConfig }) =>
    colorPrimary &&
    css`
      color: ${getColor({ siteConfig, color: 'primary' })};
    `}
`;

export const SubHeading = styled.h4<{
  colorPrimary?: boolean;
  bolded?: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  line-height: 1.25;
  font-weight: 300;
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xs};
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  ${({ colorPrimary, siteConfig }) =>
    colorPrimary &&
    css`
      color: ${getColor({ siteConfig, color: 'highlight' })};
    `};

  ${({ bolded }) =>
    bolded &&
    css`
      font-weight: 700;
    `}
`;
