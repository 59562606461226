import { RequestedBy } from 'general/created-by';
import { Construct } from 'models/construct';
import { Environment } from 'models/environment';
import moment, { Moment } from 'moment';
import { Currency } from 'product-modules/domain/product-module-definition-settings';

export enum AlterationPackageStatus {
  Pending = 'pending',
  Applied = 'applied',
}

export class AlterationPackage {
  readonly alterationPackageId: string;
  readonly organizationId: string;
  readonly environment: Environment;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;
  readonly currency: Currency;
  readonly module: { type: string;[key: string]: any };
  readonly status: AlterationPackageStatus;
  readonly sumAssured: number;
  readonly monthlyPremium: number;
  readonly changeDescription: string;
  readonly productModuleDefinitionId: string;
  readonly inputData: {
    [key: string]: any
  };
  readonly productModuleDefinitionAlterationHookId: string;

  constructor(init: Construct<AlterationPackage>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkAlterationPackage) {
    return new AlterationPackage({
      alterationPackageId: init.alteration_package_id,
      createdAt: moment(init.created_at).local(),
      createdBy: init.created_by,
      environment: init.environment,
      status: init.status,
      inputData: init.input_data,
      changeDescription: init.change_description,
      monthlyPremium: init.monthly_premium,
      currency: init.currency,
      sumAssured: init.sum_assured,
      module: init.module,
      organizationId: init.organization_id,
      productModuleDefinitionAlterationHookId: init.product_module_definition_alteration_hook_id,
      productModuleDefinitionId: init.product_module_definition_id,
    });
  }
}

export class NetworkAlterationPackage {
  alteration_package_id: string;
  organization_id: string;
  environment: Environment;
  created_at: string;
  currency: Currency;
  sum_assured: number;
  monthly_premium: number;
  change_description: string;
  created_by: RequestedBy;
  module: {
    type: string;
    [key: string]: any
  };
  product_module_definition_id: string;
  input_data: {
    [key: string]: any
  };
  product_module_definition_alteration_hook_id: string;
  status: AlterationPackageStatus;
}
