import { Title } from 'general/title';
import { Gender } from 'models/gender';
import moment, { Moment } from 'moment';
import { BeneficiaryPaymentDetails } from 'payment-methods/domain/payee-bank-details';
import { Cellphone } from 'policyholder/domain/policyholder';
import { Identification } from 'policyholder/domain/policyholder-identification';
import { snakeToCamel } from 'utils';

export enum BeneficiaryEntityType {
  Individual = 'individual',
  Company = 'company',
}

export class Beneficiary {
  public beneficiaryId: string;
  public policyholderId?: string;
  public firstName: string;
  public lastName: string;
  public percentage: number;
  public title?: Title;
  public gender?: Gender;
  public id?: Identification;
  public dateOfBirth?: Moment;
  public email: string;
  public relationship: string;
  public cellphone?: Cellphone;
  public paymentDetails?: BeneficiaryPaymentDetails;
  public companyName?: string;
  public registrationNumber?: string;
  public entityType?: BeneficiaryEntityType;

  constructor(init: Partial<Beneficiary>) {
    Object.assign(this, init);
  }

  getCountry = () => {
    return (this.id && this.id.country.toLowerCase()) || 'za';
  };

  cellNumber = () => {
    return this.cellphone ? this.cellphone.number : undefined;
  };

  prettyCellNumber = () => {
    const number = this.cellNumber();
    if (!number) {
      return undefined;
    }

    // Move to central utils
    return `+27 ${(number as any).substr(1, 2)} ${(number as any).substr(3, 3)} ${(number as any).substr(6, 4)}`;
  };

  fullname = () => {
    if (!this.firstName || !this.lastName) {
      return undefined;
    }

    return `${this.firstName} ${this.lastName}`;
  };

  isComplete = () => {
    if (!this.firstName || !this.lastName) {
      return false;
    }

    if (!this.hasPaymentDetails()) {
      return false;
    }

    if (!this.relationship) {
      return false;
    }

    return (this.cellphone !== undefined && this.cellphone.number !== undefined) || this.email !== undefined;
  };

  hasPaymentDetails = () => {
    return (
      this.paymentDetails &&
      this.paymentDetails.details &&
      this.paymentDetails.details.bankName !== undefined &&
      this.paymentDetails.details.accountNumber !== undefined
    );
  };

  prettyRelationship = () => {
    if (!this.relationship) {
      return undefined;
    }

    return this.relationship[0].toUpperCase() + this.relationship.replace(/_/gi, ' ').substr(1);
  };

  public static fromNetwork(init: NetworkBeneficiary) {
    return new Beneficiary({
      beneficiaryId: init.beneficiary_id,
      cellphone: init.cellphone
        ? typeof init.cellphone === 'string'
          ? { country: 'ZA', number: init.cellphone.replace('+27', '0') }
          : init.cellphone
        : undefined,
      dateOfBirth: init.date_of_birth ? moment(init.date_of_birth) : undefined,
      companyName: init.company_name,
      email: init.email,
      entityType: init.type,
      gender: init.gender,
      firstName: init.first_name,
      lastName: init.last_name,
      id: init.id,
      relationship: init.relationship,
      registrationNumber: init.registration_number,
      title: init.title,
      policyholderId: init.policyholder_id,
      percentage: init.percentage,
      paymentDetails: init.payment_details && snakeToCamel(init.payment_details),
    });
  }

  toNetwork(): NetworkBeneficiary {
    return {
      beneficiary_id: this.beneficiaryId,
      policyholder_id: this.policyholderId,
      first_name: this.firstName,
      last_name: this.lastName,
      company_name: this.companyName,
      type: this.entityType,
      registration_number: this.registrationNumber,
      percentage: this.percentage,
      id: this.id,
      relationship: this.relationship,
      date_of_birth: this.dateOfBirth && this.dateOfBirth.toISOString(),
      email: this.email,
      cellphone: this.cellphone ? this.cellphone.number : undefined,
      payment_details: this.paymentDetails
        ? {
            type: this.paymentDetails.type,
            details: this.paymentDetails.details,
          }
        : undefined,
    };
  }
}

export class NetworkBeneficiary {
  public beneficiary_id: string;
  public policyholder_id?: string;
  public first_name: string;
  public last_name: string;
  public percentage: number;
  public id?: Identification;
  public date_of_birth?: string;
  public email?: string;
  public cellphone?: string;
  public payment_details?: BeneficiaryPaymentDetails;
  public company_name?: string;
  public registration_number?: string;
  public type?: BeneficiaryEntityType;
  public relationship: string;
  public gender?: Gender;
  public title?: Title;
}
