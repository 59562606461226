import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import styled from 'styled-components';

export const ConsentSplashContainer = styled(SplashContainer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
`;

export const ConsentAcceptCheckboxStyle = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
