import { camelToSnake } from 'utils';
import { Beneficiary, BeneficiaryEntityType } from './domain/beneficiary';
import { omit } from 'lodash';

export const beneficiaryToNetwork = (beneficiary: Beneficiary) => {
  const networkBeneficiary = camelToSnake({
    ...omit(
      { ...beneficiary, type: beneficiary.entityType },
      ...['beneficiaryId', ...(beneficiary.policyholderId ? ['id', 'firstName', 'lastName', 'email'] : [])],
      ...(beneficiary.entityType === BeneficiaryEntityType.Company ? ['id'] : []),
    ),
    cellphone: beneficiary.cellphone?.number,
  });
  return networkBeneficiary;
};
