/* eslint-disable no-extend-native */
import moment, { Moment } from 'moment';
import React from 'react';
import { NetworkError } from './core';

export function snakeToCamel<T = any>(data: object): T {
  return _processKeys(data, _camelize) as any;
}

export function isNotEmpty(val: any) {
  return val !== null && val !== undefined && val !== '';
}

export const camelToSnake = (data: object) => {
  return _processKeys(data, _snakelize);
};

const _snakelize = (key: string) => {
  const separator = '_';
  const split = /(?=[A-Z])/;

  return key.split(split).join(separator).toLowerCase();
};

const _camelize = (key: string) => {
  key = key.replace(/[-_\s]+(.)?/g, (match, ch) => {
    return ch ? ch.toUpperCase() : '';
  });
  // Ensure 1st char is always lowercase
  return key.substr(0, 1).toLowerCase() + key.substr(1);
};

function _processKeys(obj: any, processor: (data: string) => string): object {
  if (Array.isArray(obj)) {
    return obj.map((x) => _processKeys(x, processor));
  }

  if (typeof obj !== 'object' || moment.isMoment(obj) || obj === null || obj === undefined) {
    return obj;
  }

  const result: any = {};
  const keys = Object.keys(obj);

  for (const key of keys) {
    if (key.startsWith('_') || key === 'entityType') {
      continue;
    }

    result[processor(key)] = _processKeys(obj[key], processor);
  }

  return result;
}

declare global {
  interface Number {
    formatMoney: (currency?: string) => string;
  }

  interface String {
    snakeToTitle: () => string;
    formatMoney: (currency?: string) => string;
  }

  interface Array<T> {
    any: (predicate: (value: T, index: number, obj: T[]) => boolean) => boolean;
  }
}

Number.prototype.formatMoney = function (this: number, currency?: string) {
  return (this / 100).toLocaleString('en-ZA', {
    style: 'currency',
    currency: currency || 'ZAR',
  });
};

String.prototype.formatMoney = function (this: string, currency?: string) {
  return (parseInt(this, 10) / 100).toLocaleString('en-ZA', {
    style: 'currency',
    currency: currency || 'ZAR',
  });
};

String.prototype.snakeToTitle = function (this: string) {
  return this.trim()
    .toLowerCase()
    .split('_')
    .map((word) => {
      try {
        return word.replace(word[0], word[0].toUpperCase());
      } catch (error) {
        return word;
      }
    })
    .join(' ');
};

Array.prototype.any = function <T>(this: T[], predicate: (value: T, index: number, obj: T[]) => boolean) {
  return this.findIndex(predicate) !== -1;
};

export function guidFromString(value: string) {
  const re = /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/gi;
  const match = re.exec(value);
  return match ? match[1] : null;
}

export function getBase64FromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function downloadFileFromString(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadExampleMemberCsv(filename: string) {
  // eslint-disable-next-line max-len
  const content = `External ID,Start Date,End Date,First Name,Last Name,Email,Cellphone,Gender,Salary,ID Type,ID Number,ID Country,Date of Birth,Beneficiary 1 First Name,Beneficiary 1 Last Name,Beneficiary 1 Email,Beneficiary 1 Cellphone,Beneficiary 1 ID Type,Beneficiary 1 ID Number,Beneficiary 1 ID Country,Beneficiary 1 Percentage,Beneficiary 2 First Name,Beneficiary 2 Last Name,Beneficiary 2 Email,Beneficiary 2 Cellphone,Beneficiary 2 ID Type,Beneficiary 2 ID Number,Beneficiary 2 ID Country,Beneficiary 2 Percentage,Beneficiary 3 First Name,Beneficiary 3 Last Name,Beneficiary 3 Email,Beneficiary 3 Cellphone,Beneficiary 3 ID Type,Beneficiary 3 ID Number,Beneficiary 3 ID Country,Beneficiary 3 Percentage
EMP01,20180401,20180501,Example First Name,Example Last Name,example@example.com,0821234567,male,10000,passport,12345,ZA,19620202,,,,,,,,,,,,,,,,,,,,,,,,`;
  downloadFileFromString(filename, content);
}

const debounceItems: { [key: string]: any } = {};
export function debounce(key: string, fn: () => void, timeInMs = 300) {
  if (debounceItems[key]) {
    clearTimeout(debounceItems[key]);
  }
  debounceItems[key] = setTimeout(fn, timeInMs);
}

export const getNextBillingDate = (from: Moment, billingDay: number) => {
  const billingDateInFromMonth = getBillingDateInMonth(from.clone(), billingDay);
  if (from.isBefore(billingDateInFromMonth, 'day')) {
    return billingDateInFromMonth;
  }
  return getBillingDateInMonth(from.clone().add(1, 'month'), billingDay);
};

const getBillingDateInMonth = (from: Moment, billingDay: number) => {
  const lastDayInFromMonth = from.clone().endOf('month').get('date');
  if (billingDay >= lastDayInFromMonth) {
    return from.clone().endOf('month').startOf('day');
  }
  return from.clone().date(billingDay).startOf('day');
};

export const calculateProRata = (monthlyPremium: number, billingDay: number, from: Moment = moment()) => {
  from.startOf('day'); // include start date in calculation

  const billingDateAfterIssue = getNextBillingDate(from, billingDay);

  // use the fraction of the period between one billing period and the next
  // to calculate the fraction of the premium to use as pro-rata
  const nextBillingDate = getNextBillingDate(moment(), billingDay);
  const billingDateBeforeIssue = billingDateAfterIssue.clone().subtract(1, 'month');
  const periodBetweenIssueBillingDays = billingDateAfterIssue.diff(billingDateBeforeIssue, 'days');
  const daysFromIssueToBilling = billingDateAfterIssue.diff(from, 'days');

  const proRata = Math.round(monthlyPremium * (daysFromIssueToBilling / periodBetweenIssueBillingDays));

  const months = billingDateAfterIssue.diff(nextBillingDate, 'months');
  const accumulatedPremium = months * monthlyPremium;

  return proRata + accumulatedPremium;
};

export const intToOrdinalNumberString = (num: number) => {
  if (num === 31) {
    return 'Last day';
  }

  num = Math.round(num);
  const numString = num.toString();

  // Exception for teens
  if (Math.floor(num / 10) % 10 === 1) {
    return `${numString}th`;
  }

  // Otherwise
  switch (num % 10) {
    case 1:
      return `${numString}st`;
    case 2:
      return `${numString}nd`;
    case 3:
      return `${numString}rd`;
    default:
      return `${numString}th`;
  }
};

export const clearLocalStorage = () => {
  for (const key in localStorage) {
    if (!key.includes('_sandbox')) {
      localStorage.removeItem(key);
    }
  }
};

export const renderError = (error: NetworkError) => {
  if (!error) {
    return;
  }

  if (error.message === 'validation_error' || error.message === 'Validation failed') {
    return (error as any).errors[0].message;
  }

  return error.message;
};

export const buttonText = (params: {
  loading: boolean;
  text: string | JSX.Element;
  primary?: boolean;
  key?: string;
}) => {
  const { loading, text, primary, key } = params;
  if (loading) {
    return (
      <span key={key}>
        <span className='loading-dots' style={{ position: 'relative', left: 0 }}>
          <span className={`${(primary && 'dot-primary') || 'dot'} dot-one`} />
          <span className={`${(primary && 'dot-primary') || 'dot'} dot-two`} />
          <span className={`${(primary && 'dot-primary') || 'dot'} dot-three`} />
        </span>
      </span>
    );
  }
  return <span key={key}>{text}</span>;
};

export const isUUID = (params: { uuid?: string }) => {
  const { uuid } = params;

  if (!uuid) return false;

  return !!uuid.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
};
