import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Button, DropdownToggle } from 'reactstrap';
import { globalStyles, Spacing } from '../../global-styles';
import { Dot } from '../spinner/styles';
import { devices } from 'rootstrap/global-styles/devices';
import { Button as ReactstrapButton } from 'reactstrap';
import {
  getBorderRadius,
  getColor,
  getFontFamily,
  getFontSize,
  ProductModuleDefinitionEmbeddedConfig,
} from 'site-config';
import { Colors } from 'rootstrap/global-styles/colors';

export const buttonContents = (params: { isLoading?: boolean; children: React.ReactNode }) => {
  const { isLoading, children } = params;
  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
      <span className='loading-dots' style={{ left: 'unset' }}>
        <span className='dot dot-one' />
        <span className='dot dot-two' />
        <span className='dot dot-three' />
      </span>
    </div>
  ) : (
    <span>{children}</span>
  );
};

export enum Size {
  default = 'default',
  lg = 'lg',
}

export enum Theme {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  text = 'text',
}

export const StyledButton = styled(ReactstrapButton)<{
  size: Size;
  minWidth?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  outline: boolean;
}>`
  border-radius: 4px;
  border: none !important;
  font-weight: normal;
  color: #fff;
  height: 32px;
  line-height: 20px;
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'button' })} !important;
  min-height: 44px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({ siteConfig }) =>
    siteConfig?.styles.borderRadius.button &&
    css`
      border-radius: ${getBorderRadius({ siteConfig, borderRadius: 'button' })} !important;
      font-family: ${getFontFamily({ siteConfig, fontFamily: 'body' })};
    `}


  @media ${devices.tablet} {
    height: 32px;
  }

  ${({ size }) =>
    size === Size.lg &&
    css`
      height: 44px;
      padding-left: ${Spacing.default};
      padding-right: ${Spacing.default};
      min-width: 120px;
    `}

  ${({ size }) =>
    size === Size.default &&
    css`
      padding-left: ${Spacing.sm};
      height: ${Spacing.md};
      padding-right: ${Spacing.sm};
      min-width: 72px;
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}


    ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const PrimaryButton = styled(StyledButton)<{
  fullWidth?: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
}>`
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  border: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};

  ${({ disabled, siteConfig }) =>
    !disabled &&
    css`
      &:hover {
        background: ${getColor({ siteConfig, color: 'primary' })}80;
        border-color: ${getColor({ siteConfig, color: 'primary' })}80;
      }
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const styledButtonContents = (params: {
  wide: boolean;
  disabled: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
  backgroundColor: string;
  fontColor?: string;
  display: string | undefined;
  outline?: boolean;
  fitContentHeight?: boolean;
}) => {
  const { disabled, siteConfig, wide, backgroundColor, fontColor, display, outline, fitContentHeight } = params;
  const buttonContents = `
  box-shadow: none !important;
  border: none important;
  font-size: 16px !important;
  
  ${wide ? `width: 35%; @media${devices.mobileL} { width: 60%; }; ` : ''}
  ${fontColor ? `color: ${fontColor} !important;` : `color: ${!outline ? 'white' : backgroundColor} !important`};
  ${display ? `display: ${display};` : ''}
  ${fitContentHeight ? `height: 100%;` : ''}
  
  ${
    !disabled
      ? ` 
        background: ${!outline ? backgroundColor : 'white'} !important; 
        border: 1px solid ${backgroundColor} !important; 
        &:hover { 
          background: ${!outline ? `${backgroundColor}80` : 'white'} !important; 
          border-color: ${backgroundColor}80 !important; 
          ${!outline ? 'border: none !important;' : ''}

          ${
            fontColor
              ? `color: lighten(${fontColor}, 20%) !important;`
              : `color: ${!outline ? 'white' : backgroundColor}80 !important;`
          };
          -webkit-transition: transform 0.1s ease-in-out;
        } `
      : `background: ${getColor({ siteConfig, color: 'disabled' })} !important; `
  }`;

  return buttonContents;
};

export const SuccessButton = styled(StyledButton)<{
  disabled: boolean;
  wide: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
  display: string | undefined;
  outline: boolean;
  fitContentHeight?: boolean;
}>`
  ${({ disabled, siteConfig, wide, display, outline, fitContentHeight }) =>
    css`
      ${styledButtonContents({
        disabled,
        siteConfig,
        wide,
        backgroundColor: getColor({ siteConfig, color: 'primary' }),
        display,
        outline,
        fitContentHeight,
      })}
    `}
`;

export const SteppedFormSuccessButton = styled(SuccessButton)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  disabled: boolean;
}>`
  margin-left: 0px;
  width: 142px;
  margin-top: ${globalStyles.spacing.default};

  @media ${devices.tablet} {
    width: 100%;
  }

  background-color: ${({ siteConfig, disabled }) =>
    getColor({ siteConfig, color: !disabled ? 'primary' : 'disabled', defaultColor: Colors.Loading })} !important;
`;

export const HighlightButton = styled(StyledButton)<{
  disabled: boolean;
  wide: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
  display: string | undefined;
  outline: boolean;
}>`
  ${({ disabled, siteConfig, wide, display, outline }) =>
    css`
      ${styledButtonContents({
        disabled,
        siteConfig,
        wide,
        backgroundColor: getColor({ siteConfig, color: 'highlight' }),
        display,
        outline,
      })}
    `}
  :hover {
    color: #ffffff;
  }
`;

export const LightButton = styled(StyledButton)<{
  disabled: boolean;
  wide: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig;
  display: string | undefined;
  outline: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled, siteConfig, wide, display, outline }) =>
    css`
      ${styledButtonContents({
        disabled,
        siteConfig,
        wide,
        backgroundColor: 'white',
        fontColor: getColor({ siteConfig, color: 'primary' }),
        display,
        outline,
      })}
    `};

  span {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  }
`;

export const SecondaryButton = styled(StyledButton)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isHidden: boolean | undefined;
}>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'light' })} !important;
  border: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })} !important;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })} !important;
  display: ${({ isHidden }) => (!isHidden ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'light' })};
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    transform: scale(1.03);
    -webkit-transition: transform 0.1s ease-in-out;
  }
`;

export const DangerButton = styled(StyledButton)<{
  disabled: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  color: #fff;
  border-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'warning' })};
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'warning' })};

  ${({ disabled, siteConfig }) =>
    !disabled &&
    css`
      &:hover {
        background: ${getColor({ siteConfig, color: 'warning' })}80;
        border-color: ${getColor({ siteConfig, color: 'warning' })}80;
        transform: scale(1.03);
        -webkit-transition: transform 0.1s ease-in-out;
      }
    `}
`;

export const TextButton = styled(StyledButton)<{
  disabled: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  background: white !important;
  border: none;
  color: ${({ disabled, siteConfig }) =>
    `${
      disabled ? getColor({ siteConfig, color: 'disabled' }) : getColor({ siteConfig, color: 'primary' })
    } !important;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  box-shadow: none !important;


  ${({ disabled, siteConfig }) =>
    !disabled &&
    css`
      &:hover {
        color: ${getColor({ siteConfig, color: 'primary' })}80 !important;
      }
    `}
`;

export const BlankButton = styled(StyledButton)<{
  disabled: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'light' })} !important;
  ${({ disabled, siteConfig }) =>
    `${
      disabled &&
      css`
        color: ${getColor({ siteConfig, color: 'disabled' })} !important;
      `
    }`}
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })} !important;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })} !important;

  box-shadow: none !important;

  ${({ disabled, siteConfig }) =>
    !disabled &&
    css`
      &:hover {
        background: ${getColor({ siteConfig, color: 'light' })} !important;
        color: ${getColor({ siteConfig, color: 'primary' })}80 !important;
      }
    `}
`;

// ${({ disabled, siteConfig }) =>
// !disabled &&
// css`
//   &:hover {
//     background: ${getColor({ siteConfig, color: 'light' })} !important;
//     transform: scale(1.03);
//     -webkit-transition: transform 0.1s ease-in-out;
//   }
// `}

export const LongButtonBlank = styled(BlankButton).attrs((props) => ({
  className: props.className,
  buttonStyles: props.buttonStyles,
  color: props.color,
  outline: props.outline,
}))<{ siteConfig: ProductModuleDefinitionEmbeddedConfig }>`
  height: 45px;
  margin-bottom: ${Spacing.sm};
  font-weight: normal;
  border: 0px;

  ${({ color }) =>
    color &&
    css`
      color: ${color} !important;

      :hover {
        color: ${color} !important;
        opacity: 0.8;
      }
    `}

  ${({ buttonStyles }) =>
    buttonStyles &&
    css`
      ${buttonStyles};
    `}

    ${({ outline, color }) =>
    outline
      ? css`
          border: 1px solid ${color} !important;
        `
      : `border: none !important`}
`;

export const LongButtonSuccess = styled(SuccessButton)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  width: 100%;
  height: 45px;
  margin-bottom: ${Spacing.sm};
  margin-top: ${Spacing.lg};
  font-weight: normal;
  span {
    font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'button' })} !important;
  }
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'body' })};
  font-size: ${({ siteConfig }) => getFontSize({ siteConfig, fontSize: 'button' })} !important;

  @media ${devices.tabletL} {
    width: 100%;
  }
`;

export const DisabledButton = styled(StyledButton)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  background: #dadada;
  border-color: #dadada;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })};

  :hover {
    background: #dadada;
  }

  ${Dot} {
    background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })};
  }
`;

export const ButtonGrouping = styled.div`
  display: inline-flex;
  grid-template-columns: 1fr auto auto;

  ${StyledButton} + ${StyledButton} {
    margin-left: ${Spacing.sm};
  }
`;

export const StyledSplitButton = styled(Button)`
  border: 0.5px solid #c3c3c3;
  border-right: 0px;
  width: 90px;
  height: 34px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 0 !important;
`;

export const StyledSplitButtonDropDown = styled(DropdownToggle)`
  border: 0.5px solid #c3c3c3;
  width: auto;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 0px 1px 1px 0px;
  cursor: pointer;
`;
