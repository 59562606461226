import { SuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import styled from 'styled-components';

export const StyledPolicyDetailsActionsWrapper = styled.div`
  margin-top: 10px;
  ${SuccessButton} {
    margin-top: ${globalStyles.spacing.default};
  }
`;

export const StyledPolicyDetailsLoadingInputsWrapper = styled.div`
  margin-top: ${globalStyles.spacing.lg};
`;

export const StyledPolicyDetailsScene = styled.div<{ isLoading: boolean }>`
  padding-top: ${globalStyles.spacing.default};
  margin-top: -272px;
`;
