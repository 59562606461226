import React, { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { get } from '../../../core';

export interface SvgProps {
  href: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  style?: React.CSSProperties;
  height?: string;
  width?: string;
}

interface SvgState {
  loaded: boolean;
}

class SvgBase extends React.Component<SvgProps, SvgState> {
  constructor(props: SvgProps) {
    super(props);
    this.state = {
      loaded: false,
    };

    this.getPromise().then((svg: string) => {
      localStorage.setItem(this.props.href, svg);
      this.setState({ loaded: true });
    });
  }

  getPromise() {
    const svg = localStorage.getItem(this.props.href);
    if (svg) {
      return Promise.resolve(svg);
    }

    return get<string>({
      url: this.props.href,
      isLocal: true,
    });
  }

  render() {
    return (
      <span
        style={{ ...this.props.style }}
        className={this.props.className}
        onClick={this.props.onClick}
        dangerouslySetInnerHTML={{
          __html: this.state.loaded ? (localStorage.getItem(this.props.href) as string) : '',
        }}
      />
    );
  }
}

const Svg = styled(SvgBase)<{ height?: string; width?: string }>`
  svg {
    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}
  }
`;

export default Svg;
