import { RequestedBy } from 'models';
import { Construct } from '../../models/construct';
import moment, { Moment } from 'moment';

export class RenderCreatePaymentMethod {
  readonly collectionModuleId: string;
  readonly collectionModuleTokenId: string;
  readonly html: string;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;

  constructor(init: Construct<RenderCreatePaymentMethod>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkRenderCreatePaymentMethod): RenderCreatePaymentMethod {
    return new RenderCreatePaymentMethod({
      collectionModuleId: init.collection_module_id,
      collectionModuleTokenId: init.collection_module_token_id,
      createdAt: moment(init.created_at),
      createdBy: init.created_by,
      html: init.html,
    });
  }
}

export interface NetworkRenderCreatePaymentMethod {
  html: string;
  created_at: string;
  created_by: RequestedBy;
  collection_module_id: string;
  collection_module_token_id: string;
}
