import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Col as ReactstrapCol, Row as ReactstrapRow } from 'reactstrap';
import { Transition } from 'rootstrap/components-old/animations';
import styled from 'styled-components';
import { Icon12PXChevronDown, Icon12PXChevronUp } from 'assets/icon-12-px-chevron';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { Colors } from 'rootstrap/global-styles/colors';
import { getDateFormat } from 'shared/utils';

interface PolicySummaryProps {
  moduleData: object;
  isLoading: boolean;
  currency?: string;
  columnIndex: number;
  topDivider?: boolean;
}
interface NestedKeyValue {
  [key: string]: string | number | boolean | NestedKeyValue | [];
}
interface FormValue {
  [k: string]: string | FormValue;
}
interface FormList {
  [k: string]: FormValue[][];
}

const StyledListOrObjectContentsWrapper = styled.div`
  .module-data-divider {
    border-bottom: none;
  }

  .module-data-row {
    font-size: 14.5px;
  }

  .module-data-row & .module-data-col & .module-data-row {
    color: #777777;

    :first-child {
      margin-top: 0px;
      padding-top: 5px;
    }
  }

  .module-data-row & .module-data-col {
  }
`;

const toSentenceCase = (key: string): string => {
  const formattedKey = _.snakeCase(key).replace(/_/g, ' ');
  return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1).toLowerCase();
};

export const ModuleData = (props: PolicySummaryProps) => {
  const [showAll, setShowAll] = useState<Record<string, boolean>>({
    extended_family: false,
    spouse: false,
  });
  const { siteConfig } = useSiteConfigContext();

  const toggleShow = (key: string) => {
    setShowAll((prevState) => ({
      ...prevState,
      [key]: prevState[key] === undefined ? true : !prevState[key],
    }));
  };

  const nestedModuleDataArrayToObject = (nestedData: FormList[], key: string) => {
    const keyValuePairs: NestedKeyValue = {};
    nestedData.forEach((item: FormList, index: number) => {
      if (Array.isArray(item)) {
        const dataUnusedAndNullFiltered = item.filter((dataChild) => dataChild !== null);
        if (dataUnusedAndNullFiltered && dataUnusedAndNullFiltered.length !== 0) {
          nestedModuleDataArrayToObject(item, key);
        }
      } else {
        _.set(keyValuePairs, `${index + 1}`, item);
      }
    });
    return keyValuePairs;
  };

  const isNotNullOrUndefined = (value: string | number | undefined) => {
    try {
      if (value !== null && value !== undefined) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const nestedModuleDataRender = (params: {
    moduleData: { [key: string]: any };
    lastIndex?: boolean;
    parentKey: string | undefined;
    parentIsExpanded: boolean;
    arrayIndex?: number | undefined;
  }): JSX.Element[] => {
    const { moduleData, parentKey: incomingParentKey, parentIsExpanded, arrayIndex } = params;

    return Object.keys(moduleData)
      .map((key: string) => {
        const parentKey = incomingParentKey ? incomingParentKey : key;

        if (Array.isArray(moduleData[key])) {
          const moduleDataArrayToObject = nestedModuleDataArrayToObject(moduleData[key], key);
          const isExpanded = showAll[key];

          return (
            <>
              <ParentKeyContainer>
                <Row
                  key={key}
                  hover
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleShow(key);
                  }}
                >
                  <Col>
                    {toSentenceCase(key)}
                    {isExpanded ? (
                      <Icon12PXChevronUp
                        id={`${key}-contract`}
                        fill={getColor({ color: 'highlight', siteConfig })}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleShow(key);
                        }}
                      />
                    ) : (
                      <Icon12PXChevronDown
                        id={`${key}-expand`}
                        fill={getColor({ color: 'highlight', siteConfig })}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleShow(key);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </ParentKeyContainer>
              <StyledListOrObjectContentsWrapper>
                {isExpanded && (
                  <Row style={{ marginTop: -13, marginBottom: -13 }} lastIndex={!!parentKey} key={key}>
                    <Col>
                      {Object.keys(moduleDataArrayToObject).map((moduleDataKey, index: number) => {
                        if (Array.isArray(moduleDataArrayToObject[moduleDataKey])) {
                          const Elements = nestedModuleDataRender({
                            parentKey,
                            parentIsExpanded: isExpanded,
                            arrayIndex: index,
                            moduleData: nestedModuleDataArrayToObject(
                              (moduleDataArrayToObject as any)[moduleDataKey],
                              moduleDataKey,
                            ),
                          });

                          return (
                            <div>
                              {Elements}
                              {Elements.length > 0 && <RowBorder className='row-border' />}
                            </div>
                          );
                        }
                        if (typeof moduleDataArrayToObject[moduleDataKey] === 'object') {
                          const Elements = nestedModuleDataRender({
                            moduleData: _.pick(moduleDataArrayToObject, [moduleDataKey]),
                            parentKey,
                            lastIndex: index + 1 === Object.keys(moduleDataArrayToObject).length,
                            parentIsExpanded: isExpanded,
                            arrayIndex: index,
                          });

                          return <div>{Elements}</div>;
                        }
                        const formatted = formatData(key, moduleDataArrayToObject[moduleDataKey]);

                        if (formatted === '' || formatted === null || formatted === undefined) {
                          return <></>;
                        }

                        return (
                          <Row key={moduleDataKey}>
                            <Col style={{ textAlign: 'left' }}>
                              <Transition in={!props.isLoading} width={100} percent>
                                <StyledValue siteConfig={siteConfig}>{formatted}</StyledValue>
                              </Transition>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                )}
              </StyledListOrObjectContentsWrapper>
            </>
          );
        }
        if (isNotNullOrUndefined(moduleData[key]) && typeof moduleData[key] === 'object') {
          const dataObject = moduleData[key];
          const showLabel = !parseInt(key);

          const getKey = getKeyFromParentAndIndex({
            index: arrayIndex,
            key,
            parentKey,
          });
          const isExpanded = showAll[getKey];

          return (
            <>
              {showLabel && (
                <ParentKeyContainer>
                  <Row
                    key={key}
                    hover
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleShow(getKey);
                    }}
                  >
                    <Col>
                      {toSentenceCase(key)}{' '}
                      {isExpanded ? (
                        <Icon12PXChevronUp
                          id={`${key}-contract`}
                          fill={getColor({ color: 'highlight', siteConfig })}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleShow(getKey);
                          }}
                        />
                      ) : (
                        <Icon12PXChevronDown
                          id={`${key}-expand`}
                          fill={getColor({ color: 'highlight', siteConfig })}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleShow(getKey);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </ParentKeyContainer>
              )}
              <ReactstrapCol />
              {(isExpanded || parentIsExpanded) && (
                <StyledListOrObjectContentsWrapper>
                  <Row noPadding>
                    <Col sm={12}>
                      {Object.keys(dataObject).map((key: string, index: number) => {
                        const lastIndex = index + 1 === Object.keys(dataObject).length;
                        if (typeof dataObject[key] === 'object') {
                          const Elements = nestedModuleDataRender({
                            moduleData: _.pick(dataObject, [key]),
                            parentKey,
                            parentIsExpanded: false,
                            arrayIndex,
                          });

                          return (
                            <div>
                              {Elements}
                              {Elements.length > 0 && <RowBorder />}
                            </div>
                          );
                        }
                        const formatted = formatData(key, dataObject[key]);
                        const Elements = rowComponent({
                          key,
                          formatted,
                          lastIndex: lastIndex,
                          boldLabel: showLabel,
                        });
                        return (
                          <div>
                            {Elements}
                            {lastIndex && <RowBorder />}
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </StyledListOrObjectContentsWrapper>
              )}
            </>
          );
        }
        const formatted = formatData(key, moduleData[key]);

        return rowComponent({ key, formatted });
      })
      .filter((val: object) => val !== undefined);
  };

  const rowComponent = (params: {
    key: string;
    formatted: string | number | boolean | null;
    lastIndex?: boolean;
    boldLabel?: boolean;
  }) => {
    const { key, formatted, lastIndex } = params;

    return (
      <Row lastIndex={!!lastIndex}>
        <Col key={key}>
          <Transition in={!props.isLoading} width={100} percent>
            {toSentenceCase(key)}
          </Transition>
        </Col>
        <Col>
          <Transition in={!props.isLoading} width={100} percent>
            <StyledValue siteConfig={siteConfig}>{formatted}</StyledValue>
          </Transition>
        </Col>
      </Row>
    );
  };

  const getKeyFromParentAndIndex = (params: {
    parentKey: string | undefined;
    key: string;
    index: number | undefined;
  }): string => {
    const { index, key, parentKey } = params;

    if (index !== undefined && parentKey !== undefined) {
      return `${parentKey}_${index}_${key}`;
    }
    if (parentKey !== undefined) {
      return `${parentKey}_${key}`;
    }

    return key;
  };

  const renderNestedModuleData = (moduleData: object, modulusOn: number) => {
    const nestedDataRenderComponents = nestedModuleDataRender({
      moduleData: _.pickBy(moduleData, (o) => typeof o === 'object'),
      parentKey: undefined,
      parentIsExpanded: false,
    });
    return nestedDataRenderComponents;
  };

  const renderFlatModuleData = (moduleData: object, modulusOn: number) => {
    const flatData = Object.keys(moduleData).filter((k) => !_.isObject(k));

    return flatData.sort().map((key) => {
      const formatted = formatData(key, (moduleData as any)[key]);
      if (formatted === null) {
        return null;
      }
      return (
        <Row lastIndex={false} key={key}>
          <Col>
            <Transition in={!props.isLoading} width={100} percent>
              <ParentKeyContainer>{toSentenceCase(key)}</ParentKeyContainer>
            </Transition>
          </Col>
          <Col style={{ float: 'left' }}>
            <Transition in={!props.isLoading} width={100} percent>
              <StyledValue siteConfig={siteConfig}>{formatted}</StyledValue>
            </Transition>
          </Col>
        </Row>
      );
    });
  };

  const formatData = (key: string, value: string | number | boolean | [] | {}) => {
    if (typeof value === 'string') {
      if (moment(value, moment.ISO_8601).isValid()) {
        return moment(value).format(`${getDateFormat()}`);
      }

      if (key === 'gender') {
        if (!value[0]) {
          return '';
        }
        return value[0].toUpperCase() + value.substr(1);
      }

      if (value && value.includes('_')) {
        return value
          .split('_')
          .map((x, i) => (i === 0 ? x[0].toUpperCase() : x[0]) + x.substr(1))
          .join(' ');
      }

      return value;
    }

    if (typeof value === 'number') {
      if (
        key.toLowerCase().includes('premium') ||
        key.toLowerCase().includes('amount') ||
        key.toLowerCase().includes('income') ||
        key.toLowerCase().includes('assured') ||
        key.toLowerCase().includes('value') ||
        key.toLowerCase().includes('fund')
      ) {
        return value.formatMoney(props.currency || 'ZAR');
      }

      return value;
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return null;
  };

  const { moduleData, columnIndex } = props;
  return (
    <>
      {props.topDivider && <RowBorder />}
      {[...renderFlatModuleData(moduleData, columnIndex), ...renderNestedModuleData(moduleData, columnIndex)]}
    </>
  );
};

export const ModuleDataWrapper = (props: { children: React.ReactNode }) => {
  return (
    <table>
      <tbody className='fader hover-no-background'>{props.children}</tbody>
    </table>
  );
};

const StyledValue = styled.span<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  color: ${({ siteConfig }) => getColor({ color: 'primary', siteConfig })};
`;

const Col = (params: { children: any; style?: React.CSSProperties; sm?: string | number }) => {
  const { sm } = params;
  return (
    <>
      <StyledCol className='module-data-col' sm={sm} style={params.style}>
        {params.children}
      </StyledCol>
    </>
  );
};

const StyledCol = styled(ReactstrapCol)`
  padding-left: 0px;
  padding-right: 0px;
`;

const Row = (params: {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  hover?: boolean;
  style?: React.CSSProperties;
  lastIndex?: boolean;
  noPadding?: boolean;
}) => {
  const { children, onClick, hover, style, lastIndex, noPadding } = params;

  return (
    <>
      <StyledRow
        className='module-data-row'
        style={{
          ...style,
          cursor: hover ? 'pointer' : 'default',
        }}
        onClick={onClick}
        noPadding={noPadding || false}
      >
        {children}
      </StyledRow>
      {!lastIndex && <RowBorder className='module-data-divider' />}
    </>
  );
};

const StyledRow = styled(ReactstrapRow)<{ noPadding: boolean }>`
  padding-top: ${({ noPadding }) => (!noPadding ? '13px' : '0px')};
  padding-bottom: ${({ noPadding }) => (!noPadding ? '13px' : '0px')};
  margin-right: 0px !important;
  margin-left: 0px !important;

  div {
    svg {
      margin-left: 13px;
    }
  }

  &:last-child {
    border-bottom: none !important;
  }
`;

const RowBorder = styled.div`
  border-bottom: 1px solid #dedede;
`;

export const Td = styled.td`
  padding-bottom: 13px;
  padding-top: 13px;
  width: 50%;
`;

const ParentKeyContainer = styled.div`
  color: ${Colors.Body};
`;
