import React from 'react';
import Joi from 'joi';
import { upperFirst } from 'lodash';
import { InputField, InputFieldParams } from '../input-field';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';

export const validateWithJoi = (value: unknown, joi: Joi.Schema) => {
  const errorMessage = joi.validate(value).error?.message;
  return errorMessage ? upperFirst(errorMessage.replace('"value" ', '')) : undefined;
};

export const EmailField = ({ ...props }: Omit<InputFieldParams<string>, 'type'>) => {
  const { displayProperties } = props;
  return (
    <InputField
      {...props}
      displayProperties={displayProperties}
      validators={[
        {
          validation: {
            type: ValidationTypes.EMAIL,
          },
        },
      ]}
    />
  );
};
