import { EmbedSessionPrefillData, NetworkEmbedSessionPrefillData } from 'embed-sessions/domain/embed-session-prefill-data';
import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  embedSessionId: string;
}

export const getEmbedSessionPrefillData = async (params: Params) => {
  const { auth, environment, organizationId, embedSessionId } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/embed-sessions/${embedSessionId}/prefill-data`,
    method: 'get',
    auth,
  });

  const networkEmbedSessionPrefillData: NetworkEmbedSessionPrefillData = await response.json();
  return EmbedSessionPrefillData.fromNetwork(networkEmbedSessionPrefillData);
};
