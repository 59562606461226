import { Construct } from 'models/construct';
import { PersonalDetailsPrefillParams } from 'shared/domain/prefill-values';
import { JSONObject } from 'shared/utils';


export interface EmbedSessionPrefillValuesObject {
  screeningQuestions: JSONObject | undefined;
  quote: JSONObject | undefined;
  prePersonalDetailsConsent: boolean | undefined;
  personalDetails: PersonalDetailsPrefillParams | undefined;
  application: JSONObject | undefined;
  prePaymentDetailsConsent: boolean | undefined;
  payment: JSONObject | undefined;
}
export class EmbedSessionPrefillData {
  readonly embedSessionPrefillDataId: string;
  readonly embedSessionId: string;
  readonly organizationId: string;
  readonly prefillData: EmbedSessionPrefillValuesObject

  constructor(init: Construct<EmbedSessionPrefillData>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkEmbedSessionPrefillData): EmbedSessionPrefillData {
    return new EmbedSessionPrefillData({
      embedSessionPrefillDataId: init.embed_session_prefill_data_id,
      embedSessionId: init.embed_session_id,
      organizationId: init.organization_id,
      prefillData: {
        screeningQuestions: init.prefill_data.screening_questions,
        quote: init.prefill_data.quote,
        prePersonalDetailsConsent: init.prefill_data.pre_personal_details_consent,
        personalDetails: init.prefill_data.personal_details,
        application: init.prefill_data.application,
        prePaymentDetailsConsent: init.prefill_data.pre_payment_details_consent,
        payment: init.prefill_data.payment,
      }
    });
  }
}

export class NetworkEmbedSessionPrefillData {
  embed_session_prefill_data_id: string;
  embed_session_id: string;
  organization_id: string;
  prefill_data: NetworkEmbedSessionPrefillDataObject;
}

export interface NetworkEmbedSessionPrefillDataObject {
  screening_questions: JSONObject | undefined;
  quote: JSONObject | undefined;
  pre_personal_details_consent: boolean | undefined;
  personal_details: PersonalDetailsPrefillParams | undefined;
  application: JSONObject | undefined;
  pre_payment_details_consent: boolean | undefined;
  payment: JSONObject | undefined;
}
