import { mergeWith } from 'lodash';
import { JSONObject } from 'shared/utils';

export const getApplicationListDefault = (params: {
  clonedQuoteSchemaInputData: JSONObject | undefined;
  clonedApplicationInputData: JSONObject | undefined;
}) => {
  const { clonedApplicationInputData, clonedQuoteSchemaInputData } = params;

  return mergeWith({}, clonedQuoteSchemaInputData, clonedApplicationInputData, customArrayMerge);
};

function customArrayMerge(objValue: any, srcValue: any): any[] | undefined {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    const mergedArray = objValue.map((item, index) => {
      if (srcValue[index]) {
        return mergeWith({}, item, srcValue[index], customArrayMerge);
      }
      return item;
    });
    return mergedArray.slice(0, objValue.length);
  }
}
