import { App } from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthTypes, useAuth } from './shared/api';
import { Helmet } from 'react-helmet';

const AppBody = () => {
  const auth = useAuth();

  const path = auth.type === AuthTypes.JWT ? '/organization/:organization_id' : '/:product_module_key';

  return (
    <Route path={path}>
      <App />
    </Route>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppBody />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
