import React from 'react';
import { globalStyles, Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import styled from 'styled-components';

export const LandingPageError = (props: { error: Error }) => {
  const { error } = props;
  const isEnvironmentError = error.message === 'This feature is only available in the sandbox environment.';

  const errorMessage = isEnvironmentError ? error.message : `We could not find the page you're looking for.`;

  const callToAction = () => {
    if (isEnvironmentError) {
      window.location.href = 'https://docs.rootplatform.com/docs/embed-implementation';
    } else {
      window.location.reload();
    }
  };

  const callToActionText = isEnvironmentError ? 'See the docs' : 'Reload the page';

  return (
    <DimmedBackGround>
      <ErrorContainer>
        <p className='top-header'>OOPS!</p>
        <h3>Something went wrong...</h3>
        <p className='description'>{errorMessage}</p>
        <LandingPageErrorButton onClick={callToAction}>{callToActionText}</LandingPageErrorButton>
      </ErrorContainer>
    </DimmedBackGround>
  );
};

const ErrorContainer = styled.div`
  width: 30%;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 30px 20px;

  @media${devices.laptopL} {
    width: 37%;
  }

  @media${devices.tabletL} {
    width: 70%;
  }

  @media${devices.mobileL} {
    width: 90%;
  }

  .top-header {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: ${globalStyles.fontSize.body};
  }

  h3 {
    font-size: 25px;
    margin-bottom: 1.5rem;
  }

  .description {
    font-size: 20px;
    margin-bottom: 38px;
  }
`;

const DimmedBackGround = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #f6f6f6;
  height: 100%;
  width: 100%;
  top: 0;
`;

const LandingPageErrorButton = styled.button`
  color: #000000;
  background-color: white;
  height: ${Spacing.xl};
  border-radius: 25px;
  padding: 10px 40px;
  font-weight: 600;
  border: 1px solid #000000;
`;
