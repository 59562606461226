import React from 'react';

export const PencilIcon = (props: { fill?: string; style?: React.CSSProperties }) => {
  const { fill, style } = props;
  return (
    <svg style={style} width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.94919 3.32737L13.204 6.66856L4.96526 15.1261L1.71234 11.7849L9.94919 3.32737ZM15.6737 2.52155L14.2222 1.0315C13.6612 0.455646 12.7504 0.455646 12.1875 1.0315L10.7971 2.45882L14.0519 5.80004L15.6737 4.13514C16.1088 3.68848 16.1088 2.96819 15.6737 2.52155ZM0.00905723 16.4812C-0.0501752 16.7549 0.190506 17.0001 0.457113 16.9335L4.084 16.0308L0.831082 12.6896L0.00905723 16.4812Z'
        fill={fill}
      />
    </svg>
  );
};
