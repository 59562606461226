import React from 'react';

export const BinIcon = (props: { fill?: string; style?: React.CSSProperties }) => {
  const { fill, style } = props;
  return (
    <svg style={style} width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_10772_191338)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.75 18.9312H2.25C1.85218 18.9312 1.47064 18.769 1.18934 18.4802C0.908035 18.1915 0.75 17.7998 0.75 17.3914V5.07266H14.25V17.3914C14.25 17.7998 14.092 18.1915 13.8107 18.4802C13.5294 18.769 13.1478 18.9312 12.75 18.9312ZM6 8.15234C6 7.94815 5.92098 7.75231 5.78033 7.60793C5.63968 7.46354 5.44891 7.38242 5.25 7.38242C5.05109 7.38242 4.86032 7.46354 4.71967 7.60793C4.57902 7.75231 4.5 7.94815 4.5 8.15234V15.0816C4.5 15.2858 4.57902 15.4817 4.71967 15.6261C4.86032 15.7704 5.05109 15.8516 5.25 15.8516C5.44891 15.8516 5.63968 15.7704 5.78033 15.6261C5.92098 15.4817 6 15.2858 6 15.0816V8.15234ZM10.5 8.15234C10.5 7.94815 10.421 7.75231 10.2803 7.60793C10.1397 7.46354 9.94891 7.38242 9.75 7.38242C9.55109 7.38242 9.36032 7.46354 9.21967 7.60793C9.07902 7.75231 9 7.94815 9 8.15234V15.0816C9 15.2858 9.07902 15.4817 9.21967 15.6261C9.36032 15.7704 9.55109 15.8516 9.75 15.8516C9.94891 15.8516 10.1397 15.7704 10.2803 15.6261C10.421 15.4817 10.5 15.2858 10.5 15.0816V8.15234ZM15 4.30273H0V2.76289H4.5V1.60801C4.5 1.30171 4.61853 1.00796 4.8295 0.791382C5.04048 0.5748 5.32663 0.453125 5.625 0.453125L9.375 0.453125C9.67337 0.453125 9.95952 0.5748 10.1705 0.791382C10.3815 1.00796 10.5 1.30171 10.5 1.60801V2.76289H15V4.30273ZM6 2.76289H9V1.99297H6V2.76289Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_10772_191338'>
          <rect width='15' height='18.4781' fill='white' transform='translate(0 0.453125)' />
        </clipPath>
      </defs>
    </svg>
  );
};
