import React from 'react';
import { Modal, ModalBody } from 'rootstrap/components/modal';
import { CardBody } from 'rootstrap/components/card';
import { HighlightButton, LongButtonBlank, Size } from 'rootstrap/components/button/styles';
import { useSiteConfigContext } from 'style-context';
import { getColor } from 'site-config';
import { globalStyles, Spacing } from 'rootstrap/global-styles';
import styled from 'styled-components';
import { Beneficiary } from 'beneficiaries/domain/beneficiary';
import { Icon24PxCross } from 'assets/icon-24-px-cross';

interface DeleteBeneficiaryModalParams {
  setBeneficiaries: (beneficiaries: Beneficiary[]) => void;
  beneficiary: Beneficiary | undefined;
  beneficiaries: Beneficiary[];
  closeModal: () => void;
  onSuccess?: () => void;
}

export const DeleteBeneficiaryModal = (params: DeleteBeneficiaryModalParams) => {
  const { siteConfig } = useSiteConfigContext();
  const highlight = getColor({ siteConfig, color: 'highlight' });
  const { beneficiaries, beneficiary, setBeneficiaries, closeModal, onSuccess } = params;

  const onBeneficiaryRemovedClicked = () => {
    const beneficiaryIsAtIndex = beneficiaries.findIndex(
      ({ beneficiaryId }) => beneficiaryId === beneficiary?.beneficiaryId, // there is a case where no active beneficiary and then this will not do anything
    );

    beneficiaries.splice(beneficiaryIsAtIndex, 1);

    setBeneficiaries(beneficiaries);
    onSuccess && onSuccess();
    return closeModal();
  };

  return (
    <Modal centered isOpen fullWidthOnMobile>
      <ModalBody>
        <CardBody style={{ padding: Spacing.md, textAlign: 'center' }}>
          <StyledIcon24PxCrossWrapper>
            <StyledIcon24PxCross
              fillColor={globalStyles.colors.Body}
              height={16}
              width={16}
              onClick={params.closeModal}
            />
          </StyledIcon24PxCrossWrapper>
          <StyledH5>Remove {beneficiary?.fullname()}</StyledH5>
          <p>Are you sure you want to remove this beneficiary</p>
          <DeleteBeneficiary>
            <LongButtonBlank
              style={{ marginBottom: 0, marginTop: 0 }}
              color={highlight}
              siteConfig={siteConfig}
              onClick={params.closeModal}
              outline
            >
              Back
            </LongButtonBlank>
            <HighlightButton
              id='confirm-delete-beneficiary'
              siteConfig={siteConfig}
              fullWidth
              size={Size.lg}
              onClick={onBeneficiaryRemovedClicked}
            >
              Remove
            </HighlightButton>
          </DeleteBeneficiary>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

const StyledIcon24PxCrossWrapper = styled.div`
  float: right;
  cursor: pointer;
`;

const StyledIcon24PxCross = styled(Icon24PxCross)`
  float: right;
  cursor: pointer;
`;

const DeleteBeneficiary = styled.div`
  display: grid;
  grid-column-gap: ${Spacing.default};
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
`;

const StyledH5 = styled.h5`
  font-size: 20px;
`;
