export enum Relations {
  MainMember = 'main_member',
  Spouse = 'spouse',
  Parent = 'parent',
  Son = 'son',
  Daughter = 'daughter',
  AuntOrUncle = 'aunt_or_uncle',
  Grandparent = 'grandparent',
  CousinRelative = 'cousin_or_relative',
  Brother = 'brother',
  Sister = 'sister',
  Employer = 'employer',
  Policyholder = 'policyholder',
  CreditProvider = 'credit_provider',
  Cessionary = 'cessionary',
  Estate = 'estate',
  Trust = 'trust',
  GuardianFund = 'guardian_fund',
  FuneralParlour = 'funeral_parlour',
  SonInLaw = 'son_in_law',
  DaughterInLaw = 'daughter_in_law',
  MotherInLaw = 'mother_in_law',
  FatherInLaw = 'father_in_law',
  Grandchild = 'grandchild',
  NieceOrNephew = 'niece_or_nephew',
  Other = 'other',
}

export enum BeneficiaryRelations {
  MainMember = 'main_member',
  Spouse = 'spouse',
  Parent = 'parent',
  Son = 'son',
  Daughter = 'daughter',
  AuntOrUncle = 'aunt_or_uncle',
  Grandparent = 'grandparent',
  CousinRelative = 'cousin_or_relative',
  Brother = 'brother',
  Sister = 'sister',
  Employer = 'employer',
  CreditProvider = 'credit_provider',
  Cessionary = 'cessionary',
  Estate = 'estate',
  Trust = 'trust',
  GuardianFund = 'guardian_fund',
  FuneralParlour = 'funeral_parlour',
  SonInLaw = 'son_in_law',
  DaughterInLaw = 'daughter_in_law',
  MotherInLaw = 'mother_in_law',
  FatherInLaw = 'father_in_law',
  Grandchild = 'grandchild',
  NieceOrNephew = 'niece_or_nephew',
  Other = 'other',
}

export const orderedBeneficiaryRelationshipOptions: BeneficiaryRelations[] = [
  BeneficiaryRelations.MainMember,
  BeneficiaryRelations.Spouse,
  BeneficiaryRelations.Daughter,
  BeneficiaryRelations.Son,
  BeneficiaryRelations.Parent,
  BeneficiaryRelations.Sister,
  BeneficiaryRelations.Brother,
  BeneficiaryRelations.Grandchild,
  BeneficiaryRelations.Grandparent,
  BeneficiaryRelations.AuntOrUncle,
  BeneficiaryRelations.CousinRelative,
  BeneficiaryRelations.NieceOrNephew,
  BeneficiaryRelations.DaughterInLaw,
  BeneficiaryRelations.SonInLaw,
  BeneficiaryRelations.MotherInLaw,
  BeneficiaryRelations.FatherInLaw,
  BeneficiaryRelations.Employer,
  BeneficiaryRelations.CreditProvider,
  BeneficiaryRelations.Cessionary,
  BeneficiaryRelations.Estate,
  BeneficiaryRelations.Trust,
  BeneficiaryRelations.GuardianFund,
  BeneficiaryRelations.FuneralParlour,
  BeneficiaryRelations.Other,
];
