import React from 'react';
import {
  FormOverlayFooterWrapper,
  OverlayFormInnerContentExtendedWidth,
} from 'rootstrap/components-old/forms/form-overlay';
import { globalStyles } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { getColor, ProductModuleDefinitionEmbeddedConfig, ProductModuleEmbedConfigSupportType } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

interface Props {
  children: JSX.Element | string;
  id?: string;
}

export enum BannerTypes {
  Success = 'success',
  Secondary = 'secondary',
  Warning = 'warning',
  Error = 'error',
}

export const PolicyIssuingBanner = (props: Props) => {
  const { siteConfig } = useSiteConfigContext();
  return (
    <PolicyIssuingBannerStyle siteConfig={siteConfig} className='overlay-form-footer'>
      <OverlayFormInnerContentExtendedWidth className={'overlay-form-inner-content-extended-width'}>
        <div className='spacer-left' />
        <StyledPolicyIssuingBanner id={props.id} siteConfig={siteConfig}>
          {props.children}
        </StyledPolicyIssuingBanner>{' '}
        <div className='spacer-right' />
      </OverlayFormInnerContentExtendedWidth>
    </PolicyIssuingBannerStyle>
  );
};

const PolicyIssuingBannerStyle = styled(FormOverlayFooterWrapper)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  margin-top: 35px;
  margin-bottom: 35px;
  background: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  align-items: flex-start;
`;

const StyledPolicyIssuingBanner = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  border-radius: 4px;
  display: flex;
  width: 100%;
  min-height: 44px;
  align-items: center;
  justify-content: center;
  font-size: ${globalStyles.fontSize.body};
  border-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  color: ${Colors.Subscript};
  span {
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
`;

export const SupportEmailLinkTag = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => {
  const { siteConfig } = params;
  const links = siteConfig?.quote.links;
  const supportType = siteConfig?.quote.links.supportType;
  const { Email } = ProductModuleEmbedConfigSupportType;

  const supportLinkText = (supportType === Email ? links?.supportEmail : links?.supportUrl?.label) || '';
  const supportLink = (supportType === Email ? `mailto:${links?.supportEmail}` : links?.supportUrl?.url) || '';

  return (
    <a href={supportLink} style={{ color: getColor({ siteConfig, color: 'highlight' }) }}>
      {supportLinkText}
    </a>
  );
};
