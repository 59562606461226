import { Spacing } from 'rootstrap/global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';

export const FormDetailsWrapper = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  padding: ${Spacing.default};
  margin-bottom: ${Spacing.default};
  border-radius: 4px;
`;
