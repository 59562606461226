import * as React from 'react';

export const CheckCircle = (props: { color: string }) => (
  <svg id='a' xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 30.9 30.9'>
    <defs></defs>
    <path
      fill={props.color}
      d='M15.45,.45C7.16,.45,.45,7.16,.45,15.45s6.72,15,15,15,15-6.72,15-15S23.73,.45,15.45,.45Zm8.45,11.5l-10,10c-.39,.39-1.02,.39-1.41,0l-5.49-5.49c-.39-.39-.39-1.02,0-1.41l1.59-1.59c.39-.39,1.02-.39,1.41,0l3.19,3.19,7.7-7.7c.39-.39,1.02-.39,1.41,0l1.59,1.59c.39,.39,.39,1.02,0,1.41Z'
    />
  </svg>
);
