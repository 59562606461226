import { CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';
import {
  NetworkRenderCreatePaymentMethod,
  RenderCreatePaymentMethod,
} from 'collection-modules/domain/render-create-payment-method';
import { Environment } from 'models/environment';
import { AuthTypes, EmbedAuth, apiRequest, getApiHost } from 'shared/api';
import { buildApiUrl } from 'shared/utils';

interface UpdateApplicationParams {
  policyholderId: string;
  applicationId?: string;
  policyId?: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  collectionModuleKey: string;
  version: CollectionModuleDefinitionVersion | undefined;
}

export const renderCreatePaymentMethod = async (params: UpdateApplicationParams) => {
  const { applicationId, policyholderId, auth, environment, organizationId, policyId, collectionModuleKey, version } =
    params;
  const queryParams = {
    policy_id: policyId,
    application_id: applicationId,
    policyholder_id: policyholderId,
    ...(version ? { version } : {}),
  };

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/collection-modules/${collectionModuleKey}/render-create-payment-method`
      : `/v1/insurance/collection-modules/${collectionModuleKey}/render-create-payment-method`;

  const response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${url}`, queryParams),
    method: 'get',
    auth,
  });

  const networkRenderCreatePaymentMethod: NetworkRenderCreatePaymentMethod = await response.json();
  return RenderCreatePaymentMethod.fromNetwork(networkRenderCreatePaymentMethod);
};
