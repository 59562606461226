import React from 'react';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';

export interface SiteConfigState {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  setSiteConfig: React.Dispatch<React.SetStateAction<ProductModuleDefinitionEmbeddedConfig | undefined>>;
}

export const SiteConfigContext = React.createContext<SiteConfigState | undefined>(undefined);

export function useSiteConfigContext() {
  const context = React.useContext(SiteConfigContext);
  if (context === undefined) {
    throw new Error('useStyleContext must be used within StyleContext.Provider');
  }
  return context;
}