import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputFieldParams } from './input-field';
import { useSiteConfigContext } from 'style-context';
import { getFieldError, getNextComponentId, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, SteppedFormButton } from './utils/stepped-form';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { ValidationTypes, getValidationMessage } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { Field } from '../new-field';
import { Colors } from 'rootstrap/global-styles/colors';
import { usePromise } from 'shared/hooks/promise';
import { ThemedAlert } from 'rootstrap/components/alerts/alert';
import { StyledInput } from './styled-input';
import { Address } from 'policyholder/domain/policyholder';
import SearchIcon from '../../../../assets/search-icon.svg';
import { getPublicKey } from 'public-keys/actions/get-public-key';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { getAddressStringForFetchifyDefault } from 'policy-issuing/personal-details/utils/fetchify-helpers';
import { isMobile } from 'react-device-detect';

declare global {
  interface Window {
    clickToAddress: any;
  }
}

interface ClickToAddress {
  new (config: any): any;
}

export const FetchifyAddressField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  form,
  prefillAction,
  prefillValue,
  helpText,
  disableActiveElement,
  hideDivider,
  displayProperties,
  disableScrollToElement,
  validators,
  disableNextButton,
  isTouched: defaultIsTouched,
  submitOnChange,
  disableTitle,
  hideBorder,
  onPlaceSelected,
  isSuburbRequired,
  hiddenComponent,
  isLastComponent,
  isLastStep,
}: InputFieldParams<string> & {
  isSuburbRequired: boolean;
  onPlaceSelected: (address: Address) => void;
}) => {
  const [autoCompleteError, setAutoCompleteError] = useState<string | undefined>(undefined);
  const [, setRerender] = useState(0);

  const [isTouched, setTouched] = useState<boolean>(!!prefillValue || defaultIsTouched);
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );

  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  // Clean up Fetchify instance and remove it from the DOM
  const cleanUpFetchify = () => {
    const inputElement = document.getElementById(name);
    const existingInstance = document.getElementById('cc_c2a');
    if (inputElement) {
      inputElement.removeAttribute('data-clicktoaddress'); // Ensure it's reinitialized next time
    }
    if (existingInstance) {
      existingInstance.remove(); // Remove the Fetchify DOM element
    }
  };

  useEffect(() => {
    setTouched(defaultIsTouched);
  }, [defaultIsTouched]);

  // Attach ClickToAddress to the input field
  const attachClickToAddress = (config: any): void => {
    const inputElement = document.getElementById(name);

    // Check if ClickToAddress is already applied
    if (inputElement?.getAttribute('data-clicktoaddress') === 'true') {
      console.warn('ClickToAddress already applied to this element!');
      return;
    }

    // Apply ClickToAddress if it hasn't been applied
    try {
      const cc = new (window.clickToAddress as ClickToAddress)(config);
      cc.attach();
      inputElement?.setAttribute('data-clicktoaddress', 'true'); // Mark as applied
    } catch (error) {
      console.error('Error loading ClickToAddress:', error);
    }
  };

  // Fetch the public key and initialize the ClickToAddress functionality
  const { error, isLoading } = usePromise(async () => {
    const { key } = await getPublicKey({
      auth,
      environment,
      organizationId,
      type: 'fetchify',
    });

    const config = {
      accessToken: key,
      historyTools: false,
      disableAutoSearch: false,
      dom: {
        search: name,
      },
      geocode: true,
      texts: {
        default_placeholder: 'Type an address',
        country_placeholder: 'Type here to search for a country',
        placeholder_country_overrides: {},
        country_name_overrides: {},
        country_button: 'Change country',
        generic_error: 'An error occurred. Please enter your address manually',
        no_results: 'No results found',
      },
      onResultSelected: (c2a: any, elements: any, address: any) => {
        const selectedAddress = {
          line1: `${address.line_1}${address.line_2 ? ` ${address.line_2}` : ''}`,
          line2: undefined,
          suburb: address.dependent_locality,
          city: address.locality,
          areaCode: address.postal_code,
          country: address.country.short_code.toUpperCase() || address.country.country_name,
        };

        try {
          onPlaceSelected(selectedAddress);
          setAutoCompleteError(undefined);
          setTouched(true);

          if (!fieldError) {
            setActiveElement &&
              setActiveElement({
                elementId: getNextComponentId({ ...displayProperties, key: name }),
              });
          }
        } catch (error: any) {
          if (error?.message) {
            setAutoCompleteError(error.message);
          } else {
            setAutoCompleteError('There was an error updating your address');
          }
        }
      },
      showLogo: false,
      showSearch: false,
      gfxMode: 1,
      style: { font: 'inherit' },
      domMode: 'id',
    };

    attachClickToAddress(config);

    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: false,
    });
  }, []);

  // Ensure that the Fetchify instance is fully cleaned up when component unmounts
  useEffect(() => {
    return () => {
      cleanUpFetchify(); // Clean up Fetchify on component unmount
    };
  }, []);

  useLayoutEffect(() => {
    const adjustDropdownPosition = () => {
      const dropdownElement = document.getElementById('cc_c2a') as HTMLElement;
      const inputElement = document.getElementById(name) as HTMLElement;

      if (dropdownElement && inputElement) {
        const inputRect = inputElement.getBoundingClientRect();
        dropdownElement.style.position = 'fixed';
        dropdownElement.style.top = `${inputRect.bottom - 1}px`;
      }
    };

    const overlayFormElement = document.getElementById('form-overlay-content-wrapper') as HTMLElement;

    if (overlayFormElement) {
      overlayFormElement.addEventListener('scroll', adjustDropdownPosition);
      window.addEventListener('resize', adjustDropdownPosition);

      const observer = new MutationObserver(() => {
        adjustDropdownPosition();
      });

      observer.observe(overlayFormElement, { attributes: true, childList: true, subtree: true });

      // Call once to set the initial position
      adjustDropdownPosition();

      return () => {
        overlayFormElement.removeEventListener('scroll', adjustDropdownPosition);
        window.removeEventListener('resize', adjustDropdownPosition);
        observer.disconnect();
      };
    }
  }, [name]);

  useEffect(() => {
    error && setAutoCompleteError(error.message);
  }, [error]);

  return (
    <>
      <div id={name ? `${name}-form-group` : undefined}>
        <StyledRootSchemaComponent
          className='schema-component'
          isActive={isActive}
          disableActiveElement={disableActiveElement}
          isFirstElement={displayProperties.index === 0}
          hiddenComponent={hiddenComponent}
          isMobile={isMobile}
        >
          <Field
            isTouched={isTouched}
            hiddenComponent={hiddenComponent}
            disableTitle={disableTitle}
            isActive={isActive}
            name={name}
            onClick={() => setActiveElement({ elementId: name })}
            label={label}
            errors={fieldError ? [fieldError] : []}
            isRequired={isRequired}
            helpText={helpText}
            style={{
              color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
              fontSize: 16,
            }}
          >
            <Controller
              name={name}
              control={form?.control}
              defaultValue={defaultValue}
              rules={{
                required: isRequired ? 'Required' : undefined,
                validate: (value) =>
                  getValidationMessage({
                    validators: validators,
                    value,
                    props: undefined,
                  }),
              }}
              render={({ onChange, value }) => {
                return (
                  <>
                    <StyledInput
                      id={name}
                      icon={SearchIcon}
                      isTouched={isTouched}
                      siteConfig={siteConfig}
                      isActive={isActive}
                      name={name}
                      fullWidth
                      onChange={onChange}
                      value={value}
                      disableActiveElement={undefined}
                      disabled={isLoading}
                    />
                    {autoCompleteError && (
                      <div style={{ marginTop: 10 }}>
                        <ThemedAlert id='places-autocomplete-error' body={autoCompleteError} />
                      </div>
                    )}
                  </>
                );
              }}
            />
          </Field>
          {!hiddenComponent && (
            <SteppedFormButton
              isLastStep={isLastStep}
              isLastComponent={isLastComponent}
              disableActiveElement={!!disableActiveElement}
              hideBorder={true}
              submitOnChange={submitOnChange}
              setTouched={(isTouched) => setTouched(isTouched)}
              hideDivider={hideDivider}
              nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
              getValue={() => form?.watch(name)}
              parentName={name}
              isDisabled={!!fieldError}
              isActive={isActive}
              setFieldError={(fieldError) => setFieldError(fieldError)}
              setActiveElement={(elementId) => {
                setActiveElement({
                  elementId,
                });
              }}
              disableNextButton={disableNextButton}
              validationFunction={getValidationMessage}
              validators={validators}
              validationProps={{
                prefix: undefined,
              }}
            />
          )}
        </StyledRootSchemaComponent>
      </div>
    </>
  );
};
