import React from 'react';

export interface EmbedSessionState {
  embedSessionId: string | undefined;
  setEmbedSessionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  loggedImpressions: string[];
  setLoggedImpressions: React.Dispatch<React.SetStateAction<string[]>>;
}

export const EmbedSessionContext = React.createContext<EmbedSessionState | undefined>(undefined);

export function useEmbedSessionContext() {
  const context = React.useContext(EmbedSessionContext);
  if (context === undefined) {
    throw new Error('useSiteConfigContext must be used within EmbedSessionContext.Provider');
  }
  return context;
}
