import { Beneficiary, NetworkBeneficiary } from 'beneficiaries/domain/beneficiary';
import moment, { Moment } from 'moment';
import { NetworkPolicyholder, Policyholder } from 'policyholder/domain/policyholder';
import { JSONObject } from 'shared/utils';
import { Construct } from '../../models/construct';
import { RequestedBy } from '../../models/created-by';
import { PolicyBillingFrequency } from './policy-billing';
import { PolicyStatus } from './policy-status';

export enum ReactivationOptionType {
  Reinstatement = 'reinstatement',
  Recommencement = 'recommencement',
}

export enum PolicySchemeType {
  Individual = 'individual',
  Group = 'group',
}

export class Policy {
  readonly policyId: string;
  readonly schemeType: string;
  readonly createdAt: Moment;
  readonly createdBy: RequestedBy;
  readonly policyNumber: string;
  readonly policyholderId: string;
  readonly packageName: string;
  readonly sumAssured: number;
  readonly basePremium: number;
  readonly monthlyPremium: number;
  readonly billingAmount: number;
  readonly billingFrequency: PolicyBillingFrequency;
  readonly billingDay: number;
  readonly startDate: Moment;
  readonly endDate?: Moment;
  readonly cancelledAt?: Moment;
  readonly reasonCancelled?: string;
  readonly appData: JSONObject;
  readonly module: { type: string } & JSONObject;
  readonly productModuleId: string;
  readonly productModuleDefinitionId: string;
  readonly beneficiaries: Beneficiary[];
  // readonly coveredPeople: PolicyCoveredPeople[];
  // readonly coveredItems: PolicyCoveredItems[];
  readonly scheduleVersions: any[];
  readonly currentVersion: number;
  readonly termsUri: string;
  readonly policyScheduleUri: string;
  readonly policyWelcomeLetterUri?: string;
  readonly claimIds: string[];
  readonly complaintIds: string[];
  readonly status: PolicyStatus;
  readonly balance: number;
  readonly currency: string;
  readonly paymentMethodId?: string;
  readonly policyholder?: Policyholder;
  readonly isPolicyholderBeneficiary: boolean;
  readonly applicationId: string;
  readonly updatedAt: Moment;
  readonly nextBillingDate?: Moment;
  // readonly cancellationRequestor: PolicyCancellationRequestor;
  // readonly cancellationType: PolicyClientCancellationOptions | PolicyInsurerCancellationOptions;

  constructor(init: Construct<Policy>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkPolicy): Policy {
    return new Policy({
      policyId: init.policy_id,
      schemeType: init.scheme_type,
      createdAt: moment(init.created_at),
      createdBy: init.created_by,
      policyNumber: init.policy_number,
      policyholderId: init.policyholder_id,
      packageName: init.package_name,
      sumAssured: init.sum_assured,
      basePremium: init.base_premium,
      monthlyPremium: init.monthly_premium,
      billingAmount: init.billing_amount,
      billingFrequency: init.billing_frequency,
      billingDay: init.billing_day,
      startDate: moment(init.start_date),
      endDate: init.end_date ? moment(init.end_date) : undefined,
      cancelledAt: moment(init.cancelled_at),
      reasonCancelled: init.reason_cancelled,
      appData: init.app_data,
      module: init.module,
      productModuleId: init.product_module_id,
      productModuleDefinitionId: init.product_module_definition_id,
      beneficiaries: init.beneficiaries
        ? init.beneficiaries.map((beneficiary) => Beneficiary.fromNetwork(beneficiary))
        : [],
      scheduleVersions: init.schedule_versions,
      currentVersion: init.current_version,
      termsUri: init.terms_uri,
      policyScheduleUri: init.policy_schedule_uri,
      policyWelcomeLetterUri: init.policy_welcome_letter_uri,
      claimIds: init.claim_ids,
      complaintIds: init.complaint_ids,
      status: init.status,
      balance: init.balance,
      currency: init.currency,
      isPolicyholderBeneficiary: true,
      paymentMethodId: init.payment_method_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      // coveredPeople: init.covered_people ? init.covered_people.map(PolicyCoveredPeople.fromNetwork) : [],
      // coveredItems: init.covered_items ? init.covered_items.map(PolicyCoveredItems.fromNetwork) : [],
      // isPolicyholderBeneficiary: !!(beneficiaries && beneficiaries.length === 1 && beneficiaries[0].policyholderId),
      applicationId: init.application_id,
      updatedAt: moment(init.updated_at),
      nextBillingDate: init.next_billing_date ? moment(init.next_billing_date).local() : undefined,
      // cancellationRequestor: init.cancellation_requestor,
      // cancellationType: init.cancellation_type,
    });
  }

  prettyPolicyStatus = () => {
    switch (this.status) {
      case PolicyStatus.Active:
        return 'Active';
      case PolicyStatus.Cancelled:
        return 'Cancelled';
      case PolicyStatus.PendingInitialPayment:
        return 'Pending';
      case PolicyStatus.Lapsed:
        return 'Lapsed';
      case PolicyStatus.Expired:
        return 'Expired';
      case PolicyStatus.NotTakenUp:
        return 'Not taken up';
      default:
        return '';
    }
  };

  isGroup = () => {
    return this.schemeType === PolicySchemeType.Group;
  };
}

export class NetworkPolicy {
  policy_id: string;
  scheme_type: string;
  created_at: string;
  created_by: RequestedBy;
  policy_number: string;
  policyholder_id: string;
  package_name: string;
  sum_assured: number;
  base_premium: number;
  monthly_premium: number;
  billing_amount: number;
  billing_frequency: PolicyBillingFrequency;
  billing_day: number;
  start_date: string;
  end_date?: string;
  cancelled_at?: string;
  reason_cancelled?: string;
  app_data: JSONObject;
  module: { type: string } & JSONObject;
  product_module_id: string;
  product_module_definition_id: string;
  beneficiaries: NetworkBeneficiary[];
  schedule_versions: any[];
  current_version: number;
  terms_uri: string;
  policy_schedule_uri: string;
  policy_welcome_letter_uri?: string;
  claim_ids: string[];
  complaint_ids: string[];
  status: PolicyStatus;
  balance: number;
  currency: string;
  payment_method_id?: string;
  policyholder?: NetworkPolicyholder;
  // covered_people?: PolicyCoveredPeople[];
  // covered_items?: NetworkPolicyCoveredItems[];
  application_id: string;
  updated_at: string;
  next_billing_date: string;
  // cancellation_requestor: PolicyCancellationRequestor;
  // cancellation_type: PolicyClientCancellationOptions | PolicyInsurerCancellationOptions;
}

export const prettyPolicyStatus = (params: { status: PolicyStatus }) => {
  const { status } = params;
  switch (status) {
    case PolicyStatus.Active:
      return 'Active';
    case PolicyStatus.Cancelled:
      return 'Cancelled';
    case PolicyStatus.PendingInitialPayment:
      return 'Initial payment pending';
    case PolicyStatus.Lapsed:
      return 'Lapsed';
    case PolicyStatus.Expired:
      return 'Expired';
    case PolicyStatus.NotTakenUp:
      return 'Not taken up';
    default:
      return '';
  }
};

export const isPolicyActive = (policy: Policy) => {
  const activePolicyStatuses = [PolicyStatus.Active, PolicyStatus.PendingInitialPayment];
  return activePolicyStatuses.includes(policy.status);
};
