import React from 'react';
import { orderBy } from 'lodash';
import { sentenceCase } from 'sentence-case';
import { SelectFieldParams, SelectField } from '../select-field';
import { AccountType } from 'payment-methods/domain/payment-method';

const accountTypeOptions = Object.values(AccountType).map((accountType) => ({
  label: sentenceCase(accountType),
  value: accountType,
}));

export const AccountTypeSelectField = (props: Omit<SelectFieldParams<string>, 'options'>) => {
  return (
    <SelectField
      searchable
      {...props}
      clearable={true}
      options={orderBy(accountTypeOptions, (accountType) => accountType.label)}
    />
  );
};
