import React from 'react';
import { Size, SuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';

export const PolicySummaryErrorPage = () => {
  const { siteConfig } = useSiteConfigContext();
  return (
    <PolicySummaryErrorStyle>
      <StyledPolicySummaryPreTitle siteConfig={siteConfig}>OOPS!</StyledPolicySummaryPreTitle>
      <StyledPolicySummaryTitle>Something went wrong....</StyledPolicySummaryTitle>
      <StyledPolicySummaryText>
        We could not display the page you're looking for. Try reloading the page.
      </StyledPolicySummaryText>
      <SuccessButton
        id='close-confirmation'
        siteConfig={siteConfig}
        disabled={false}
        wide
        size={Size.lg}
        onClick={async (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          window.location.reload();
        }}
      >
        <span style={{ justifyContent: 'center' }}>Reload page</span>
      </SuccessButton>
    </PolicySummaryErrorStyle>
  );
};

const PolicySummaryErrorStyle = styled.div`
  margin-top: ${globalStyles.spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    font-size: 16px;
    display: flex;
    justify-content: center;
  }

  ${SuccessButton} {
    margin-top: 30px;
    width: 100%;
  }
`;

const StyledPolicySummaryTitle = styled.div`
  font-size: 34px;
`;

const StyledPolicySummaryPreTitle = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  color: ${({ siteConfig }) => getColor({ color: 'highlight', siteConfig })};
  font-size: 16px;
`;

const StyledPolicySummaryText = styled.div`
  margin-top: ${globalStyles.spacing.default};
  margin-bottom: ${globalStyles.spacing.default};
`;
