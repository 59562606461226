import React, { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { usePromise } from 'shared/hooks/promise';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { useSiteConfigContext } from 'style-context';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { Moment } from 'moment';
import { ProductModule } from 'product-modules/domain/product-module';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { Gender } from 'models/gender';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { TextField } from 'rootstrap/components/forms/new-fields/extended-components/text-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { PolicyholderPrefillValues } from 'policy-issuing/personal-details/views/personal-details';
import { EmailField } from 'rootstrap/components/forms/new-fields/extended-components/email-field';
import { Beneficiary, BeneficiaryEntityType } from 'beneficiaries/domain/beneficiary';
import { IdTypeSelectField } from 'rootstrap/components/forms/new-fields/id-type-select-field';
import { SuccessButton } from 'rootstrap/components/button/styles';
import { Cellphone } from 'policyholder/domain/policyholder';
import { TitleSelectField } from 'rootstrap/components/forms/new-fields/unused-components/title-select-field';
import { Title } from 'general/title';
import uuid from 'uuid';
import _ from 'lodash';
import { BeneficiaryRelationshipSelectField } from 'rootstrap/components/forms/new-fields/extended-components/beneficiary-relationship-select';
import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { PhoneNumberField } from 'rootstrap/components/forms/new-fields/phone-number-field';
import phone from 'phone';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { getWording, getWordingForIdTypeHandlebars } from 'site-config';

export enum BeneficiaryDetailsInputs {
  Title = 'title',
  FirstName = 'firstName',
  LastName = 'lastName',
  Relationship = 'relationship',
  IdType = 'idType',
  IdentificationNumber = 'identificationNumber',
  IdentificationCountry = 'IdentificationCountry',
  IdentificationExpirationDate = 'identificationExpirationDate',
  Gender = 'gender',
  DateOfBirth = 'dateOfBirth',
  Email = 'email',
  Cellphone = 'cellphone',
}

export interface BeneficiaryDetailsData {
  [BeneficiaryDetailsInputs.Title]?: Title;
  [BeneficiaryDetailsInputs.FirstName]?: string;
  [BeneficiaryDetailsInputs.LastName]?: string;
  [BeneficiaryDetailsInputs.Relationship]?: string;
  [BeneficiaryDetailsInputs.IdType]?: IdentificationType;
  [BeneficiaryDetailsInputs.IdentificationNumber]?: string;
  [BeneficiaryDetailsInputs.IdentificationCountry]?: string;
  [BeneficiaryDetailsInputs.IdentificationExpirationDate]?: Moment;
  [BeneficiaryDetailsInputs.Gender]?: Gender;
  [BeneficiaryDetailsInputs.DateOfBirth]?: Moment;
  [BeneficiaryDetailsInputs.Email]?: string;
  [BeneficiaryDetailsInputs.Cellphone]?: Cellphone;
}

interface Params {
  productModule?: ProductModule;
  prefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  beneficiary: Beneficiary | undefined;
  beneficiaries: Beneficiary[];
  setBeneficiaries: (beneficiary: Beneficiary[]) => void;
  toggle: () => void;
}

export const BeneficiaryDetailsSection = (params: Params) => {
  const { productModule } = params;
  const { siteConfig } = useSiteConfigContext();
  const { Id, Passport } = IdentificationType;
  const { activeElement, setActiveElement, beneficiary, toggle, beneficiaries, setBeneficiaries } = params;
  const submitButtonRef = useRef<any>();

  usePromise(async () => {
    await form.trigger();
  }, []);

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });
  form.watch();

  const execute = (formValues: BeneficiaryDetailsData) => {
    const newBeneficiaries = createOrUpdateBeneficiary({
      beneficiaries,
      beneficiary,
      formValues,
    });

    setBeneficiaries(newBeneficiaries);
    return toggle();
  };

  if (!productModule) {
    return (
      <FormDetailsWrapper siteConfig={siteConfig}>
        <LoadingLines />
      </FormDetailsWrapper>
    );
  }

  const idType = form.watch(BeneficiaryDetailsInputs.IdType);

  return (
    <BeneficiaryDetailsStyle style={{ paddingBottom: 60 }}>
      <form onSubmit={form.handleSubmit((data: BeneficiaryDetailsData) => execute(data))}>
        <StyledBeneficiaryCol sm={12}>
          <TitleSelectField
            clearable={true}
            isTouched={false}
            defaultValue={beneficiary?.title}
            name={BeneficiaryDetailsInputs.Title}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.title.label })}
            validators={[]}
            form={form}
            placeholder={''}
            disableScrollToElement={false}
            disableActiveElement={true}
            hideBorder={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.FirstName,
                index: 0,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>

        <StyledBeneficiaryCol sm={12}>
          <InputField
            isTouched={false}
            placeholder={undefined}
            defaultValue={beneficiary?.firstName}
            validators={[
              {
                validation: {
                  type: ValidationTypes.REQUIRED,
                },
              },
            ]}
            name={BeneficiaryDetailsInputs.FirstName}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.firstName.label })}
            form={form}
            hideBorder={true}
            disableScrollToElement={false}
            disableActiveElement={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.LastName,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>

        <StyledBeneficiaryCol sm={12}>
          <InputField
            isTouched={false}
            placeholder={undefined}
            hideBorder={true}
            defaultValue={beneficiary?.lastName}
            validators={[
              {
                validation: {
                  type: ValidationTypes.REQUIRED,
                },
              },
            ]}
            name={BeneficiaryDetailsInputs.LastName}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.lastName.label })}
            form={form}
            disableScrollToElement={false}
            disableActiveElement={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.Relationship,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>
        {!beneficiary?.policyholderId && (
          <StyledBeneficiaryCol sm={12}>
            <BeneficiaryRelationshipSelectField
              clearable={true}
              isTouched={false}
              defaultValue={beneficiary?.relationship}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              name={BeneficiaryDetailsInputs.Relationship}
              label={getWording({ wording: siteConfig?.inputFields.beneficiaries.relationship.label })}
              form={form}
              hideBorder={true}
              disableScrollToElement={false}
              disableActiveElement={true}
              disableNextButton={true}
              hideDivider={true}
              placeholder={''}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: BeneficiaryDetailsInputs.Cellphone,
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        )}
        <StyledBeneficiaryCol sm={12}>
          <PhoneNumberField
            isTouched={false}
            defaultValue={{
              countryCode: phone(beneficiary?.cellphone?.number || '').countryIso2 || 'ZA',
              number: beneficiary?.cellphone?.number.replace(
                phone(beneficiary?.cellphone?.number || '').countryCode || '',
                '',
              ),
            }}
            validators={[]}
            placeholder={''}
            name={BeneficiaryDetailsInputs.Cellphone}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.cellphone.label })}
            form={form}
            hideBorder={true}
            disableScrollToElement={false}
            disableActiveElement={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.Email,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>

        <StyledBeneficiaryCol sm={12}>
          <EmailField
            isTouched={false}
            placeholder={undefined}
            defaultValue={beneficiary?.email}
            validators={[]}
            name={BeneficiaryDetailsInputs.Email}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.email.label })}
            form={form}
            hideBorder={true}
            disableScrollToElement={false}
            disableActiveElement={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.IdType,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>

        <StyledBeneficiaryCol sm={12}>
          <IdTypeSelectField
            isTouched={false}
            clearable={true}
            placeholder={undefined}
            defaultValue={beneficiary?.id?.type}
            validators={[]}
            name={BeneficiaryDetailsInputs.IdType}
            label={getWording({ wording: siteConfig?.inputFields.beneficiaries.idType.label })}
            form={form}
            isDisabled={false}
            hideBorder={true}
            disableScrollToElement={false}
            disableActiveElement={true}
            disableNextButton={true}
            hideDivider={true}
            displayProperties={
              {
                activeElement,
                setActiveElement,
                nextComponentName: BeneficiaryDetailsInputs.IdentificationNumber,
              } as InputFieldDisplayProperties
            }
          />
        </StyledBeneficiaryCol>
        {idType === Id && (
          <StyledBeneficiaryCol sm={12}>
            <InputField
              isTouched={false}
              placeholder={undefined}
              defaultValue={beneficiary?.id?.number}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.ZAId,
                  },
                },
              ]}
              name={BeneficiaryDetailsInputs.IdentificationNumber}
              label={getWordingForIdTypeHandlebars({
                identificationType: IdentificationType.Id,
                wording: siteConfig?.inputFields.beneficiaries.identificationNumber.label,
              })}
              form={form}
              disableScrollToElement={false}
              disableActiveElement={true}
              disableNextButton={true}
              hideDivider={true}
              hideBorder={true}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: '',
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        )}
        {idType === Passport && (
          <StyledBeneficiaryCol sm={12}>
            <TextField
              isTouched={false}
              placeholder={undefined}
              defaultValue={beneficiary?.id?.number}
              validators={[]}
              name={BeneficiaryDetailsInputs.IdentificationNumber}
              label={getWordingForIdTypeHandlebars({
                identificationType: IdentificationType.Passport,
                wording: siteConfig?.inputFields.beneficiaries.identificationNumber.label,
              })}
              form={form}
              disableScrollToElement={false}
              disableActiveElement={true}
              disableNextButton={true}
              hideDivider={true}
              hideBorder={true}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: BeneficiaryDetailsInputs.IdentificationCountry,
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        )}
        {idType === Passport && (
          <StyledBeneficiaryCol sm={12}>
            <CountrySelectField
              clearable={true}
              isTouched={false}
              placeholder={undefined}
              form={form}
              label={getWording({ wording: siteConfig?.inputFields.beneficiaries.identificationCountry.label })}
              validators={[]}
              name={BeneficiaryDetailsInputs.IdentificationCountry}
              defaultValue={beneficiary?.id?.country}
              disableScrollToElement={false}
              disableActiveElement={true}
              disableNextButton={true}
              hideDivider={true}
              hideBorder={true}
              displayProperties={
                {
                  activeElement: {
                    elementId: BeneficiaryDetailsInputs.LastName,
                  },
                  setActiveElement,
                  nextComponentName: BeneficiaryDetailsInputs.Gender,
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        )}
        {idType === Passport && (
          <StyledBeneficiaryCol sm={12}>
            <SelectField
              isTouched={false}
              clearable={true}
              placeholder={undefined}
              options={genderSelectOptions}
              form={form}
              label={getWording({ wording: siteConfig?.inputFields.beneficiaries.gender.label })}
              name={BeneficiaryDetailsInputs.Gender}
              validators={[]}
              defaultValue={beneficiary?.gender}
              disableScrollToElement={false}
              disableActiveElement={true}
              disableNextButton={true}
              hideDivider={true}
              hideBorder={true}
              displayProperties={
                {
                  activeElement: {
                    elementId: BeneficiaryDetailsInputs.LastName,
                  },
                  setActiveElement,
                  nextComponentName: '',
                } as InputFieldDisplayProperties
              }
            />
          </StyledBeneficiaryCol>
        )}
        <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
        <SuccessButton
          id='beneficiary-details-submit-button'
          disabled={!form.formState.isValid}
          siteConfig={siteConfig}
          onClick={() => submitButtonRef.current.click()}
          fullWidth={true}
        >
          {beneficiary ? 'Update' : 'Add'}
        </SuccessButton>
      </form>
    </BeneficiaryDetailsStyle>
  );
};

const createOrUpdateBeneficiary = (params: {
  beneficiary: Beneficiary | undefined;
  beneficiaries: Beneficiary[];
  formValues: BeneficiaryDetailsData;
}) => {
  const { beneficiary: incomingBeneficiary, formValues, beneficiaries } = params;

  const cellphone = formValues.cellphone;

  const beneficiary = new Beneficiary({
    beneficiaryId: incomingBeneficiary?.beneficiaryId || uuid().toString(),
    companyName: incomingBeneficiary?.companyName, // Group is not yet supported!!
    registrationNumber: incomingBeneficiary?.registrationNumber,
    policyholderId: incomingBeneficiary?.policyholderId,
    title: formValues.title,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    email: formValues.email,
    relationship: formValues.relationship,
    gender: formValues.gender,
    entityType: BeneficiaryEntityType.Individual, // No group policies handled as of yet
    cellphone: cellphone?.number
      ? {
          country: cellphone.country,
          number: cellphone.internationalNumber || cellphone.number,
        }
      : undefined,
    percentage: beneficiaries.length === 0 ? 100 : incomingBeneficiary?.percentage || 0,
    id: formValues.identificationNumber
      ? {
          type: formValues.idType || IdentificationType.Id, // We need to test that the idType field correctly updates this
          country: formValues.IdentificationCountry || 'ZA',
          number: formValues.identificationNumber || '',
        }
      : undefined,
  });

  if (incomingBeneficiary) {
    const beneficiaryIsAtIndex = beneficiaries.findIndex(
      ({ beneficiaryId }) => beneficiaryId === incomingBeneficiary.beneficiaryId,
    );
    _.set(beneficiaries, `[${beneficiaryIsAtIndex}]`, beneficiary);
    return beneficiaries;
  }

  beneficiaries.push(beneficiary);
  return beneficiaries;
};

const genderSelectOptions = [
  {
    label: 'Male',
    value: Gender.Male,
  },
  {
    label: 'Female',
    value: Gender.Female,
  },
];
