import * as React from 'react';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';

export const WarningIcon = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => (
  <svg width='24' height='24' viewBox='0 0 38 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7184_51886)'>
      <path
        d='M36.92 30.0801L20.77 1.0001C20.6003 0.699579 20.3557 0.448094 20.06 0.270097C19.7572 0.0903439 19.4122 -0.00624545 19.06 -0.00990292C18.71 -0.0203081 18.364 0.0662094 18.06 0.240097C17.7607 0.407035 17.5094 0.648075 17.33 0.940097L0.28003 29.4801C0.100277 29.783 0.0036874 30.1279 2.99311e-05 30.4801C-0.0114297 30.8302 0.0751585 31.1766 0.25003 31.4801C0.412715 31.7825 0.654713 32.0349 0.95003 32.2101C1.24979 32.397 1.5968 32.4942 1.95003 32.4901L35.18 33.0001C35.5299 33.0095 35.8757 32.923 36.18 32.7501C36.4793 32.5832 36.7307 32.3421 36.91 32.0501C37.0863 31.7463 37.1794 31.4014 37.18 31.0501C37.1819 30.7093 37.0921 30.3743 36.92 30.0801V30.0801ZM20.77 29.1301L16.45 29.0601L16.52 24.7901L20.84 24.8701L20.77 29.1301ZM19.94 23.0601H17.51L16.44 10.1901L21.39 10.2901L19.94 23.0601Z'
        fill={getColor({ siteConfig: params.siteConfig, color: 'highlight' })}
      />
    </g>
    <defs>
      <clipPath id='clip0_7184_51886'>
        <rect width='37.18' height='33' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
