import { Moment } from 'moment';
import { DebitOrderPaymentMethod, PaymentMethodAll, PaymentMethodType } from 'payment-methods/domain/payment-method';
import { ProductModule } from 'product-modules/domain/product-module';

export const formatBillingDate = (date: Moment) => date.format('MMMM Do YYYY');

export const getPaymentMethodType = (paymentMethod: PaymentMethodAll | undefined, productModule: ProductModule) => {
  const existingPaymentMethodType =
    paymentMethod &&
    (paymentMethod instanceof DebitOrderPaymentMethod ? PaymentMethodType.DebitOrder : PaymentMethodType.External);

  const productModulePaymentMethodTypes =
    productModule?.productModuleDefinition?.settings.billing.paymentMethodTypes[0];

  return existingPaymentMethodType || productModulePaymentMethodTypes;
};
