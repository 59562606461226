import React, { useMemo, useState } from 'react';
import { Moment } from 'moment';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { usePromise } from 'shared/hooks/promise';
import { useForm } from 'react-hook-form';
import { CardGroup } from 'rootstrap/components/card';
import { debounce } from 'utils';
import { Col, Row } from 'reactstrap';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { DatePickerField } from 'rootstrap/components/forms/new-fields/date-picker-field';
import { PrimitiveListField } from 'rootstrap/components/forms/new-fields/primitive-list-field';
import { useSiteConfigContext } from 'style-context';
import { getWording } from 'site-config';

export interface PersonalDetailsCompanyDetailsData {
  companyName?: string;
  registrationNumber?: string;
  companyWebsiteUrl?: string;
  contactPosition?: string;
  dateOfEstablishment?: Moment;
  subsidiaryCompanies?: string[];
}

interface Props {
  setIsValid: (params: boolean) => void;
  setSectionData: (params: PersonalDetailsCompanyDetailsData) => void;
  personalDetailCompanyFormSectionData: PersonalDetailsCompanyDetailsData | undefined;
  prefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  isCompleted: boolean;
  isPolicyholderTypeFieldDisplayed: boolean;
}

export const CompanyDetailsSection = (props: Props) => {
  const {
    activeElement,
    isCompleted,
    isPolicyholderTypeFieldDisplayed,
    personalDetailCompanyFormSectionData,
    prefillValues,
    setActiveElement,
    setIsValid,
    setSectionData,
  } = props;
  const { siteConfig } = useSiteConfigContext();

  const [isTouched] = useState<boolean>(isCompleted);

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: personalDetailCompanyFormSectionData,
      }),
      [],
    ),
  });

  usePromise(async () => {
    await form.trigger();
  }, []);

  usePromise(async () => {
    setIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  const execute = () => {
    const formValues = form.getValues() as PersonalDetailsCompanyDetailsData;

    setSectionData({ ...formValues, subsidiaryCompanies: formValues.subsidiaryCompanies || [] });
  };

  return (
    <CardGroup>
      <form
        onBlur={() => debounce('', () => execute())}
        onChange={() => execute()}
        onSubmit={form.handleSubmit((data: PersonalDetailsCompanyDetailsData) => setSectionData(data))}
      >
        <Row>
          <Col sm={12}>
            <InputField
              defaultValue={personalDetailCompanyFormSectionData?.registrationNumber}
              isTouched={true}
              placeholder={undefined}
              disableScrollToElement={isPolicyholderTypeFieldDisplayed}
              name={PersonalDetailsInputs.RegistrationNumber}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.registrationNumber.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.registrationNumber.prefillAction}
              hideDivider={false}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              prefillValue={prefillValues.registrationNumber}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.CompanyName,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InputField
              defaultValue={personalDetailCompanyFormSectionData?.companyName}
              isTouched={isTouched}
              placeholder={undefined}
              disableScrollToElement={false}
              name={PersonalDetailsInputs.CompanyName}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.companyName.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.companyName.prefillAction}
              hideDivider={false}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              prefillValue={prefillValues.companyName}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.DateOfEstablishment,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DatePickerField
              defaultValue={personalDetailCompanyFormSectionData?.dateOfEstablishment}
              isTouched={isTouched}
              placeholder={undefined}
              disableScrollToElement={false}
              name={PersonalDetailsInputs.DateOfEstablishment}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.dateOfEstablishment.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.dateOfEstablishment.prefillAction}
              hideDivider={false}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              prefillValue={prefillValues.companyWebsiteUrl}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.SubsidiaryCompanies,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <PrimitiveListField
              defaultValues={personalDetailCompanyFormSectionData?.subsidiaryCompanies || []}
              isTouched={isTouched}
              placeholder={undefined}
              disableScrollToElement={false}
              name={PersonalDetailsInputs.SubsidiaryCompanies}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.subsidiaryCompanies.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.subsidiaryCompanies.prefillAction}
              hideDivider={false}
              form={form}
              validators={[]}
              prefillValue={prefillValues.subsidiaryCompanies}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.CompanyWebsiteUrl,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InputField
              defaultValue={personalDetailCompanyFormSectionData?.companyWebsiteUrl}
              isTouched={isTouched}
              placeholder={undefined}
              disableScrollToElement={false}
              name={PersonalDetailsInputs.CompanyWebsiteUrl}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.companyWebsiteUrl.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.companyWebsiteUrl.prefillAction}
              hideDivider={false}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
                {
                  validation: {
                    type: ValidationTypes.URL,
                  },
                },
              ]}
              prefillValue={prefillValues.companyWebsiteUrl}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.ContactPosition,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InputField
              defaultValue={personalDetailCompanyFormSectionData?.contactPosition}
              isTouched={isTouched}
              placeholder={undefined}
              disableScrollToElement={false}
              name={PersonalDetailsInputs.ContactPosition}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.contactPosition.label })}
              prefillAction={siteConfig?.inputFields.personalDetails.contactPosition.prefillAction}
              hideDivider={false}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              prefillValue={prefillValues.contactPosition}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.FirstName,
                } as InputFieldDisplayProperties
              }
            />
          </Col>
        </Row>
      </form>
    </CardGroup>
  );
};
