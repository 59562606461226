import moment, { Moment } from 'moment';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { Construct } from '../../../models/construct';

export class Quote {
  readonly quotePackageId: string;
  readonly packageName: string;
  readonly sumAssured: number;
  readonly basePremium: number;
  readonly suggestedPremium: number;
  readonly module: { type: string;[key: string]: any };
  readonly createdAt: Moment;
  readonly currency: Currency;
  readonly billingFrequency: PolicyBillingFrequency;
  readonly inputData: any;
  readonly productModuleDefinitionId: string;

  constructor(init: Construct<Quote>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkQuote): Quote {
    return new Quote({
      quotePackageId: init.quote_package_id,
      sumAssured: init.sum_assured,
      basePremium: init.base_premium,
      billingFrequency: init.billing_frequency,
      suggestedPremium: init.suggested_premium,
      packageName: init.package_name,
      module: init.module,
      inputData: init.input_data,
      createdAt: moment(init.created_at).local(),
      currency: init.currency,
      productModuleDefinitionId: init.product_module_definition_id,
    });
  }
}

export interface NetworkQuote {
  quote_package_id: string;
  package_name: string;
  sum_assured: number;
  base_premium: number;
  suggested_premium: number;
  module: { type: string;[key: string]: any };
  created_at: string;
  currency: Currency;
  billing_frequency: PolicyBillingFrequency;
  input_data: any;
  product_module_definition_id: string;
}
