import { SuccessButton } from 'rootstrap/components/button/styles';
import { SplashContainer } from 'rootstrap/components/splash-header/splash-container';
import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';
import { PolicySummaryStyles } from './policy-summary-styles';

export const PolicyViewSplashContainer = styled(SplashContainer)`
  padding: 0px;
  border-radius: 30px;
`;

export const PolicyViewBodyWrapper = styled(PolicySummaryStyles.BodyWrapper)`
  padding: 0px;

  button:nth-child(1) {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }
`;

export const PolicyViewSuccessButton = styled(SuccessButton)`
  border-radius: 0px !important;
  border-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })} !important;
  height: 65px;
  color: ${globalStyles.colors.Body} !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${globalStyles.spacing.lg};

  &:hover {
    border-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })} !important;
  }

  span {
    vertical-align: middle;
    padding-left: ${globalStyles.spacing.sm};
  }
`;

export const PolicyViewSummaryPanel = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  border-radius: 30px;
`;

export const PolicyViewFooterStyle = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  margin-top: 100px;
  border-top: 1px solid;
  border-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'border' })};
  padding-top: ${globalStyles.spacing.default};

  div {
    font-weight: bold;
    margin-bottom: ${globalStyles.spacing.sm};
  }

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }

  svg {
    margin-right: ${globalStyles.spacing.sm};
  }

  a {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })};
  }
`;

export const PolicyViewStatusBadgeWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: -20px;
`;

export const PolicyViewStatusBadge = styled.div<{ color: string }>`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  height: 51px;
  background: ${({ color }) => color};
  width: 141px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @media ${devices.tablet} {
    border-bottom-right-radius: 0px;
    width: 80px;
  }
`;

export const PolicyViewLoadingWrapper = styled.div`
  margin-left: ${globalStyles.spacing.default};
  margin-right: ${globalStyles.spacing.default};
`;
