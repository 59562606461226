import { ProductModule } from 'product-modules/domain/product-module';
import React from 'react';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { ContactDetailsData, ContactDetailsSection } from './contact-details';
import { IdentificationSection, PersonalDetailsIdentificationData } from './identification';
import { PolicyholderPrefillValues } from './personal-details';
import { PersonalAddressData, PhysicalAddressSection } from './physical-address-section';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { getSelectableIdTypes } from '../utils/helpers';
import { useEnvironment } from 'shared/api';
import { Environment } from 'models/environment';
import { Address } from 'policyholder/domain/policyholder';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';

interface Props {
  isPolicyholderTypeFieldDisplayed: boolean;
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
  setIdentificationSectionData: (params: PersonalDetailsIdentificationData) => void;
  setIdentificationIsValid: (identificationIsValid: boolean) => void;
  productModule: ProductModule | undefined;
  policyholderPrefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  isCompleted: boolean;
  contactDetailsSectionData: ContactDetailsData | undefined;
  setContactDetailsIsValid: (contactDetailsIsValid: boolean) => void;
  setContactDetailsSectionData: (contactDetailsSectionData: ContactDetailsData) => void;
  addressOptIn: boolean | undefined;
  setAddressOptIn: (v: boolean) => void;
  setAddressDetailsIsValid: (contactDetailsIsValid: boolean) => void;
  addressDetailsSectionData: PersonalAddressData | undefined;
  setAddressDetailsSectionData: (contactDetailsSectionData: PersonalAddressData) => void;
  fetchifyData: Address | undefined;
  setFetchifyData: (v: Address | undefined) => void;
  policyholderLookupFormData: PolicyholderLookUpFormData | undefined;
}

export const IndividualPolicyholderForms = (props: Props) => {
  const {
    setActiveElement,
    identificationSectionData,
    isPolicyholderTypeFieldDisplayed,
    setIdentificationIsValid,
    setIdentificationSectionData,
    productModule,
    policyholderPrefillValues,
    activeElement,
    isCompleted,
    contactDetailsSectionData,
    setContactDetailsIsValid,
    setContactDetailsSectionData,
    addressOptIn,
    setAddressOptIn,
    setAddressDetailsIsValid,
    addressDetailsSectionData,
    setAddressDetailsSectionData,
    policyholderLookupFormData,
  } = props;
  const allowedIdTypes = getSelectableIdTypes(productModule?.productModuleDefinition?.settings.policyholder.idTypes);
  const environment = useEnvironment();

  if (
    (policyholderPrefillValues.identification?.type === IdentificationType.Custom || allowedIdTypes?.length === 0) &&
    !policyholderPrefillValues?.identification?.number
  ) {
    const errorMessage =
      environment === Environment.Production
        ? 'We apologise for the inconvenience. The issue is currently being addressed and we appreciate your patience.'
        : 'To use the custom ID type, please make sure to provide an identification_number using prefill values.';
    return <ErrorAlert title='Missing identification number' error={new Error(errorMessage)} />;
  }

  return (
    <>
      <IdentificationSection
        policyholderLookupFormData={policyholderLookupFormData}
        isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
        identificationSectionData={identificationSectionData}
        setIsValid={(identificationIsValid) => setIdentificationIsValid(identificationIsValid)}
        setSectionData={(identificationSectionData) => setIdentificationSectionData(identificationSectionData)}
        productModule={productModule}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
      />

      <ContactDetailsSection
        setIsValid={(contactDetailsIsValid) => setContactDetailsIsValid(contactDetailsIsValid)}
        setSectionData={(contactDetailsSectionData) => setContactDetailsSectionData(contactDetailsSectionData)}
        contactDetailsSectionData={contactDetailsSectionData}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
        policyholderLookupFormData={policyholderLookupFormData}
      />

      <PhysicalAddressSection
        addressOptIn={addressOptIn}
        productModule={productModule}
        setAddressOptIn={setAddressOptIn}
        setIsValid={setAddressDetailsIsValid}
        addressDetailsSectionData={addressDetailsSectionData}
        setSectionData={(addressDetailsSectionData) => setAddressDetailsSectionData(addressDetailsSectionData)}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
        fetchifyData={props.fetchifyData}
        setFetchifyData={props.setFetchifyData}
      />
    </>
  );
};
