import React from 'react';

export const Icon16PXCreditCard = (params: { fill: string; style?: React.CSSProperties }) => (
  <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.9089 0H1.87877C0.836051 0 0.00939383 0.852917 0.00939383 1.91667L0 13.4167C0 14.4804 0.836051 15.3333 1.87877 15.3333H16.9089C17.9516 15.3333 18.7877 14.4804 18.7877 13.4167V1.91667C18.7877 0.852917 17.9516 0 16.9089 0ZM16.9085 13.4167H1.87839V7.66669H16.9085V13.4167ZM1.87839 3.83335H16.9085V1.91669H1.87839V3.83335Z'
      fill={params.fill}
    />
  </svg>
);
