import React from 'react';

export const Icon13PXInformation = (params: { fill: string; style?: React.CSSProperties }) => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.32 0C2.83 0 0 2.83 0 6.32C0 9.81 2.83 12.64 6.32 12.64C9.81 12.64 12.64 9.81 12.64 6.32C12.64 2.83 9.81 0 6.32 0ZM7.18 9.89H5.47V5.04H7.18V9.88V9.89ZM7.18 4.39H5.47V2.75H7.18V4.39Z'
      fill={params.fill}
    />
  </svg>
);
