import { Gender } from 'models/gender';
import { Moment } from 'moment';
import { upsertPolicyholder } from 'policyholder/actions/upsert-policyholder';
import { Cellphone, Policyholder, PolicyholderAddressOptInOptions, Address } from 'policyholder/domain/policyholder';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { ProductModule } from 'product-modules/domain/product-module';
import React, { useEffect, useState } from 'react';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { ContactDetailsData, ContactDetailCellphone } from './contact-details';
import { PersonalDetailsIdentificationData } from './identification';
import { PersonalAddressData } from './physical-address-section';
import { ActiveElement, scrollToTopOfPage } from 'rootstrap/components/forms/new-fields/utils';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { JSONObject } from 'shared/utils';
import { isPolicyholderPrefillComplete } from '../utils/is-policyholder-prefilled';
import { useSiteConfigContext } from 'style-context';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { PolicyholderEntityType } from 'policyholder/domain/policyholder-entity-type';
import { PersonalDetailsTypeSection } from './personal-details-type';
import { PersonalDetailsCompanyDetailsData } from './company-details';
import { IndividualPolicyholderForms } from './individual-policyholder-forms';
import { CompanyPolicyholderForm } from './company-policyholder-form';
import { getPolicyholderDataFromPrefillValues } from '../utils/get-policyholder-data-from-prefill-values';
import { PolicyholderLookUpFormData, UnauthenticatedPolicyholderLookup } from './unauthenticated-policyholder-lookup';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { resetPolicyholderToPrefill } from '../utils/reset-policyholder-to-prefill';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { WrappedLoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { GetPolicyIssuingFlowStepFromStepIndex, IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';
import { EmbeddedConfigSection } from 'site-config';

interface Props extends SteppedFullScreenModalComponentParams {
  createPolicyholderSubmitButtonRef: React.MutableRefObject<any>;
  policyholder?: Policyholder;
  productModule?: ProductModule;
  addressOptIn: boolean | undefined;
  isPolicyholderTypeFieldDisplayed: boolean;
  policyholderType: PolicyholderEntityType | undefined;
  productModuleDefinitionApplicationSchema?: ProductModuleDefinitionSchema;
  productModuleDefinition?: ProductModuleDefinition;
  quoteSchemaFormData: JSONObject | undefined;
  applicationSchemaFormData: JSONObject | undefined;
  isCompleted: boolean;
  isCompletedLookup: boolean;
  priorStepKey?: IssuingSceneStepKeys;
  stepOrder: Record<IssuingSceneStepKeys, number>;
  policyholderLookupFormData: PolicyholderLookUpFormData;
  selectedQuote: Quote | undefined;
  sectionIndices: number[];
  setStepProgress: (stepProgress: string) => void;
  setAddressOptIn: (v: boolean) => void;
  setPolicyholderType: (params: PolicyholderEntityType | undefined) => void;
  setPolicyholderLookupFormData: (v: PolicyholderLookUpFormData) => void;
  setPolicyholder: (policyholder: Policyholder) => void;
  setPolicyholderIsValid: (policyholderIsValid: boolean) => void;
  setCurrentStepKey: (stepKey: IssuingSceneStepKeys) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsPersonalDetailsCompleted: (isPersonalDetailsCompleted: boolean) => void;
  setIsCreatingPolicyholderFromPrefill: (value: boolean) => void;
}

export enum PersonalDetailsInputs {
  FirstName = 'firstName',
  LastName = 'lastName',
  IdType = 'idType',
  IdentificationNumber = 'identificationNumber',
  IdentificationCountry = 'identificationCountry',
  IdentificationExpirationDate = 'identificationExpirationDate',
  Gender = 'gender',
  DateOfBirth = 'dateOfBirth',
  Email = 'email',
  Cellphone = 'cellphone',
  CellphoneCountryCode = 'CellphoneCountryCode',
  InternationalNumber = 'InternationalNumber',
  AddressOptIn = 'AddressOptIn',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AreaCode = 'areaCode',
  Suburb = 'suburb',
  City = 'city',
  Country = 'country',
  RegistrationNumber = 'registrationNumber',
  CompanyName = 'companyName',
  SubsidiaryCompanies = 'subsidiaryCompanies',
  DateOfEstablishment = 'dateOfEstablishment',
  CompanyWebsiteUrl = 'companyWebsiteUrl',
  PolicyholderType = 'policyholderType',
  ContactPosition = 'contactPosition',
  FetchifyAutocomplete = 'fetchifyAutocomplete',
}

export const incomingCellphoneCountryCodeToState = (
  cellphone: Cellphone | undefined,
): ContactDetailCellphone | undefined => {
  if (!cellphone) {
    return undefined;
  }

  return {
    countryCode: cellphone.country,
    number: cellphone.number,
  };
};

export const PersonalDetails = (props: Omit<Props, 'quoteSchemaFormData'>) => {
  const { addressOptIn, setAddressOptIn, isCompleted, isCompletedLookup, setIsCreatingPolicyholderFromPrefill } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [isMounting, setIsMounting] = useState<boolean>(true);
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId, isUnauthenticated } = embedParams;
  const fetchifyAutoCompleteEnabled =
    siteConfig?.[EmbeddedConfigSection.PersonalDetails].displayOptionalSections.fetchifyAutoComplete;

  const setMasterIsMounting = (value: boolean) => {
    setIsMounting(value);
    setIsCreatingPolicyholderFromPrefill(value);
  };

  const {
    setPolicyholder,
    policyholder,
    createPolicyholderSubmitButtonRef,
    setIsPersonalDetailsCompleted,
    setPolicyholderIsValid,
    setCurrentStepKey,
    productModule,
    onNextCompleted,
    productModuleDefinitionApplicationSchema,
    productModuleDefinition,
    priorStepKey,
    setStepProgress,
    isPolicyholderTypeFieldDisplayed,
    stepOrder,
    policyholderLookupFormData,
    setPolicyholderLookupFormData,
    selectedQuote,
    sectionIndices,
    prefillValues,
  } = props;

  const { startedTrack } = useMixpanelTrack();

  const isCompany = props.policyholderType === PolicyholderEntityType.Company;

  const [companyDetailsIsValid, setCompanyDetailsIsValid] = useState<boolean>(false);
  const [identificationIsValid, setIdentificationIsValid] = useState<boolean>(false);
  const [contactDetailsIsValid, setContactDetailsIsValid] = useState<boolean>(false);
  const [addressDetailsIsValid, setAddressDetailsIsValid] = useState<boolean>(false);
  const [newPersonalDetailsAccepted, setNewPersonalDetailsAccepted] = useState(false);
  const [fetchifyData, setFetchifyData] = useState<Address | undefined>(policyholder?.address);
  const [foundPolicyholder, setFoundPolicyholder] = useState<Policyholder | undefined>();
  const [isLookupPolicyholderModalOpen, setLookupPolicyholderModalOpen] = useState(false);

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.PersonalDetails,
    });
  }, []);

  useEffect(() => {
    scrollToTopOfPage();
  }, [isUnauthenticated && !newPersonalDetailsAccepted]);

  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: isCompany
      ? PersonalDetailsInputs.RegistrationNumber
      : !isCompleted
      ? isPolicyholderTypeFieldDisplayed
        ? PersonalDetailsInputs.PolicyholderType
        : PersonalDetailsInputs.FirstName
      : '',
  });

  const [lookupActiveElement, setLookupActiveElement] = useState<ActiveElement>({
    elementId: !isCompletedLookup ? PersonalDetailsInputs.IdType : '',
  });

  const policyholderPrefillValues = getPolicyholderDataFromPrefillValues({
    prefillValues: prefillValues?.personalDetails,
    selectedQuote,
  });

  const [identificationSectionData, setIdentificationSectionData] = useState<
    PersonalDetailsIdentificationData | undefined
  >(setPolicyholderState({ policyholder, policyholderPrefillValues }));

  const [contactDetailsSectionData, setContactDetailsSectionData] = useState<ContactDetailsData | undefined>({
    cellphone:
      incomingCellphoneCountryCodeToState(policyholder?.cellphone) ||
      incomingCellphoneCountryCodeToState(policyholderPrefillValues?.cellphone),
    email: policyholder?.email || policyholderPrefillValues?.email,
  });

  const [addressDetailsSectionData, setAddressDetailsSectionData] = useState<PersonalAddressData | undefined>({
    addressLine1: policyholder?.address?.line1 || policyholderPrefillValues.address?.addressLine1,
    addressLine2: policyholder?.address?.line2 || policyholderPrefillValues.address?.addressLine2,
    areaCode: policyholder?.address?.areaCode || policyholderPrefillValues.address?.areaCode,
    city: policyholder?.address?.city || policyholderPrefillValues.address?.city,
    country: policyholder?.address?.country || policyholderPrefillValues.address?.country,
    suburb: policyholder?.address?.suburb || policyholderPrefillValues.address?.suburb,
    geoCoordinatesLatitude: policyholder?.address?.geoCoordinatesLatitude,
    geoCoordinatesLongitude: policyholder?.address?.geoCoordinatesLongitude,
    googlePlaceId: policyholder?.address?.googlePlaceId,
  });

  const [companyDetailsSectionData, setCompanyDetailsSectionData] = useState<
    PersonalDetailsCompanyDetailsData | undefined
  >({
    companyName: policyholder?.companyName || policyholderPrefillValues.companyName,
    registrationNumber: policyholder?.registrationNumber || policyholderPrefillValues.registrationNumber,
    contactPosition: policyholder?.contactPosition || policyholderPrefillValues.contactPosition,
    companyWebsiteUrl: policyholder?.companyWebsiteUrl || policyholderPrefillValues.companyWebsiteUrl,
    dateOfEstablishment: policyholder?.dateOfEstablishment || policyholderPrefillValues.dateOfEstablishment,
    subsidiaryCompanies: policyholder?.subsidiaryCompanies || policyholderPrefillValues.subsidiaryCompanies,
  });

  usePromise(async () => {
    if (props.policyholderType === PolicyholderEntityType.Individual) {
      setPolicyholderIsValid(identificationIsValid && contactDetailsIsValid && addressDetailsIsValid);
    } else {
      setPolicyholderIsValid(companyDetailsIsValid && addressDetailsIsValid);
    }
  }, [identificationIsValid, contactDetailsIsValid, addressDetailsIsValid, companyDetailsIsValid]);

  const onCreatePolicyholderClicked = async () => {
    setIsLoading(true);
    setError(undefined);
    const { error } = await createPolicyholderAction.execute();
    if (!error) {
      setIsPersonalDetailsCompleted(true);
    }

    setError(error);
    setIsLoading(false);
  };

  const createPolicyholderAction = usePromiseLazy(async () => {
    if (!productModuleDefinitionApplicationSchema || !productModuleDefinition) {
      throw new Error('Product module not found');
    }

    const line1 = addressDetailsSectionData?.addressLine1 || '';
    const line2 = addressDetailsSectionData?.addressLine2 || '';
    const suburb = addressDetailsSectionData?.suburb || '';
    const city = addressDetailsSectionData?.city || '';
    const areaCode = addressDetailsSectionData?.areaCode || '';
    const country = addressDetailsSectionData?.country || '';
    const geoCoordinatesLatitude = addressDetailsSectionData?.geoCoordinatesLatitude || '';
    const geoCoordinatesLongitude = addressDetailsSectionData?.geoCoordinatesLongitude || '';
    const googlePlaceId = addressDetailsSectionData?.googlePlaceId;

    const isAddressRequired = line1 || suburb || city || country || areaCode;

    // Use create for unauthenticated and upsert for authenticated
    const policyholder = await upsertPolicyholder({
      isCompany,
      environment,
      auth,
      data: {
        firstName: identificationSectionData?.firstName,
        lastName: identificationSectionData?.lastName,
        identification: {
          country:
            identificationSectionData?.idType === IdentificationType.Id
              ? 'ZA'
              : identificationSectionData?.identificationCountry,
          type: identificationSectionData?.idType,
          number: identificationSectionData?.identificationNumber,
          expirationDate: identificationSectionData?.identificationExpirationDate,
        },
        gender: identificationSectionData?.gender,
        dateOfBirth:
          identificationSectionData?.idType !== IdentificationType.Id
            ? identificationSectionData?.dateOfBirth
            : undefined,
        email: contactDetailsSectionData?.email,
        cellphone: {
          countryCode: contactDetailsSectionData?.cellphone?.countryCode,
          number: contactDetailsSectionData?.cellphone?.number,
          internationalNumber: contactDetailsSectionData?.cellphone?.number,
        },
        address:
          fetchifyAutoCompleteEnabled && fetchifyData
            ? {
                ...fetchifyData,
                line2,
                ...(suburb ? { suburb } : { suburb: fetchifyData?.suburb }),
              }
            : addressDetailsSectionData && isAddressRequired && addressOptIn
            ? {
                line1,
                line2,
                suburb,
                city,
                areaCode,
                country,
                geoCoordinatesLatitude,
                geoCoordinatesLongitude,
                googlePlaceId,
              }
            : undefined,
        registrationNumber: companyDetailsSectionData?.registrationNumber,
        companyName: companyDetailsSectionData?.companyName,
        companyWebsiteUrl: companyDetailsSectionData?.companyWebsiteUrl,
        contactPosition: companyDetailsSectionData?.contactPosition,
        subsidiaryCompanies: companyDetailsSectionData?.subsidiaryCompanies,
        dateOfEstablishment: companyDetailsSectionData?.dateOfEstablishment,
      },
      organizationId,
    });

    setPolicyholder(policyholder);
    setCurrentStepKey(StaticSceneStepKeys.Application);
    onNextCompleted && onNextCompleted();
  }, []);

  const { isLoading: creatingFromPrefill } = usePromise(async () => {
    const skipOnPrefill = siteConfig?.personalDetails.displayOptionalSections.skipOnPrefill;

    const policyholderPrefillValues = getPolicyholderDataFromPrefillValues({
      prefillValues: prefillValues?.personalDetails,
      selectedQuote,
    });

    if (skipOnPrefill && policyholderPrefillValues) {
      const isComplete = isPolicyholderPrefillComplete({
        identificationSectionData: setPolicyholderState({ policyholderPrefillValues }),
        addressDetailsSectionData: {
          addressLine1: policyholderPrefillValues.address?.addressLine1,
          addressLine2: policyholderPrefillValues.address?.addressLine2,
          areaCode: policyholderPrefillValues.address?.areaCode,
          city: policyholderPrefillValues.address?.city,
          country: policyholderPrefillValues.address?.country,
          suburb: policyholderPrefillValues.address?.suburb,
        },
        contactDetailsSectionData: {
          cellphone: incomingCellphoneCountryCodeToState(policyholderPrefillValues?.cellphone),
          email: policyholder?.email || policyholderPrefillValues?.email,
        },
        addressOptIn: policyholderPrefillValues.addressOptIn === PolicyholderAddressOptInOptions.Yes,
      });

      if (
        priorStepKey &&
        stepOrder[priorStepKey] > stepOrder.personalDetails &&
        skipOnPrefill &&
        (isComplete || isUnauthenticated)
      ) {
        setMasterIsMounting(false);

        return setStepProgress(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder.personalDetails - 1 < 0 ? 0 : stepOrder.personalDetails - 1,
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            sectionIndices,
          }),
        );
      }

      if (isUnauthenticated && !newPersonalDetailsAccepted) {
        return setMasterIsMounting(false);
      }

      if (isComplete && skipOnPrefill) {
        await createPolicyholderAction.execute();
      }

      return setMasterIsMounting(false);
    }
    setMasterIsMounting(false);
  }, [newPersonalDetailsAccepted]);

  usePromise(async () => {
    props.setIsLoading(isLoading);
  }, [isLoading]);

  if (creatingFromPrefill) {
    return (
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <WrappedLoadingInputs count={3} />
      </div>
    );
  }

  if (isUnauthenticated && !newPersonalDetailsAccepted) {
    return (
      <>
        <UnauthenticatedPolicyholderLookup
          isLoading={isLoading || isMounting}
          policyholderPrefillValues={policyholderPrefillValues}
          lookupPolicyholderError={error}
          isLookupPolicyholderModalOpen={isLookupPolicyholderModalOpen}
          foundPolicyholder={foundPolicyholder}
          productModule={productModule}
          isCompleted={isCompletedLookup}
          createPolicyholderSubmitButtonRef={createPolicyholderSubmitButtonRef}
          policyholderLookupFormData={policyholderLookupFormData}
          activeElement={lookupActiveElement}
          setLookupPolicyholderModalOpen={setLookupPolicyholderModalOpen}
          setPolicyholderLookupFormData={(policyholderLookupFormData) => {
            const policyholderPrefillValues = getPolicyholderDataFromPrefillValues({
              prefillValues: prefillValues?.personalDetails,
              selectedQuote,
              policyholderLookupFormData,
            });

            setIdentificationSectionData(setPolicyholderState({ policyholder, policyholderPrefillValues }));
            setContactDetailsSectionData({
              cellphone:
                incomingCellphoneCountryCodeToState(policyholder?.cellphone) ||
                incomingCellphoneCountryCodeToState(policyholderPrefillValues?.cellphone),
              email: policyholder?.email || policyholderPrefillValues?.email,
            });
            setAddressDetailsSectionData(
              setPolicyholderAddressState({
                addressDetailsSectionData,
                policyholder,
              }),
            );

            setCompanyDetailsSectionData({
              companyName: policyholder?.companyName || policyholderPrefillValues.companyName,
              registrationNumber: policyholder?.registrationNumber || policyholderPrefillValues.registrationNumber,
              contactPosition: policyholder?.contactPosition || policyholderPrefillValues.contactPosition,
              companyWebsiteUrl: policyholder?.companyWebsiteUrl || policyholderPrefillValues.companyWebsiteUrl,
              dateOfEstablishment: policyholder?.dateOfEstablishment || policyholderPrefillValues.dateOfEstablishment,
              subsidiaryCompanies: policyholder?.subsidiaryCompanies || policyholderPrefillValues.subsidiaryCompanies,
            });
            setPolicyholderLookupFormData(policyholderLookupFormData);
          }}
          setNewPersonalDetailsAccepted={setNewPersonalDetailsAccepted}
          setPolicyholderIsValid={setPolicyholderIsValid}
          setFoundPolicyholder={setFoundPolicyholder}
          onNextCompleted={onNextCompleted}
          setCurrentStepKey={setCurrentStepKey}
          setPolicyholder={setPolicyholder}
          setActiveElement={setLookupActiveElement}
          policyholder={policyholder}
          resetPolicyholderData={() => {
            resetPolicyholderToPrefill({
              prefillValues: policyholderPrefillValues,
              setIdentificationSectionData,
              setAddressDetailsSectionData,
              setCompanyDetailsSectionData,
              setContactDetailsSectionData,
              policyholderLookupFormData,
            });
          }}
        />
      </>
    );
  }

  return (
    <FormWrapperStyle>
      <ErrorAlert error={error} displayFieldError />
      {isPolicyholderTypeFieldDisplayed && (
        <PersonalDetailsTypeSection
          policyholderPrefillValues={policyholderPrefillValues}
          activeElement={activeElement}
          isCompleted={isCompleted}
          policyholderType={props.policyholderType}
          setActiveElement={setActiveElement}
          setPolicyholderType={props.setPolicyholderType}
        />
      )}
      {props.policyholderType === PolicyholderEntityType.Individual && (
        <>
          <IndividualPolicyholderForms
            policyholderLookupFormData={policyholderLookupFormData}
            activeElement={activeElement}
            isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
            identificationSectionData={identificationSectionData}
            productModule={productModule}
            policyholderPrefillValues={policyholderPrefillValues}
            isCompleted={isCompleted}
            contactDetailsSectionData={contactDetailsSectionData}
            addressOptIn={addressOptIn}
            addressDetailsSectionData={addressDetailsSectionData}
            setAddressOptIn={setAddressOptIn}
            setActiveElement={setActiveElement}
            fetchifyData={fetchifyData}
            setFetchifyData={setFetchifyData}
            setAddressDetailsIsValid={setAddressDetailsIsValid}
            setAddressDetailsSectionData={setAddressDetailsSectionData}
            setContactDetailsIsValid={setContactDetailsIsValid}
            setContactDetailsSectionData={setContactDetailsSectionData}
            setIdentificationIsValid={setIdentificationIsValid}
            setIdentificationSectionData={setIdentificationSectionData}
          />
        </>
      )}
      {props.policyholderType === PolicyholderEntityType.Company && (
        <CompanyPolicyholderForm
          productModule={productModule}
          fetchifyData={fetchifyData}
          setFetchifyData={setFetchifyData}
          isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
          personalDetailCompanyFormSectionData={companyDetailsSectionData}
          setCompanyDetailsIsValid={(companyDetailsIsValid) => setCompanyDetailsIsValid(companyDetailsIsValid)}
          setCompanyDetailsSectionData={(companyDetailsSectionData) =>
            setCompanyDetailsSectionData(companyDetailsSectionData)
          }
          policyholderPrefillValues={policyholderPrefillValues}
          setActiveElement={setActiveElement}
          activeElement={activeElement}
          isCompleted={isCompleted}
          addressDetailsSectionData={addressDetailsSectionData}
          setAddressDetailsIsValid={setAddressDetailsIsValid}
          setAddressDetailsSectionData={setAddressDetailsSectionData}
          contactDetailsSectionData={contactDetailsSectionData}
          setContactDetailsIsValid={setContactDetailsIsValid}
          setContactDetailsSectionData={setContactDetailsSectionData}
          identificationSectionData={identificationSectionData}
          setIdentificationIsValid={setIdentificationIsValid}
          setIdentificationSectionData={setIdentificationSectionData}
          addressOptIn={addressOptIn}
          setAddressOptIn={setAddressOptIn}
        />
      )}
      <button
        style={{ display: 'none' }}
        onClick={async () => await onCreatePolicyholderClicked()}
        ref={createPolicyholderSubmitButtonRef}
        type='submit'
      />
    </FormWrapperStyle>
  );
};
export interface PolicyholderPrefillValues {
  policyholderType?: PolicyholderEntityType;
  identification?: {
    type?: IdentificationType;
    number?: string;
    country?: string;
    expirationDate?: Moment;
    gender?: string;
  };
  firstName?: string;
  lastName?: string;
  email?: string;
  cellphone?: Cellphone;
  dateOfBirth?: Moment;
  gender?: Gender;
  address?: PersonalAddressData;
  addressOptIn?: string;
  // Company fields
  companyName?: string;
  registrationNumber?: string;
  companyWebsiteUrl?: string;
  contactPosition?: string;
  subsidiaryCompanies?: string[];
  dateOfEstablishment?: Moment;
}

export interface NetworkPolicyholderPrefillValues {
  policyholder_type?: PolicyholderEntityType;
  id?: {
    type?: IdentificationType;
    number?: string;
    country?: string;
    expiration_date?: Moment;
    gender?: string;
  };
  first_name?: string;
  last_name?: string;
  email?: string;
  cellphone?: Cellphone;
  date_of_birth?: Moment;
  gender?: Gender;
  address?: {
    line_1?: string;
    line_2?: string;
    country?: string;
    area_code?: string;
    suburb?: string;
    city?: string;
  };
  address_opt_in?: string;
  // Company fields
  company_name?: string;
  registration_number?: string;
  company_website_url?: string;
  contact_position?: string;
  subsidiary_companies?: string[];
  date_of_establishment?: Moment;
}

const setPolicyholderState = (params: {
  policyholder?: Policyholder;
  policyholderPrefillValues: PolicyholderPrefillValues;
}) => {
  const {
    FirstName,
    LastName,
    IdType,
    IdentificationNumber,
    IdentificationCountry,
    DateOfBirth,
    IdentificationExpirationDate,
    Gender,
  } = PersonalDetailsInputs;

  const { policyholder, policyholderPrefillValues } = params;
  const idType = policyholder?.identification?.type || policyholderPrefillValues?.identification?.type;

  const isTypeIsPassport = idType === IdentificationType.Passport;
  // const isTypeIsCustom = idType === IdentificationType.Custom;
  // const isEmailOrCellphone = idType && [IdentificationType.Cellphone, IdentificationType.Email].includes(idType);

  const prefillExpirationDate = policyholderPrefillValues.identification?.expirationDate;
  const existingPolicyholderExpirationDate = policyholder?.identification?.expirationDate;

  const prefillDateOfBirth = policyholderPrefillValues.dateOfBirth;
  const existingPolicyholderDateOfBirth = policyholder?.dateOfBirth;

  const prefillCountry = policyholderPrefillValues.identification?.country;
  const existingPolicyholderCountry = policyholder?.identification?.country;

  const prefillGender = policyholderPrefillValues.gender;
  const existingPolicyholderGender = policyholder?.gender;

  const gender = existingPolicyholderGender || prefillGender;
  const dateOfBirth = existingPolicyholderDateOfBirth || prefillDateOfBirth;

  return {
    [FirstName]: policyholder?.firstName || policyholderPrefillValues.firstName,
    [LastName]: policyholder?.lastName || policyholderPrefillValues.lastName,
    [IdType]: idType,
    [IdentificationNumber]: policyholder?.identification?.number || policyholderPrefillValues.identification?.number,
    [IdentificationCountry]: existingPolicyholderCountry || prefillCountry,
    [DateOfBirth]: dateOfBirth,
    [IdentificationExpirationDate]: isTypeIsPassport
      ? existingPolicyholderExpirationDate || prefillExpirationDate
      : undefined,
    [Gender]: gender,
  };
};

const setPolicyholderAddressState = (params: {
  addressDetailsSectionData: PersonalAddressData | undefined;
  policyholder: Policyholder | undefined;
}) => {
  const { addressDetailsSectionData, policyholder } = params;

  const addressLine1 = addressDetailsSectionData?.addressLine1 || policyholder?.address?.line1;

  const addressLine2 = addressDetailsSectionData?.addressLine2 || policyholder?.address?.line2;

  const areaCode = addressDetailsSectionData?.areaCode || policyholder?.address?.areaCode;

  const city = addressDetailsSectionData?.city || policyholder?.address?.city;

  const country = addressDetailsSectionData?.country || policyholder?.address?.country;

  const suburb = addressDetailsSectionData?.suburb || policyholder?.address?.suburb;

  const geoCoordinatesLatitude =
    addressDetailsSectionData?.geoCoordinatesLatitude || policyholder?.address?.geoCoordinatesLatitude;

  const geoCoordinatesLongitude =
    addressDetailsSectionData?.geoCoordinatesLongitude || policyholder?.address?.geoCoordinatesLongitude;

  const googlePlaceId = addressDetailsSectionData?.googlePlaceId || policyholder?.address?.googlePlaceId;

  return {
    addressLine1,
    addressLine2,
    areaCode,
    city,
    country,
    suburb,
    geoCoordinatesLatitude,
    geoCoordinatesLongitude,
    googlePlaceId,
  };
};
