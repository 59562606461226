import { CollectionModulePaymentMethod } from 'payment-methods/domain/payment-method';
import React from 'react';
import { ModuleData } from 'rootstrap/components/module-data/module-data-component';
import styled from 'styled-components';

// TODO depricate this in light of the function below
export const CollectionModulePaymentMethodSummaryModuleDataView = (params: {
  collectionModulePaymentMethod: CollectionModulePaymentMethod;
}) => {
  const { collectionModulePaymentMethod } = params;
  return (
    <StyledModuleDataWrapper>
      <tbody>
        <ModuleData isLoading={false} moduleData={collectionModulePaymentMethod.module} columnIndex={0} />
      </tbody>
    </StyledModuleDataWrapper>
  );
};

const StyledModuleDataWrapper = styled.table`
  width: 100%;
  padding: 50px;
  margin-top: 0px;
`;
