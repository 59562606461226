export enum Spacing {
  xxs = '4px',
  xs = '8px',
  sm = '16px',
  default = '20px',
  md = '32px',
  lg = '40px',
  xl = '48px',
  xxl = '56px',
}
