import { AlterationPackage, NetworkAlterationPackage } from 'alteration-hooks/domain/alteration-package';
import { Environment } from 'models/environment';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { JSONObject } from 'shared/utils';

interface Params {
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  policyId: string;
  alterationHookKey: string;
  data: JSONObject;
}

export const createAlterationPackages = async (params: Params) => {
  const { auth, environment, organizationId, alterationHookKey, policyId, data } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });
  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/policies/${policyId}/alteration-packages`,
    method: 'post',
    auth,
    body: {
      key: alterationHookKey,
      data,
    },
  });

  const networkAlterationPackages: NetworkAlterationPackage[] = await response.json();

  const alterationPackages = networkAlterationPackages.map((networkAlterationPackage) =>
    AlterationPackage.fromNetwork(networkAlterationPackage),
  );

  return alterationPackages;
};
