import moment, { Moment } from 'moment';
import { Construct } from '../../models/construct';
import { Policy, NetworkPolicy } from '../../policies/domain/policy';
import { BANVStatus } from './banv-status';
import { BANVResponse } from './banv-response';
import { Identification, IdentificationType } from 'policyholder/domain/policyholder-identification';
import { NetworkPolicyholder, Policyholder } from 'policyholder/domain/policyholder';
import { CollectionModule, NetworkCollectionModule } from 'collection-modules/domain/collection-module';
import { Bank } from './payee-bank-details';

export enum PaymentMethodType {
  DebitOrder = 'debit_order',
  Card = 'card',
  Eft = 'eft',
  External = 'external',
  CollectionModule = 'collection_module',
}

export enum AccountType {
  Cheque = 'cheque',
  Savings = 'savings',
}

export type PaymentMethodAll =
  | DebitOrderPaymentMethod
  | CardPaymentMethod
  | ExternalPaymentMethod
  | EftPaymentMethod
  | CollectionModulePaymentMethod;

export class PaymentMethod {
  readonly paymentMethodId: string;
  readonly type: PaymentMethodType;
  readonly createdAt: Moment;
  readonly organizationId: string;
  readonly policyholderId: string;
  readonly policyholder?: Policyholder;
  readonly policies?: Policy[];
}

export interface NetworkPaymentMethod {
  payment_method_id: string;
  type: PaymentMethodType;
  created_at: Moment;
  organization_id: string;
  policyholder_id: string;
  policyholder?: NetworkPolicyholder;
  policies?: NetworkPolicy[];
}

export interface NetworkDebitOrderPaymentMethod extends NetworkPaymentMethod {
  bank_details: NetworkDebitOrderPaymentMethodDetails;
}

interface NetworkCardPaymentMethod extends NetworkPaymentMethod {
  card: NetworkCardPaymentMethodDetail;
}

export interface NetworkExternalPaymentMethod extends NetworkPaymentMethod {
  external_reference?: string;
  banv_status: BANVStatus;
  blocked_reason?: string;
}

interface NetworkEftPaymentMethod extends NetworkPaymentMethod {}

export class DebitOrderPaymentMethod extends PaymentMethod {
  readonly bankDetails?: DebitOrderPaymentMethodDetails;

  constructor(init: Construct<DebitOrderPaymentMethod>) {
    super();
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkDebitOrderPaymentMethod) {
    return new DebitOrderPaymentMethod({
      paymentMethodId: init.payment_method_id,
      type: PaymentMethodType.DebitOrder,
      createdAt: moment(init.created_at),
      organizationId: init.organization_id,
      policyholderId: init.policyholder_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      bankDetails: DebitOrderPaymentMethodDetails.fromNetwork(init.bank_details),
    });
  }
}

export interface NetworkDebitOrderPaymentMethodDetails {
  account_holder: string;
  account_number: string;
  account_holder_identification?: {
    type: IdentificationType;
    number: string;
    country: string;
  };
  bank: Bank;
  banv_status: BANVStatus;
  blocked_reason?: string;
  branch_code: string;
  banv_response: BANVResponse;
  payment_method_id: string;
  account_type: AccountType;
}

export class DebitOrderPaymentMethodDetails {
  readonly paymentMethodId: string;
  readonly accountHolder: string;
  readonly accountHolderIdentification?: Identification;
  readonly accountNumber: string;
  readonly bank: Bank;
  readonly banvStatus: BANVStatus;
  readonly blockedReason?: string;
  readonly branchCode: string;
  readonly banvResponse: BANVResponse;
  readonly accountType?: AccountType;

  constructor(init: Construct<DebitOrderPaymentMethodDetails>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkDebitOrderPaymentMethodDetails) {
    return new DebitOrderPaymentMethodDetails({
      paymentMethodId: init.payment_method_id,
      accountHolder: init.account_holder,
      accountHolderIdentification: init.account_holder_identification && {
        type: init.account_holder_identification.type,
        country: init.account_holder_identification.country,
        number: init.account_holder_identification.number,
      },
      accountNumber: init.account_number,
      bank: init.bank,
      banvStatus: init.banv_status,
      blockedReason: init.blocked_reason,
      branchCode: init.branch_code,
      banvResponse: init.banv_response,
      accountType: init.account_type,
    });
  }
}

export class EftPaymentMethod extends PaymentMethod {
  constructor(init: Construct<EftPaymentMethod>) {
    super();
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkEftPaymentMethod) {
    return new EftPaymentMethod({
      paymentMethodId: init.payment_method_id,
      type: PaymentMethodType.Eft,
      createdAt: moment(init.created_at),
      organizationId: init.organization_id,
      policyholderId: init.policyholder_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
    });
  }
}

export interface NetworkCollectionModulePaymentMethod extends NetworkPaymentMethod {
  type: PaymentMethodType.CollectionModule;
  collection_module_key: string;
  collection_module_definition_id: string;
  module: Record<string, unknown>;
  collection_module: NetworkCollectionModule | undefined;
}

export class CardPaymentMethod extends PaymentMethod {
  readonly card: CardPaymentMethodDetail;

  constructor(init: Construct<CardPaymentMethod>) {
    super();
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkCardPaymentMethod) {
    return new CardPaymentMethod({
      paymentMethodId: init.payment_method_id,
      type: PaymentMethodType.Card,
      createdAt: moment(init.created_at),
      organizationId: init.organization_id,
      policyholderId: init.policyholder_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      card: CardPaymentMethodDetail.fromNetwork(init.card),
    });
  }
}

export interface NetworkCardPaymentMethodDetail {
  bin: string;
  brand: string;
  expiry_month: string;
  expiry_year: string;
  holder: string;
  last_4_digits: string;
}

export class CardPaymentMethodDetail {
  readonly bin: string;
  readonly brand: string;
  readonly expiryMonth: string;
  readonly expiryYear: string;
  readonly holder: string;
  readonly last4Digits: string;

  constructor(init: Construct<CardPaymentMethodDetail>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkCardPaymentMethodDetail) {
    return new CardPaymentMethodDetail({
      bin: init.bin,
      brand: init.brand,
      expiryMonth: init.expiry_month,
      expiryYear: init.expiry_year,
      holder: init.holder,
      last4Digits: init.last_4_digits,
    });
  }
}

export class ExternalPaymentMethod extends PaymentMethod {
  readonly externalReference?: string;
  readonly key?: string;
  readonly banvStatus: BANVStatus;
  readonly blockedReason?: string;

  constructor(init: Construct<ExternalPaymentMethod>) {
    super();
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkExternalPaymentMethod) {
    return new ExternalPaymentMethod({
      paymentMethodId: init.payment_method_id,
      type: PaymentMethodType.External,
      key: init.type,
      createdAt: moment(init.created_at),
      organizationId: init.organization_id,
      policyholderId: init.policyholder_id,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      externalReference: init.external_reference,
      banvStatus: init.banv_status,
      blockedReason: init.blocked_reason,
    });
  }
}

export class PaymentMethodFactory {
  static fromNetwork(init: any) {
    switch (init.type) {
      case PaymentMethodType.Card:
        return CardPaymentMethod.fromNetwork(init);
      case PaymentMethodType.DebitOrder:
        return DebitOrderPaymentMethod.fromNetwork(init);
      default:
        return ExternalPaymentMethod.fromNetwork(init);
    }
  }
}

export class CollectionModulePaymentMethod extends PaymentMethod {
  readonly type = PaymentMethodType.CollectionModule;
  readonly collectionModuleKey: string;
  readonly collectionModuleDefinitionId: string;
  readonly module: Record<string, unknown>;
  readonly collectionModule: CollectionModule | undefined;

  constructor(init: Construct<CollectionModulePaymentMethod>) {
    super();
    Object.assign(this, init);
  }

  /** We should use the common output map instead */
  public static fromNetwork(init: NetworkCollectionModulePaymentMethod) {
    return new CollectionModulePaymentMethod({
      paymentMethodId: init.payment_method_id,
      collectionModuleKey: init.collection_module_key,
      createdAt: moment(init.created_at),
      module: init.module,
      organizationId: init.organization_id,
      type: init.type,
      policyholder: init.policyholder ? Policyholder.fromNetwork(init.policyholder) : undefined,
      policies: init.policies ? init.policies.map(Policy.fromNetwork) : undefined,
      collectionModuleDefinitionId: init.collection_module_definition_id,
      policyholderId: init.policyholder_id,
      collectionModule: init.collection_module ? CollectionModule.fromNetwork(init.collection_module) : undefined,
    });
  }
}

export const paymentMethodFromNetwork = (networkPaymentMethod: NetworkPaymentMethod) => {
  const mapping: { [key in PaymentMethodType]: (networkPaymentMethod: any) => PaymentMethodAll } = {
    [PaymentMethodType.DebitOrder]: DebitOrderPaymentMethod.fromNetwork,
    [PaymentMethodType.External]: ExternalPaymentMethod.fromNetwork,
    [PaymentMethodType.Card]: CardPaymentMethod.fromNetwork,
    [PaymentMethodType.Eft]: EftPaymentMethod.fromNetwork,
    [PaymentMethodType.CollectionModule]: CollectionModulePaymentMethod.fromNetwork,
  };

  const fromNetwork = mapping[networkPaymentMethod.type] || ExternalPaymentMethod.fromNetwork;

  if (!fromNetwork) {
    throw new Error(`fromNetwork not defined for payment method with type: ${networkPaymentMethod.type}`);
  }

  const paymentMethod = fromNetwork(networkPaymentMethod);

  return paymentMethod;
};
