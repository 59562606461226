import React from 'react';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';
import { DescriptionContainer, DescriptionContent } from '../modal/fullscreen-modal-styles/styles';
import { SpacerLeft, SpacerRight } from '../modal/styles/form-overlay-styles';

interface Props {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  removeBorderRadius?: boolean;
  removeMarginBottom: boolean;
}

export const SplashHeader = (props: Props) => {
  const { removeMarginBottom, siteConfig, removeBorderRadius } = props;
  return (
    <SplashHeadingContainer
      id='splash-heading-container'
      removeBorderRadius={removeBorderRadius}
      removeMarginBottom={removeMarginBottom}
      siteConfig={siteConfig}
    >
      <SpacerLeft />
      <SplashHeadingContent />
      <SpacerRight />
    </SplashHeadingContainer>
  );
};

const SplashHeadingContainer = styled(DescriptionContainer)<{ removeBorderRadius?: boolean }>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
  ${({ removeBorderRadius }) => !removeBorderRadius && 'border-radius: 20px 20px 0px 0px;'}
  height: 272px;
`;

const SplashHeadingContent = styled(DescriptionContent)`
  position: absolute;
  right: 0px;
  left: 0px;
  z-index: -1;
  margin-top: -20px;
  border-radius: 20px 20px 0px 0px;
`;
