import { Environment } from 'models/environment';

interface Params {
  policyholderId: string;
  policyId: string;
  isConfirmationSkipped: boolean;
  redirectOnCompletedUrl: string | undefined;
  environment: Environment;
  baseUrl: string;
  confirmationUrl: string;
  history: any;
  applyPolicyDetailsToUrl: (props: {
    baseUrl: string;
    policyId: string;
    policyholderId: string;
    environment: Environment;
  }) => string;
}

export const handleRedirect = (params: Params) => {
  const {
    policyId,
    policyholderId,
    isConfirmationSkipped,
    environment,
    redirectOnCompletedUrl,
    applyPolicyDetailsToUrl,
    history,
    baseUrl,
    confirmationUrl,
  } = params;
  if (isConfirmationSkipped) {
    const exitRedirect =
      redirectOnCompletedUrl &&
      applyPolicyDetailsToUrl({ baseUrl: redirectOnCompletedUrl, environment, policyholderId, policyId });

    if (exitRedirect) {
      window.parent.location.href = exitRedirect;
    } else {
      // This state is not allowed by the embed config validation
      // Leaving it incase we decide to change that
      history.push(baseUrl);
    }
  } else {
    history.push(applyPolicyDetailsToUrl({ baseUrl: confirmationUrl, environment, policyholderId, policyId }));
  }
};
