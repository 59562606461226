import { Address } from 'policyholder/domain/policyholder';
import { PersonalAddressData } from '../views/physical-address-section';

export const getAddressStringForFetchifyDefault = (address: Partial<Address> | undefined) => {
  if (!address) {
    return '';
  }
  const { line1, suburb, city, areaCode } = address;

  let addressString = line1;

  if (suburb) {
    addressString += `, ${suburb}`;
  }

  if (city) {
    addressString += `, ${city}`;
  }

  if (areaCode) {
    addressString += `, ${areaCode}`;
  }

  return addressString;
};

export const getFetchifyDefaultValues = (addressDetailsSectionData: PersonalAddressData | undefined) => {
  if (addressDetailsSectionData?.addressLine1) {
    return getAddressStringForFetchifyDefault({
      line1: addressDetailsSectionData?.addressLine1,
      suburb: addressDetailsSectionData?.suburb,
      city: addressDetailsSectionData?.city,
      areaCode: addressDetailsSectionData?.areaCode,
    });
  }

  return undefined;
};
