import { ConsentSection } from 'policy-issuing/consent-section/consent-section';
import { PersonalDetails } from 'policy-issuing/personal-details/views/personal-details';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { SetStateAction, useEffect, useState } from 'react';
import { DefaultStepKeys } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { PrefillValues } from 'shared/domain/prefill-values';
import { JSONObject } from 'shared/utils';
import { getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { getStepHeaderDetails } from '../utils/helpers';
import { IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';

interface ProductModuleAndSchema {
  productModuleDefinitionQuoteSchema: ProductModuleDefinitionSchema;
  productModule: ProductModule;
  productModuleDefinitionApplicationSchema: ProductModuleDefinitionSchema;
}

export const PersonalDetailsStep = (params: {
  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
  getPriorStepKey: () => SetStateAction<any | undefined>;
  onNextCompleted: (() => void | Promise<void>) | undefined;
  policyholderLookupFormData: PolicyholderLookUpFormData;
  setPolicyholderLookupFormData: (v: PolicyholderLookUpFormData) => void;
  isLoadingProductModule: boolean;
  isPrePersonalDetailsConsentChecked: boolean;
  setIsPrePersonalDetailsConsentChecked: (isChecked: boolean) => void;
  applicationIsLoading: boolean | undefined;
  policyholderIsLoading: boolean;
  policyholderIsValid: boolean;
  prefillValues: PrefillValues;
  setIsPersonalDetailsCompleted: (isCompleted: boolean) => void;
  policyholder: Policyholder | undefined;
  setPolicyholderIsLoading: (isLoading: boolean) => void;
  applicationSchemaFormData: JSONObject | undefined;
  productModuleAndSchema: ProductModuleAndSchema | undefined;
  createPolicyholderSubmitButtonRef: React.RefObject<HTMLButtonElement>;
  setPolicyholder: (policyholder: Policyholder) => void;
  setPolicyholderIsValid: (isValid: boolean) => void;
  isPersonalDetailsCompleted: boolean;
  stepOrder: Record<IssuingSceneStepKeys, number>;
  selectedQuote: Quote | undefined;
  isCreatingPolicyholderFromPrefill: boolean;
  setIsCreatingPolicyholderFromPrefill: (value: boolean) => void;
  sectionIndices: number[];
}) => {
  const {
    getPriorStepKey,
    setPriorStepKey,
    isLoadingProductModule,
    onNextCompleted,
    setCurrentStepKey,
    isPrePersonalDetailsConsentChecked,
    setIsPrePersonalDetailsConsentChecked,
    applicationIsLoading,
    policyholderIsLoading,
    policyholderIsValid,
    prefillValues,
    setPolicyholderIsLoading,
    policyholder,
    applicationSchemaFormData,
    productModuleAndSchema,
    createPolicyholderSubmitButtonRef,
    setPolicyholder,
    setPolicyholderIsValid,
    isPersonalDetailsCompleted,
    setIsPersonalDetailsCompleted,
    stepOrder,
    policyholderLookupFormData,
    setPolicyholderLookupFormData,
    selectedQuote,
    isCreatingPolicyholderFromPrefill,
    setIsCreatingPolicyholderFromPrefill,
    sectionIndices,
  } = params;
  const { siteConfig } = useSiteConfigContext();
  const [addressOptIn, setAddressOptIn] = useState<boolean | undefined>(prefillValues.personalDetails?.address_opt_in);
  const [isLoadingUnauthenticated, setIsLoadingUnauthenticated] = useState<boolean>(false);
  const prePersonalDetailsCompliance = siteConfig?.prePersonalDetailsCompliance;
  const personalDetails = siteConfig?.personalDetails;
  const defaultPersonalDetailsDescription = "Let's get to know you. Please complete your personal details below.";
  const { stepCompletedTrack } = useMixpanelTrack();

  const priorStepKey = getPriorStepKey();
  // console.log('Prior step key: ', priorStepKey);
  const priorStepHeaderDetails = getStepHeaderDetails(priorStepKey, siteConfig);
  // console.log('Prior step details: ', priorStepHeaderDetails);

  const prePersonalDetailsRequired = Boolean(
    siteConfig?.prePersonalDetailsCompliance &&
      siteConfig.prePersonalDetailsCompliance.displayOptionalSections.displayPrePersonalDetailsCompliance,
  );

  const productModule = productModuleAndSchema?.productModule;
  const allowedPolicyHolderTypes = productModule?.productModuleDefinition?.settings.policyholder.entityTypes;
  const isPolicyholderTypeFieldDisplayed = Boolean(allowedPolicyHolderTypes && allowedPolicyHolderTypes.length > 1);
  const [policyholderType, setPolicyholderType] = useState(
    allowedPolicyHolderTypes?.length === 1 ? allowedPolicyHolderTypes[0] : undefined,
  );

  useEffect(() => {
    setPolicyholderType(allowedPolicyHolderTypes?.length === 1 ? allowedPolicyHolderTypes[0] : undefined);
  }, [productModule?.productModuleDefinition?.settings.policyholder.entityTypes]);

  const productModuleDefinition = productModuleAndSchema?.productModule.productModuleDefinition;
  const productModuleDefinitionApplicationSchema = productModuleAndSchema?.productModuleDefinitionApplicationSchema;

  return [
    {
      key: StaticSceneStepKeys.PrePersonalDetailsConsent,
      title: prePersonalDetailsCompliance?.wording.title,
      stepName: DefaultStepKeys.PrePersonalDetailsConsent,
      description: getWording({ wording: prePersonalDetailsCompliance?.wording.description }),
      onBackClick: () => undefined,
      continueButtonProps: {
        isLoading: isLoadingProductModule,
        disabled: !isPrePersonalDetailsConsentChecked,
        isNextButtonHidden: false,
      },
      body: () => (
        <ConsentSection
          isRequired={prePersonalDetailsRequired}
          stepOrder={stepOrder}
          step={StaticSceneStepKeys.PrePersonalDetailsConsent}
          content={getWording({ wording: prePersonalDetailsCompliance?.wording.content })}
          priorStepKey={getPriorStepKey()}
          setIsConsentChecked={(isConsentChecked) => setIsPrePersonalDetailsConsentChecked(isConsentChecked)}
          setStepProgress={(step) => setCurrentStepKey(step)}
          setPriorStepKey={(step) => setPriorStepKey(step)}
          isLoading={isLoadingProductModule}
          isConsentChecked={isPrePersonalDetailsConsentChecked}
          sectionIndices={sectionIndices}
        />
      ),
      onNextClick: async () => {
        stepCompletedTrack({
          stepName: MixpanelStepNames.ConsentPrePersonalDetails,
        });
        onNextCompleted && onNextCompleted();
      },
    },
    {
      key: StaticSceneStepKeys.PersonalDetails,
      title: isCreatingPolicyholderFromPrefill ? priorStepHeaderDetails.title : personalDetails?.wording.title,
      stepName: DefaultStepKeys.PersonalDetails,
      description: isCreatingPolicyholderFromPrefill
        ? priorStepHeaderDetails.description
        : personalDetails?.wording.description || defaultPersonalDetailsDescription,
      onBackClick: () => undefined,
      continueButtonProps: {
        isLoading: applicationIsLoading || policyholderIsLoading || isLoadingUnauthenticated, //Change to isCreatingPolicyholder
        disabled: !policyholderIsValid || policyholderIsLoading || isLoadingUnauthenticated,
        isNextButtonHidden: false,
      },
      body: () => (
        <PersonalDetails
          setIsCreatingPolicyholderFromPrefill={setIsCreatingPolicyholderFromPrefill}
          policyholderLookupFormData={policyholderLookupFormData}
          setPolicyholderLookupFormData={setPolicyholderLookupFormData}
          stepOrder={stepOrder}
          prefillValues={prefillValues}
          priorStepKey={getPriorStepKey()}
          setIsLoading={(policyholderIsLoading) => setPolicyholderIsLoading(policyholderIsLoading)}
          policyholder={policyholder}
          setStepProgress={(step) => setCurrentStepKey(step)}
          applicationSchemaFormData={applicationSchemaFormData}
          productModule={productModuleAndSchema?.productModule}
          createPolicyholderSubmitButtonRef={createPolicyholderSubmitButtonRef}
          setPolicyholder={(policyholder) => setPolicyholder(policyholder)}
          setCurrentStepKey={(currentStepKey) => setCurrentStepKey(currentStepKey)}
          setPolicyholderIsValid={(policyholderIsValid) => setPolicyholderIsValid(policyholderIsValid)}
          productModuleDefinition={productModuleDefinition}
          productModuleDefinitionApplicationSchema={productModuleDefinitionApplicationSchema}
          addressOptIn={addressOptIn}
          setAddressOptIn={setAddressOptIn}
          onNextCompleted={() => onNextCompleted && onNextCompleted()}
          isCompleted={isPersonalDetailsCompleted}
          isCompletedLookup={policyholderIsValid}
          isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
          policyholderType={policyholderType}
          setPolicyholderType={setPolicyholderType}
          selectedQuote={selectedQuote}
          setIsPersonalDetailsCompleted={setIsPersonalDetailsCompleted}
          sectionIndices={sectionIndices}
        />
      ),
      onNextClick: async () => {
        stepCompletedTrack({
          stepName: MixpanelStepNames.PersonalDetails,
          extraProperties: {
            policyholder: {
              identificationType: policyholder?.identification?.type,
              identificationCountry: policyholder?.identification?.country,
              city: policyholder?.address?.city,
              country: policyholder?.address?.country,
              dateOfBirth: policyholder?.dateOfBirth?.format('YYYY-MM-DD'),
              gender: policyholder?.gender,
            },
          },
        });
        createPolicyholderSubmitButtonRef?.current?.click();
      },
    },
  ];
};
