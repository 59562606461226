import { ArrowIcon } from 'assets/arrow-icon';
import React, { useEffect, useState } from 'react';
import { devices } from 'rootstrap/global-styles/devices';
import { getColor } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { getPremiumDisplay } from 'shared/utils';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import NewSpinner, { AnimationTypes, SpinnerSize } from '../spinner/new-spinner';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

interface ProgressBarProps {
  total: number;
  stepKey: number;
  totalColor: string;
  completedColor: string;
  navigateBack: (activeStepKey: IssuingSceneStepKeys) => void;
  activeStepKey: IssuingSceneStepKeys;
  firstStep: IssuingSceneStepKeys;
  premiumDisplay: ProgressBarPremiumDisplay | undefined;
  isLoadingBackButton: boolean;
}

export interface ProgressBarPremiumDisplay {
  premiumValue?: number;
  billingFrequency?: PolicyBillingFrequency;
  currency?: Currency;
  isLoading?: boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    total,
    stepKey,
    totalColor,
    completedColor,
    navigateBack,
    activeStepKey,
    firstStep,
    premiumDisplay,
    isLoadingBackButton = false, // Destructured here
  } = props;

  const { siteConfig } = useSiteConfigContext();
  const percentage = (total !== 0 ? (stepKey / total) * 100 : 0).toFixed(0);
  const defaultHeight = '8px';
  const [backButtonEnabled, setBackButtonEnabled] = useState(true);

  const premiumValueColor = getColor({ siteConfig, color: 'primary' });

  const premiumInProgressBar = siteConfig?.header.displayOptionalSections.premiumInProgressBar;

  useEffect(() => {
    const landingPageDisabled = siteConfig?.landing.displayOptionalSections.displayLandingPage === false;

    const isEnabled = !landingPageDisabled || (landingPageDisabled && activeStepKey !== firstStep);
    setBackButtonEnabled(isEnabled);
  }, [activeStepKey, siteConfig, firstStep]);

  const formattedPremium =
    premiumDisplay?.premiumValue && premiumDisplay?.billingFrequency && premiumDisplay?.currency
      ? getPremiumDisplay({
          premiumValue: premiumDisplay.premiumValue,
          billingFrequency: premiumDisplay.billingFrequency,
          currency: premiumDisplay.currency,
        })
      : undefined;

  return (
    <ProgressBarStyle>
      {backButtonEnabled && (
        <ArrowIconWrapper
          id='progress-bar-back-button'
          isDisabled={isLoadingBackButton}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isLoadingBackButton) {
              return;
            }
            scrollTo({
              scrollToId: 'form-overlay-content-wrapper',
              containerId: 'form-overlay-content-wrapper',
            });
            navigateBack(activeStepKey);
          }}
        >
          {isLoadingBackButton ? (
            <NewSpinner
              animation={AnimationTypes.Border}
              size={SpinnerSize.sm}
              color={getColor({ siteConfig, color: 'primary' })}
            />
          ) : (
            <ArrowIcon
              id='progress-bar-arrow-back-button'
              color={getColor({ siteConfig, color: 'disabled' })}
              size={22}
            />
          )}
        </ArrowIconWrapper>
      )}
      <Container>
        <Total height={defaultHeight} color={totalColor}>
          <Completed percentage={percentage} height={defaultHeight} color={completedColor} />
        </Total>
        {!premiumInProgressBar && (
          <div>
            <InfoSpan color={totalColor}>{percentage}%</InfoSpan>
          </div>
        )}
        {premiumInProgressBar && (
          <div>
            <PremiumSpan id='progress-bar-premium' color={premiumValueColor}>
              {!premiumDisplay?.isLoading ? (
                formattedPremium
              ) : (
                <NewSpinner
                  animation={AnimationTypes.Border}
                  size={SpinnerSize.sm}
                  color={getColor({ siteConfig, color: 'primary' })}
                />
              )}
            </PremiumSpan>
          </div>
        )}
      </Container>
    </ProgressBarStyle>
  );
};

const Total = styled.div<{ height: string; color: string }>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

const Completed = styled.div<{ percentage: string; height: string; color: string }>`
  width: ${({ percentage }) => percentage}%;
  height: ${({ height }) => height};
  background-color: ${({ color }) => color};
  border-radius: 5px;

  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 500ms ease-in-out;
  -o-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
`;

const InfoSpan = styled.span<{ color: string }>`
  padding-left: 20px;
  font-size: 12px;
  white-space: nowrap;
  color: ${({ color }) => color};
`;

const PremiumSpan = styled.span<{ color: string }>`
  padding-left: 20px;
  font-size: 16px;
  white-space: nowrap;
  color: ${({ color }) => color};
`;

const ProgressBarStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
`;

export const ArrowIconWrapper = styled.div<{ isDisabled?: boolean }>`
  margin-right: 20px;
  display: flex;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  transition: opacity 0.3s ease, cursor 0.3s ease;
`;
