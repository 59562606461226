import { Application } from 'applications/domain/application';
import { Environment } from 'models/environment';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import { EmbedAuth } from 'shared/api';
import { camelToSnake } from 'utils';
import { compileHandleBars } from './compile-handlebars';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  environment: Environment;
  policy?: Policy;
  policyholder: Policyholder;
  application?: Application;
  siteConfigSummaryWording: { label: string; content: string }[];
}

export const compileHandleBarsWithLabels = async (params: Params) => {
  const { siteConfigSummaryWording, auth, environment, organizationId, policy, policyholder, application } = params;
  const handleBarValues = siteConfigSummaryWording.map((w) => w.content);

  const mergeVars: any = {};

  if (policy) {
    mergeVars.policy = { ...policy, policyholder };
  }

  if (application) {
    mergeVars.application = { ...application, policyholder };
  }

  const sanitizedMergeVars = camelToSnake(mergeVars);

  const compiledHandleBars = await compileHandleBars({
    auth,
    organizationId,
    environment,
    content: handleBarValues,
    mergeVars: sanitizedMergeVars,
  });

  return siteConfigSummaryWording.map((w, i) => ({ label: w.label, content: compiledHandleBars[i] }));
};
