import React from 'react';
import styled from 'styled-components';
import { FormWrapperStyle } from '../root-schema-form/root-schema-form';

export const LoadingLines = (params: { lines?: number[]; height?: number }) => {
  const { lines, height } = params;
  return (
    <div>
      {(lines || [70, 100, 80, 60, 90]).map((width, index) => (
        <div id={`loading-line-${index}`} key={index} style={{ paddingTop: !!index ? '20px' : '0px' }}>
          <LoadingLine width={width} height={height} />
        </div>
      ))}
    </div>
  );
};

export const LoadingLine = styled.div<{ width: number; height?: number; absoluteHeight?: boolean }>`
  background: linear-gradient(-45deg, rgb(254 254 254), #dbdfe9);
  background-size: 150% 150%;
  animation: gradient 2s ease infinite;
  height: 20px;
  border-radius: 4px;
  width: ${({ width }) => `${width}%`};
  height: ${({ height, absoluteHeight }) => (height ? `${height}${absoluteHeight ? 'px' : 'vh'}` : '20px')};
`;

export const LoadingImage = (params: { lines?: number[] }) => {
  const lines = params.lines || [100, 100, 100];
  return (
    <div style={{ height: '100%', padding: '10% 15%' }}>
      {lines.map((width, index) => (
        <div id={`loading-line-${index}`} key={index} style={{ paddingBottom: '20px' }}>
          <LoadingLine height={index === 0 ? 50 : 10} width={width} />
        </div>
      ))}
    </div>
  );
};

export const LoadingInputs = (props: { count: number }) => {
  return (
    <div className='loading-inputs'>
      {[...Array(props.count)].map((_, i) => (
        <div>
          <div style={{ paddingBottom: '20px' }}>
            <LoadingLine height={42} width={100} absoluteHeight />
          </div>
          <div style={{ paddingBottom: '20px' }}>
            <LoadingLine height={61} width={100} absoluteHeight />
          </div>
          <div style={{ paddingBottom: '50px' }}>
            <LoadingLine height={61} width={100} absoluteHeight />
          </div>
        </div>
      ))}
    </div>
  );
};

export const WrappedLoadingInputs = (props: { count: number }) => {
  return (
    <FormWrapperStyle>
      <div className='loading-inputs'>
        {[...Array(props.count)].map((_, i) => (
          <div>
            <div style={{ paddingBottom: '20px' }}>
              <LoadingLine height={42} width={100} absoluteHeight />
            </div>
            <div style={{ paddingBottom: '20px' }}>
              <LoadingLine height={61} width={100} absoluteHeight />
            </div>
            <div style={{ paddingBottom: '50px' }}>
              <LoadingLine height={61} width={100} absoluteHeight />
            </div>
          </div>
        ))}
      </div>
    </FormWrapperStyle>
  );
};
