import React from 'react';

export const Icon16PXShield = (params: { fill: string; style?: React.CSSProperties }) => (
  <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_12800_378074)'>
      <path
        d='M17.9075 1.79346C12.5145 2.84491 9.77628 0.58857 9.4079 0.255791C9.4079 0.255791 9.4003 0.223004 9.38582 0.235791C9.37171 0.222349 9.36411 0.255791 9.36411 0.255791C8.995 0.58857 6.25746 2.8459 0.864169 1.79346C0.864169 1.79346 0.0362129 10.53 3.18013 14.0938C6.53791 17.9006 9.37171 19.0518 9.38582 19.0416C9.39994 19.0514 12.2583 17.9226 15.5919 14.0938C18.7105 10.5123 17.9075 1.79346 17.9075 1.79346Z'
        fill={params.fill}
      />
      <path
        d='M16.0002 4.22968C12.1075 4.98889 9.66752 3.27653 9.4012 3.036C9.4012 3.036 9.39586 3.01184 9.38558 3.02151C9.37529 3.01184 9.36957 3.036 9.36957 3.036C9.10287 3.27757 6.68845 4.98371 2.79537 4.22519C2.79537 4.22519 2.63649 10.4503 4.90574 13.0223C7.32968 15.7693 9.37529 16.5996 9.38558 16.5927C9.39586 16.5996 11.459 15.7848 13.865 13.0223C16.1164 10.4372 16.0002 4.22968 16.0002 4.22968Z'
        fill='white'
      />
      <path
        d='M13.3155 6.41659C10.9498 6.87422 9.46696 5.84205 9.30511 5.69706C9.30511 5.69706 9.30187 5.6825 9.29562 5.68833C9.28937 5.6825 9.28589 5.69706 9.28589 5.69706C9.12381 5.84267 7.65648 6.8711 5.29052 6.41388C5.29052 6.41388 5.19396 10.1663 6.57307 11.7166C8.04618 13.3724 9.28937 13.8729 9.29562 13.8687C9.30187 13.8729 10.5557 13.3818 12.0179 11.7166C13.3862 10.1584 13.3155 6.41659 13.3155 6.41659Z'
        fill={params.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_12800_378074'>
        <rect width='17.2344' height='18.8091' fill='white' transform='translate(0.765625 0.232422)' />
      </clipPath>
    </defs>
  </svg>
);
