import { clearLocalStorage } from 'utils';
import { NetworkError, NetworkErrorType } from './error';

export interface RequestInput<TData = object> {
  url: string;
  isLocal?: boolean;
  forceLive?: boolean;
  data?: TData;
  downloadProgressCallback?: (percent: number) => void;
  uploadProgressCallback?: (percent: number) => void;
}

export function getQueryString(data: object) {
  const components = [];
  for (const key in data) {
    components.push(`${encodeURIComponent(key)}=${encodeURIComponent((data as any)[key])}`);
  }

  return components.join('&');
}

export function get<TReturn = string | object | object[]>(input: RequestInput | string): Promise<TReturn> {
  if (typeof input === 'string') {
    input = { url: input };
  } else if (typeof input.data === 'object') {
    input.url = `${input.url}?${getQueryString(input.data)}`;
  }

  return makeRequest<TReturn>('GET', input);
}

function makeRequest<TReturn = string | object | object[]>(
  type: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  input: RequestInput,
) {
  return new Promise<TReturn>((resolve, reject) => {
    Requestor.doRequest<TReturn>(type, input).then(resolve).catch(reject);
  });
}

export class Requestor {
  static getNewXhr() {
    return new XMLHttpRequest();
  }

  static doRequest<TReturn = string | object | object[], TData = object>(
    type: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    input: RequestInput<TData>,
  ) {
    return new Promise<TReturn>((resolve, reject) => {
      const xhr = Requestor.getNewXhr();

      let localUrl = 'sandbox';
      let sandbox = input.forceLive === undefined ? true : input.forceLive;
      const orgId = window.location.pathname.split('/')[2];
      if (!input.forceLive && orgId) {
        const key = `${orgId}_sandbox`;
        const entry = localStorage.getItem(key);
        if (entry !== null) {
          sandbox = entry === 'true';
        }
      }

      const date = new Date();
      date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
      const expires = `; expires=${date.toUTCString()}`;
      document.cookie = `sandbox=${sandbox ? 'true' : 'false'}${expires}; path=/`;

      if (!sandbox) {
        localUrl = 'root';
      }

      const urlToUse = input.isLocal === true ? input.url : `/${localUrl}-api/v1${input.url}`;
      xhr.open(type, urlToUse);
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
          return;
        }

        if (xhr.status >= 200 && xhr.status < 300) {
          if ((xhr.getResponseHeader('Content-Type') || '').includes('application/json')) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            resolve(xhr.responseText as any);
          }
        } else {
          // 404 {"error":true,"message":"Not found"}
          // 502 Bad Gateway HTML
          // 400 {"error":true,"message":"Whatever"}
          // 400 {"error":"invalid body","details":[{"message":"\"text\" is required","path":["text"],"type":"any.required","context":{"key":"text","label":"text"}}]}
          // 400 content-type: text/html; start_date is badly formed, expected format YYYYMMDD
          // TOOD:504

          if (xhr.status >= 500 || xhr.status === 400) {
            try {
              let error: any = { text: xhr.responseText };
              try {
                error = JSON.parse(xhr.responseText);
              } catch (error_) {}

              const shouldIgnoreReport =
                error && ((error.error && error.error.type === 'validation_error') || error.type === 'user_activated');

              if (!shouldIgnoreReport) {
                (window as any).reportError(new Error('Network error'), 'network', {
                  code: xhr.status,
                  reqType: type,
                  response: error,
                  url: urlToUse,
                });
              }
            } catch (error) {}
          }

          if (xhr.status === 400) {
            try {
              const data = JSON.parse(xhr.responseText);
              if (typeof data.error === 'object') {
                reject(
                  new NetworkError(
                    400,
                    NetworkErrorType.BadRequest,
                    data.error.message,
                    xhr.responseText,
                    data.error.errors,
                  ),
                );
              } else if (data.error === 'invalid body') {
                reject(new NetworkError(400, NetworkErrorType.BadRequest, data.details[0].message, xhr.responseText));
              } else if (data.error === true) {
                reject(new NetworkError(400, NetworkErrorType.BadRequest, data.message, xhr.responseText));
              } else {
                reject(
                  new NetworkError(
                    400,
                    NetworkErrorType.BadRequest,
                    data.error && data.error.message,
                    xhr.responseText,
                  ),
                );
              }
            } catch (error) {
              reject(
                new NetworkError(400, NetworkErrorType.BadRequest, xhr.responseText.split('\n')[0], xhr.responseText),
              );
            }

            return;
          }

          if (xhr.status === 401) {
            clearLocalStorage();
            window.location.href = '/';
            return;
          }

          if (xhr.status === 403) {
            clearLocalStorage();
            window.location.href = '/';
            return;
          }

          if (xhr.status === 404) {
            reject(new NetworkError(404, NetworkErrorType.NotFound, xhr.responseText, xhr.responseText));
            return;
          }

          if (xhr.status >= 500) {
            reject(
              new NetworkError(xhr.status, NetworkErrorType.InternalServer, 'Internal server error', xhr.responseText),
            );
            return;
          }

          // TODO: better handling of unknown errors.
          reject(new NetworkError(xhr.status, NetworkErrorType.Unknown, xhr.responseText, xhr.responseText));
        }
      };

      if (input.downloadProgressCallback) {
        xhr.onprogress = (event: ProgressEvent) => {
          if (event.lengthComputable) {
            const progress = Math.ceil((event.loaded / event.total) * 100);
            (input.downloadProgressCallback as any)(progress);
          }
        };
      }

      if (input.uploadProgressCallback && xhr.upload) {
        xhr.upload.onprogress = (event: ProgressEvent) => {
          if (event.lengthComputable) {
            const progress = Math.ceil((event.loaded / event.total) * 100);
            (input.uploadProgressCallback as any)(progress);
          }
        };
      }

      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('login_token')}`);
      xhr.setRequestHeader('X-SANDBOX', sandbox ? 'true' : 'false');

      if (input.data) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(input.data));
      } else {
        xhr.send();
      }
    });
  }
}
