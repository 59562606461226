import {
  NetworkRenderCreatePaymentMethod,
  RenderCreatePaymentMethod,
} from 'collection-modules/domain/render-create-payment-method';
import { Environment } from 'models/environment';
import { AuthTypes, EmbedAuth, apiRequest, getApiHost } from 'shared/api';
import { buildApiUrl } from 'shared/utils';

interface UpdateApplicationParams {
  policyholderId: string;
  applicationId?: string;
  policyId?: string;
  organizationId: string;
  environment: Environment;
  auth: EmbedAuth;
  paymentMethodId: string;
  collectionModuleKey: string;
  collectionModuleDefinitionId: string;
}

export const renderViewPaymentMethodSummary = async (params: UpdateApplicationParams) => {
  const {
    applicationId,
    policyholderId,
    auth,
    environment,
    organizationId,
    policyId,
    collectionModuleKey,
    collectionModuleDefinitionId,
    paymentMethodId,
  } = params;
  const queryParams = {
    policy_id: policyId,
    application_id: applicationId,
    policyholder_id: policyholderId,
    ...(collectionModuleDefinitionId ? { collection_module_definition_id: collectionModuleDefinitionId } : {}),
  };

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/collection-modules/${collectionModuleKey}/payment-method/${paymentMethodId}/render-view-payment-method-summary`
      : `/v1/insurance/collection-modules/${collectionModuleKey}/payment-method/${paymentMethodId}/render-view-payment-method-summary`;

  const response = await apiRequest({
    url: buildApiUrl(`${getApiHost({ environment })}${url}`, queryParams),
    method: 'get',
    auth,
  });

  const networkRenderCreatePaymentMethod: NetworkRenderCreatePaymentMethod = await response.json();
  return RenderCreatePaymentMethod.fromNetwork(networkRenderCreatePaymentMethod);
};
