import React from 'react';
import { StyledManagePaymentMethodCard } from '../styles';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { Policy } from 'policies/domain/policy';
import { Bank, prettyBankName } from 'payment-methods/domain/payee-bank-details';
import { formatBillingDate } from '../utils';
import { UpdateBillingDayButton } from './components/update-billing-day-button';
import { DebitOrderPaymentMethod } from 'payment-methods/domain/payment-method';

export const ManageDebitOrderPaymentMethod = (props: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  paymentMethod: DebitOrderPaymentMethod;
  policy: Policy;
  setShowUpdateBillingDay: (showUpdateBillingDay: boolean) => void;
}) => {
  const { paymentMethod, siteConfig, policy, setShowUpdateBillingDay } = props;
  return (
    <StyledManagePaymentMethodCard siteConfig={siteConfig}>
      <p className='header'>Debit order</p>
      <p className='details'>
        {prettyBankName(paymentMethod.bankDetails?.bank as Bank)} {paymentMethod.bankDetails?.accountNumber}
      </p>
      {policy.nextBillingDate && (
        <p className='billing'>Next billing day: {formatBillingDate(policy.nextBillingDate)}</p>
      )}
      <UpdateBillingDayButton setShowUpdateBillingDay={setShowUpdateBillingDay} />
    </StyledManagePaymentMethodCard>
  );
};
