import { Construct } from 'models/construct';

export class EmbedSession {
  readonly embedSessionId: string;

  constructor(init: Construct<EmbedSession>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkEmbedSession): EmbedSession {
    return new EmbedSession({
      embedSessionId: init.embed_session_id,
    });
  }
}

export class NetworkEmbedSession {
  embed_session_id: string;
}
