import React, { ReactNode } from 'react';
import { FormGroup, Label, FormText, FormFeedback } from 'reactstrap';
import { globalStyles } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled, { css } from 'styled-components';
import { getSteppedFormLabelColor } from './new-fields/utils/stepped-form';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { isString } from 'lodash';

export const Field = (params: {
  label: string | ReactNode;
  errors?: string[];
  helpText?: string;
  isRequired?: boolean;
  children: ReactNode;
  style: React.CSSProperties | undefined;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined;
  centerTitle?: boolean;
  cursor?: string;
  name: string;
  disableTitle: boolean | undefined;
  ref?: any;
  isActive: boolean;
  isTouched: boolean;
  hiddenComponent?: boolean;
}) => {
  const {
    ref,
    children,
    label,
    errors,
    helpText,
    isRequired,
    style,
    onClick,
    centerTitle,
    cursor,
    disableTitle,
    isActive,
    isTouched,
    hiddenComponent,
  } = params;

  const { siteConfig } = useSiteConfigContext();

  return (
    <StyledFormGroup
      ref={ref}
      isActive={isActive}
      style={{ ...style, cursor, ...(hiddenComponent ? { display: 'none' } : {}) }}
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
    >
      {!disableTitle && label && (
        <StyledLabel siteConfig={siteConfig} centerTitle={centerTitle} isActive={isActive || isTouched}>
          <StyledLabelContents>
            <DisplayMarkdown label={label} />
            {shouldAddAsterisk(label, isRequired) && <span>&nbsp;{'*'}</span>}
          </StyledLabelContents>
        </StyledLabel>
      )}
      {children}
      {helpText && <StyledFormText>{helpText}</StyledFormText>}
      {errors &&
        errors.map((error) => (
          <StyledFormFeedback siteConfig={siteConfig} key={error}>
            {error}
          </StyledFormFeedback>
        ))}
    </StyledFormGroup>
  );
};

export const DisplayMarkdown = (params: { label: string | ReactNode }) => {
  const { label } = params;
  return (
    <>
      {typeof label === 'string' ? (
        <ReactMarkdown
          linkTarget='_blank'
          components={{
            p: ({ node, children }) => <>{children}</>,
          }}
          children={label}
        />
      ) : (
        label
      )}
    </>
  );
};

const StyledLabelContents = styled.span`
  p {
    display: inline-block;
  }

  span {
    display: inline-block;
  }
`;

const StyledFormFeedback = styled(FormFeedback)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: block;
  color: ${({ siteConfig }) => getColor({ siteConfig, color: 'warning' })};
`;

const StyledFormText = styled(FormText)`
  color: ${globalStyles.colors.Superscript};
  font-weight: normal;
  cursor: pointer;
`;

const StyledFormGroup = styled(FormGroup)<{ isActive: boolean }>`
  margin-bottom: 0px !important;
  cursor: pointer !important;
`;

export const StyledLabel = styled(Label)<{
  centerTitle: boolean;
  isActive: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  color: ${({ isActive, siteConfig }) => getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })};
  display: inline-block;
  padding-bottom: 10px;
  font-size: ${globalStyles.fontSize.title};
  font-weight: 400;
  cursor: pointer !important;

  ${({ centerTitle }) =>
    centerTitle &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

const shouldAddAsterisk = (label: any, isRequired: boolean | undefined) => {
  if (!isRequired) {
    return false;
  }

  if (!isString(label)) {
    return true;
  }

  return label.charAt(label.length - 1) !== '*';
};
