import { Beneficiary } from 'beneficiaries/domain/beneficiary';
import { apiRequest, EmbedAuth, getApiHost, getBasePathname } from 'shared/api';
import { Environment } from 'models/environment';
import { Application, NetworkApplication } from 'applications/domain/application';
import { beneficiaryToNetwork } from 'beneficiaries/utils';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  applicationId: string;
  beneficiaries: Beneficiary[];
  environment: Environment;
}

export const updateApplicationBeneficiaries = async (params: Params) => {
  const { applicationId, auth, beneficiaries, environment, organizationId } = params;
  const basePathName = getBasePathname({
    organizationId,
    auth,
    clientAppAuth: true,
  });

  const response = await apiRequest({
    url: `${getApiHost({ environment })}${basePathName}/applications/${applicationId}/beneficiaries`,
    method: 'put',
    body: beneficiaries.map((beneficiary) => beneficiaryToNetwork(beneficiary)),
    auth,
  });

  const networkApplication: NetworkApplication = await response.json();

  const application = Application.fromNetwork(networkApplication);

  return application;
};
