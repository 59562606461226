import { Environment } from 'models/environment';
import { NetworkPaymentMethod, paymentMethodFromNetwork } from 'payment-methods/domain/payment-method';
import { apiRequest, AuthTypes, EmbedAuth, getApiHost } from 'shared/api';

interface Params {
  environment: Environment;
  organizationId: string;
  auth: EmbedAuth;
  policyId: string;
}

export const getPolicyPaymentMethod = async (params: Params) => {
  const { auth, environment, organizationId, policyId } = params;

  const url =
    auth.type === AuthTypes.JWT
      ? `/v1/insurance/organizations/${organizationId}/embed/policies/${policyId}/payment-method`
      : `/v1/insurance/policies/${policyId}/payment-method`;

  const response: Response = await apiRequest({
    url: `${getApiHost({ environment })}${url}`,
    method: 'get',
    auth,
  });

  const networkPaymentMethod: NetworkPaymentMethod = await response.json();

  const paymentMethod = paymentMethodFromNetwork(networkPaymentMethod);

  return paymentMethod;
};
