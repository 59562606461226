import { Colors } from 'rootstrap/global-styles/colors';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import styled from 'styled-components';

export const DescriptionContainer = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  removeMarginBottom: boolean;
}>`
  background-color: ${({ siteConfig }) => getColor({ siteConfig, color: 'backgroundHighlight' })};
  min-height: 100px;
  display: flex;
  margin-bottom: ${({ removeMarginBottom }) => (removeMarginBottom ? '0px' : '20px')};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DescriptionContent = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 812px;

  span {
    line-height: 30px;
  }

  @media ${devices.tablet} {
    // text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .description-header {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }

  .description-subheader {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.Subscript};
  }

  @media ${devices.tablet} {
    // text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
      line-height: 25px;
    }

    .description-header {
      font-weight: 600;
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
  }
`;
