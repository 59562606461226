import { globalStyles } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import styled from 'styled-components';

export const SplashContainer = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  font-size: 16px;
  display: grid;

  border: none;
  border-radius: 8px;

  @media ${devices.tablet} {
    border-radius: 20px 20px 0px 0px;
  }

  box-shadow: 0px 0px 5px #d6d6d6;

  @media ${devices.tablet} {
    box-shadow: none;
    margin-top: 0px;
    padding-top: 0px;
  }

  padding-top: 10px;
  padding-bottom: ${globalStyles.spacing.default};
  padding-left: ${globalStyles.spacing.default};
  padding-right: ${globalStyles.spacing.default};

  background: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;

  @media ${devices.mobileXS} {
    flex-direction: column;
  }
`;
