import { Policyholder } from "policyholder/domain/policyholder";
import { PersonalDetailsPrefillParams } from "shared/domain/prefill-values";

export const getPrefillValuesFromPolicyholder = (params: {
  policyholder?: Policyholder;
}): PersonalDetailsPrefillParams | undefined => {
  const { policyholder } = params;
  if (!policyholder) {
    return undefined;
  }
  return {
    policyholder_type: policyholder.type,
    first_name: policyholder.firstName,
    last_name: policyholder.lastName,
    email: policyholder.email,
    gender: policyholder.gender,
    country: policyholder.address?.country,
    identification_number: policyholder.identification?.number,
    identification_country: policyholder.identification?.country,
    registration_number: policyholder.registrationNumber,
    identification_expiration_date: policyholder.identification?.expirationDate?.format('YYYY-MM-DD'),
    identification_gender: policyholder.gender,
    date_of_birth: policyholder.dateOfBirth?.format('YYYY-MM-DD'),
    company_name: policyholder.companyName,
    identification_type: policyholder.identification?.type,
    contact_position: policyholder.contactPosition,
    company_website_url: policyholder.companyWebsiteUrl,
    subsidiary_companies: policyholder.subsidiaryCompanies,
    cellphone_country: policyholder.cellphone?.country,
    cellphone_number: policyholder.cellphone?.number,
    area_code: policyholder.address?.areaCode,
    suburb: policyholder.address?.suburb,
    address_line_1: policyholder.address?.line1,
    address_line_2: policyholder.address?.line2,
    address_opt_in:
      !!policyholder.address?.suburb ||
      !!policyholder.address?.city ||
      !!policyholder.address?.line1 ||
      !!policyholder.address?.line2 ||
      !!policyholder.address?.areaCode,
    city: policyholder.address?.city,
    date_of_establishment: policyholder.dateOfEstablishment?.format('YYYY-MM-DD'),
  };
};
