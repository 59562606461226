import { JSONObject } from "shared/utils";
import { BaseStoreModel, FormStoreModelList } from "../interfaces";
import { set, get } from 'lodash';
import { evaluateDisplayConditionsForOutputData } from "../root-schema-form";
import { sanitizeModel } from "policy-issuing/utils";

type FormData = JSONObject;


export const toOutputData = ({ formData, schema }: { formData: FormData; schema: FormStoreModelList[] }) => {
  const outputData = schema.reduce((acc, componentSchema) => {
    if (componentSchema.type === 'list') {
      const { components, outputPathList } = componentSchema;
      const fieldsLength = outputPathList ? formData[outputPathList] : [];

      if (Array.isArray(fieldsLength) && outputPathList) {
        fieldsLength.forEach((_, index: number) => {
          components.forEach((componentSchema) => {
            const shouldRenderComponent = evaluateDisplayConditionsForOutputData({
              displayConditions: componentSchema.displayConditions,
              formData,
              fieldIndex: index,
              parentKey: outputPathList,
            });

            const outputPath = getOutputPath({
              key: componentSchema.key as string,
              outputPath: componentSchema.outputPath
                ?.split('.')
                .filter((path) => path !== outputPathList)
                .join('.'),
            });

            const key = getFormKey(componentSchema);
            const getPath = `${outputPathList}[${index}].${key}`;
            const setPath = `${outputPathList}[${index}].${outputPath}`;
            const value = get(formData, getPath);

            if (shouldRenderComponent && value !== undefined) {
              set(acc, setPath, componentSchema.type === 'cellphone' ? sanitizeModel(value) : value);
            }
          });
        });
      }
    } else {
      const shouldRenderComponent = evaluateDisplayConditionsForOutputData({
        displayConditions: componentSchema.displayConditions,
        formData,
      });

      const outputPath = getOutputPath({
        key: componentSchema.key as string,
        outputPath: componentSchema.outputPath,
      });

      const key = getFormKey(componentSchema);
      const value = get(formData, key);
      if (shouldRenderComponent && value !== undefined) {
        return set(acc, outputPath, componentSchema.type === 'cellphone' ? sanitizeModel(value) : value);
      }
    }

    return acc;
  }, {});

  return outputData;
};

export const toInputData = (params: { formData?: FormData; schema: FormStoreModelList[]; context?: any }) => {
  const { schema, formData } = params;
  const outputData = schema.reduce((acc, componentSchema) => {
    if (Array.isArray(componentSchema.key)) {
      throw new TypeError('Unhandled array key');
    }

    const outputPath = getOutputPath({
      key: componentSchema.key,
      outputPath: componentSchema.outputPath,
    });
    const key = getFormKey(componentSchema);
    return outputPath ? { ...acc, [key]: get(formData, outputPath ? outputPath : key) } : acc;;
  }, {});

  return outputData as JSONObject;
};

export const getFormKey = (componentSchema?: BaseStoreModel) => {
  if (Array.isArray(componentSchema?.key)) {
    throw new TypeError('Unhandled array key');
  }

  return componentSchema?.outputPathList || componentSchema?.key || '';
};

export const getOutputPath = (params: { outputPath: string | undefined; key: string }): string => {
  const { key, outputPath } = params;
  if (outputPath) {
    return outputPath;
  }

  return key;
};
