import { DisplayConditions } from '../interfaces';
import { get } from 'lodash';
import { UseFormMethods } from 'react-hook-form';
import { JSONObject } from 'shared/utils';

type FormData = JSONObject;

export const displayConditionalsMap: {
  [key: string]: ({ formValue, conditional }: { formValue: any; conditional: DisplayConditions }) => boolean;
} = {
  '===': ({ formValue, conditional }) => {
    return formValue === conditional.value;
  },
  '!==': ({ formValue, conditional }) => {
    return formValue !== conditional.value;
  },
  '>=': ({ formValue, conditional }) => {
    if (isNaN(formValue)) return false;
    const formValueAsNumber = parseFloat(formValue);
    if (typeof conditional.value === 'number') {
      return formValueAsNumber >= conditional.value;
    }
    return false;
  },
  '<=': ({ formValue, conditional }) => {
    if (isNaN(formValue)) return false;
    const formValueAsNumber = parseFloat(formValue);
    if (typeof conditional.value === 'number') {
      return formValueAsNumber <= conditional.value;
    }
    return false;
  },
};

const getConditionFunc = (condition: DisplayConditions) => {
  const conditionFunc = displayConditionalsMap[condition.condition];

  if (conditionFunc === undefined) {
    throw new Error(`No conditional defined for condition: ${condition.condition}`);
  }

  return conditionFunc;
};

// TODO: Dedup with 'computeShouldRender' above.
export const formComputeShouldRender = ({
  displayConditions,
  form,
  secondaryStepStaticData,
}: {
  displayConditions: DisplayConditions[];
  form: UseFormMethods<FormData>;
  secondaryStepStaticData: FormData | undefined;
}): boolean => {
  return displayConditions.every((conditional) => {
    let formValue = form.watch(conditional.path);

    if (secondaryStepStaticData) {
      formValue = formValue !== undefined ? formValue : get(secondaryStepStaticData, conditional.path);
    }

    const checkCondition = getConditionFunc(conditional);
    const resultParent = checkCondition({ formValue, conditional });

    // TODO: conditional.add[0].path matches the componentSchema.key value, but the form uses the componentSchema.outputPath so checkCondition() always returns false
    if (conditional.and && conditional.or) {
      const resultAnd = formComputeShouldRender({ displayConditions: conditional.and, form, secondaryStepStaticData });
      const resultOr = formComputeShouldRender({ displayConditions: conditional.or, form, secondaryStepStaticData });
      return (resultParent && resultAnd) || resultOr;
    }

    if (conditional.and) {
      return (
        resultParent && formComputeShouldRender({ displayConditions: conditional.and, form, secondaryStepStaticData })
      );
    }

    if (conditional.or) {
      return (
        resultParent || formComputeShouldRender({ displayConditions: conditional.or, form, secondaryStepStaticData })
      );
    }

    return resultParent;
  });
};
